import frLocal from 'element-ui/lib/locale/lang/fr';
const fr = {
    ...frLocal,
    common: {
        datastatistics: 'Statistiques',
        Transactionstatistics: 'Statistiques des transactions',
        LabelOptions: "Options d'étiquetage",
        Orderdatastatistics: 'Statistiques des commandes',
        Hourlystatistics: 'par heure',
        Countbyday: 'par jour',
        Spain: 'Espagne',
        Italy: 'Italie',
        Germany: 'Allemagne',
        France: 'France',
        poland: 'Pologne',
        Netherlands: 'Pays-Bas',
        Britain: 'Grande-Bretagne',
        UnitedStates: 'États-Unis',
        Portugal: 'Portugal',
        SalesCountry: 'Pays de vente',
        query: 'Demande',
        Placinganorder: 'Quantité commandée',
        Orderamount: 'Montant de la commande',
        Numberofusersplacingorders: "Nombre d'utilisateurs passant des commandes",
        Ordertime: 'Heure de la commande',
        time: 'Heure',
        usermanagement: 'Gestion des utilisateurs',
        UserID_loginemail_registrationinviterID_phonenumber: "ID de l'utilisateur / email de connexion / ID de l'invité enregistré / numéro de téléphone mobile",
        country: 'Pays',
        Whethertosubscribe: "S'abonner ou non",
        Loginstatus: 'Statut de connexion',
        normal: 'Normal',
        Disabled: 'Désactivé',
        Createdon: 'Date de création',
        Lastlogintime: 'Heure de la dernière connexion',
        Reset: 'Réinitialisation',
        export: 'Exporter',
        UserID: "ID de l'utilisateur",
        Registeredcountry: "Pays d'enregistrement",
        mailbox: 'E-mail',
        Mobilephonenumber: 'Numéro de téléphone mobile',
        Patrioticcoin: 'Pièces Aigo',
        RegistrationInviterID: "ID d'invitation à l'inscription",
        brand: 'Marque',
        Creationtime: 'Heure de création',
        operation: 'Opération',
        check: 'Voir',
        orderform: 'Commandes',
        consultingservice: 'Demande',
        address: 'Adresse',
        LoginLog: 'Journal de connexion',
        Rechargepatrioticcoins: 'Recharger les pièces Aigo',
        Returnsandexchanges: 'Retours et échanges',
        evaluate: 'Tarif',
        Shippingaddress: "Recevoir l'adresse",
        shoppingcart: 'Panier',
        collection: 'Collecte',
        invoice: 'Facture',
        nickname: 'Surnom',
        nothing: 'Aucun',
        close: 'Fermer',
        source: 'Source',
        Subscriptiontime: "Heure de l'inscription",
        Thirdpartylogin: "Connexion d'un tiers",
        Bound: 'Lié',
        Unbound: 'Non lié',
        Invitationcode: "Code d'invitation",
        Inviteduser: 'Utilisateurs invités',
        Invitetoreceivepatrioticcoins: 'Inviter pour obtenir des pièces Aigo',
        Isthefirstorderfreeofshipping: 'Livraison gratuite ou non sur votre première commande',
        shopping: 'Achats',
        Collectproducts: 'Articles favoris',
        ShoppingCartItems: 'Articles du panier',
        PaymentOrder: 'Commandes payées',
        Ordercumulativeamount: 'Montant total de la commande',
        Lastordertime: 'Heure de la dernière commande',
        Returnandexchangeorders: "Commandes de retour et d'échange",
        Consultationfrequency: 'Fréquence des demandes',
        Numberofevaluations: "Nombre d'avis",
        Numberofapprovedevaluations: "Nombre d'avis approuvés",
        ShowCountry: 'Afficher le pays',
        name1: 'Nom',
        TaxID: 'Code fiscal',
        province: 'Province',
        city: 'Ville',
        Detailedaddress: 'Adresse détaillée',
        Housenumber: 'Numéro de maison',
        AddressAdditionalContent: "Information supplémentaire sur l'adresse",
        RecipientCompany: 'Société destinataire',
        Defaultshippingaddress: "Adresse d'expédition par défaut",
        Defaultinvoiceaddress: 'Adresse de facturation par défaut',
        ProductID1: 'ID du produit',
        Productname1: 'Nom du produit',
        CollectionPrice: 'Prix de la collecte',
        Currentprice: 'Prix actuel',
        Collectiontime1: 'Heure de la collecte',
        Additionalpurchaseprice: 'Le prix des articles ajoutés à la liste de souhaits',
        Additionalpurchasetime: "L'heure d'ajout des articles à la liste de souhaits",
        Listingstatus: 'Statut du rayon',
        Listed: 'Mis en rayon',
        Notlisted: 'Non disponible',
        Numberofpatrioticcoins: 'Nombre de pièces Aigo',
        Enteraquantitybetween0and1000: 'Saisissez une quantité comprise entre 0 et 1000',
        Remarks: 'Remarques',
        cancel: 'Annuler',
        UserDetails: "Détails de l'utilisateur",
        Areyousuretoexportthefile: "Voulez-vous confirmer l'exportation du fichier, voulez-vous continuer ?",
        prompt: 'Indice',
        Exportsuccessful: 'Exportation réussie',
        today: "Aujourd'hui",
        Lastweek: 'Dernière semaine',
        Thismonth: 'Ce mois-ci',
        Lastmonth1: 'Le mois dernier',
        thisyear: 'Cette année',
        Inputcannotbeempty: "L'entrée ne peut pas être vide",
        Pleaseenterapositiveinteger: 'Veuillez entrer un nombre entier positif',
        Pleaseenterthequantityofpatrioticcoins: 'Veuillez entrer le nombre de pièces Aigo',
        Pleaseenteracomment: 'Veuillez saisir une remarque',
        Pleaseselectacountry: 'Veuillez sélectionner un pays',
        Successfullysubmitted: 'Soumis avec succès',
        Nopatrioticcoinrulesconfigured: "Aucune règle de pièces Aigo n'a été configurée.",
        Rechargefailed: 'Echec de la recharge',
        Savefailed: 'Echec de la sauvegarde',
        ordermanagement: 'Gestion des commandes',
        OrderList: 'Liste des commandes',
        Ordernumber: 'Numéro de commande',
        Paymentcode: 'Code de paiement',
        barcode: 'Code-barres',
        Pleaseselectasource: 'Veuillez sélectionner la source',
        OrderStatus: 'Statut de la commande',
        Unpaid: 'Non payé',
        Paid: 'Payé',
        Shippable: 'Disponible pour livraison',
        Shipped: 'Expédié',
        complete: 'Complet',
        Pleaseselectpaymentmethod: 'Veuillez sélectionner le mode de paiement',
        Shipmentstatus: "Statut de l'expédition",
        Notshipped: 'Non expédié',
        Partialshipment: 'Partiellement expédié',
        Returned: 'Renvoyé',
        Remittanceconfirmation: 'Versement confirmé',
        Notremitted: 'Pas de versement',
        Unconfirmed: 'Non confirmé',
        adopt: 'Transmettre',
        Notpassed: 'Ne pas transmettre',
        Whethertomanuallyship: 'Livraison manuelle ou non',
        yes: 'Oui',
        no: 'Non',
        Originalordertype: 'Type de commande initiale',
        Presaleorder: 'Commande préalable',
        Officialorder: 'Commande officielle',
        Preorderstatus: 'Statut de la pré-commande',
        Deposittobepaid: 'Acompte à verser',
        Depositpaid: 'Acompte versé',
        Finalpaymenttobemade: 'Solde à payer',
        Finalpaymentpaid: 'Solde payé',
        Presalepaymentmethod: 'Le mode de paiement pour la prévente',
        Finaldeposit: "Paiement final de l'acompte",
        Fullpayment: 'Paiement intégral',
        Whetherthepresaleorderisconvertedtoformal: 'Pré-commande officielle ou non',
        Combinedorders: 'Commande combinée',
        to: "Jusqu'à",
        Startdate: 'Date de début',
        Enddate: 'Date de fin',
        Paymenttime: 'Heure de paiement',
        Pleaseenterthesource: 'Veuillez saisir la source',
        platform: 'Plateforme',
        Positiveconversionsingle: 'Transfert vers une commande officielle',
        Numberofproductmodels: "Nombre d'articles",
        activity: 'Activité',
        money: 'Montant',
        Paymentmethod: 'Le mode de paiement',
        Deliverytime: "Délai d'expédition",
        Whethertotransfertoformal: 'Convertir ou non à officiel',
        Tobereplied_consulted: 'à répondre/ consulté',
        detailed: 'Détails',
        Manualshipping: 'Livraison manuelle',
        Manuallogisticsshipment: 'Livraison logistique manuelle',
        logisticscompany: 'Société de logistique',
        Pleaseselectalogisticscompany: 'Veuillez sélectionner une société de logistique',
        Logisticstrackingnumber: 'Numéro de suivi logistique',
        SelectDateTime: "Sélectionnez la date et l'heure",
        Shippingremarks: "Remarques sur l'expédition",
        Pleasenote: 'Veuillez noter',
        confirm1: 'Confirmer',
        state: 'Statut',
        Pleaseselect: 'Veuillez sélectionner',
        Pleaseselectastatus: 'Veuillez sélectionner le statut',
        Homepagerecommendation: "Recommandation de la page d'accueil",
        Pleaseenterthelogisticstrackingnumber: 'Veuillez saisir le numéro de suivi',
        Pleaseselecttheshippingtime: "Veuillez sélectionner une heure d'expédition",
        edit: 'Editer',
        Successfullyshippedmanually: 'Livraison manuelle réussie',
        Remittanceconfirmationsuccessful: 'Paiement confirmé avec succès',
        confirmdeletion: 'Confirmation de la suppression',
        Successfullydeleted: 'Supprimer avec succès',
        Pleaseselectapresaleorder: 'Veuillez sélectionner une commande de prévente',
        Confirmtoconverttheorderintoaformalorder: 'Confirmation de la conversion de la commande en commande officielle',
        success: 'Succès',
        OrderData: 'Données de la commande',
        Areyousuretoexportthefileandcontinue: "Confirmer l'exportation du fichier ou non, continuer ou non",
        Basicinformationoftheorder: 'Informations de base sur la commande',
        Useremail: "E-mail de l'utilisateur",
        Documentremarks: 'Notes sur la commande',
        combination: 'Combinaison',
        PostalCode: 'Code postal',
        RecipientCompanyAddressAdditionalContent: "Société destinataire, information supplémentaire sur l'adresse",
        Invoiceaddress: 'Adresse de la facture',
        InvoiceStatus: 'Statut de la facture',
        Paymentinformation: 'Informations sur le paiement',
        Paymentstatus: 'Statut du paiement',
        coupon: 'Coupon',
        freight: 'Frais de transport',
        CouponName: 'Nom du coupon',
        Couponcode: 'Code du coupon',
        Paymentamount: 'Montant du paiement',
        Trackingnumber: 'Numéro de commande de suivi',
        Manualshippingoperator: "Opérateur d'expédition manuelle",
        Manualshippingoperationtime: "Heure de l'opération de livraison manuelle",
        Bankremittance: 'Virement bancaire',
        Remittancestatus: 'Statut du virement',
        Remittanceconfirmationoperator: 'Opérateur de confirmation de virement',
        Remittanceconfirmationoperationtime: 'Heure de confirmation du virement',
        Preorderinformation: 'Informations sur la commande de prévente',
        Converttoformaldocument: 'Transfert vers la commande officielle ou non',
        Positiveconversionsinglemode: 'Mode de transfert vers la commande officielle',
        automatic: 'Automatique',
        Manual: 'Manuel',
        Positiveconversionsingletime: 'Heure du transfert vers la commande officielle',
        category1: 'Catégorie',
        Subordernumber: 'Numéro de sous-commande',
        Paymentdeadline: 'Délai de paiement',
        Addremarks: 'Ajouter des remarques',
        ProductInformation: 'Informations sur le produit',
        Serialnumber: 'Numéro de série',
        name: 'Nom',
        VariantInformation: 'Informations sur les variantes',
        Pricing_unitprice: 'Prix (prix unitaire)',
        Sellingprice_unitprice: 'Prix de vente (prix unitaire)',
        quantity: 'Quantité',
        Totalprice: 'Prix total',
        MerchandiseSubtotal: 'Sous-total des marchandises',
        total: 'Total',
        CancelOrder: 'Annuler la commande',
        Areyousuretocancelthisorder: "Êtes-vous sûr d'annuler la commande ?",
        reason: 'Raison',
        Pleaseselectabrand: 'Veuillez sélectionner une marque',
        Pleaseselectacategory: 'Veuillez sélectionner une catégorie',
        Successfullyadded1: 'Ajouter avec succès',
        Addfailed: "Échec de l'ajout",
        Whethertosubmitdata: 'Soumettre les données ou non',
        Canceledsuccessfully: 'Annuler avec succès',
        Onlypendingorderscanbeshipped: 'Seules les commandes en cours peuvent être expédiées.',
        Existinglogisticstrackingnumber: 'La commande a un numéro de suivi logistique et ne peut pas être soumise.',
        Thereareonlytworemittancestatuses: "Le statut du virement ne peut être qu'approuvé ou non.",
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Seules les commandes remises peuvent être confirmées.',
        Orderdoesnotexist: "La commande n'existe pas.",
        Thisordercannotbecancelled: 'La commande ne peut pas être annulée.',
        Norulesconfigured: "Le tenancier actuel n'a pas configuré de règles de pièces Aigo.",
        Parametererror: 'Erreur de paramètres',
        Countrycodecannotbeempty1: 'Le code pays ne peut pas être vide.',
        Thecurrentcountrypricingisnotconfigured: "Le prix du pays actuel n'est pas configuré.",
        Thepriceconfigurationdoesnotexist: "La configuration du prix n'existe pas, veuillez configurer le prix.",
        Reasonforcancellationoforderisrequired: "Raison de l'annulation de la commande requise",
        Exportorderinformation: 'Exportation des informations relatives à la commande',
        OrderIDmustbepassed: "L'identifiant de la commande doit être transmis.",
        Youcanonlybatchprocessupto100itemsatatime: 'Traitement par lots de 100 commandes maximum à la fois',
        Notapresaleorder: "Le numéro de commande 101 n'est pas une pré-commande, veuillez d'abord le désélectionner.",
        Transferredtoofficial: "Le numéro de commande 101 a été transféré à une commande officielle, veuillez le désélectionner d'abord.",
        Ordernotpaid: "Le numéro de commande 101 n'est pas une commande payée, veuillez d'abord l'annuler.",
        Nonpresaleorderscannotbeoperated: 'Les commandes non pré-commerciales ne sont pas disponibles.',
        Transferredtoformalformandcannotbeoperated: 'Les commandes transférées ne sont pas disponibles.',
        Nonpaidorderscannotbeoperated: 'Les commandes non payées ne sont pas disponibles.',
        Orderremarksarerequired: 'Les notes de commande sont obligatoires.',
        Upto500wordscanbeentered: 'Maximum 500 caractères',
        common: 'au total',
        strip: 'enregistrements',
        goto: 'Aller à',
        page: 'Page',
        Returnandexchangeapplication: 'Demande de retour',
        type: 'Type',
        Return: 'Retour',
        Exchange: 'Échange',
        Auditstatus: "Statut de l'avis",
        Approved: 'Approuvé',
        Auditfailed: 'Échec',
        Inprogress: 'en cours',
        Auditended: "Fin de l'audit",
        Processingstatus: 'Statut du traitement',
        Completed: 'Terminé',
        Applicationtime: "Durée de l'application",
        Aftersalestype: 'Type de service après-vente',
        ApplicationDescription: 'Description de la demande',
        Numberofexplanatoryimages: "Description du nombre d'images",
        Illustrativeimage: 'Description des images',
        Returnlogisticstrackingcode: 'Code de suivi logistique du retour',
        CanIcontinuetoapply: 'Poursuite ou non de la demande',
        Auditing: 'Audit',
        details: 'Détails',
        handle: 'Processus',
        Add1: 'Ajouter',
        ApplicationDetails: 'Détails de la demande',
        Exchangeitem: 'Échange de marchandises',
        Returnedgoods: 'Retourner des marchandises',
        Audittime: "Heure de l'avis",
        Reviewedby: 'Examiné par',
        result: 'Résultat',
        Failed: 'Non approuvé',
        Thereplycontentwillbedisplayedtotheuser: 'La réponse sera affichée aux utilisateurs.',
        Submit: 'Soumettre',
        Replycontent: 'Le contenu de la réponse',
        Operator: 'Opérateurs',
        Pleaseenterthereason: 'Veuillez saisir un motif',
        Pleasechoosewhethertocontinueapplying: 'Veuillez indiquer si vous pouvez continuer la demande.',
        Pleaseselectaresult: 'Veuillez sélectionner le résultat',
        Successfullymodified: 'Modification réussie',
        Verificationfailed: 'Échec de la vérification',
        Datadoesnotexist: "Les données n'existent pas.",
        OrderNumber_UserID: "Numéro de commande/identifiant de l'utilisateur",
        ReplyStatus: 'Statut de la réponse',
        Replied: 'Répondu',
        Noresponse: 'Pas de réponse',
        MyOrder: 'Ma commande',
        refund: 'Remboursement',
        Canwedeliverontheagreeddate: 'Pouvez-vous livrer à la date convenue ?',
        Modifyshippingaddress: "Changement d'adresse de livraison",
        Otherissuesrelatedtotransportation: "Autres questions concernant l'expédition",
        Receivedincorrectproduct: 'Réception du mauvais produit',
        Receiveddamagedproductsandproductswithqualityissues: 'Réception de produits endommagés ou présentant des problèmes de qualité',
        RegulationsonReturnsandRefunds: 'Réglementation sur les retours et les remboursements',
        Applyforinvoice: 'Demander une facture',
        productdetails1: 'Détails du produit',
        Thankyouletter: 'Lettre de remerciement',
        Otherissues: 'Autres questions',
        Respondent: 'Réponse par',
        Consultationdate: 'Date de la demande',
        content: 'Contenu',
        explain: 'Description',
        Consultationtime: 'Heure de la consultation',
        Replytime: 'Heure de la réponse',
        reply: 'Réponse',
        OrderInformation: 'Informations sur la commande',
        Consultationcontent: 'Contenu de la demande',
        Consultationinstructions: 'Instructions pour la demande',
        EnterReply: 'Saisir une réponse',
        Consultationrecords: 'Historique de la demande',
        Replycontentisrequired: 'Le contenu de la réponse est requis.',
        Replysuccessful: 'Répondre avec succès',
        Successfullyedited: 'Modifier avec succès',
        Ordernumber_UserID_Taxnumber: 'Numéro de commande/identifiant utilisateur/code fiscal',
        Unaudited: 'Non audité',
        Passed: 'Approuvé',
        Notuploaded: 'Non téléchargé',
        Uploaded: 'Téléchargé',
        AuditDescription: 'Instructions pour les avis',
        download: 'Télécharger',
        Applying: 'Demande en cours',
        Enterthereason: "Entrez la raison, vous devez renseigner la raison si la demande n'est pas approuvée.",
        Thereasonfornotpassingwillbedisplayedtotheuser: "Les raisons de l'échec seront indiquées à l'utilisateur.",
        Pleaseenterareply: 'Veuillez saisir une réponse',
        Auditsuccessful: 'Révision réussie',
        ProductManagement: 'Gestion des produits',
        Categorymanagement: 'Gestion des catégories',
        Categoryname1: 'Nom de la catégorie',
        Isthehomepagedisplayed: "Affichage ou non de la page d'accueil",
        Enableornot: 'Activer ou non',
        search: 'Recherche',
        Directoryhierarchy: 'Niveau du catalogue',
        Category: 'Catégorie',
        sort: 'Trier par',
        picture: 'Image',
        Updatetime: 'Temps de mise à jour',
        Multilingualconfiguration: 'Configuration multilingue',
        Relatedimportantparameters: 'Paramètres importants associés',
        Associatesearchparameters: 'Paramètres de recherche associés',
        delete: 'Supprimer',
        SelectAll: 'Sélectionner tout',
        Imagesize: "Taille de l'image",
        icon: 'Icônes',
        intotal25: '25 éléments au total',
        Gotopage1: 'Aller à la page 1',
        Chinese: 'Chinois',
        English: 'Anglais',
        German: 'Allemand',
        Polishlanguage: 'Polonais',
        Italian: 'Italien',
        French: 'Français',
        Dutchlanguage: 'Néerlandais',
        Spanish: 'Espagnol',
        Pleaseenteraname: 'Veuillez saisir un nom',
        Areyousureyouwanttodeleteit: 'Voulez-vous supprimer ?',
        Categoryassociation: 'Association de catégories',
        PrimaryKeyID: 'ID de la clé primaire',
        CatalogID: 'ID du catalogue',
        Addtime: 'Ajouter une heure',
        Relatedcategories: 'Catégorie associée',
        Selectmaincategory: 'Sélectionner la catégorie principale',
        addassociations: 'Ajouter une association',
        Maincategory: 'Catégorie principale',
        ProductCatalogID: 'ID du catalogue de produits',
        RelatedCategoryName: 'Nom de la catégorie associée',
        AddAssociatedCategory: 'Ajouter une catégorie associée',
        Pleaseselectacategoryfirst1: "Veuillez d'abord sélectionner une catégorie",
        Productvariants: 'Variante du produit',
        ID: 'ID',
        Add: 'Ajouter',
        Productquantity: 'Nombre de produits',
        Selectvariant: 'Sélectionner une variante',
        Selectparameters: 'Sélectionner les paramètres',
        commodity: 'Produit',
        Selectproduct: 'Sélectionner le produit',
        Colortemperature: 'Température de couleur',
        frequency: 'Fréquence',
        power: 'Puissance',
        style: 'Style',
        MoveDown: 'Déplacer vers le bas',
        MoveUp: 'Déplacer vers le haut',
        Selectvariantparameters: 'Sélectionner les paramètres de la variante',
        SKU_EAN_Encoding: 'SKU/EAN/code',
        Pleaseselectparameters: 'Veuillez sélectionner les paramètres',
        Countrieslisted: 'Pays où les produits sont mis en rayon',
        ProductID: 'ID du produit',
        Productimage1: 'Image du produit',
        ProductName: 'Nom du produit',
        VariantManagement: 'Gestion des variantes',
        Class: 'Catégorie',
        Selected: 'Sélectionné',
        Thereiscurrentlynodataavailable: 'Aucune donnée disponible',
        Variantparameters: 'Paramètres de la variante',
        VariantName: 'Nom de la variante',
        CustomName: 'Nom personnalisé',
        Batchlisting: 'Mise en rayon de lots',
        Pleaseselectaproduct: 'Veuillez sélectionner les produits',
        Batchdelisting: 'Suppression en masse de la liste',
        Batchsearch: 'Recherche de lots',
        Enterproductbarcodeandsubmitforaddition: 'Saisir le code-barres du produit pour soumettre des ajouts, plusieurs codes-barres doivent être saisis sur une nouvelle ligne.',
        Enterproductbarcode: "Saisir le code-barres du produit, plusieurs codes-barres doivent être saisis sur une nouvelle ligne jusqu'à 500.",
        Pleaseenterbarcode: 'Veuillez saisir le code-barres',
        Editlistingcountry: 'Modifier les pays où les produits sont mis en rayon',
        Pleasecompletetheproductinformationbeforelistingit: 'Veuillez compléter les informations sur le produit avant de le mettre en rayon.',
        ProductTitle: 'Titre du produit',
        ProductLargePicture: 'Image du produit',
        commodityprice: 'Prix du produit',
        Confirmtheexecutionofbatchdelisting: 'Confirmer la suppression du lot',
        EditCategory: 'Modifier la catégorie',
        SynchronizeSKU: 'Synchroniser les UGS (SKUs)',
        ConfirmsynchronizationofSKU: 'Confirmer la synchronisation des UGS (Unités de Gestion de Stock)',
        SynchronizationrequiresdockingwithEBP: "Comme la synchronisation doit être effectuée avec EBP, pour éviter les erreurs, la synchronisation par lots peut être effectuée une fois toutes les 30 minutes. Veuillez patienter car la synchronisation sera effectuée de manière asynchrone après confirmation et les UGS (Unités de Gestion de Stock) seront affichées dès qu'elles seront disponibles.",
        Synccompleted: 'Synchronisation terminée',
        Setlabel: 'Configurer les étiquettes',
        label: 'Etiquettes',
        SynchronizeEBPproductinformation: 'Synchronisation des informations sur les produits EBP',
        Afterturningonsynchronization: 'Lorsque la synchronisation est activée, les informations suivantes concernant les produits EBP sont synchronisées par UGS de produit.',
        Synchronizeproducttitlesbylanguage: 'Titre du produit : synchronisation du titre de la langue correspondante par langue',
        Synchronizeproductimagesbylanguage: "Image du produit : synchronisation de l'image dans la langue correspondante par langue, lecture du tri des images EBP, la première image EBP est automatiquement définie comme image principale.",
        Synchronizeproductimagesandtextbylanguage: "Détails de l'image : synchronisation par langue pour la langue correspondante",
        Productkeywords: 'Mots-clés du produit',
        Automaticallysynchronizeornot: 'Synchronisation automatique ou non',
        Doyouwanttoforcesynchronization: 'Synchronisation forcée ou non',
        tenant: 'Locataires',
        Noinventorydisplay: "Pas d'affichage de l'inventaire",
        Synchronizetoothertenants: "Synchronisation avec d'autres locataires",
        UpdateRecords: 'Mise à jour des enregistrements',
        Importtime: "Temps d'importation",
        number: 'Numéro',
        file: 'Fichier',
        UpdateResults: 'Mettre à jour les résultats',
        Downloadfiles: 'Télécharger le fichier',
        Isthereafixedprice: 'Y a-t-il un prix fixe ?',
        Whethertopromoteornot: 'En vente ou non',
        Inpromotion: 'En vente',
        whole: 'Tous',
        Fixedprice1: 'Prix fixe',
        Lowestprice: 'Prix le plus bas',
        fixedprice: 'Prix fixe',
        Promotionprice_unitprice: 'Prix promotionnel (prix unitaire)',
        price1: 'Prix de vente',
        inventory: 'Inventaire',
        Inventorybottomline: "Bilan de l'inventaire",
        AutomaticallysynchronizeEBP: "Synchronisation automatique ou non de l'EBP",
        ForcesynchronizationofEBP: 'Forcer ou non la synchronisation de EBP',
        Presaleornot: 'Prévente ou non',
        Evaluation_Shown_total: 'Commentaires (affichés/total)',
        coding: 'Code',
        ProductDetails: 'Détails du produit',
        EditProduct: 'Modifier le produit',
        Basicinformation: 'Informations de base',
        price: 'Prix',
        Pleaseentertheproducttitle: 'Veuillez saisir le titre du produit',
        Sizeinformation: 'Informations sur la taille',
        weight: 'Poids',
        volume: 'Volume',
        Salesmultiples: 'Multiplicateur de vente',
        Numberofitems: "Nombre d'articles",
        EditFixedPrice: 'Modifier le prix fixe',
        Largeimage: 'Grande image',
        Uploadimages: 'Télécharger une image',
        UploadVideo: 'Télécharger une vidéo',
        Batchdisplay: 'Affichage par lot',
        BatchCloseDisplay: 'Affichage de fermeture du lot',
        Batchdeletion: 'Effacer le lot',
        Synchronizetootherlanguages: "Synchronisation avec d'autres langues",
        Selectfrompicturelibrary: "Sélectionner dans la galerie d'images",
        Pleaseenterthemaintext: 'Veuillez saisir le corps du texte',
        Hideemptyparameters: 'Masquer les paramètres vides',
        Detailedcontentofgraphicsandtext: "Veuillez d'abord saisir les détails graphiques en anglais",
        parameter: 'Paramètre',
        Parametervalue: 'Valeur du paramètre',
        ParameterProperties: 'Propriétés du paramètre',
        Pleaseenteraparametername: 'Veuillez saisir le nom du paramètre',
        Pagekeywords: 'Mot-clé de la page',
        PageDescription: 'Description de la page',
        Pleaseentercontent: 'Veuillez saisir le contenu',
        PictureLanguage: "Langue de l'image",
        Pictures_Videos: 'Image/Vidéo',
        ImageID: "ID de l'image",
        language: 'Langue',
        size: 'Taille',
        Selectapictureorvideo: "Veuillez d'abord sélectionner l'image ou la vidéo dans la galerie !",
        Selectionsuccessful: 'Sélection réussie',
        Isitthemainimage: 'Image principale ou non',
        Displayornot: 'Afficher ou non',
        Whethertodisplayall: 'Tout afficher ou non',
        Areallclosed: 'Fermer tout ou non',
        Confirmdeletionoftheselectedpicture_video: "Confirmer la suppression de l'image/vidéo sélectionnée",
        ProductReview: 'Commentaires sur le produit',
        PromotionName_ProductID_Barcode: 'Nom de la promotion/ID du produit/code-barres',
        PromotionCountry: 'Pays de la promotion',
        Activitystatus: "Statut de l'événement",
        begininaminute: 'Commence bientôt',
        Ended: 'Terminé',
        Isitaflashkill: "S'agit-il d'une promotion limitée ?",
        PromotionName: 'Nom de la promotion',
        CommentID: 'ID du commentaire',
        RatingStarRating: 'Étoiles de notation',
        star0: 'étoile',
        star1: '1 étoile',
        stars2: '2 étoiles',
        stars3: '3 étoiles',
        stars4: '4 étoiles',
        stars5: '5 étoiles',
        Commentstartdate: 'Date de début des commentaires',
        CommentEndDate: 'Date de fin des commentaires',
        Batchreview: 'Revue de lot',
        Productimage: 'Images du produit',
        Evaluationcontent: 'Contenu du commentaire',
        video: 'Vidéo',
        ReviewerId: "Id de l'auteur de l'avis",
        user: 'Utilisateur',
        Pendingreview: 'A revoir',
        Evaluationtime: 'Heure du commentaire',
        ReviewerName: "Nom de l'auteur de l'avis",
        Numberofselectedcomments: "Nombre d'avis sélectionnés",
        Picturepreview: "Aperçu de l'image",
        VideoPreview: 'Aperçu de la vidéo',
        Lastmonth: 'Dernier mois',
        LastThreeMonths: 'Trois derniers mois',
        Areyousuretodelete: 'Confirmer la suppression ou non',
        Pleaseselectacomment: 'Veuillez sélectionner un avis',
        Amazon: 'Amazon',
        Aigostarbrandnetwork: 'Réseau de marques Aigostar',
        marketingmanagement: 'Gestion du marketing',
        Productrecommendations: 'Recommandation de produit',
        Recommendedlocation: 'Recommander un lieu',
        Categoryrecommendation: 'Recommandation de catégorie',
        Recommendationmodule: 'Recommander un module',
        NewProductZone: 'Nouvelles arrivées',
        HotSellingZone: 'Produits chauds',
        Recommendedforyou1: 'Recommandé pour vous',
        Numberofproducts: 'Nombre de produits',
        Addproduct: 'Ajouter un produit',
        DirectoryName: 'Nom du catalogue',
        title: 'Titre',
        ContentTitle: 'Titre du contenu',
        EnablePlatform: 'Activer la plateforme',
        DisplayPlatform: 'Afficher la plateforme',
        catalogue: 'Catalogue',
        JumpAddress: "Aller à l'adresse",
        PageTitle: 'Titre de la page',
        CategoryName: 'Nom de la catégorie',
        Cancelleddeletion: 'Suppression annulée',
        Pleaseselectacategoryfirst: "Veuillez d'abord sélectionner une catégorie.",
        TheEnglishcategorynamecannotbeempty: 'Le nom de la catégorie en anglais ne peut pas être vide.',
        Successfullysaved: 'Sauvegardé avec succès',
        DeleteError: 'Erreur de suppression',
        Apieceofcontentalreadyexists: 'Un contenu existe déjà.',
        Pleaseselecttherecommendedlocation: 'Veuillez sélectionner un lieu recommandé.',
        Pleaseselecttherecommendedmodule: 'Veuillez sélectionner un module de recommandation.',
        remove: 'Supprimer',
        Successfullyadded2: 'Ajouté avec succès',
        Areyousuretoremovethisproduct: 'Êtes-vous sûr de vouloir supprimer ce produit ?',
        Successfullyremoved: 'Supprimé avec succès',
        Dataalreadyexists: 'Les données existent déjà.',
        PortfolioManagement: 'Gestion de portefeuille',
        Combinationname: 'Nom de la combinaison',
        Remainingquantity_combinedquantity: 'Quantité restante / Quantité combinée restante',
        starttime: 'Heure de début',
        Endtime: 'Heure de fin',
        Creator: 'Créé par',
        NationalSDPDictionary: 'Dictionnaire national sdp',
        Combinationtime: 'Heure de combinaison',
        Combinationquantity: 'Quantité de combinaisons',
        CanIusecoupons: 'Les coupons peuvent être ou non utilisés.',
        Mainimage: 'Image principale',
        Setcombinationdiscount: 'Fixer une remise combinée',
        Combinationprice: 'Prix de la combinaison',
        Combinedtotalprice: 'Prix total de la combinaison',
        Isitontheshelf: 'Est-il en rayon ?',
        Selectupto5: "Sélectionner jusqu'à 5",
        Promotionprice1: 'Prix promotionnel',
        Pleaseenterthecombinationquantity: 'Veuillez saisir le nombre de combinaisons.',
        Pleaseenteracombinationname: 'Veuillez saisir un nom de combinaison.',
        Upto100wordscanbeentered: "Entrez jusqu'à 100 caractères.",
        Pleaseselectadaterange: 'Veuillez sélectionner une plage de dates.',
        Pleaseselectastartdate: 'Veuillez sélectionner une date de début.',
        Pleaseselecttheenddate: 'Veuillez sélectionner une date de fin.',
        Pleasechoosewhethertousecoupons: "Veuillez choisir d'utiliser ou non le coupon.",
        Pleaseselect25items: 'Sélectionnez 2 à 5 articles',
        Pleaseselectaproductthathasbeenlisted: 'Veuillez sélectionner un article qui a été mis en rayon.',
        Inventoryofselecteditems: "Le multiplicateur stock/ventes de l'article sélectionné ne peut être inférieur à la quantité d'activité.",
        Combinationpricecannotbeempty: 'Le prix de la combinaison ne peut pas être vide.',
        Caninput0andpositiveintegers: "0 et les nombres entiers positifs peuvent être saisis, les symboles ne peuvent être saisis que sous forme de % et de ., jusqu'à deux décimales.",
        Presaleproductscannotbeadded: "Il s'agit d'un article en prévente qui ne peut pas être ajouté à une combinaison.",
        Pleaseselectaproductfirst: "Veuillez d'abord sélectionner un article.",
        Freeshippingonfirstorder: 'Livraison gratuite lors de votre première commande',
        Firstfreeshippingitem: 'Livraison gratuite des produits sur votre première commande',
        SelectedProduct: 'Produit sélectionné',
        Between1and50charactersinlength: 'Longueur entre 1 et 50 caractères',
        Thisoperationwillremovethefile: 'Cette opération va supprimer le fichier, voulez-vous continuer ?',
        Brandandcountrycannotbeempty: 'La marque et le pays ne peuvent pas être vides.',
        Pleaseselectatime: 'Veuillez sélectionner une heure',
        Editedsuccessfully: 'Modifié avec succès !',
        Shoppingrebate: 'Rabais sur les achats',
        Rebateratio: 'Pourcentage de remise',
        Rebatestarttime: 'Heure de début de la remise',
        Rebateendtime: 'Heure de fin de la remise',
        Rebatetime: 'Heure de la remise',
        doyouwanttodelete2: 'Supprimer ou non',
        ActivityTopic: "Sujet de l'événement",
        ActivityName: "Nom de l'événement",
        Whethertodisplayonthehomepage: "Affichage ou non sur la page d'accueil",
        QuerySearch: 'Recherche',
        TopicName: 'Nom du sujet',
        Activitytime: "Heure de l'événement",
        Richtextornot: 'Texte enrichi ou non',
        ActivityDescription: "Description de l'événement",
        SpecialSEO: 'Référencement (SEO) thématique',
        LogisticsPricing: 'Prix de la logistique',
        Basicfreight: "Tarifs d'expédition de base",
        Freeshippingprice: "Tarifs d'expédition gratuite",
        Deliverytimeprompt: 'Conseils sur les délais de livraison',
        Receivingtimeprompt: "Rappel de l'heure de réception",
        PricingSettings: 'Paramètres de tarification',
        Freightstandard: "Standard d'expédition",
        Freightallocation: "Configuration de l'expédition",
        FreightType: "Type d'expédition",
        Freeshipping: 'Expédition gratuite',
        Configureshippingcosts: 'Configurer les frais de port',
        Fullreductionoffreight: 'Remboursement des frais de port',
        Orderamountfull: 'Le montant de la commande atteint',
        Receivingandshippingreminder: "Rappel de réception et d'expédition",
        link: 'Lien',
        confirm: 'Confirmer',
        Cannotbe0: 'Ne peut être 0',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Seules deux décimales peuvent être saisies.',
        Pleaseinputthenumbercorrectly: 'Veuillez saisir le numéro correct.',
        Pleaseenterthecountry: 'Veuillez saisir le pays.',
        Pleaseselectshippingcost: "Veuillez sélectionner les frais d'expédition.",
        Pleasecompletetheshippingcostconfiguration: "Veuillez compléter la configuration de l'envoi.",
        Pleaseenteradeliveryprompt: 'Veuillez saisir un rappel de réception.',
        Pleaseenteralink: 'Veuillez saisir un lien',
        Addlogisticsconfiguration: 'Ajouter une configuration logistique',
        Editlogisticsconfiguration: 'Modifier la configuration logistique',
        Pleaseenterthelogisticscompany: 'Veuillez saisir une société de logistique',
        Editingfailed: 'Echec de la modification',
        Addlogisticscompany: 'Ajouter une société de logistique',
        Pleaseuploadtheicon: 'Veuillez télécharger une icône',
        Pleaseenterthelinkcorrectly: 'Veuillez saisir le lien correctement',
        Successfullyadded: 'Ajouté avec succès',
        Editlogisticscompany: "Modifier l'entreprise de logistique",
        Thisisalreadythefirstitemmoveupfailed: 'Le premier élément est déjà présent, le déplacement vers le haut a échoué',
        Thisisalreadythelastitemmovedownfailed: 'Déjà le dernier article, le déplacement vers le bas a échoué',
        Costprice: 'Prix de revient',
        taxrate: "Taux d'imposition",
        commission: 'Commissions',
        Grossmargin: 'Marge brute',
        doyouwanttodelete: 'Le nombre renseigné est trop grand.',
        Pleaseselectapricetype: 'Veuillez sélectionner un type de prix.',
        Pleasecompletethetaxrateconfiguration: "Veuillez compléter la configuration du taux d'imposition.",
        Pleasecompletethecommissionconfiguration: 'Veuillez compléter la configuration de la commission.',
        PleasecompleteGrossmarginconfiguration: 'Veuillez compléter la configuration du taux brut.',
        Weightrange_start_kg: 'Fourchette de poids (début) kg',
        Weightrange_end_kg: 'Fourchette de poids (fin) kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'Un maximum de quatre décimales peut être saisi.',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'La valeur finale doit être supérieure ou égale à la valeur initiale.',
        Pleasecompletetheweightrangeconfiguration: "Veuillez compléter la configuration de l'échelle de poids.",
        Pleaseentertheshippingcost: "Veuillez saisir les frais d'expédition.",
        Addshippingstandard: "Ajouter une norme d'expédition",
        EditFreightStandard: "Modifier la norme d'expédition",
        Allocationofpatrioticcoins: 'Configuration des pièces Aigo (Aigo Coins)',
        RuleConfiguration: 'Configuration des règles',
        ObtainingPatrioticCoinObject: "Objets d'obtention de pièces Aigo",
        Rewardconditionsobtained: "Conditions d'obtention des récompenses",
        Obtainpatrioticcoinallocation: "Configuration de l'obtention de pièces Aigo",
        Inputquantity: 'Entrer la quantité',
        EnterName: 'Entrer le nom',
        Registrationsuccessfulnot: "Enregistrement réussi (sans code d'invitation)",
        Inviteduserplacesanorder: 'Utilisateur invité passant une commande',
        Registrationsuccessful: "Enregistrement réussi (avec code d'invitation)",
        Registeredaccount: 'Compte enregistré (pays enregistré)',
        Registrationsuccessfulnot1: "Enregistrement réussi du compte sans code d'invitation",
        Numberoflocalcurrencies: 'Les quantités indiquées ci-dessous sont exprimées dans la monnaie nationale du pays correspondant.',
        Inviteraccount: "Compte de l'invitant (fournir le compte du code d'invitation, le pays du code d'invitation)",
        Provideinvitationcode: "Fournir le code d'invitation à d'autres utilisateurs, les autres utilisateurs utilisent le code d'invitation pour s'inscrire et passer une commande avec succès (chaque utilisateur invité ne peut obtenir qu'un seul bonus).",
        Usinganinvitationcode: "Enregistrer un compte avec succès en utilisant le code d'invitation",
        ProductPromotion: 'Promotions des produits',
        discount: 'Réductions',
        Freeshippingornot: 'Livraison gratuite ou non',
        Secondkillsorting: 'Tri des promotions limitées',
        surplus: 'Restant',
        Totalnumber: 'Total',
        ImportFile: 'Importer des fichiers',
        Selectupto50: "Sélectionner jusqu'à 50",
        Setuppromotions: 'Définir la promotion',
        Nocountrieslisted: 'Pas de pays où les produits sont mis en rayon',
        Promotiontime: 'Durée de la promotion',
        Displaypromotionalprices: 'Affichage ou non du prix promotionnel',
        PromotionPrice: 'Prix promotionnel',
        Promotionquantity: 'Quantité promotionnelle',
        Doyousupporttheuseofcoupons: "Prise en charge ou non de l'utilisation de coupons",
        Purchaserestrictionsforthesameuser: "Restrictions d'achat pour le même utilisateur",
        Singleorderlimit: 'Limité à une seule commande',
        CurrentactivitypricesinSpain: "La marge brute sur le prix d'activité actuel en {countryCode} est de",
        SubmitConfirmation: 'Soumettre la confirmation',
        Productdistribution: 'Distribution du produit',
        DistributionName: 'Nom de la distribution',
        DistributionCountry: 'Pays de distribution',
        Distributionprice1: 'Prix de distribution',
        Distributiontime: 'Heure de distribution',
        DistributionPrice: 'Prix de distribution',
        Distributionquantity: 'Quantité distribuée',
        CopyConnection: 'Copier le lien',
        Successfullycopied: 'Copié avec succès',
        Countryofuse: "Pays d'utilisation",
        Discounttype: "Type d'offre",
        Fullreduction: 'Remboursement',
        Isthereafixedcode: 'Y a-t-il des codes fixes ?',
        Effective: 'Valable',
        Comingsoon1: 'Bientôt valable',
        Expired: 'Expiré',
        Overlayornot: 'Cumulé ou non',
        Istheexchangecodefixed: 'Fixer ou non le code de remboursement',
        Received: 'Reçu',
        Used: 'Utilisé',
        Availableamount: 'Montant disponible',
        Maximumdeductionamount: 'Montant maximum de la déduction',
        Canitbestacked: 'Cumulable ou non',
        Redemptioncode: 'Code de remboursement',
        Fullreductionamount: 'Montant de la remise en argent',
        Enterredemptioncode: 'Saisir le code de remboursement',
        Numberofredemptioncodes: 'Nombre de codes',
        Numberofusesbythesameuser: "Nombre d'utilisations pour le même utilisateur",
        Effectivetime: 'Durée de validité',
        Isitpossibletooverlaydistribution: 'Cumulable avec ou sans distribution',
        Canpromotionsbestacked: 'Cumulable avec ou sans promotion',
        Entercoupondescription: 'Saisir les instructions du bon',
        Pleaseenteradescription: 'Veuillez saisir une description',
        Pleaseselectadate: 'Veuillez sélectionner une date',
        Pleaseenterthenumberoftimesthesameuserhasusedit: "Veuillez saisir le nombre d'utilisations pour le même utilisateur.",
        Pleaseenteranavailableamountof0toindicateunlimited: "Veuillez entrer la quantité disponible de 0 pour indiquer qu'elle est illimité.",
        Pleaseentertheavailableamount: 'Veuillez saisir le montant disponible.',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: "Le nombre de codes de remboursement ne peut qu'être supérieur à l'original.",
        Pleaseenterthenumberofredemptioncodes: 'Veuillez saisir le nombre de codes de remboursement.',
        Applicableclassification: 'Catégories applicables',
        Applicableproducts: 'Produits concernés',
        Useordernumber: 'Utiliser le numéro de commande',
        Claimstatus: 'Etat de la collecte',
        Notclaimed: 'Non collecté',
        notused: 'Non utilisé',
        Usagestatus: "Statut d'utilisation",
        Collectiontime: 'Heure de collecte',
        Usagetime: "Durée d'utilisation",
        UsingUsers: 'Utilisateurs',
        BannerManagement: 'Gestion de la bannière',
        position: 'Emplacement',
        JumpType: 'Type de saut',
        Nojump: 'Pas de saut',
        Homepagerecommendationposition: "Page d'accueil en vedette",
        Homebanner: "Bannière d'accueil",
        Displaytime: "Durée d'affichage",
        JumpLink: 'Lien de saut',
        Suggestedsize: 'Taille suggérée',
        Enterjumplink: 'Saisir le lien de saut',
        Uploadingimagesonlysupports: 'Télécharger des images en jpg, png, gif, jpeg uniquement',
        Topwithlargenumbers: 'La valeur par défaut est 0. Plus le nombre est élevé, plus le classement est important.',
        Pleaseselectatleastonecountry: 'Veuillez sélectionner au moins un pays.',
        Boutiquerecommendationspace: 'Espace de recommandation de la boutique',
        Fullproductrecommendation: 'Recommandation de produits complets',
        popupnotification: 'Fenêtre pop-up',
        Addbanner: 'Ajouter une bannière',
        subscriptionmanagement: 'Gestion des abonnements',
        Emailaddress: 'Adresse électronique',
        Subscribing: 'Abonnement en cours',
        Canceled: 'Annulé',
        SubscriptionSource: "Source de l'abonnement",
        Unsubscribe: "Annuler l'abonnement",
        Resubscribe: 'Se réabonner',
        Pleaseselectatimerange: 'Veuillez sélectionner une période',
        WebsiteManagement: 'Gestion du site',
        informationservice: "Services d'information",
        New: 'Créer',
        Isitdisplayedatthebottomofthewebsite: 'A afficher ou non en bas du site',
        Pleaseselectaninformationservice: "Veuillez sélectionner le service d'information.",
        deleteconfirmation: 'Confirmer ou non la suppression du nœud et de toutes les informations sous le nœud',
        Pleaseenteracolumnname: 'Veuillez saisir un nom de colonne.',
        Thelengthcannotexceed50characters: 'La longueur ne doit pas dépasser 50 caractères.',
        ThelengthcannotexceedXcharacters: 'La longueur ne doit pas dépasser {num} caractères.',
        Pleaseentersorting: 'Veuillez saisir la commande.',
        Selectedcountrycontentisrequired: 'Le contenu du pays sélectionné est requis.',
        TitleName: 'Nom du titre',
        Chinesetitleisrequired: 'Le titre chinois est requis.',
        Selectedcountrytitleisrequired: 'Le titre du pays sélectionné est requis.',
        EditingInformationServices: "Modifier le service d'information",
        ColumnName: 'Nom de la colonne',
        Pleaseenterthecreationtime: "Veuillez saisir l'heure de création.",
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Veuillez indiquer si vous souhaitez afficher cela au bas du site.',
        ViewInformationServices: "Voir le service d'information",
        Modificationtime: 'Heure de modification',
        Countrycannotbeempty: 'Le pays ne peut pas être vide.',
        Titlecannotbeempty: 'Le titre ne peut pas être vide.',
        Richtextcontentonthepagecannotbeempty: 'Le contenu textuel riche de la page ne peut pas être vide.',
        Nationalconfiguration: 'Configuration du pays',
        currency: 'Devise',
        allocation: 'Configuration',
        Statuseditedsuccessfully: 'Statut édité avec succès',
        Areyousuretocontinuedeletingdata: 'Êtes-vous sûr de vouloir continuer à supprimer des données ?',
        WebsiteAddress: 'Adresse du site web',
        Contactinformation: 'Informations de contact',
        Customerserviceemail: 'E-mail du service clientèle',
        Socialmedia: 'Médias sociaux',
        Socialmediainformation: 'Informations sur les médias sociaux',
        Pleasechooseapaymentmethod: 'Veuillez sélectionner un mode de paiement.',
        Lengthgreaterthan20characters: 'Longueur supérieure à 20 caractères',
        Lengthgreaterthan100characters: 'Longueur supérieure à 100 caractères',
        Successfullydeletedinformation: 'Supprimer le message avec succès',
        Iconmustbeuploaded: "L'icône doit être téléchargée.",
        Successfullyaddedinformation: 'Ajouter un message avec succès',
        Successfullyeditedinformation: 'Modifier le message avec succès',
        Pleaseentertheemailcorrectly: 'Veuillez saisir correctement votre adresse électronique.',
        Thesizeoftheuploadedimagecannotexceed: "La taille de l'image téléchargée ne doit pas dépasser",
        Countryname: 'Nom du pays',
        Pleaseuploadpictures: 'Veuillez télécharger une image',
        NewCountryInformation: 'Créer de nouvelles informations sur le pays',
        EditCountryInformation: 'Modifier les informations sur le pays',
        Picturesmustbetransmitted: 'Les images doivent être téléchargées.',
        NewCountryInformationTableImageAddress: "Créer une nouvelle feuille d'information sur le pays, image et adresse",
        Operationsuccessful: 'Opération réussie',
        Euro: 'Euro',
        pound: 'Livre',
        Zloti: 'Zloty',
        Brandcannotbeempty: 'La marque ne peut être vide.',
        Thewebsiteaddresscannotbeempty: "L'adresse du site web ne peut pas être vide.",
        Contactinformationcannotbeempty: 'Les informations de contact ne peuvent pas être vides.',
        Thecustomerserviceemailaddresscannotbeempty: "L'adresse électronique du service clientèle ne peut être vide.",
        Medianamecannotbeempty: 'Le nom du média ne peut être vide.',
        Themediaiconcannotbeempty: "L'icône du média ne peut être vide.",
        Medialinkcannotbeempty: 'Le lien vers le média ne peut pas être vide.',
        Thecountryiconcannotbeempty: "L'icône du pays ne peut pas être vide.",
        Languagecannotbeempty: 'La langue ne peut pas être vide.',
        Currencycannotbeempty: 'La devise ne peut pas être vide.',
        Pagedescriptioncannotbeempty: 'La description de la page ne peut pas être vide.',
        Statusenable_disablecannotbeempty: 'UserID_IP_LoginDevice',
        SEOManagement: 'Gestion du référencement (SEO)',
        Import: 'Importer',
        DownloadImportTemplate: "Télécharger le modèle d'importation",
        PageURL: 'URL de la page',
        SEOdata: 'Données SEO',
        SEOConfigurationTemplate: 'Modèle de configuration SEO',
        Downloadsuccessful: 'Télécharger avec succès',
        SEOManagementImport: 'Importation de la gestion SEO',
        DragtheExcelfilehere_orclickonupload: 'Glissez-déposez le fichier Excel ici ou cliquez sur Upload.',
        Fileimportedsuccessfully: 'Fichier importé avec succès',
        Fileimportfailed_pleasecheckthefilecontent: "L'importation du fichier a échoué, veuillez vérifier le contenu du fichier.",
        Pleaseenterthelanguage: 'Veuillez saisir la langue.',
        Thelengthcannotexceed2characters: 'La longueur ne doit pas dépasser 2 caractères.',
        PleaseenterthepageURL: "Veuillez saisir l'URL de la page.",
        Thelengthcannotexceed100characters: 'La longueur ne doit pas dépasser 100 caractères.',
        Thelengthcannotexceed20characters: 'La longueur ne doit pas dépasser 20 caractères.',
        No_Illegalrowdata: 'La ligne ? de données est illégale',
        Failedtouploadfile_Pleasecheckthefilecontent: 'Le téléchargement du fichier a échoué, veuillez vérifier le contenu du fichier.',
        Namecannotbeempty: 'Le nom ne doit pas être vide.',
        PageURLcannotbeempty: "L'URL de la page ne doit pas être vide.",
        Thepagetitlecannotbeempty: 'Le titre de la page ne doit pas être vide.',
        SEOManagementImportTemplate: "Modèle d'importation pour la gestion du référencement (SEO)",
        Donotmodify: 'Veuillez ne pas modifier les trois premières lignes du titre du modèle.',
        Required: "Les champs marqués d'un " * ' sont obligatoires.',
        Brandandlanguagemustbefullycapitalized: 'La marque et la langue doivent être en majuscules.',
        SEOManagementExport: 'Exportation de la gestion SEO',
        Pagekeywordscannotbeempty: 'Les mots-clés de la page ne peuvent pas être vides.',
        Prohibitedsalesarea: 'Zones de vente interdites',
        Pleaseenterthepostalcodecorrectly: 'Veuillez saisir correctement votre code postal.',
        Pleasefillinthepostalcode: 'Veuillez saisir votre code postal.',
        Countrycodecannotbeempty: 'Le code pays ne doit pas être vide.',
        Postalcodecannotbeempty: 'Le code postal ne doit pas être vide.',
        Postalcodealreadyexists: 'Le code postal existe déjà.',
        Homepagearticle: "Articles de la page d'accueil",
        BrandStory: 'Histoires de la marque',
        SciencePopularizationZone: 'Zone de vulgarisation scientifique',
        Lightstripzone: 'Zone des bandes lumineuses',
        Pictureandtext: 'Image et texte',
        Comingsoon: 'Lancement à venir',
        Publishinginprogress: 'Lancement en cours',
        column: 'Colonne',
        Releasetime: 'Date de lancement',
        Pleaseenteratitle: 'Veuillez saisir un titre.',
        Pleaseselectacolumn: 'Veuillez sélectionner une colonne.',
        Pleaseselectatype: 'Veuillez sélectionner un type.',
        Patrioticcoinusagelog: "Journal d'utilisation des pièces Aigo",
        income: 'Revenus',
        expenditure: 'Dépenses',
        mode: 'Méthode',
        Orderexpenses: 'Dépenses de la commande',
        Orderrewards: 'Récompenses de la commande',
        OrderCloseReturn: 'Clôture et retour de la commande',
        other: 'Autre',
        Backendrecharge: "Recharge d'arrière-plan",
        Orderrebate: 'Remise de commande',
        Numberofchangesinpatrioticcoins: 'Nombre de pièces Aigo échangées',
        Numberofpatrioticcoinsafterchanges: 'Nombre de pièces Aigo après échange',
        CountryCode: 'Code pays',
        Changetime: "Heure de l'échange",
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: "Veuillez sélectionner l'historique de consommation de pièces Aigo de l'utilisateur.",
        WebsiteNavigation: 'Navigation sur le site',
        HomepageConfiguration: "Configuration de la page d'accueil",
        Navigationcontent: 'Contenu de la navigation',
        Recommendedforyou: 'Recommandé pour vous',
        SelectNavigation: 'Sélectionner la navigation',
        Pleasecheckfirst: 'Veuillez cocher la première case.',
        Logmanagement: 'Gestion du journal',
        UserID_IP_LoginDevice: "Identité de l'utilisateur/IP/dispositif de connexion",
        logintime: 'Heure de connexion',
        Loginmethod: 'Méthode de connexion',
        IPaddress: 'Adresse IP',
        Logindevice: 'Dispositif de connexion',

        //11-22
        AssociatedOrderNumber: 'Numéro de commande associé',

        recordId: "Identifiant d'enregistrement",

        UserAccess: 'Accès utilisateur',
        PleaseSelectTheStatisticalType: 'Veuillez sélectionner le type de statistiques.',
        StatisticalByDate: 'Statistiques par date',
        StatisticalBySource: 'Statistiques par source',
        Date: 'Date',
        Visits: 'Visites',
        NewVisitors: 'Nouveaux visiteurs',
        OrderStatistics: 'Statistiques sur les commandes',
        Sales: 'Ventes',
        TotalOrders: 'Total des commandes',
        AverageOrderAmount: 'Montant moyen des commandes',
        SynchronizeEBP: 'Synchroniser EBP',
        ParameterName: 'Nom du paramètre',
        OnTheShelf: 'En rayon',
        OfflineShoppingCart: "Panier d'achats hors ligne",
        AddToCart: 'Ajouter au panier',
        EnterCheckout: 'Passer à la caisse',
        UserLogin: "Login de l'utilisateur",
        UserRegistration: "Enregistrement de l'utilisateur",
        DifferentIPNumbersForAddingItemsToTheShoppingCart: "Différents numéros IP pour l'ajout d'articles au panier",
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Différents numéros IP pour accéder à la page de confirmation de la commande',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: "Différents numéros IP pour ceux qui ont terminé le processus de connexion après avoir été redirigés du panier d'achat hors ligne vers la page de connexion.",
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: "Différents numéros IP pour ceux qui ont terminé le processus d'enregistrement après avoir été redirigés du panier d'achat hors ligne vers la page d'enregistrement.",
        UserBehavior: "Comportement de l'utilisateur",
        Display: 'Afficher',
        DoNotDisplay: 'Ne pas afficher',
        AddToPresale: 'Ajouter à la prévente',
        CancelPresale: 'Annuler la prévente',
        PresaleConfiguration: 'Configuration de la prévente',
        OffTheShelf: 'Retiré du rayon',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Veuillez compléter les informations sur le produit avant de configurer la prévente.',
        SynchronizedEBPPrompts: "En raison de la nécessité d'une synchronisation avec EBP, la synchronisation par lots ne peut être effectuée qu'une seule fois en l'espace de X minutes afin d'éviter les erreurs. Après confirmation, la synchronisation est effectuée de manière asynchrone et l'UGS est affichée immédiatement après son obtention. Veuillez patienter.",
        PleaseWait: 'Veuillez patienter.',
        PleaseSelectATag: 'Veuillez sélectionner une étiquette.',
        Updating: 'Mise à jour',
        Abortive: 'Échec',
        DefaultRule: 'Règle par défaut',
        BottomInventory: 'Inventaire du bas',
        SynchronisedAlertsForGraphicDetails: 'Détails graphiques : Synchroniser les [mots-clés du produit] de la langue correspondante en fonction de la langue.',
        SynchronisedAlertsForLargeProductImages: "Image du produit : Synchronisation de l'image de la langue correspondante en fonction de la langue, lecture du tri des images EBP et définition automatique de la première image EBP comme image principale.",
        Account: 'Compte',
        Account1: 'Compte',
        EnterUpToNumItems: "Saisir jusqu'à {num} articles",
        SynchronizedSuccessfully: 'Synchronisation réussie',
        SuccessfullyListed: 'Listé avec succès',
        SearchSuccessful: 'Recherche réussie',
        EnterNewPassword: 'Entrer un nouveau mot de passe',
        OnlyNumbersAndLettersAreAllowed: 'Seuls les chiffres et les lettres sont autorisés',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: "Veuillez compléter la configuration de la prévente avant d'inscrire l'article en prévente.",
        ProductWeight: 'Poids du produit',
        ConfirmExecution: "Confirmation de l'exécution ?",
        ConfirmBatchListing: 'Confirmation de la liste des lots ?',
        Template: 'Modèle',
        PleaseSelectTheProductsToBeSynchronized: 'Veuillez sélectionner les produits à synchroniser.',
        ForcedSynchronisationDescription: 'La synchronisation forcée supprimera les données modifiées et les remplacera par les données synchronisées. La synchronisation ordinaire ne synchronisera pas les données modifiées du produit.',
        ConfirmSynchronizationExecution: "Confirmer l'exécution de la synchronisation",
        SynchronisationHasBeenSentPleaseWait: 'Le message de synchronisation a été envoyé, veuillez attendre patiemment que la synchronisation des données soit terminée.',
        ProhibitLogin: 'Interdire la connexion',
        IsTheUserLoggedIn: "L'utilisateur est-il connecté ?",
        Prohibit: 'Interdiction',
        ProhibitedSuccessfully: 'Interdit avec succès',
        EnableLogin: 'Activer la connexion',
        EnabledSuccessfully: 'Activé avec succès',
        OperationCanceled: 'Opération annulée',
        UserData: 'Données utilisateur.xlsx',
        GraphicDetails: 'Détails graphiques',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: "Ce produit a été défini comme un produit combiné et ne peut pas être défini pour la prévente. Veuillez d'abord retirer le produit de la combinaison.",
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: "Ce produit est un produit promotionnel et ne peut pas être mis en prévente. Veuillez d'abord annuler la promotion.",
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: "Ce produit est un produit de distribution et ne peut pas être mis en prévente. Veuillez d'abord annuler la distribution.",
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'Ce produit a déjà une activité de réduction complète et ne peut pas être ajouté.',
        ESNoPriceNotAvailableForPresale: "Ce produit n'a pas de prix en {Any} et ne peut pas être ajouté à la prévente. Veuillez d'abord supprimer le pays correspondant ou synchroniser le prix du pays correspondant avant d'ajouter des produits en prévente !",
        ConfirmSettingThisProductAsAPresaleItem: "Confirmer l'ajout de ce produit à la prévente ?",
        PresaleAddedSuccessfully: 'Prévente ajoutée avec succès',
        ConfirmSettingThisProductAsANonpresaleItem: "Confirmer que ce produit n'est pas en prévente ?",
        CancelPresaleSuccessful: 'Annulation de la prévente réussie',
        Preview: 'Aperçu',
        TheInventoryOfCurrentProducts0Continue: "L'inventaire du produit {stock} est de 0, continuer ?",
        TheInputContentCannotExceedCharacters: 'Le contenu de la saisie ne peut excéder {x} caractères.',
        PleaseSelectTheActivity: "Veuillez sélectionner l'activité",
        OrderDetails: 'Détails de la commande',
        LogisticsInformation: 'Informations logistiques',
        CancelRemark: 'Annuler la remarque',
        DepositAmount: 'Montant du dépôt',
        FinalPaymentAmount: 'Montant du paiement final',
        FullReductionDiscount: 'Réduction totale',
        YourBrowserDoesNotSupportVideoPlayback: 'Votre navigateur ne prend pas en charge la lecture des vidéos.',
        EditBanner: 'Modifier la bannière',
        AddBanner: 'Ajouter une bannière',
        UpdateTime: 'Heure de mise à jour',
        HomepageRecommendationPosition: "Position de la recommandation sur la page d'accueil",
        ActivityID: "ID d'activité",
        PleaseSelectThePosition: 'Veuillez sélectionner la position',
        PleaseSelectTheJumpType: 'Veuillez sélectionner le type de saut',
        PleaseEnterTheAssociatedID: "Veuillez saisir l'ID associé",
        TheUploadedImageSizeCannotExceed5MB: "La taille de l'image téléchargée ne doit pas dépasser {num} Mo !",
        OnlyMp4FormatIsSupportedForVideoUpload: 'Seul le format mp4 est pris en charge pour le téléchargement de vidéos.',
        TheUploadedVideoSizeCannotExceed500MB: 'La taille de la vidéo téléchargée ne doit pas dépasser {num} Mo !',
        PleaseUploadTheVideo: 'Veuillez télécharger la vidéo',
        PleaseEnterTheJumpLink: 'Veuillez saisir le lien de saut',
        PleaseEnterTheCorrectLink: 'Veuillez saisir le lien correct',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: "La vérification a échoué, veuillez d'abord saisir les champs obligatoires.",
        AddFailed: "L'ajout a échoué",
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: "C'est déjà le premier et le changement vers le haut a échoué.",
        PleaseEnterTheRebateRatio: 'Veuillez saisir le taux de remise',
        EditCoupon: 'Modifier le coupon',
        AddCoupon: 'Ajouter un coupon',
        IDCouponName: 'ID/nom du coupon/Code de remboursement',
        Random: 'Au hasard',
        Fixed: 'Fixe',
        AboutToTakeeffect: 'Sur le point de prendre effet',
        NotStackable: 'Non cumulable',
        Stackable: 'Cumulable',
        Frozen: 'Gelé',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Veuillez saisir le montant maximum de la déduction, 0 signifie illimité',
        UseUserID: "Utiliser l'ID utilisateur",
        UseOrderNumberExchangeCodeUserID: "Utiliser le numéro de commande/code d'échange/identifiant de l'utilisateur",
        PleaseEnterTheDiscount: 'Veuillez saisir la remise',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: "Seuls les chiffres de 0 à 100 peuvent être saisis, jusqu'à la première décimale.",
        PleaseEnterTheFullReductionAmount: 'Veuillez saisir le montant total de la réduction',
        PleaseEnterTheExchangeCode: "Veuillez saisir le code d'échange",
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: "Seuls des chiffres/lettres peuvent être saisis, au moins 3 chiffres, jusqu'à 20 chiffres.",
        PleaseSelectAFixedExchangeCode: "Veuillez sélectionner un code d'échange fixe",
        PleaseEnterTheMaximumDeductionAmount: 'Veuillez saisir le montant maximum de la réduction',
        PleaseSelectWhetherItCanbeStacked: "Veuillez indiquer s'il est possible de cumuler",
        WarehousingTime: "Temps d'entreposage",
        EnterActivityDescription: "Saisir la description de l'activité",
        ActivityTitleCannotBeEmpty: "Le titre de l'activité ne peut pas être vide.",
        TheTitleCannotExceed200Characters: 'Le titre ne peut pas dépasser {num} caractères.',
        PleaseEnterTheActivityDescription: "Veuillez saisir la description de l'activité.",
        ActivityTimeCannotBeEmpty: "L'heure de l'activité ne peut pas être vide.",
        ActivityDescriptionLimitOf10000Words: "La description de l'activité est limitée à {num} mots.",
        ActivityRecommendation: "Recommandation d'activité",
        Promotion: 'Promotion',
        EveryFull: 'Tous les {num} pleins',
        EnterAmount: 'Saisir le montant',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: "Seuls des nombres positifs peuvent être saisis, jusqu'à 2 décimales, ils ne peuvent pas commencer par 0.",
        PleaseEnterTheCorrectAmount: 'Veuillez saisir le montant correct',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Veuillez sélectionner les catégories ou les produits applicables',
        Rules: 'Règles',
        CopyLink: 'Copier le lien',
        DistributionNameProductIDBarcode: 'Nom de la distribution/identification du produit/code-barres',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: "Cette sous-fonction n'est pas disponible dans ce pays pour le moment, elle le sera ultérieurement.",
        PleaseEnterThePrice: 'Veuillez saisir le prix',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Calcul automatique après la saisie de la remise',
        PleaseEnterTheQuantity: 'Veuillez saisir la quantité',
        OrderRemarkLabel: 'Étiquette de remarque sur la commande',
        OrdersWorkWithInfluencers: 'Les commandes fonctionnent avec des influenceurs',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: "La marge bénéficiaire brute actuelle du prix de l'activité actuelle est la suivante",
        ThePriceCannotBeLowerThanTheLowestPrice: 'Le prix ne peut être inférieur au prix le plus bas :',
        Distribution: 'Distribution',
        IDName: 'ID/nom',
        ProductSelected: 'Produit sélectionné',
        ProductHasNoPricing: "Le produit n'a pas de prix",
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: "La marge brute actuelle du prix de l'activité actuelle est de {rate} %, êtes-vous sûr de vouloir soumettre ?",
        SubscriptionStatus: "Statut de l'abonnement",
        URLClickToCopyAddress: "URL (cliquer pour copier l'adresse)",
        URLSourceManagement: 'URL Gestion de la source',
        PleaseEnterTheURL: "Veuillez saisir l'URL",
        PleaseEnterEnglishOrNumbers: "Veuillez saisir l'anglais ou les chiffres",
        LengthCannotExceed1000Characters: 'La longueur ne peut excéder 1000 caractères',
        URLFixedAddressIsEmpty: "L'adresse fixe de l'URL est vide",
        cover: 'Couverture',
        PleaseEnterOrPasteTheVideoScript: 'Veuillez saisir ou coller le script de la vidéo',
        VideoScript: 'Script vidéo',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Veuillez télécharger un fichier vidéo ou remplir le script vidéo',
        PleaseEnterTheBrand: 'Veuillez saisir la marque',
        Portuguese: 'Portugais',
        Register: "S'inscrire",
        InviteUsers: 'Inviter des utilisateurs',
        OrderPayment: 'Paiement de la commande',
        SupportsMP4Forma500MB: 'Prend en charge le format mp4, ≤500Mo',
        NoProductIsCurrentlySelectedWhetherToCloseIt: "Aucun produit n'est actuellement sélectionné, faut-il fermer ?",
        ExitFullScreen: 'Quitter le plein écran',
        FullScreen: 'Plein écran',
        Logout: 'Déconnexion',
        OnlySupportsFilesInFormat: 'Prend en charge uniquement les fichiers au format xxx',
        PleaseSetAllPromotionalProducts: 'Veuillez définir tous les produits promotionnels',
        PleaseEnterTheWeight: 'Veuillez saisir le poids',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'Le prix fixe ne peut être inférieur au prix le plus bas',
        CloseOthers: 'Fermer les autres',
        CloseAll: 'Fermer tout',
        ImportSuccessful: 'Importation réussie',
        BatchRecharge: 'Recharge par lot',
        RechargeEmail: 'Courriel de recharge',
        RechargeCountry: 'Pays de recharge',
        RechargeSuccessful: 'Recharge réussie',
        RechargeInformation: 'Informations sur la recharge',
        PleaseDownloadTheRechargeInformationFirst: "Veuillez d'abord télécharger les informations de recharge",
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'Les données de recharge par lot de la pièce AIGO ne peuvent pas être vides',
        TheBatchRechargeDataCannotExceed1000Records: 'Les données de recharge par lot ne peuvent pas dépasser 1000 enregistrements',
        ConfirmSubmission: 'Confirmer la soumission',
        ExportFailed: "L'exportation a échoué",
        PackingQuantity: 'Quantité de paquets',
        Activity: 'Activité',
        PleaseSelectWhetherToEnable: "Veuillez choisir d'activer ou non",
        OnlyOneProductCanBeSynchronized: 'Un seul produit peut être synchronisé.',
        SKUWithAQuantityOfNumDoesNotExist: "L'UGS avec une quantité de {num} n'existe pas.",
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: "L'UGS avec une quantité de {num} ne correspond pas au multiple de vente.",
        SalesMultipleDoesNotMatchCannotBeListed: 'Le multiple de vente ne correspond pas, ne peut pas être listé',
        SalesMultipleIsEmptyInSpain: 'Le multiple de vente est vide en Espagne',
        SynchronizationOfPackageQuantityIsAbnormal: 'La synchronisation de la quantité de colis est anormale.',
        CannotContinueToMoveUp: 'Impossible de continuer à monter',
        CannotContinueToMoveDown: 'Impossible de continuer à descendre',
        SynchronizeThePackageQuantity: 'Synchroniser la quantité de colis',
        ProductAssociationIDCannotBeEmpty: "L'ID d'association de produits ne peut pas être vide.",
        PackageQuantityCannotBeEmpty: 'La quantité de colis ne peut pas être vide.',
        PleaseEnterTheCorrectQuantityFormat: 'Veuillez saisir le format de quantité correct',
        PleaseEnterSeparatedBy: 'Veuillez saisir (séparé par #)',
        PackageQuantity: 'Quantité de colis',
        LengthBetween1And140Characters: 'Longueur entre 1 et 140 caractères',
        PleaseSelectTheVariantParametersFirst: "Veuillez d'abord sélectionner les paramètres de la variante",
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'Le paramètre est associé à la variante, veuillez faire attention',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Veuillez sélectionner des données de la même catégorie, veuillez resélectionner',
        UpTo5VariantsCanBeSelected: "Jusqu'à 5 variantes peuvent être sélectionnées",
        NoVariant: 'Pas de variante',
        VerificationFailedPleaseSelectTheVariant: 'La vérification a échoué, veuillez sélectionner la variante',
        VerificationFailedPleaseSelectTheProduct: 'Échec de la vérification, veuillez sélectionner le produit',
        VerificationFailedTheProductParameterValueIsEmpty: 'Échec de la vérification, la valeur du paramètre du produit est vide',
        SetSuccessfully: 'Réglé avec succès',
        UploadingVideo: 'Téléchargement de la vidéo',
        SearchParameters: 'Paramètres de recherche',
        ImportantParameters: 'Paramètres importants',
        Select: 'Sélectionner',
        ConfirmSettingThisImageAsTheMainImage: 'Confirmer le choix de cette image comme image principale',
        ConfirmDisplayDoNotDisplay: "Confirmer l'affichage/ne pas afficher",
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'La limite actuelle est de sélectionner {num1} fichiers, cette fois vous avez sélectionné {num2} fichiers, un total de {num3} fichiers a été sélectionné.',
        ConfirmToRemoveAny: 'Confirmer la suppression de {Any} ?',
        TheTestEnvironmentCannotUploadFiles: "L'environnement de test ne peut pas télécharger de fichiers",
        PleaseEnterTheEnglishTitle: 'Veuillez saisir le titre anglais',
        CancelSettingAsTheMainImage: "Annuler la définition de l'image principale ?",
        SetAsTheMainImage: 'Définir comme image principale ?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'Impossible de soumettre des images ou des vidéos qui existent déjà',
        SynchronizeAnyImagesToOtherLanguages: "Synchroniser les images {Any} dans d'autres langues ?",
        PleaseSelectTheProductToBeSynchronizedFirst: "Veuillez d'abord sélectionner le produit à synchroniser",
        PleaseSelectTheProductFirst: "Veuillez d'abord sélectionner le produit",
        ConfirmWhetherToDeleteTheSelectedImageVideo: "Confirmer la suppression de l'image/vidéo sélectionnée ?",
        TheMainPictureCannotBeTurnedOff: "L'image principale ne peut pas être désactivée",
        CancelDisplay: "Annuler l'affichage ?",
        ConfirmWhetherToDelete: 'Confirmer la suppression ?',
        PleaseUploadTheEnglishImage: "Veuillez télécharger l'image en anglais",
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Échec, veuillez saisir le titre du produit pour tous les pays de vente',
        PresaleTime: 'Heure de prévente',
        PresalePrice: 'Prix de prévente',
        PleaseEnter: 'Veuillez saisir',
        PresaleQuantity: 'Quantité de prévente',
        DepositRatio: 'Taux de dépôt',
        AssociatedAGBSOnthewayOrders: "Commandes AGBS associées en cours d'acheminement",
        EstimatedDeliveryTime: 'Délai de livraison estimé',
        FinalPaymentDeadline: 'Date limite de paiement',
        SelectTime: 'Sélectionner l’heure',
        FixedPrice: 'Prix fixe',
        ByProportion: 'Au prorata',
        ByFullPayment: 'Par paiement intégral',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: "Seuls les nombres supérieurs à 0 peuvent être saisis, jusqu'à deux décimales.",
        PresaleTimeCannotBeEmpty: "L'heure de prévente ne peut pas être vide.",
        PresalePriceTypeCannotBeEmpty: 'Le type de prix de prévente ne peut pas être vide.',
        PresaleQuantityCannotBeEmpty: 'La quantité de prévente ne peut pas être vide.',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Seuls des nombres entiers positifs peuvent être saisis, ils ne peuvent pas commencer par 0.',
        DepositRatioTypeCannotBeEmpty: 'Le type de taux de dépôt ne peut pas être vide.',
        DepositRatioCannotBeEmpty: 'Le ratio de dépôt ne peut pas être vide.',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: "Seuls des nombres entiers compris entre 0 et 100 peuvent être saisis, à l'exclusion de 0 et 100.",
        EstimatedDeliveryTimeCannotBeEmpty: 'Le délai de livraison estimé ne peut pas être vide.',
        FinalPaymentDeadlineCannotBeEmpty: 'La date limite de paiement final ne peut pas être vide.',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'Le paiement final doit être effectué 5 heures avant la fin de la prévente.',
        TheRemainingAmount: 'Le montant restant',
        PresaleStatus: 'Statut de la prévente',
        PresaleStartTime: 'Heure de début de la prévente',
        PresaleEndTime: 'Heure de fin de la prévente',
        OnPresale: 'En prévente',
        DirectVisit: 'Visite directe',
        BackendManagementSystem: 'Système de gestion du backend',
        Login: 'Connexion',
        TipsFillInTheUsernameAndPasswordCasually: "Conseils : Le nom d'utilisateur et le mot de passe sont facultatifs.",
        PleaseEnterUserName: "Veuillez saisir le nom d'utilisateur",
        PleaseEnterPassword: 'Veuillez saisir le mot de passe',
        LoginSuccessful: 'Connexion réussie',
        PleaseEnterYourAccountNumberAndPassword: 'Veuillez saisir votre numéro de compte et votre mot de passe',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'Le même produit peut seulement être retourné ou échangé',
        B2CBrandWebsite: "Site web d'une marque B2C",
        CommentManagement: 'Gestion des commentaires',
        AIGOCoins: 'Pièces de monnaie AIGO',
        PleaseEnterThePackageQuantity: 'Veuillez saisir la quantité de colis',
        PresalePriceCannotBeEmpty: 'Le prix de prévente ne peut pas être vide',
        Product: 'Produit',
        HomePageColumn: "Colonne de la page d'accueil",
        ColumnDescription: 'Description de la colonne',
        FlashSaleArea: 'Zone de vente flash',

        //1.2.8之后
        SoldOutOrNot: 'Épuisé ou non',
        VirtualQuantity: 'Quantité virtuelle',
        VirtualQuantityPackageQuantity: 'Quantité virtuelle/quantité de colis',
        CombinationIcon: 'Icône de combinaison',
        UploadFailedFileSizeShouldBe3MB: 'Échec du téléchargement. La taille du fichier doit être inférieure à 3 Mo.',
        PromotionQuantityPackageQuantity: 'Quantité promotionnelle/quantité de colis',
        RemainingTotal: 'Restant/Total',
        RecommendedSize100: 'Taille recommandée : 100*100, ≤ 20 Ko',
        TotalRemainingTotal: 'Total - Restant/Total',
        Verification: 'Vérification',
        VirtualSalesVolume: 'Volume des ventes virtuelles',
        VirtualSalesVolumePackageQuantity: 'Volume des ventes virtuelles/quantité de colis',
        DeleteOrNot: 'Supprimer ou pas ?',
        EnabledOrNot: 'Activé ou non',
        Cancel: 'Annuler',
        Code: 'Code',
        Confirm: 'Confirmer',
        RebateDimension: 'Dimension de remise',
        InProgress: 'En cours',
        ActivityStatus: "Statut d'activité",
        RebateRatioManagement: 'Gestion du taux de remise',
        StartTime: 'Heure de début',
        EndTime: 'Heure de fin',
        RebateAmount: 'Montant du rabais',
        NewUser: 'Nouvel utilisateur',
        RebateObjectNumber: "Numéro d'objet de remise",
        NumberOfCompletedOrders: 'Nombre de commandes complétées',
        RebateObject: 'Objet de remise',
        Operation: 'Opération',
        RecentOperator: 'Opérateur récent',
        Country: 'Pays',
        Prompt: 'Indice',
        RebateProducts: 'Produits en rabais',
        NewUserPromotion: 'Promotion des nouveaux utilisateurs',
        RegularUserPromotion: 'Promotion des utilisateurs réguliers',
        Upcoming: 'A venir',
        RebateRatio: 'Taux de remise',
        RebateOrNot: 'Rabais ou pas',
        Rebate: 'Rabais',
        PleaseSelectARebateDimension: 'Veuillez sélectionner la dimension de la remise.',
        PleaseSelectARebateObject: "Veuillez sélectionner l'objet de la remise.",
        PleaseSelectACountry: 'Veuillez sélectionner un pays.',
        PleaseSelectARebateRatio: 'Veuillez sélectionner le ratio de remise.',
        RecentOperationTime: 'Heure de la dernière opération',
        PromotionObjectType: "Type d'objet de promotion",
        ProductPromotionType: 'Type de promotion du produit',
        UserType: "Type d'utilisateur",
        ApplicableUserType: "Type d'utilisateur concerné",
        RegularUser: 'Utilisateur régulier',
        UseAigoCoinsOrNot: 'Utiliser ou non des pièces Aigo',
        EndDateOfMembership: "date de fin d'adhésion",
        StartDateOfMembership: "date de début d'adhésion",
        memberOrNot: 'membre ou pas',
        No: 'Non',
        Yes: 'Oui',
        LowInventory: 'Faible stock',
        RechargeEmailbox: 'Recharger la boîte mail',
        SubmittedSuccessfully: 'Soumis avec succès',
        Importing: 'importation',
        InformationOfTheRechargeFailed: "L'information de la recharge a échoué.",
        RechargeFailed: 'Échec de la recharge',
        RechargeInBatch: 'Recharger par lots',
        RechargeSuccessfully: 'Recharger avec succès',
        RechargeAigoCoins: 'Recharger les pièces Aigo',
        RequiredField: 'Champ obligatoire',
        PDFFormat: 'Format PDF',
        FileSize3MB: 'Taille du fichier <3MB',
        UploadFailedPDFFormatRequired: 'Échec du téléchargement. Format PDF requis',
        AuditFailedPleaseUploadInvoiceAttachment: "L'audit a échoué, veuillez télécharger la facture en pièce jointe",
        PleaseUploadInvoiceAttachment: 'Veuillez télécharger la facture en pièce jointe',
        InvoiceAttachment: 'Pièce jointe à la facture',
        Upload: 'Télécharger',
        UploadFailed: 'Échec du téléchargement',

        HaveAnAccount: "Vous n'avez pas de compte ? Cliquez ici pour vous inscrire.",
        SoldOut: 'Épuisé',
        HaveAnInvitationCode: "Facultatif, laissez le champ vide si vous n'avez pas de code d'invitation.",
        RestockedItems: 'Réapprovisionnement',

        NotMeetPleaseEnterAgain: 'Le pseudo ne répond pas aux exigences, veuillez le saisir à nouveau.',
        ThisCouponIsOnlyAvailableForNewUsers: 'Ce coupon est uniquement disponible pour les nouveaux utilisateurs.',
        ThiscouponIsOnlyAvailableForExistingUsers: "Ce coupon n'est disponible que pour les utilisateurs existants.",
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Seuls les retours ou échanges du même produit sont autorisés.',
        With365DaysRemaining: '（Expires on 9/ 25/2024） with 365 days remaining',

        TheNicknameDoesNotMeet: 'The nickname does not meet the requirements, please enter a new one.',

        GoToView: 'Voir plus',
        DoNotRegisterRepeatedly: 'Merci de ne pas vous enregistrer à plusieurs reprises.',
        ValidityPeriod: 'Période de validité',
        RegisterSuccessfully: 'Enregistrement réussi',
        MemberRegistration: 'Inscription des membres',
        RegistrationFailed: "Échec de l'enregistrement",
        OneYear: 'Un an',
        AddToShoppingCart: 'Ajouter au panier',
        AddANewAddress: 'Ajouter une nouvelle adresse',
        ZIPCode: 'Code postal*',

        EditYourAddress: 'Modifier votre adresse',
        Remove: 'Éliminer',
        SetAsDefault: 'Définir par défaut',
        FailedChangeDefaultAddress: "Échec de la modification de l'adresse par défaut, veuillez réessayer plus tard.",
        Fullname: 'Nom complet (nom et prénom)*',
        PhoneNumber: 'Numéro de téléphone',
        DefaultAddressHasBeenChanged: "L'adresse par défaut a été modifiée.",
        ViewComments: 'Voir les commentaires',
        ClickToCheckLogisticsInfo: 'Cliquez pour vérifier les informations logistiques.',
        RestockingOfOutOfStockItems: 'Réapprovisionnement des articles en rupture de stock',
        ThisWeekRestockedItems: 'Articles réapprovisionnés cette semaine',
        ProductListInTheShoppingCart: 'Liste des produits du panier',
        WarmReminderVideoResources: "Rappel : Les ressources vidéo ne prennent en charge qu'un seul téléchargement.",
        PleaseLeaveYourMessage: 'Veuillez laisser votre message.',
        InvoiceManagement: 'Gestion des factures',

        ReadmeFile: 'Fichier Readme',
        HelpCenter: "Centre d'aide",
        FileUpload: 'Téléchargement de fichiers',
        Globalization: 'Globalisation',
        SalespersonProfile: 'Profil du vendeur',
        BatchSyncPage: 'Page de synchronisation par lots',
        CountryConfigurationPage: 'Page de configuration des pays',
        UserConsultation: 'Consultation des utilisateurs',
        PreSaleItems: 'Articles en prévente',
        EventManagement: 'Gestion des événements',
        BlankPage: 'Page vierge',
        ThereAreCurrentlyNoProductPromotions: "Il n'y a actuellement aucune promotion de produit.",
        EditInventoryBottomLine: "Modifier le résultat de l'inventaire",
        SetAFixedPrice: 'Fixer un prix fixe',
        EnterUpTo500Entries: "Saisir jusqu'à 500 entrées",
        CantRelateToYourself: 'Vous ne pouvez pas vous identifier',
        PleaseSelectTheApplicableUserType: "Veuillez sélectionner le type d'utilisateur applicable",
        TheActivityDescriptionLimitIs10000Words: "La limite de la description de l'activité est de 10 000 mots",
        EnabledStatus: 'Statut activé',
        Enable: 'Activer',
        OperatingTime: 'Durée de fonctionnement',
        NewUserPromotionEnabled: 'Promotion des nouveaux utilisateurs activée',
        NewUserPromotionDisabled: 'Promotion des nouveaux utilisateurs désactivée',
        OldUserPromotionEnabled: 'Promotion des anciens utilisateurs activée',
        OldUserPromotionDisabled: 'Promotion des anciens utilisateurs désactivée',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'Le prix promotionnel ne peut être inférieur au prix le plus bas.',
        AutomaticallyCalculateAfterEnteringPrice: 'Calcul automatique après la saisie du prix',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'Le prix promotionnel pour les nouveaux utilisateurs est supérieur au prix promotionnel pour les anciens utilisateurs. Voulez-vous continuer ?',
        PleaseSelectPromotionCountry: 'Veuillez sélectionner le pays de la promotion',
        PromotionDiscount: 'Remise promotionnelle',
        PleaseEnterThePromotionalQuantity: 'Veuillez saisir la quantité promotionnelle',
        PleaseEnterVirtualQuantity: 'Veuillez saisir la quantité virtuelle',
        NumberOfUserPurchases: "Nombre d'achats de l'utilisateur",
        PleaseEnterThePurchaseLimitForTheSameUser: "Veuillez saisir la limite d'achat pour un même utilisateur",
        PleaseEnterThePurchaseLimitForASingleOrder: "Veuillez saisir la limite d'achat pour une seule commande",
        QuantityGreaterThan0: 'Quantité supérieure à 0',
        GrossProfitMarginCalculationResults: 'Résultats du calcul de la marge brute',
        FixedPriceGrossMargin: 'Marge brute du prix fixe',
        SellingPriceGrossProfitMargin: 'Marge brute du prix de vente',
        PromotionalPriceGrossProfitMargin: 'Marge brute du prix promotionnel',
        PromotionStartTime: 'Heure de début de la promotion',
        PromotionEndTime: 'Heure de fin de la promotion',
        PromotionType: 'Type de promotion',
        PleaseSetTheSKUToPromotionalProducts: "Veuillez définir l'UGS sur ${xxx} produits promotionnels",
        DistributionOffers: 'Offres de distribution',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Après avoir saisi la quantité {Any} elle sera automatiquement calculée en fonction de la quantité du produit.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: "Le nombre d'achats de l'utilisateur doit être supérieur à 0.",
        PleaseSelectCountry: 'Veuillez sélectionner le pays ${typeName}',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Après avoir saisi la quantité promotionnelle, celle-ci sera automatiquement calculée en fonction de la quantité de marchandises.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: "Veuillez d'abord annuler l'association dans Réserve de produits à tarif réduit [Rebate Product Pool]",
        EditBottomLineInventory: "Modifier l'inventaire de base",
        PleaseConfigureFixedPrice: 'Veuillez configurer le prix fixe',
        BalancePayment: 'Paiement du solde',
        Deposit: 'Dépôt',

        EndofValidity: "Veuillez saisir la date d'expiration",
        Year: 'Année',
        Month: 'Mois',
        Day: 'Jour',
        UnitOfValidity: 'Unité de validité',
        AIGOCoinDetails: 'Détails de la pièce AIGO',
        AIGOCoinsAreCloseToExpiring: "Les pièces AIGO sont proches de l'expiration (jour)",
        CountryOfShipmentAddress: "Pays de l'adresse d'expédition",

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Délai de livraison estimé',
        DataCorrespondingToTheSource: 'Données correspondant à la source',
        AccountStatus: 'Statut du compte',
        OrderID: 'ID de la commande',
        RecipientName: 'Nom du destinataire',
        RecipientLastName: 'Nom de famille du destinataire',
        MailsOfUser: "Courrier de l'utilisateur",
        StateProvince: 'État/Province',
        CityTown: 'Ville',
        County: 'Comté/Département',
        ReceivingCompany: 'Société destinataire',
        BatchInput: 'Saisie par lots',
        InputManually: 'Saisie manuelle',
        ProductCommentsInputTemplate: 'Modèle de saisie des commentaires sur les produits',
        UserName: "Nom de l'utilisateur",
        Comments: 'Commentaires',
        StarRatingOfTheComment: 'Classement par étoiles du commentaire',
        CommentTime: 'Heure du commentaire',
        UploadAttachment: 'Télécharger une pièce jointe',
        NumberOfAttachments: 'Nombre de pièces jointes',
        FileName: 'Nom du fichier',
        Appendix: 'Annexe',
        BatchInputOfProductComments: 'Saisie par lots des commentaires sur les produits',
        SoldOut1: 'Épuisé',
        PreSalePromotionID: 'ID de la promotion avant la vente',
        PromotionAbnormal: 'Anomalie de la promotion',
        Updater: 'Mise à jour',
        AddPreSalePromotion: 'Ajouter une promotion avant la vente',
        EditPreSalePromotion: 'Modifier la promotion avant la vente',
        Barcode: 'Code-barres',
        PreSaleCountries: 'Pays de prévente',
        PreSaleDeposit: 'Dépôt de prévente',
        PleaseEnterTheDocumentCode: 'Veuillez saisir le code du document',
        BalancePaymentDeadline: 'Date limite de paiement du solde',
        FixedShippingFee: "Frais d'expédition fixes",
        PreSaleShippingFee: "Frais d'expédition avant la vente",
        FreeShippingSubjectToConditions: 'Frais de port gratuits sous réserve de conditions',
        OrderPrice: 'Prix de la commande',
        PleaseEnterPriceConditions: 'Veuillez saisir les conditions de prix',
        TheEndTimeNeedsToBeLaterThanTheStartTime: "L'heure de fin doit être postérieure à l'heure de début.",
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'Il y a déjà des promotions qui se chevauchent partiellement dans ce pays, veuillez les modifier.',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: "La quantité en prévente ne peut être qu'un nombre entier positif supérieur à 0.",
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: "L'heure de livraison estimée doit être postérieure à l'heure de fin de l'événement.",
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'La date limite de paiement du solde doit être antérieure à la date de livraison estimée.',
        PromotionID: 'ID de la promotion',
        DistributionPromotionID: 'ID de la promotion de distribution',
        CouponID: 'ID du coupon',
        PromotionManagementID: 'ID de gestion de la promotion',
        PortfolioManagementID: 'ID de gestion de portefeuille',
        ExportForFiltering: 'Exporter les filtres',
        ListDisplay: 'Affichage de la liste',
        InventoryItems: "Éléments d'inventaire",
        InStock: 'En stock',
        OutOfStock: 'En rupture de stock',
        FieldName: 'Nom du champ',
        PleaseSelectInventory: 'Veuillez sélectionner un stock',
        PreSaleEvent: 'Événement de prévente',
        PerPage: 'Chaque page montre',

        // V1.3.3 end

        // V1.3.4 start

        PleaseProvideTheNameOfTheLandingPageActivity: "Veuillez indiquer le nom de l'activité de la page de destination.",
        PleaseIndicateTheNumberOfActivities: "Veuillez indiquer le nombre d'activités.",
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: "La quantité d'activité doit être supérieure à la quantité offerte.",
        PleaseChooseATimeForTheEvent: "Veuillez sélectionner une heure pour l'événement.",
        ActivityNameExceedsLength: "Le nom de l'activité dépasse la longueur maximale.",
        ThisItemIsRestrictedToAMaximumOf10PerOrder: 'Cet article est limité à 10 par commande.',
        PleaseChooseTheGiveawayAgain: 'Veuillez choisir à nouveau le cadeau à offrir.',
        AnalysisOfGiveawayActivities: 'Analyse des activités de cadeaux',
        StatisticsOfGiveawayActivities: 'Statistiques sur les activités de cadeaux',
        PleaseEnterTheNameOfTheEvent: "Veuillez saisir le nom de l'événement.",
        PleaseSelectTheActivityType: "Veuillez choisir le type d'activité.",
        PleaseCompleteTheActivityRating: "Veuillez remplir l'évaluation de l'activité.",
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Veuillez configurer la campagne de pré-vente {Any} avant de configurer le pays où les articles en pré-vente seront présentés.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: 'Le montant de la commande et le nombre de cadeaux gratuits ne peuvent pas être inférieurs au niveau précédent.',
        SelectImage: 'Sélectionnez une image',
        GiftInformation: 'Informations sur les cadeaux',
        Gifts: 'Cadeaux ',
        GiftsOfEndOfEvent: "Cadeaux de fin d'événement",
        CheckOutTheGifts: 'Voir les cadeaux',
        GiveawayEvent: 'Événement cadeaux',
        SelectAGift: 'Sélectionner un cadeau',
        YouHaveNotSelectedAGift: "Vous n'avez pas sélectionné de cadeau",
        NoNeedForGifts: 'Pas besoin de cadeaux',
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Certains cadeaux sont en rupture de stock, veuillez resélectionner les cadeaux',
        ReplaceAGift: 'Remplacer un cadeau',
        ThereAreCurrently3FreeGiftsAvailable: 'Il y a actuellement 3 cadeaux gratuits disponibles',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Actuellement 3 cadeaux gratuits sont disponibles, et 4 autres cadeaux sont disponibles pour un achat de 99 €.',
        GiftList: 'Liste des cadeaux',
        GiftID: 'ID du cadeau',
        CurrentInventory: 'Inventaire actuel',
        NotEnabled: 'Non activé',
        GoodsForFree: 'Produits gratuits',
        ConfirmToDeleteThisGiftInformation: 'Confirmer la suppression de ces informations sur les cadeaux ?',
        GiftsSortingManagement: 'Gestion du tri des cadeaux',
        GiftsTopicManagement: 'Gestion des thèmes des cadeaux',
        GiveawayEventID: "ID de l'événement cadeaux",
        GiveawayEventName: "Nom de l'événement cadeaux",
        ProductCategory: 'Catégorie de produit',
        ActivityMode: "Mode d'activité",
        TotalNumberOfActivities: "Nombre total d'activités",
        GiftsGivenQuantity: 'Quantité de cadeaux offerts ',
        ThemeConfiguration: 'Configuration du thème',
        GiftSorting: 'Tri des cadeaux ',
        GlobalConfiguration: 'Configuration globale',
        ActiveCountry: 'Pays actif',
        PromotionalPpicture: 'Image promotionnelle',
        RecommendedSizeX: 'Taille recommandée : {XXX*XXX}',
        FileSize1MB: 'Taille du fichier : ≤1MB',
        ActivitySorting: 'Tri des activités',
        DragToSort: 'Faire glisser pour trier',
        ActivityCode: "Code d'activité",
        GiftsForOrderAmount: 'Cadeaux pour le montant de la commande',
        ActivityRating: "Évaluation de l'activité",
        GiftsQuantity: 'Quantité de cadeaux',
        EventGiftsSorting: 'Tri des cadeaux pour les événements',
        DefaultSelectionOfUsers: 'Sélection par défaut des utilisateurs',
        LandingPageDisplay: 'Affichage de la page d’accueil',
        LandingPageActivityName: "Nom de l'activité de la page d'accueil",
        ConfirmDeleteThisGiftEvent: 'Êtes-vous sûr de vouloir supprimer ce cadeau ?',
        IsThereAGiftEvent: 'Existe-t-il un événement ?',
        OrderQuantity: 'Quantité commandée',
        NumberOfGiftsIssued: 'Nombre de cadeaux émis',
        CustomerPrice: 'Prix client',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: "La fonction de quantité d'événements virtuels ne peut être définie que si la durée de l'activité de prévente est au moins supérieure à 2 jours et si le nombre d'événements est supérieur à 50.",
        PreSaleSwitch: 'Changement de prévente',
        PreSaleActivity: 'Activité de prévente',
        GiftManagement: "Gestion des cadeaux",

        // V1.3.4 end

        //V1.3.5 start
        OrderSource: "Source de la commande",
        NumberOfNewUserRegistrations: "Nombre d'inscriptions de nouveaux utilisateurs",
        RegisteredUsersWhoVsitTheWebsite: "Utilisateurs enregistrés qui visitent le site web",
        SoldDistributionQuantity: "Quantité de distribution vendue",
        DistributionQuantityLock: "Blocage de la quantité de distribution",
        InsufficientDstributionQuantityRemaining: "Quantité de distribution restante insuffisante",
        Previous: "Précédent",
        Next: "Suivant",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Gestion des recettes",
        RecipeDisplayImage: "Image d'affichage de la recette",
        NewRecipeDisplayImage: "Modifier/créer une nouvelle image d'affichage de la recette",
        CoverPicture: "Image de couverture",
        PicturesVideos: "Jusqu'à 10 photos + vidéos peuvent être téléchargées, chaque photo doit être ≤5MB, et la vidéo au format .mp4 est prise en charge",
        AddPicturesVideos: "Ajouter des images/vidéos",
        CommenOnPicturesVideos: "Commenter des images/vidéos",
        FileSize: "Taille du fichier",
        BarcodeAssociated: "Code-barres associé",
        FirstUploadTime: "Heure du premier téléchargement",
        EditTime: "Modifier l’heure",
        TagType: "Type d'étiquette",
        FileName1: "Nom du fichier",
        NameLengthISTooLong: "Le nom est trop long",
        ProductDoesNoExist: "Le produit n'existe pas",
        PleaseUploadImages: "Veuillez télécharger des images jpg/png/jpeg de moins de 5MB",
        PleaseUploadTheVideoMP4: "Veuillez télécharger la vidéo au format mp4",
        PictureOrVideo: "L'image ou la vidéo ne peut pas être vide",
        allowed: "Autoriser tout",
        cookie1: "Gestion des cookies",
        collectsordinances: "Aigostar utilise des cookies pour améliorer l'expérience de navigation des visiteurs et à des fins de marketing. Consultez et gérez vos paramètres de cookies ci-dessous. Pour en savoir plus sur la façon dont nous utilisons les cookies, veuillez consulter notre Politique de confidentialité.",
        RequiredCookies: "Cookies nécessaires",
        cookie2: "Ces cookies sont nécessaires pour les fonctionnalités de base telles que la connexion, l'utilisation du panier d'achat et les paiements. Les cookies nécessaires ne peuvent pas être désactivés. Mais ne vous inquiétez pas, ces cookies ne sauvegardent aucune de vos informations.",
        AnalyticsCookies: "Cookies de marketing et d'analyse",
        cookie3: "Ces cookies collectent des informations telles que le nombre de visites sur le site web ou les pages les plus populaires, afin de nous aider à améliorer l'expérience de nos clients. Vous pouvez choisir de désactiver ces cookies, mais nous ne pourrons alors pas collecter les informations dont nous avons besoin pour améliorer votre expérience sur notre site web.",
        StoragePreferences: "Sauvegarder les préférences",
        BuyItNow: "Acheter maintenant",
        PictureVideoSize: "La taille ou le format de l'image ou de la vidéo n'est pas correct",
        RecipeConfiguration: "Configuration des recettes",
        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Confirmer la synchronisation des sous-codes-barres ?",
        SynchronizationEbpEanSUB: "En raison de la nécessité de se connecter avec EBP, la synchronisation par lots devrait être terminée en 3 minutes. Après confirmation, la synchronisation se déroulera de manière asynchrone et les sous-codes-barres seront affichés immédiatement après réception. Veuillez patienter.",
        Keywords: "Mots-clés",
        SystemAutoUpdate: "Mise à jour automatique du système",
        IPAddress: "Adresse IP",
        SearchKeywords: "Rechercher des mots-clés",
        LoginEmail: "E-mail de connexion",
        SearchTime: "Temps de recherche",
        NumbeOfTimes: "Nombre de fois",
        MyBrowsing: "Mes navigations",
        LoggingOuOfYouAccount: "La déconnexion de votre compte sera considérée comme une renonciation volontaire et vous ne pourrez plus continuer à utiliser ce compte.",
        RequestLogout: "Demander la déconnexion",
        ProductsYet: "Vous n'avez pas encore navigué sur des produits",
        clearall: "Effacer l'historique",
        CancellTip1: "Les listes de panier d'achat, les enregistrements d'achat de commande et les enregistrements de favoris seront supprimés de manière permanente et ne pourront pas être récupérés.",
        CancellTip2: "Toutes les informations de compte, les enregistrements de service, les données de consommation, etc., sur la plateforme seront supprimés de manière permanente et ne pourront pas être récupérés.",
        CancellTip3: "Vous ne pourrez plus vous connecter ou utiliser ce compte AigoSmart.",
        CancellTip4: "Les informations personnelles et les messages historiques (y compris l'avatar, le surnom, ma monnaie Aiguo, les notifications de la plateforme, mes commentaires, etc.) associés à votre compte AigoSmart ne seront pas récupérables.",
        CancellTip5: "Vous devez gérer correctement les fonds du compte (points, niveaux d'adhésion, qualifications, commandes, coupons, accessoires et autres services précieux) avant de vous déconnecter, car l'annulation du compte sera considérée comme une renonciation volontaire et ne pourra pas être annulée. Vous comprenez et acceptez qu'AigoSmart ne puisse pas vous aider à restaurer les services susmentionnés.",
        SynchronizeSubBarcodes: "Synchroniser les sous-codes-barres",
        PleaseEnterKeywords: "Veuillez entrer des mots-clés",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Statistiques horaires",
        NumbeOfUsers: "Nombre d'utilisateurs ayant passé des commandes",
        NumberOfCanceled: "Nombre de commandes annulées",
        RefundAmount: "Montant des remboursements",
        UsersWhoHavePaid: "Utilisateurs ayant payé (commandes pouvant être expédiées, expédiées, complétées)",
        TotalNumber: "Nombre total de commandes annulées par la plateforme",
        AmountOfCanceled: "Montant des commandes annulées qui ont été payées mais non expédiées, montant des commandes retournées qui ont été payées (en transit, livrées)",
        RedemptionCode: "ID/Nom du coupon/Code de rédemption",
        DeleteAll: "Supprimer",
        AccountIsUnderReview: "Le compte actuel est en cours de révision pour désactivation. Continuer à se connecter annulera le processus de désactivation. Voulez-vous continuer à l'utiliser ?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "Le temps ne peut pas être vide",
        ProductImporTemplate: "Modèle d'importation de produits",
        NormalOrdersExceed: "Les commandes normales dépassent :",
        OrderSautomaticallyClosed: "Les commandes non payées seront automatiquement fermées",
        OrdeIsShipped: "Après l'expédition de la commande :",
        AutomaticallyComplete: "Compléter automatiquement la commande",
        Minutes: "Minutes",
        Days: "Jours",
        OrderSettings: "Paramètres de commande",
        ClearCache: "Vider le cache",
        PleaseEnterNavigationContent: "Veuillez entrer le contenu de la navigation",
        WebsiteNavigationConfiguration: "Configuration de la navigation du site web",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Temps de retard de la fenêtre contextuelle",
        SubscriptionPopup: "Fenêtre contextuelle d'abonnement",
        MeansNoPopupDelay: "Veuillez entrer l'heure de l'invite de délai de la fenêtre contextuelle",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "Espace UGC",

        //V1.5.0 end

        //V1.6.0 start
        uploaFilesInJpg: "Ne télécharger que des fichiers au format jpg/png de moins de 2M",
        SynchronizeEbpPrice: "Synchroniser le prix EBP",
        ConfirmToSynchronizeEbp: "Confirmer la synchronisation du prix EBP",
        AsynchronousEbp: "Après confirmation, la synchronisation est asynchrone. Le prix sera affiché immédiatement après avoir été obtenu. Veuillez patienter.",
        CopywritingDisplay: "Affichage du copywriting",
        HomepageCopywriting: "Rédaction de la page d'accueil",
        EditHomepageCopywriting: "Modifier le copywriting de la page d'accueil",
        SuperimposedEvents: "Superposer des événements ?",
        GiftsEvents: "(Les cadeaux pour cet événement ne peuvent pas être collectés en même temps que d'autres événements)",
        SmallGiftEvent: "Événement petits cadeaux",
        SpecialProductEvent: "Événement produits spéciaux",
        SpecialGiftEvent: "Les cadeaux de l'événement petits cadeaux et de l'événement produits spéciaux ne peuvent pas être cumulés.",
        ProductsWithSKU: "Veuillez sélectionner les produits avec une UGS",
        LimitWereSelected: "La limite actuelle est de {limitNum} fichiers. Cette fois, {selectNum} fichiers ont été sélectionnés.",
        FileUploadFailed: "Le téléchargement du fichier a échoué !",
        CompleteMultiLanguage: "Veuillez compléter la configuration multilingue",
        FirstSynchronizeSKU: "Veuillez d'abord synchroniser le SKU de ce produit.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Rendre le coupon public ou non",
        RedemptionCode1: "Code de remboursement",
        NowOnlyNeed: "Il suffit maintenant de 10 €.",
        WebLogReporting: "Rapports de journaux Web",
        PageAddress: "Adresse de la page",
        ErrorLogs: "Journaux d'erreurs",
        CustomParameter: "Paramètre Personnalisé",
        ParameterID: "ID du Paramètre",
        // ParameterName: "Nom du Paramètre",
        SearchParameter: "Paramètre de Recherche",
        EnterParameterName: "Veuillez saisir le nom du paramètre",
        ParameterType: "Type de Paramètre",
        AssociatedProductCount: "Nombre de Produits Associés",
        MandatoryPortugueseContent: "Contenu en portugais obligatoire",
        MandatoryEnglishContent: "Contenu en anglais obligatoire",
        MandatoryDutchContent: "Contenu en néerlandais obligatoire",
        MandatoryPolishContent: "Contenu en polonais obligatoire",
        MandatoryFrenchContent: "Contenu en français obligatoire",
        MandatoryGermanContent: "Contenu en allemand obligatoire",
        MandatoryItalianContent: "Contenu en italien obligatoire",
        MandatorySpanishContent: "Contenu en espagnol obligatoire",
        MandatoryChineseContent: "Contenu en chinois obligatoire",
        ImportTemplate: "Modèle d'importation de produits avec paramètres personnalisés",
        ConfirmImportFile: "Confirmer le fichier d'importation, continuer?",
        SelectDataRemove: "Sélectionner les données à supprimer",
        ImportTemplateDownload: "Télécharger le modèle d'importation",
        AssociatedProducts: "Produits associés",

        //V1.7.0 end

        //V1.8.0 start
        Published: "Publié",
        CanBeIgnored: "Peut être ignoré",
        Resolved: "Résolu",
        ActivityType: "Type d'activité",
        PromotionActivity: "Activité promotionnelle",
        DistributionActivity: "Activité de distribution",
        InputLink: "Entrer le lien",
        EnterAddress: "Veuillez entrer l'adresse du lien",
        DetailPageAd: "Emplacement publicitaire sur la page de détails",

        //V1.8.0 end

        //V1.9.0 start
        ProductPromotionID: "ID de promotion du produit.",
        PromotionalActivityCollection: "Collection d'activités promotionnelles.",
        PromotionalActivityName: "Nom de l'activité promotionnelle.",
        ProductPromotionBarcode: "ID de promotion du produit/Code-barres.",
        ProductPromotionIDCopy: "ID de promotion du produit (double-cliquez pour copier tous les ID).",
        ProductBarcode: "Code-barres du produit (double-cliquez pour copier tous les codes-barres).",
        SelectPromotionalActivity: "Sélectionner une activité promotionnelle.",
        SelectPromotionalList: "Sélectionner la liste des promotions.",
        TypesPerOrder: "Limiter le nombre de types d'articles par commande.",
        PromotionalActivity: "Veuillez sélectionner une activité promotionnelle.",
        ProductAlready: "Ce produit a déjà une activité promotionnelle. Veuillez procéder à l'édition.",
        PromotionalActivityNameID: "Nom/ID de la Promotion",
        PromotionalCollectionName: "Nom de la collection promotionnelle.",
        SelectedPromotionalActivity: "Activité promotionnelle sélectionnée.",
        PromotionalCollectionActivit: "Une activité de collection promotionnelle existe déjà. Veuillez procéder à l'édition.",

        EnterHholidayTheme: "euillez entrer un thème de fête.",
        HolidayTheme: "Thème de fête.",
        HolidayImage: "Image de fête.",
        AddLogoImage: "Ajouter l'image du logo.",
        PleaseProceedEdit: "Veuillez procéder à l'édition.",
        SKUBIDActivities: "SKU: {sku}, il y a déjà {country} activités sous {activity}.",
        LogImageManagement: "Gestion des images du logo",
        LogoManagement: "Gestion du logo",

    //V1.9.0 end

    }
};

export default fr;