import daLocal from 'element-ui/lib/locale/lang/da';
const da = {
    ...daLocal,
    common: {
        //11-22

        AssociatedOrderNumber: '关联订单号',
        recordId: '记录id',

        UserAccess: 'Brugeradgang',
        PleaseSelectTheStatisticalType: 'Vælg venligst den statistiske type',
        StatisticalByDate: 'Statistik efter dato',
        StatisticalBySource: 'Statistik efter kilde',
        Date: 'Dato',
        Visits: 'Besøg',
        NewVisitors: 'Nye besøgende',
        OrderStatistics: 'Statistik over ordrer',
        Sales: 'Salg',
        TotalOrders: 'Ordrer i alt',
        AverageOrderAmount: 'Gennemsnitligt ordrebeløb',
        SynchronizeEBP: 'Synkroniser EBP',
        ParameterName: 'Navn på parameter',
        OnTheShelf: 'På hylden',
        OfflineShoppingCart: 'Offline indkøbskurv',
        AddToCart: 'Tilføj til kurv',
        EnterCheckout: 'Gå til kassen',
        UserLogin: 'Bruger login',
        UserRegistration: 'Registrering af bruger',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Forskellige IP-numre til at lægge varer i indkøbskurven',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Forskellige IP-numre til at gå ind på ordrebekræftelsessiden',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Forskellige IP-numre for dem, der gennemførte login-processen efter at være blevet omdirigeret fra offline-indkøbskurven til login-siden.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Forskellige IP-numre for dem, der gennemførte registreringsprocessen efter at være blevet omdirigeret fra offline-indkøbskurven til registreringssiden.',
        UserBehavior: 'Brugeradfærd',
        Display: 'Visning',
        DoNotDisplay: 'Må ikke vises',
        AddToPresale: 'Tilføj til forsalg',
        CancelPresale: 'Annuller forsalg',
        PresaleConfiguration: 'Konfiguration af forsalg',
        OffTheShelf: 'Fra hylden',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Udfyld venligst produktoplysningerne, før du konfigurerer forsalget.',
        SynchronizedEBPPrompts: "På grund af behovet for synkronisering med EBP, kan batch-synkronisering kun udføres én gang inden for X minutter for at undgå fejl. Efter bekræftelse udføres synkroniseringen asynkront, og SKU'en vises umiddelbart efter, at den er hentet. Vær venligst tålmodig.",
        PleaseWait: 'Vent venligst',
        PleaseSelectATag: 'Vælg venligst et tag',
        Updating: 'Opdatering',
        Abortive: 'Mislykket',
        DefaultRule: 'Standardregel',
        BottomInventory: 'Nederste lagerbeholdning',
        SynchronisedAlertsForGraphicDetails: 'Grafiske detaljer: Synkroniser det tilsvarende sprogs [produktnøgleord] i henhold til sproget',
        SynchronisedAlertsForLargeProductImages: 'Produktbillede: Synkroniser det tilsvarende sprogs billede i henhold til sproget, læs EBP-billedsorteringen, og indstil automatisk det første EBP-billede som hovedbillede',
        Account: 'Konto',
        Account1: 'Konto',
        EnterUpToNumItems: 'Indtast op til {num} varer',
        SynchronizedSuccessfully: 'Korrekt synkroniseret',
        SuccessfullyListed: 'Korrekt opført',
        SearchSuccessful: 'Korrekt søgning',
        EnterNewPassword: 'Indtast ny adgangskode',
        OnlyNumbersAndLettersAreAllowed: 'Kun tal og bogstaver er tilladt',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: 'Udfyld venligst forsalgskonfigurationen, før du viser forsalgsvaren',
        ProductWeight: 'Produktets vægt',
        ConfirmExecution: 'Bekræft gennemførelse?',
        ConfirmBatchListing: 'Bekræft batch-liste?',
        Template: 'Skabelon',
        PleaseSelectTheProductsToBeSynchronized: 'Vælg de produkter, der skal synkroniseres',
        ForcedSynchronisationDescription: 'Tvungen synkronisering sletter de ændrede data og erstatter dem med synkroniserede data. Almindelig synkronisering vil ikke synkronisere de ændrede produktdata.',
        ConfirmSynchronizationExecution: 'Bekræft gennemførelse af synkronisering',
        SynchronisationHasBeenSentPleaseWait: 'Meddelelsen om synkroniseringsopgaven er blevet sendt, vent tålmodigt på, at datasynkroniseringen er gennemført.',
        ProhibitLogin: 'Forbyd login',
        IsTheUserLoggedIn: 'Er brugeren logget ind?',
        Prohibit: 'Forbyd',
        ProhibitedSuccessfully: 'Korrekt forbudt',
        EnableLogin: 'Aktiver login',
        EnabledSuccessfully: 'Korrekt aktiveret',
        OperationCanceled: 'Operation annulleret',
        UserData: 'Brugerdata.xlsx',
        GraphicDetails: 'Grafiske detaljer',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'Dette produkt er blevet indstillet som et kombinationsprodukt og kan ikke omfattes af forsalg. Vil du venligst fjerne produktet fra kombinationen først?',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'Dette produkt er et kampagneprodukt og kan ikke omfattes af forsalg. Annuller venligst kampagnen først.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'Dette produkt er et distributionsprodukt og kan ikke omfattes af forsalg. Annullér venligst distributionen først.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'Dette produkt har allerede en fuld reduktionsaktivitet og kan ikke tilføjes.',
        ESNoPriceNotAvailableForPresale: 'Dette produkt har ikke en pris i {Any} og kan ikke tilføjes til forsalg. Fjern først det tilsvarende land, eller synkroniser prisen for det tilsvarende land, før du tilføjer forsalgsprodukter!',
        ConfirmSettingThisProductAsAPresaleItem: 'Bekræft indstilling af dette produkt som en forsalgsvare?',
        PresaleAddedSuccessfully: 'Forsalg tilføjet korrekt',
        ConfirmSettingThisProductAsANonpresaleItem: 'Bekræft indstilling af dette produkt som en ikke-forsalgsvare?',
        CancelPresaleSuccessful: 'Annullering af forsalg korrekt',
        Preview: 'Forhåndsvisning',
        TheInventoryOfCurrentProducts0Continue: 'Beholdningen af det aktuelle produkt {stock} er 0, fortsæt?',
        TheInputContentCannotExceedCharacters: 'Det indtastede indhold må ikke overstige{x} tegn',
        PleaseSelectTheActivity: 'Vælg venligst aktiviteten',
        OrderDetails: 'Ordreoplysninger',
        LogisticsInformation: 'Oplysninger om logistik',
        CancelRemark: 'Annuller bemærkning',
        DepositAmount: 'Depositum beløb',
        FinalPaymentAmount: 'Endeligt betalingsbeløb',
        FullReductionDiscount: 'Fuld rabat',
        YourBrowserDoesNotSupportVideoPlayback: 'Din browser understøtter ikke videoafspilning',
        EditBanner: 'Rediger banner',
        AddBanner: 'Tilføj banner',
        UpdateTime: 'Opdateringstid',
        HomepageRecommendationPosition: 'Anbefalet position på hjemmesiden',
        ActivityID: 'Aktivitets-ID',
        PleaseSelectThePosition: 'Vælg venligst position',
        PleaseSelectTheJumpType: 'Vælg venligst springtype',
        PleaseEnterTheAssociatedID: 'Indtast venligst det tilknyttede ID',
        TheUploadedImageSizeCannotExceed5MB: 'Den uploadede billedstørrelse må ikke overstige {num} MB!',
        OnlyMp4FormatIsSupportedForVideoUpload: 'Kun mp4-format understøttes til video-upload',
        TheUploadedVideoSizeCannotExceed500MB: 'Den uploadede videostørrelse må ikke overstige {num} MB!',
        PleaseUploadTheVideo: 'Upload venligst videoen',
        PleaseEnterTheJumpLink: 'Indtast venligst jump-linket',
        PleaseEnterTheCorrectLink: 'Indtast venligst det korrekte link',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'Bekræftelse mislykkedes, indtast venligst de obligatoriske felter først',
        AddFailed: 'Tilføjelse mislykkedes',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: 'Det er allerede den første, og den opadgående bevægelse mislykkedes',
        PleaseEnterTheRebateRatio: 'Indtast venligst rabatforholdet',
        EditCoupon: 'Rediger kupon',
        AddCoupon: 'Tilføj kupon',
        IDCouponName: 'ID/kuponnavn/Indløsningskode',
        Random: 'Tilfældig',
        Fixed: 'Fast',
        AboutToTakeeffect: 'Ved at træde i kraft',
        NotStackable: 'Kan ikke stables',
        Stackable: 'Kan stables',
        Frozen: 'Frosset',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Indtast venligst det maksimale fradragsbeløb. 0 betyder ubegrænset',
        UseUserID: 'Brug bruger-ID',
        UseOrderNumberExchangeCodeUserID: 'Brug ordrenummer/byttekode/bruger-ID',
        PleaseEnterTheDiscount: 'Indtast venligst rabatten',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Der kan kun indtastes tal fra 0 til 100 med op til 1 decimal',
        PleaseEnterTheFullReductionAmount: 'Indtast venligst det fulde reduktionsbeløb',
        PleaseEnterTheExchangeCode: 'Indtast venligst ombytningskoden',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Kun tal/bogstaver kan indtastes, mindst 3 cifre, op til 20 cifre',
        PleaseSelectAFixedExchangeCode: 'Vælg venligst en fast vekselkode',
        PleaseEnterTheMaximumDeductionAmount: 'Indtast venligst det maksimale fradragsbeløb',
        PleaseSelectWhetherItCanbeStacked: 'Vælg venligst, om det kan stables',
        WarehousingTime: 'Lagertid',
        EnterActivityDescription: 'Indtast beskrivelse af aktivitet',
        ActivityTitleCannotBeEmpty: 'Aktivitetens titel må ikke være tom',
        TheTitleCannotExceed200Characters: 'Titlen må ikke overstige {num} tegn',
        PleaseEnterTheActivityDescription: 'Indtast venligst beskrivelse af aktivitet',
        ActivityTimeCannotBeEmpty: 'Aktivitetstid kan ikke være tom',
        ActivityDescriptionLimitOf10000Words: 'Begrænsning på aktivitetsbeskrivelse på {num} ord',
        ActivityRecommendation: 'Anbefaling af aktivitet',
        Promotion: 'Kampagne',
        EveryFull: 'Hver {num} fuld',
        EnterAmount: 'Indtast beløb',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Kun positive tal kan indtastes, op til 2 decimaler, kan ikke starte med 0',
        PleaseEnterTheCorrectAmount: 'Indtast venligst det korrekte beløb',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Vælg venligst relevante kategorier eller relevante produkter',
        Rules: 'Regler',
        RemainingTotal: 'Resterende/total',
        CopyLink: 'Kopier link',
        DistributionNameProductIDBarcode: 'Distributionsnavn/produkt-ID/stregkode',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'Denne underfunktion er i øjeblikket ikke tilgængelig i dette land, men vil blive tilgængelig senere',
        PleaseEnterThePrice: 'Indtast venligst prisen',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Beregnes automatisk efter indtastning af rabatten',
        PleaseEnterTheQuantity: 'Indtast venligst antal',
        OrderRemarkLabel: 'Etiket til ordrebemærkning',
        OrdersWorkWithInfluencers: 'Ordrer arbejder med influencers',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: 'Den aktuelle bruttofortjenstmargen for den aktuelle aktivitetspris er',
        ThePriceCannotBeLowerThanTheLowestPrice: 'Prisen kan ikke være lavere end den laveste pris:',
        Distribution: 'Distribution',
        IDName: 'ID/navn',
        ProductSelected: 'Produkt valgt',
        SelectedProduct: 'Valgt produkt',
        ProductHasNoPricing: 'Produktet har ingen prisfastsættelse',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: 'Den aktuelle bruttofortjeneste for den aktuelle aktivitetspris er {rate}%, er du sikker på, at du vil indsende?',
        SubscriptionStatus: 'Status for abonnement',
        URLClickToCopyAddress: 'URL (klik for at kopiere adressen)',
        URLSourceManagement: 'URL kildehåndtering',
        PleaseEnterTheURL: "Indtast venligst URL'en",
        PleaseEnterEnglishOrNumbers: 'Indtast venligst engelsk eller tal',
        LengthCannotExceed1000Characters: 'Længden må ikke overstige 1000 tegn',
        URLFixedAddressIsEmpty: 'URL fast adresse er tom',
        cover: 'Omslag',
        PleaseEnterOrPasteTheVideoScript: 'Indtast eller indsæt venligst videoscriptet',
        VideoScript: 'Video-script',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Upload venligst en videofil eller udfyld videoscriptet',
        PleaseEnterTheBrand: 'Indtast venligst mærket',
        Portuguese: 'Portugisisk',
        Chinese: 'Kinesisk',
        Register: 'Opret dn registrering',
        InviteUsers: 'Inviter brugere',
        OrderPayment: 'Betaling for ordre',
        SupportsMP4Forma500MB: 'Understøtter mp4-format, ≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'Intet produkt er valgt i øjeblikket, skal jeg lukke det?',
        ExitFullScreen: 'Log ud af fuld skærm',
        FullScreen: 'Fuld skærm',
        Logout: 'Log ud',
        OnlySupportsFilesInFormat: 'Understøtter kun filer i {xxx}-format',
        PleaseSetAllPromotionalProducts: 'Angiv venligst alle salgsfremmende produkter',
        PleaseEnterTheWeight: 'Indtast venligst vægten',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'Fast pris kan ikke være lavere end den laveste pris',
        CloseOthers: 'Luk andre',
        CloseAll: 'Luk alle',
        ImportSuccessful: 'Import vellykket',
        BatchRecharge: 'Batch genopladning',
        RechargeEmail: 'Genopladnings-e-mail',
        RechargeCountry: 'Genoplad land',
        Submit: 'Indsend',
        RechargeSuccessful: 'Genopladning vellykket',
        RechargeInformation: 'Information om genopladning',
        PleaseDownloadTheRechargeInformationFirst: 'Download venligst opladningsoplysningerne først',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'Batchopladningsdataene for AIGO-mønten kan ikke være tomme',
        TheBatchRechargeDataCannotExceed1000Records: 'Batchopladningsdataene må ikke overstige 1000 poster',
        ConfirmSubmission: 'Bekræft indsendelse',
        ExportFailed: 'Eksport mislykkedes',
        PackingQuantity: 'Pakkemængde',
        Activity: 'Aktivitet',
        PleaseSelectWhetherToEnable: 'Vælg venligst, om du vil aktivere',
        OnlyOneProductCanBeSynchronized: 'Kun ét produkt kan synkroniseres',
        SKUWithAQuantityOfNumDoesNotExist: 'SKU med en mængde på {num}findes ikke',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU med en mængde på {num} opfylder ikke salgsmultiplikatoren',
        SalesMultipleDoesNotMatchCannotBeListed: 'Salgsmultipel matcher ikke, kan ikke vises',
        SalesMultipleIsEmptyInSpain: 'Salgsmultipel er tom i Spanien',
        SynchronizationOfPackageQuantityIsAbnormal: 'Synkronisering af pakkemængde er unormal',
        CannotContinueToMoveUp: 'Kan ikke fortsætte med at bevæge sig op',
        CannotContinueToMoveDown: 'Kan ikke fortsætte med at bevæge sig ned',
        SynchronizeThePackageQuantity: 'Synkroniser antallet af pakker',
        ProductAssociationIDCannotBeEmpty: 'Produkttilknytnings-ID kan ikke være tomt',
        PackageQuantityCannotBeEmpty: 'Pakkemængden kan ikke være tom',
        PleaseEnterTheCorrectQuantityFormat: 'Indtast venligst det korrekte antalsformat',
        PleaseEnterSeparatedBy: 'Indtast venligst (adskilt af #)',
        PackageQuantity: 'Antal af pakker',
        LengthBetween1And140Characters: 'Længde mellem 1 og 140 tegn',
        PleaseSelectTheVariantParametersFirst: 'Vælg venligst variantparametrene først',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'Parameteren er forbundet med varianten, vær forsigtig',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Vælg venligst data i samme kategori, vælg venligst igen',
        UpTo5VariantsCanBeSelected: 'Der kan vælges op til 5 varianter',
        NoVariant: 'Ingen variant',
        VerificationFailedPleaseSelectTheVariant: 'Verifikation mislykkedes, vælg venligst varianten',
        VerificationFailedPleaseSelectTheProduct: 'Verificering mislykkedes, vælg venligst produktet',
        VerificationFailedTheProductParameterValueIsEmpty: 'Verifikation mislykkedes, produktparameterværdien er tom',
        SetSuccessfully: 'Indstillet korrekt',
        UploadingVideo: 'Upload af video',
        SearchParameters: 'Søgeparametre',
        ImportantParameters: 'Vigtige parametre',
        Select: 'Vælg',
        ConfirmSettingThisImageAsTheMainImage: 'Bekræft indstilling af dette billede som hovedbillede',
        ConfirmDisplayDoNotDisplay: 'Bekræft visning/vis ikke',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'Den nuværende grænse er at vælge {num1} filer, denne gang har du valgt {num2} filer, i alt {num3} filer er blevet valgt',
        ConfirmToRemoveAny: 'Bekræft for at fjerne {Any}?',
        TheTestEnvironmentCannotUploadFiles: 'Testmiljøet kan ikke uploade filer',
        PleaseEnterTheEnglishTitle: 'Indtast venligst den engelske titel',
        CancelSettingAsTheMainImage: 'Annuller indstilling som hovedbillede?',
        SetAsTheMainImage: 'Indstillet som hovedbillede?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'Kan ikke indsende billeder eller videoer, der allerede findes',
        SynchronizeAnyImagesToOtherLanguages: 'Synkronisere {Any}-billeder til andre sprog?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Vælg først det produkt, der skal synkroniseres',
        PleaseSelectTheProductFirst: 'Vælg venligst produktet først',
        ConfirmWhetherToDeleteTheSelectedImageVideo: 'Bekræft, om det valgte billede/video skal slettes?',
        TheMainPictureCannotBeTurnedOff: 'Hovedbilledet kan ikke slukkes',
        CancelDisplay: 'Annuller visning?',
        ConfirmWhetherToDelete: 'Bekræft, om du vil slette?',
        PleaseUploadTheEnglishImage: 'Upload venligst det engelske billede',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Mislykket, indtast venligst produkttitlen for alle salgslande',
        PresaleTime: 'Tidspunkt for forsalg',
        PresalePrice: 'Forsalgspris',
        PleaseEnter: 'Indtast venligst',
        PresaleQuantity: 'Forsalg antal',
        DepositRatio: 'Depositum forhold',
        AssociatedAGBSOnthewayOrders: 'Tilknyttede AGBS-ordrer på vej',
        EstimatedDeliveryTime: 'Forventet leveringstid',
        FinalPaymentDeadline: 'Sidste frist for betaling',
        SelectTime: 'Vælg tid',
        PreSalePrice: 'Forsalgspris',
        FixedPrice: 'Fast pris',
        ByProportion: 'Efter andel',
        ByFullPayment: 'Ved fuld betaling',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Kun tal større end 0 kan indtastes, op til to decimaler.',
        PresaleTimeCannotBeEmpty: 'Tidspunkt for forsalg kan ikke være tomt',
        PresalePriceTypeCannotBeEmpty: 'Forsalgs-pristype kan ikke være tom',
        PresaleQuantityCannotBeEmpty: 'Antal før salg kan ikke være tom',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Kun positive heltal kan indtastes, kan ikke starte med 0',
        DepositRatioTypeCannotBeEmpty: 'Type af indbetalingsforhold kan ikke være tom',
        DepositRatioCannotBeEmpty: 'Depositumsprocent kan ikke være tom',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Kun heltal mellem 0 og 100 kan indtastes, undtagen 0 og 100.',
        EstimatedDeliveryTimeCannotBeEmpty: 'Forventet leveringstid kan ikke være tom',
        FinalPaymentDeadlineCannotBeEmpty: 'Sidste betalingsfrist kan ikke være tom ',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'Den endelige betaling skal ske inden 5 timer før afslutningen af forsalgskampagnen.',
        TheRemainingAmount: 'Det resterende beløb',
        PresaleStatus: 'Status for forsalg',
        PresaleStartTime: 'Starttidspunkt for forsalg',
        PresaleEndTime: 'Sluttidspunkt for forsalg',
        OnPresale: 'På forsalg',
        DirectVisit: 'Direkte besøg',
        BackendManagementSystem: 'Backend-styringssystem',
        Login: 'Login',
        TipsFillInTheUsernameAndPasswordCasually: 'Tips: Udfyld brugernavn og adgangskode uden problemer.',
        PleaseEnterUserName: 'Indtast venligst brugernavn',
        PleaseEnterPassword: 'Indtast venligst adgangskode',
        LoginSuccessful: 'Login vellykket',
        PleaseEnterYourAccountNumberAndPassword: 'Indtast venligst dit kontonummer og din adgangskode',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'Det samme produkt kan kun returneres eller ombyttes',
        B2CBrandWebsite: 'B2C-brandets hjemmeside',
        CommentManagement: 'Administration af kommentarer',
        AIGOCoins: 'AIGO-mønter',
        PleaseEnterThePackageQuantity: 'Indtast venligst antallet af pakker',
        PresalePriceCannotBeEmpty: 'Forsalgsprisen kan ikke være tom',
        Product: 'Produkt',
        HomePageColumn: 'Hjemmesidespalte',
        ColumnDescription: 'Beskrivelse af kolonnen',
        FlashSaleArea: 'Område for lynudsalg',

        //1.2.8之后
        SoldOutOrNot: 'Udsolgt eller ej',
        VirtualQuantity: 'Virtuelt antal',
        VirtualQuantityPackageQuantity: 'Virtuelt antal/pakkeantal',
        CombinationIcon: 'Ikon for kombination',
        UploadFailedFileSizeShouldBe3MB: 'Upload mislykkedes, filstørrelsen skal være <3 MB',
        PromotionQuantityPackageQuantity: 'Kampagne-antal/pakkeantal',
        RecommendedSize100: 'Anbefalet størrelse: 100*100, ≤ 20 KB',
        TotalRemainingTotal: 'Total - Resterende/Total',
        Verification: 'Bekræftelse',
        VirtualSalesVolume: 'Virtuel salgsvolumen',
        VirtualSalesVolumePackageQuantity: 'Virtuel salgsmængde/pakkeantal',
        DeleteOrNot: 'Slet eller ej?',
        EnabledOrNot: 'Aktiveret eller ej',
        Cancel: 'Annuller',
        Code: 'Kode',
        Confirm: 'Bekræft',
        RebateDimension: 'Dimension for rabat',
        InProgress: 'I gang',
        ActivityStatus: 'Status for aktivitet',
        Ended: 'Afsluttet',
        RebateRatioManagement: 'Håndtering af rabatforhold',
        StartTime: 'Starttidspunkt',
        EndTime: 'Sluttidspunkt',
        ID: 'ID',
        RebateAmount: 'Rabatbeløb',
        NewUser: 'Ny bruger',
        RebateObjectNumber: 'Nummer på rabatobjekt',
        NumberOfCompletedOrders: 'Antal gennemførte ordrer',
        RebateObject: 'Objekt for rabat',
        Operation: 'Drift',
        RecentOperator: 'Seneste operatør',
        Country: 'Land',
        Prompt: 'Spørg',
        RebateProducts: 'Rebate-produkter',
        NewUserPromotion: 'Kampagne for nye brugere',
        RegularUserPromotion: 'Kampagne for almindelige brugere',
        Upcoming: 'Kommende',
        RebateRatio: 'Rabatforhold',
        RebateOrNot: 'Rabat eller ej',
        Rebate: 'Rabat',
        PleaseSelectARebateDimension: 'Vælg venligst en rabatdimension',
        PleaseSelectARebateObject: 'Vælg venligst et rabatobjekt',
        PleaseSelectACountry: 'Vælg venligst et land',
        PleaseSelectARebateRatio: 'Vælg venligst et rabatforhold',
        RecentOperationTime: 'Seneste driftstid',
        PromotionObjectType: 'Objekttype for kampagne',
        ProductPromotionType: 'Type af produktkampagne',
        UserType: 'Brugertype',
        ApplicableUserType: 'Anvendelig brugertype',
        RegularUser: 'Almindelig bruger',
        UseAigoCoinsOrNot: 'Brug Aigo-mønter eller ej',
        EndDateOfMembership: 'slutdato for medlemskab',
        StartDateOfMembership: 'startdato for medlemskab',
        memberOrNot: 'medlem eller ej',
        No: 'Nej',
        Yes: 'Ja',
        LowInventory: 'Lav lagerbeholdning',
        RechargeEmailbox: 'Genoplad e-mailboks',
        SubmittedSuccessfully: 'Indsendt korrekt',
        Importing: 'importerer',
        InformationOfTheRechargeFailed: 'Oplysninger om genopladningen mislykkedes',
        Remarks: 'Bemærkninger',
        RechargeFailed: 'Genopladning mislykkedes',
        RechargeInBatch: 'Genopladning i batch',
        RechargeSuccessfully: 'Genopladning gennemført korrekt',
        RechargeAigoCoins: 'Genoplad Aigo-mønter',
        PDFFormat: 'PDF-format',

        HaveAnAccount: "Don't have an account? Click here to register",
        SoldOut: 'Sold out',
        HaveAnInvitationCode: "Optional, leave blank if you don't have an invitation code.",
        RestockedItems: 'Restocked items',
        NotMeetPleaseEnterAgain: 'Nickname does not meet the requirements, please enter again.',
        ThisCouponIsOnlyAvailableForNewUsers: 'This coupon is only available for new users.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'This coupon is only available for existing users.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Only returns or exchanges of the same product are allowed.',
        With365DaysRemaining: '（Expires on 9/ 25/2024） with 365 days remaining',

        TheNicknameDoesNotMeet: 'The nickname does not meet the requirements, please enter a new one.',

        GoToView: 'Go to view',
        DoNotRegisterRepeatedly: 'Do not register repeatedly',
        ValidityPeriod: 'Validity period',
        RegisterSuccessfully: 'Register successfully',
        MemberRegistration: 'Member registration',
        RegistrationFailed: 'Registration failed',
        OneYear: 'One year',
        AddToShoppingCart: 'Add to shopping cart',
        AddANewAddress: 'Add a new address',
        ZIPCode: 'ZIP Code*',

        EditYourAddress: 'Edit your address',
        SetAsDefault: 'Set as Default',
        FailedChangeDefaultAddress: 'Failed to change default address, please try again later',
        Fullname: 'Full name(First and Last name)*',
        PhoneNumber: 'Phone number',
        DefaultAddressHasBeenChanged: 'default address has been changed',
        ViewComments: 'View comments',
        ClickToCheckLogisticsInfo: 'Click to check logistics info.',
        RestockingOfOutOfStockItems: 'Restocking of out-of-stock items',
        ThisWeekRestockedItems: "This week's restocked items",
        ProductListInTheShoppingCart: 'product list in the shopping cart',
        WarmReminderVideoResources: 'warm reminder: Video resources are only available for single download.',
        PleaseLeaveYourMessage: 'Please leave your message',

        //1.2.5 之前
        Isitaflashkill: 'Er det et flash-udsalg',
        Isitontheshelf: 'Er det på hylden',
        Areyousuretocontinuedeletingdata: 'Er du sikker på du gerne vil fortsætte med at slette data',
        Isthereafixedcode: 'Er der en fast kode',
        Isthereafixedprice: 'Er der en fast pris',
        Areyousuretoexportthefile: 'Vil du bekræfte eksporten af filen, vil du fortsætte?',
        Canwedeliverontheagreeddate: 'Kan du levere på den aftalte dato?',
        star1: '1 stjerne',
        stars2: '2 stjerner',
        intotal25: '25 varer i alt',
        stars3: '3 stjerner',
        stars4: '4 stjerner',
        stars5: 'Tilbudspris',
        to: 'Til',
        Inpromotion: 'På udsalg',
        Whethertopromoteornot: 'På udsalg eller ej',
        Tobereplied_consulted: 'at blive besvaret/konsulteret',
        UpdateRecords: 'Opdater optegnelser',
        UpdateResults: 'Opdater resultat',
        Isitpossibletooverlaydistribution: 'Kan stables med eller uden distribution',
        Canpromotionsbestacked: 'Kan stables med eller uden promovering',
        German: 'Tysk',
        Germany: 'Tyskland',
        Amazon: 'Amazon',
        Successfullyadded: 'Tilføjet korrekt',
        Successfullyadded1: 'Tilføjet korrekt',
        Successfullyadded2: 'Tilføjet korrekt',
        Add: 'Tilføj',
        Add1: 'Tilføj',
        addassociations: 'Tilføj association',
        Addremarks: 'Tilføj bemærkninger',
        Addlogisticscompany: 'Tilføj logistikvirksomhed',
        Addlogisticsconfiguration: 'Tilføj logistikkonfiguration',
        Addshippingstandard: 'Tilføj forsendelsesstandard',
        Addproduct: 'Tilføj produkt',
        Addtime: 'Tilføj tid',
        AddAssociatedCategory: 'Tilføj en tilknyttet kategori',
        Homebanner: 'Banner til hjemmet',
        Kitchenappliance: 'Køkkenmaskiner',
        Canitbestacked: 'Stabelbar eller ej',
        Overlayornot: 'stabel eller ej',
        Applying: 'Ansøgning i gang',
        Approved: 'Godkendt',
        Passed: 'Godkendt',
        file: 'Fil',
        Fileimportedsuccessfully: 'Filen er importeret korrekt',
        DragtheExcelfilehere_orclickonupload: 'Træk og slip excel-filen her, eller klik på Upload',
        ShoppingCartItems: 'Varer i indkøbskurven',
        Homepagearticle: 'Hjemmeside-artikler',
        Collectproducts: 'Foretrukne varer',
        Categoryassociation: 'Kategori tilknytning',
        Auditing: 'Revision',
        automatic: 'automatisk',
        Cancelleddeletion: 'annulleret sletning',
        Batchdeletion: 'Batch-sletning',
        QuerySearch: 'søg',
        search: 'Søg',
        Batchsearch: 'Batch-søgning',
        Expired: 'Udløbet',
        Modifyshippingaddress: 'Ændring af leveringsadresse',
        Canceled: 'Annulleret',
        Canceledsuccessfully: 'Annuller korrekt',
        CancelOrder: 'annuller ordren',
        Unsubscribe: 'annuller abonnement',
        quantity: 'Antal',
        Combinationquantity: 'antal kombinationer',
        Distributionquantity: 'Antal af distribution',
        Placinganorder: 'ordreantal',
        Promotionquantity: 'Antal på tilbud',
        Remainingquantity_combinedquantity: 'resterende antal / resterende kombineret antal',
        Pleaseuploadpictures: 'Upload venligst et billede',
        Thankyouletter: 'takkebrev',
        catalogue: 'Katalog',
        CatalogID: 'Katalog ID',
        Category: 'Kategori',
        category1: 'kategori',
        Class: 'Kategori',
        Relatedcategories: 'Tilknyttet kategori',
        Maincategory: 'Hovedkategori',
        Applicableclassification: 'Anvendelige kategorier',
        close: 'Luk',
        shoppingcart: 'Indkøbskurv',
        OrderCloseReturn: 'Lukning og returnering af ordre',
        city: 'By',
        Secondkillsorting: 'flash-udsalg sortering',
        PrimaryKeyID: 'Primær nøgle ID',
        barcode: 'Stregkode',
        Redemptioncode: 'Indløsningskode',
        Invitationcode: 'Invitationskode',
        CountryCode: 'Landekode',
        Returnlogisticstrackingcode: 'Kode til sporing af returlogistik',
        Couponcode: 'Kuponkode',
        Paymentcode: 'Betalingskode',
        TaxID: 'Skattekode',
        PostalCode: 'Postnummer',
        Batchlisting: 'Batch, der sættes på hylden',
        column: 'Kolonne',
        combination: 'Kombination',
        Evaluationcontent: 'kommentarindhold',
        ProductReview: 'Kommentarer til produkter',
        commission: 'Kommissioner',
        Pleasecompletetheshippingcostconfiguration: 'Udfyld venligst forsendelseskonfigurationen',
        Completed: 'Gennemført',
        complete: 'Gennemført',
        shopping: 'Indkøb',
        Rewardconditionsobtained: 'Betingelser for at opnå belønninger',
        allocation: 'Konfiguration',
        Freightallocation: 'Konfiguration af forsendelse',
        HomepageConfiguration: 'Konfiguration af startside',
        RuleConfiguration: 'Konfiguration af regler',
        Allocationofpatrioticcoins: 'Konfiguration af Aigo-mønter',
        PricingSettings: 'Indstillinger for prissætning',
        Nationalconfiguration: 'Konfiguration af land',
        Multilingualconfiguration: 'Konfiguration af flere sprog',
        Setlabel: 'Opsætning af tags',
        Configureshippingcosts: 'Konfigurer forsendelsesomkostninger',
        Setuppromotions: 'Indstil kampagne',
        Remittanceconfirmationsuccessful: 'Bekræftelse af korrekt overførsel',
        Confirmtoconverttheorderintoaformalorder: 'Bekræftelse af ordrekonvertering til officiel ordre',
        Confirm1: 'Bekræft',
        Areyousuretodelete: 'bekræft at slette eller ej',
        Areyousuretoexportthefileandcontinue: 'bekræft at eksportere filen eller ej, fortsæt eller ej',
        deleteconfirmation: 'Bekræft sletning af noden og alle oplysninger under noden eller ej',
        Confirmdeletionoftheselectedpicture_video: 'Bekræft for at slette valgt billede/video',
        Confirmtheexecutionofbatchdelisting: 'Bekræft for at udføre masseafnotering',
        ConfirmsynchronizationofSKU: 'Bekræft SKU-synkronisering',
        Obtainpatrioticcoinallocation: 'Få konfiguration af Aigo-mønter',
        consultingservice: 'Forespørgsel',
        query: 'Forespørgsel',
        content: 'Indhold',
        Consultationcontent: 'Forespørgslens indhold',
        Navigationcontent: 'Navigationens indhold',
        Replycontent: 'indholdet af svaret',
        Successfullycopied: 'Kopieret korrekt',
        CopyConnection: 'Kopier link',
        Emailaddress: 'E-mailadresse',
        mailbox: 'E-mail',
        Customerserviceemail: 'Kundeservice e-mail',
        Useremail: 'E-mail til bruger',
        Creator: 'Oprettet af',
        New: 'Opret',
        NewCountryInformationTableImageAddress: 'Nyoprettet informationsark om land, billede og adresse',
        NewCountryInformation: 'Opret nye oplysninger om land',
        Afterturningonsynchronization: 'Når synkronisering er aktiveret, synkroniseres følgende oplysninger for EBP-produkter pr. produkt-SKU',
        Inviteraccount: 'Inviterende konto (angiv konto for invitationskode, land for invitationskode)',
        Registeredaccount: 'Registreret konto (registreret land)',
        coupon: 'Kupon',
        SynchronizationrequiresdockingwithEBP: "Da synkronisering skal ske med EBP, kan batch-synkronisering udføres en gang hvert 30. minut for at undgå fejl. Vær tålmodig, da synkroniseringen udføres asynkront efter bekræftelse, og SKU'er vises, så snart de er tilgængelige.",
        OrderData: 'Ordredata',
        SEOdata: 'SEO-data',
        OrderIDmustbepassed: 'Ordre-id skal angives',
        Picturesmustbetransmitted: 'Billeder skal være uploadet',
        Deposittobepaid: 'Depositum, der skal betales',
        Depositpaid: 'Depositum betalt',
        Disabled: 'Deaktiver',
        download: 'Download',
        Downloadfiles: 'Download fil',
        DownloadImportTemplate: 'Download importskabelon',
        explain: 'Beskrivelse',
        ApplicationDescription: 'Beskrivelse af applikation',
        Illustrativeimage: 'Beskrivelse af billeder',
        PageDescription: 'Beskrivelse af side',
        ActivityDescription: 'Beskrivelse af begivenhed',
        Numberofexplanatoryimages: 'Beskrivelse af antallet af billeder',
        discount: 'Rabatter',
        MoveDown: 'Skift ned',
        Unbound: 'Ubundet',
        Detailed: 'Detaljer',
        Details: 'Detaljer',
        Synchronizeproductimagesandtextbylanguage: 'Billeddetaljer: synkroniser efter sprog for det tilsvarende sprog',
        UserDetails: 'Brugeroplysninger',
        ProductDetails: 'Detaljer om produktet',
        ProductDetails1: 'Detaljer om produktet',
        Return: 'Returnering',
        Returnedgoods: 'Returnering af varer',
        Returnsandexchanges: 'Returnering og ombytning',
        Returnandexchangeorders: 'Retur- og ombytningsordrer',
        Returned: 'Returneret',
        NationalSDPDictionary: 'national sdp ordbog',
        Address: 'Adresse',
        AddressAdditionalContent: 'Adresse yderligere indhold',
        Detailedaddress: 'detaljeret adresse',
        Defaultshippingaddress: 'Standard leveringsadresse',
        Invoiceaddress: 'Fakturareringsadresse',
        Defaultinvoiceaddress: 'Standard faktureringsadresse',
        Shippingaddress: 'Modtagelsesadresse',
        WebsiteAddress: 'Hjemmeside-adresse',
        IPaddress: 'ip-adresse',
        Shippable: 'Tilgængelig for levering',
        Logindevice: 'Login-enhed',
        Productdistribution: 'Produktdistribution',
        Currency: 'Valuta',
        Editedsuccessfully: 'Redigering korrekt!',
        Successfullyedited: 'Redigering korrekt',
        Edit: 'Redigér',
        EditCategory: 'Rediger kategori',
        Editlogisticsconfiguration: 'Rediger logistikkonfiguration',
        Editlogisticscompany: 'Rediger logistikvirksomhed',
        EditCountryInformation: 'Rediger oplysninger om land',
        Editlistingcountry: 'rediger lande, hvor produkter sættes på hylden',
        EditFreightStandard: 'Rediger forsendelsesstandard',
        EditFixedPrice: 'Rediger fast pris',
        EditProduct: 'Rediger produkt',
        EditingInformationServices: 'Rediger informationstjeneste',
        Countrycodecannotbeempty: 'Landekode må ikke være tom',
        Countrycodecannotbeempty1: 'Landekode kan ikke være tom',
        Postalcodecannotbeempty: 'Postnummer må ikke være tomt',
        Postalcodealreadyexists: 'Postnummer findes allerede',
        Replycontentisrequired: 'Svarindhold er påkrævet',
        Richtextcontentonthepagecannotbeempty: 'Sidens rich text-indhold kan ikke være tomt',
        Apieceofcontentalreadyexists: 'Der findes allerede et indhold',
        Medialinkcannotbeempty: 'Medielink kan ikke være tomt',
        Statusenable_disablecannotbeempty: 'Status Aktiver/Deaktiver kan ikke være tom',
        Thereareonlytworemittancestatuses: 'Status for overførslen kan kun være godkendt eller ikke godkendt',
        Iconmustbeuploaded: 'Ikonet skal være uploadet',
        Themediaiconcannotbeempty: 'Medieikonet kan ikke være tomt',
        Thecountryiconcannotbeempty: 'Lande-ikonet kan ikke være tomt',
        Languagecannotbeempty: 'Sprog kan ikke være tomt',
        Norulesconfigured: 'Den aktuelle lejer har ingen Aigo-møntregler konfigureret',
        CurrentactivitypricesinSpain: 'Bruttomarginen på den aktuelle aktivitetspris i Spanien er 0,29%.',
        Lastmonth: 'Sidste måned',
        Positiveconversionsingletime: 'tidspunktet for overførsel til den officielle ordre',
        Inventoryofselecteditems: 'Multiplikatoren for lager/salg for den valgte vare kan ikke være mindre end aktivitetsantal',
        TheEnglishcategorynamecannotbeempty: 'Det engelske kategorinavn kan ikke være tomt',
        Medianamecannotbeempty: 'Mediets navn kan ikke være tomt',
        Namecannotbeempty: 'Navn må ikke være tomt',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'Antallet af indløsningskoder kan kun være større end det oprindelige antal',
        Transferredtoofficial: 'Ordrenummer 101 er blevet overført til en officiel ordre, fravælg venligst først',
        Numberofselectedcomments: 'antallet af valgte anmeldelser',
        Doyouwanttodelete: 'det udfyldte antal er for stort',
        Countrycannotbeempty: 'Land kan ikke være tomt',
        Orderdoesnotexist: 'Ordren eksisterer ikke',
        Thisordercannotbecancelled: 'Ordren kan ikke annulleres',
        Notapresaleorder: 'Ordrenummer 101 er en ikke-forudbestilt ordre, fjern venligst markeringen først',
        Ordernotpaid: 'Ordrenummer 101 er ikke en betalt ordre, annullér den først',
        Existinglogisticstrackingnumber: 'Ordren har et logistisk sporingsnummer og kan ikke indsendes',
        Thecurrentcountrypricingisnotconfigured: 'Den aktuelle landepris er ikke konfigureret',
        Combinationpricecannotbeempty: 'Kombinationsprisen kan ikke være tom',
        Thesizeoftheuploadedimagecannotexceed: 'Størrelsen på det uploadede billede må ikke overskride',
        Thepagetitlecannotbeempty: 'Sidens titel må ikke være tom',
        Chinesetitleisrequired: 'Kinesisk titel er påkrævet',
        Selectedcountrytitleisrequired: 'Titel for det valgte land er påkrævet',
        Titlecannotbeempty: 'Titlen kan ikke være tom',
        Orderamountfull: 'Ordrebeløbet når op på',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'Slutværdien skal være større end eller lig med startværdien',
        Successfullyremoved: 'Fjernet korrekt',
        Delete: 'Slet',
        Remove: 'Fjern',
        Logisticscompany: 'Logistikvirksomhed',
        RecipientCompany: 'Modtagende virksomhed',
        RecipientCompanyAddressAdditionalContent: 'Modtagervirksomhed, adresse yderligere indhold',
        Common: 'i alt',
        Link: 'Link',
        JumpLink: 'Spring link',
        Manuallogisticsshipment: 'Manuel levering af logistik',
        Manualshipping: 'Manuel levering',
        Successfullyshippedmanually: 'Manuel levering korrekt',
        Whethertomanuallyship: 'Manuel levering eller ej',
        Shipped: 'Afsendt',
        SubmitConfirmation: 'Send bekræftelse',
        Whethertosubmitdata: 'Indsend data eller ej',
        Freeshipping: 'Gratis forsendelse',
        Firstfreeshippingitem: 'Gratis forsendelse af varer på din første ordre',
        Freeshippingonfirstorder: 'Gratis fragt på din første ordre',
        Freeshippingornot: 'Gratis forsendelse eller ej',
        Isthefirstorderfreeofshipping: 'Gratis fragt eller ej på din første ordre',
        Failedtouploadfile_Pleasecheckthefilecontent: 'Filupload mislykkedes, tjek venligst filens indhold',
        Fileimportfailed_pleasecheckthefilecontent: 'Filimport mislykkedes, tjek venligst filens indhold',
        DeleteError: 'Fejl ved sletning',
        Parametererror: 'fejl i parametre',
        Selectedcountrycontentisrequired: 'Det valgte lands indhold er påkrævet',
        Pleaseenterthelanguage: 'Indtast venligst sproget',
        Pleaseentertheavailableamount: 'Indtast venligst det tilgængelige beløb',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Indtast venligst antallet af anvendelser for den samme bruger',
        Pleaseentersorting: 'Indtast venligst ordren',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Indtast venligst det tilgængelige beløb på 0 for at angive ubegrænset',
        Pleaseenterthecreationtime: 'Indtast venligst oprettelsestidspunktet',
        Entercoupondescription: 'Indtast voucher-instruktioner',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Indtast venligst, hvis det vises nederst på hjemmesiden',
        Pleasefillinthepostalcode: 'Udfyld venligst dit postnummer',
        Pleaseenterthepostalcodecorrectly: 'Indtast venligst dit postnummer korrekt',
        Pleaseentertheemailcorrectly: 'Indtast venligst din e-mail korrekt',
        Pleaseenteracomment: 'Indtast venligst en bemærkning',
        Pleaseenteracolumnname: 'Indtast venligst et kolonnenavn',
        Pleaseenteratitle: 'Indtast venligst en titel',
        Pleaseenteradescription: 'Indtast venligst en beskrivelse',
        Inputquantity: 'Indtast antal',
        Enterjumplink: 'Indtast springlink',
        EnterName: 'Indtast navn',
        Boutiquerecommendationspace: 'Boutique-anbefalingsrum',
        Spain: 'Spanien',
        Spanish: 'Spanien',
        Thisoperationwillremovethefile: 'Denne handling vil fjerne filen, ønsker du at fortsætte?',
        Areyousuretoremovethisproduct: 'Er du sikker på, at du vil fjerne dette produkt?',
        datastatistics: 'Statistik',
        Orderdatastatistics: 'Ordrestatistik',
        Transactionstatistics: 'Transaktionsstatistik',
        State: 'Status',
        Loginstatus: 'Login-status',
        Listingstatus: 'status for hylde',
        InvoiceStatus: 'Status for faktura',
        Claimstatus: 'Status for opkrævning',
        ReplyStatus: 'Status for svar',
        Auditstatus: 'Status for gennemgang',
        Remittancestatus: 'Overførselsstatus',
        Preorderstatus: 'Status for forudbestilling',
        Processingstatus: 'Status for behandling',
        Usagestatus: 'Status for brug',
        Shipmentstatus: 'Status for forsendelse',
        Paymentstatus: 'Status for betaling',
        OrderStatus: 'Status for ordre',
        Statuseditedsuccessfully: 'Status redigeret korrekt',
        UnitedStates: 'USA',
        Areyousuretocancelthisorder: 'Er du sikker på, at du gerne vil annullere ordren?',
        thisyear: 'I år',
        Presaleproductscannotbeadded: 'Dette er en pre-sale vare og kan ikke tilføjes som en combo',
        Thismonth: 'Denne måned',
        style: 'Stil',
        RatingStarRating: 'Bedømmelse Stjerne',
        label: 'Mærker',
        Euro: 'Euro',
        success: 'Succes',
        SEOManagementExport: 'SEO Management Export',
        export: 'Eksport',
        Exportsuccessful: 'Eksport korrekt',
        invoice: 'Faktura',
        Auditfailed: 'Mislykket',
        Savefailed: 'gem mislykkedes',
        Editingfailed: 'Redigering mislykkedes',
        Verificationfailed: 'Verifikation mislykkedes',
        Createdon: 'oprettelsesdato',
        Rebateendtime: 'sluttidspunkt for rabat',
        CommentEndDate: 'slutdato for kommentarer',
        Startdate: 'Startdato',
        Rebatestarttime: 'starttidspunkt for rabatten',
        Commentstartdate: 'startdato for kommentarerne',
        Consultationdate: 'Dato for forespørgsel',
        Additionalpurchasetime: 'tidspunktet for tilføjelse af varer til ønskelisten',
        Releasetime: 'Dato for frigivelse',
        Lastordertime: 'Tidspunkt for sidste ordre',
        Enddate: 'Slutdato',
        Paymentdeadline: 'betalingsfrist',
        Setcombinationdiscount: 'Indstil kombinationsrabat',
        Auditended: 'Afslutning af revision',
        Paymentmethod: 'betalingsmetoden',
        Presalepaymentmethod: 'betalingsmetoden for forsalg',
        ForcesynchronizationofEBP: 'Om EBP-synkronisering skal gennemtvinges',
        French: 'Fransk',
        France: 'Frankrig',
        frequency: 'Hyppighed',
        Consultationfrequency: 'hyppighed af forespørgsler',
        source: 'Kilde',
        SubscriptionSource: 'Kilde til abonnement',
        expenditure: 'Udgift',
        Orderexpenses: 'Ordreudgifter',
        PortfolioManagement: 'Porteføljestyring',
        Categorymanagement: 'Kategoristyring',
        marketingmanagement: 'Marketing management',
        ordermanagement: 'Ordrehåndtering',
        ProductManagement: 'Produktstyring',
        Logmanagement: 'Log management',
        subscriptionmanagement: 'Administration af abonnementer',
        usermanagement: 'Brugeradministration',
        VariantManagement: 'Variant Management',
        BannerManagement: 'Bannerstyring',
        WebsiteManagement: 'Site Management',
        SEOManagement: 'SEO Management',
        Britain: 'Storbritannien',
        Successfullysaved: 'Gemt korrekt',
        EnablePlatform: 'Aktivér platform',
        Consultationrecords: 'Forespørgselshistorik',
        BrandStory: 'Brand-historier',
        Dutchlanguage: 'Hollandsk',
        time: 'Tid',
        Remittanceconfirmationoperationtime: 'Tidspunkt for bekræftelse af overførsel',
        logintime: 'Login-tid',
        Subscriptiontime: 'Tidspunkt for abonnement',
        Modificationtime: 'Ændringstidspunkt',
        Receivingtimeprompt: 'Modtagelsestid prompt',
        Collectiontime: 'Indsamlingstid',
        Displaytime: 'Visningstidspunkt',
        Changetime: 'Tidspunkt for ændring',
        Activitytime: 'Tidspunkt for hændelse',
        Ordertime: 'Tidspunkt for ordre',
        today: 'i dag',
        icon: 'Ikoner',
        CommentID: 'Kommentar-ID',
        RegistrationInviterID: 'Registreret indbyder-ID',
        UserID: 'Bruger-ID',
        UserID_loginemail_registrationinviterID_phonenumber: 'Bruger-ID / login-e-mail / registreret inviteret ID / mobiltelefonnummer',
        ProductCatalogID: 'Produktkatalog ID',
        ProductID1: 'Produkt-ID',
        ReviewerId: 'Reviewer Id',
        UserID_IP_LoginDevice: 'Bruger-id/ip/login-enhed',
        ProductID: 'Produkt-ID',
        language: 'Sprog',
        PictureLanguage: 'Sprog for billede',
        picture: 'Billede',
        Productimage1: 'Produktbillede',
        ProductLargePicture: 'Produktbillede',
        Synchronizeproductimagesbylanguage: 'Produktbillede: Synkroniser billedet på det tilsvarende sprog efter sprog, læs EBP-billedsorteringen, det første EBP-billede indstilles automatisk som hovedbillede.',
        Largeimage: 'Stort billede',
        ImageID: 'Billede-ID',
        Mainimage: 'Hovedbillede',
        Isitthemainimage: 'Hovedbillede eller ej',
        Pictureandtext: 'billede og tekst',
        Pictures_Videos: 'Billede/video',
        Productimage: 'Produktbilleder',
        SEOManagementImport: 'Import af SEO-styring',
        Import: 'Importer',
        ImportFile: 'Importer filer',
        money: 'Beløb',
        Paymentamount: 'Betalingsbeløb',
        Orderamount: 'Ordrebeløb',
        Fullreductionamount: 'cash back-beløb',
        Availableamount: 'tilgængeligt beløb',
        Maximumdeductionamount: 'Maksimalt fradragsbeløb',
        Ordercumulativeamount: 'Samlet ordrebeløb',
        Basicinformation: 'Grundlæggende information',
        Basicinformationoftheorder: 'Grundlæggende ordreoplysninger',
        Contactinformation: 'Kontaktoplysninger',
        OrderInformation: 'Ordreoplysninger',
        Preorderinformation: 'Information om bestilling før salg',
        ProductInformation: 'produktinformation',
        Paymentinformation: 'Information om betaling',
        Sizeinformation: 'Information om størrelse',
        VariantInformation: 'Information om varianter',
        Exportorderinformation: 'oplysninger om eksportordre',
        Socialmediainformation: 'Information om sociale medier',
        English: 'Engelsk',
        Pleaseenteradeliveryprompt: 'Indtast venligst en kvitteringspåmindelse',
        income: 'Indkomst',
        Thirdpartylogin: 'Tredjeparts login',
        tenant: 'Lejere',
        AuditDescription: 'Gennemgå instruktioner',
        Exchangeitem: 'Ombytning af varer',
        Exchange: 'Ombytning',
        Pleaseenteracombinationname: 'Indtast venligst et kombinationsnavn',
        Pleaseenteraparametername: 'Indtast venligst parameternavnet',
        Pleaseinputthenumbercorrectly: 'Indtast venligst det korrekte antal',
        Pleaseenterthecombinationquantity: 'Indtast venligst antallet af kombinationer',
        Pleaseenterthecountry: 'Indtast venligst land',
        Upto100wordscanbeentered: 'Indtast op til 100 tegn',
        Pleaseentertheshippingcost: 'Indtast venligst forsendelsesomkostningerne',
        Detailedcontentofgraphicsandtext: 'Indtast venligst de grafiske detaljer på engelsk først',
        Pleaseenteralink: 'Indtast venligst et link',
        Pleaseenterthelogisticscompany: 'Indtast venligst et logistikfirma',
        EnterReply: 'Indtast et svar',
        Enterredemptioncode: 'Indtast indløsningskode',
        Enteraquantitybetween0and1000: 'Indtast et antal på mellem 0 og 1000',
        Pleaseenterbarcode: 'Indtast venligst stregkode',
        Enterproductbarcodeandsubmitforaddition: 'Indtast produktets stregkode for at indsende tilføjelser, flere stregkoder skal indtastes i en ny linje',
        Pleaseenterthemaintext: 'Indtast venligst brødtekst',
        Enterthereason: 'Indtast årsagen, du skal udfylde årsagen, hvis ansøgningen ikke er godkendt',
        Pleaseenterthenumberofredemptioncodes: 'Indtast venligst antallet af indløsningskoder',
        Pleaseenterthequantityofpatrioticcoins: 'Indtast venligst antallet af Aigo-mønter',
        PleaseenterthepageURL: 'Indtast venligst sidens URL',
        Enterproductbarcode: 'Indtast produktstregkoder, flere stregkoder skal indtastes i en ny linje op til 500',
        Pleaseenterthelogisticstrackingnumber: 'Indtast venligst sporingsnummeret',
        Consultationinstructions: 'Instruktioner til forespørgsler',
        inventory: 'optælling af lagerbeholdning',
        Invitetoreceivepatrioticcoins: 'Inviter til at få Aigo-mønter',
        goto: 'Gå til',
        JumpAddress: 'Spring til adresse',
        Gotopage1: 'Gå til side 1',
        Italy: 'Italien',
        Italian: 'Italiensk',
        Thepriceconfigurationdoesnotexist: 'Priskonfiguration findes ikke, venligst konfigurer pris',
        Registrationsuccessfulnot1: 'Korrekt kontoregistrering uden invitationskode',
        Pagedescriptioncannotbeempty: 'Sidebeskrivelsen må ikke være tom',
        Thecustomerserviceemailaddresscannotbeempty: 'Kundeservices e-mailadresse må ikke være tom',
        Thewebsiteaddresscannotbeempty: 'Webstedsadresse må ikke være tom',
        Currencycannotbeempty: 'Valuta kan ikke være tom',
        Inputcannotbeempty: 'Input kan ikke være tomt',
        Positiveconversionsinglemode: 'måden at overføre til den officielle ordre',
        Contactinformationcannotbeempty: 'Kontaktoplysninger må ikke være tomme',
        No_Illegalrowdata: 'Den ? linje med data er ulovlig',
        Thelengthcannotexceed2characters: 'Længden må ikke overstige 2 tegn',
        Thelengthcannotexceed50characters: 'Længden må ikke overstige 50 tegn',
        ThelengthcannotexceedXcharacters: 'Længden må ikke overstige {num} tegn',
        Brandcannotbeempty: 'Mærket må ikke være tomt',
        Brandandlanguagemustbefullycapitalized: 'Brand og sprog skal være med store bogstaver',
        Brandandcountrycannotbeempty: 'Mærke og land kan ikke være tomme',
        Operationsuccessful: 'Vellykket operation',
        Thereplycontentwillbedisplayedtotheuser: 'Svaret vil blive vist til brugerne',
        PageURLcannotbeempty: 'Sidens URL må ikke være tom',
        Publishinginprogress: 'frigivelse i gang',
        Numberoflocalcurrencies: 'De antal, der er konfigureret nedenfor, er i den nationale valuta i det tilsvarende land',
        Orderremarksarerequired: 'Bestillingsnoter er påkrævet',
        Pagekeywordscannotbeempty: 'Sidens nøgleord må ikke være tomme',
        Thereasonfornotpassingwillbedisplayedtotheuser: 'Årsager til ikke at bestå vil blive vist til brugeren',
        pound: 'Pund',
        Singleorderlimit: 'Begrænset til en enkelt ordre',
        OrderList: 'Bestillingsliste',
        Between1and50charactersinlength: 'Længde på mellem 1 og 50 tegn',
        Lengthgreaterthan100characters: 'Længde større end 100 tegn',
        Lengthgreaterthan20characters: 'Længde større end 20 tegn',
        Required: 'Felter markeret med ' * ' er obligatoriske',
        CanIusecoupons: 'kuponer kan bruges eller ej',
        Datadoesnotexist: 'Data findes ikke',
        Dataalreadyexists: 'Data findes allerede',
        Nonpresaleorderscannotbeoperated: 'Ikke-forhåndssalgsordrer er ikke tilgængelige',
        Transferredtoformalformandcannotbeoperated: 'Overførte ordrer er ikke tilgængelige',
        Manual: 'manual',
        brand: 'Brand',
        Grossmargin: 'Bruttomargin',
        Pleasecheckfirst: 'Sæt venligst kryds først',
        Upto500wordscanbeentered: 'Maksimalt 500 tegn',
        Successfullyaddedinformation: 'Tilføj besked korrekt',
        Successfullydeletedinformation: 'Slet besked korrekt',
        Successfullyeditedinformation: 'Rediger besked korrekt',
        mode: 'Metode',
        Loginmethod: 'Login-metode',
        MyOrder: 'Min ordre',
        Successfullymodified: 'Ændring gennemført korrekt',
        Patrioticcoin: 'Aigo-mønter',
        Displaypromotionalprices: 'Om man vil vise kampagneprisen',
        Isitdisplayedatthebottomofthewebsite: 'Vises eller ej i bunden af hjemmesiden',
        Displayornot: 'Vis eller ej',
        Whethertodisplayonthehomepage: 'Om det skal vises på forsiden',
        ShowCountry: 'Vis land',
        Whethertodisplayall: 'Vis alle eller ej',
        Reasonforcancellationoforderisrequired: 'Årsag til annullering af ordre påkrævet',
        Salesmultiples: 'Salgsmultiplikator',
        WebsiteNavigation: 'Navigation på siden',
        nickname: 'Kælenavn',
        nothing: 'Ingen',
        Directoryhierarchy: 'Katalogniveau',
        Failed: 'Ikke godkendt',
        Unaudited: 'Ikke revideret',
        Notuploaded: 'Ikke uploadet',
        Thelengthcannotexceed100characters: 'Længden må ikke overstige 100 tegn',
        Thelengthcannotexceed20characters: 'Længden må ikke overstige 20 tegn',
        Nonpaidorderscannotbeoperated: 'Ikke tilgængelig for ikke-betalte ordrer',
        Notshipped: 'Ikke afsendt',
        Nocountrieslisted: 'Ingen lande, hvor produkterne er sat på hylden',
        Donotmodify: 'Du må ikke ændre de første tre linjer i skabelonens titel',
        Notpassed: 'ikke gennemført',
        Notlisted: 'ikke på hylden',
        Cannotbe0: 'Kan ikke være 0',
        Notclaimed: 'Ikke indsamlet',
        Nopatrioticcoinrulesconfigured: 'Ingen regler for Aigo-mønter konfigureret',
        Noinventorydisplay: 'Ingen visning af lagerbeholdning',
        notused: 'Ikke brugt',
        name: 'navn',
        name1: 'Navn',
        ColumnName: 'Kolonnenavn',
        DistributionName: 'Navn på distribution',
        CategoryName: 'Kategori navn',
        Categoryname1: 'Kategori-navn',
        RelatedCategoryName: 'Tilknyttet kategorinavn',
        Combinationname: 'Kombinationsnavn',
        PromotionName: 'Navn på kampagne',
        PromotionName_ProductID_Barcode: 'Kampagnenavn/Produkt-ID/Stregkode',
        DirectoryName: 'Navn på katalog',
        CouponName: 'Kuponnavn',
        ActivityName: 'Navn på begivenhed',
        Countryname: 'Landets navn',
        ProductName: 'Produktnavn',
        Productname1: 'Produktnavn',
        TopicName: 'Emne navn',
        TitleName: 'Navn på titel',
        CustomName: 'Brugerdefineret navn',
        VariantName: 'Variant navn',
        normal: 'Normal',
        Freightstandard: 'Standard for forsendelse',
        RegulationsonReturnsandRefunds: 'Regler for returnering og tilbagebetaling',
        Documentremarks: 'Bemærkninger om ordren',
        number: 'Antal',
        Numberofitems: 'Antal varer',
        Numberofproductmodels: 'Antal varer',
        Housenumber: 'husnummer',
        Numberofredemptioncodes: 'Antal koder',
        Numberofpatrioticcoins: 'Antal Aigo-mønter',
        Numberofchangesinpatrioticcoins: 'Antal Aigo-mønter ændret',
        Numberofpatrioticcoinsafterchanges: 'Antal Aigo-mønter efter ændring',
        Ordernumber: 'Ordrenummer',
        OrderNumber_UserID: 'Ordrenummer/bruger-ID',
        Ordernumber_UserID_Taxnumber: 'Ordrenummer/bruger-ID/afgiftskode',
        Numberofproducts: 'Antal produkter',
        Productquantity: 'Antal produkter',
        Numberofevaluations: 'antallet af anmeldelser',
        Numberofapprovedevaluations: 'antallet af godkendte anmeldelser',
        Trackingnumber: 'Sporing af ordrenummer',
        Logisticstrackingnumber: 'Logistisk sporingsnummer',
        Serialnumber: 'Serienummer',
        Subordernumber: 'underordrenummer',
        Mobilephonenumber: 'Mobiltelefonnummer',
        Numberofusesbythesameuser: 'Antal anvendelser for den samme bruger',
        Numberofusersplacingorders: 'Antal brugere, der afgiver ordrer',
        ObtainingPatrioticCoinObject: 'genstande for at få Aigo-mønter',
        Shippingremarks: 'Forsendelsesbemærkninger',
        Hideemptyparameters: 'Skjul tomme parametre',
        LabelOptions: 'Etiketindstillinger',
        Remittanceconfirmationoperator: 'Operatør til bekræftelse af overførsel',
        Manualshippingoperator: 'Operatør for manuel forsendelse',
        Operator: 'Operatører',
        sort: 'Sorter efter',
        Otherissues: 'Andre spørgsmål',
        Otherissuesrelatedtotransportation: 'Andre spørgsmål om forsendelse',
        other: 'Andet',
        Paid: 'betalt',
        page: 'Side',
        Fullpayment: 'Fuld betaling',
        Finaldeposit: 'Endelig betaling af depositum',
        DistributionCountry: 'Land for distribution',
        PromotionCountry: 'Kampagneland',
        Registeredcountry: 'Land for registrering',
        Countryofuse: 'Land for brug',
        SalesCountry: 'Land for salg',
        Netherlands: 'Holland',
        Countrieslisted: 'lande, hvor produkterne er sat på hylden',
        Pagekeywords: 'Søgeord på siden',
        Productkeywords: 'Nøgleord for produkt',
        Pendingreview: 'Skal anmeldes',
        ReviewerName: 'Anmelderens navn',
        parameter: 'Parameter',
        Associatesearchparameters: 'Tilknyttede søgeparametre',
        Relatedimportantparameters: 'Relaterede vigtige parametre',
        Variantparameters: 'Variant-parametre',
        Partialshipment: 'Delvist afsendt',
        adopt: 'Bestået',
        Whetherthepresaleorderisconvertedtoformal: 'Forudbestilling til officiel eller ej',
        Combinedorders: 'Kombineret ordre',
        Officialorder: 'Officiel ordre',
        orderform: 'Bestillinger',
        Presaleorder: 'Forudbestilling',
        PaymentOrder: 'Betalte ordrer',
        Distributiontime: 'Distributionstid',
        Promotiontime: 'Tid til promovering',
        Effectivetime: 'Gyldig tid',
        weight: 'Vægt',
        SEOConfigurationTemplate: 'SEO-konfigurationsskabelon',
        SEOManagementImportTemplate: 'Importskabelon til SEO-styring',
        platform: 'Platform',
        DisplayPlatform: 'Visningsplatform',
        power: 'Kraft',
        Polishlanguage: 'Polsk',
        poland: 'Polen',
        Listed: 'sat på hylde',
        Topwithlargenumbers: 'Standard er 0, jo større tallet er, jo højere er rangeringen',
        Countbyday: 'pr. dag',
        Pleaseselectacomment: 'Vælg venligst en anmeldelse',
        Pleasecompletethecommissionconfiguration: 'Udfyld venligst provisionskonfigurationen',
        PleasecompleteGrossmarginconfiguration: 'Udfyld venligst konfigurationen af bruttosatsen',
        Pleasecompletetheweightrangeconfiguration: 'Fuldfør konfigurationen af vægtintervallet',
        Pleasecompletethetaxrateconfiguration: 'Udfyld venligst konfigurationen af afgiftssatsen',
        Pleaseentertheproducttitle: 'Indtast venligst produkttitel',
        Pleaseenterthelinkcorrectly: 'Indtast venligst linket korrekt',
        Pleaseenterthesource: 'Indtast venligst kilden',
        Pleaseenteraname: 'Indtast venligst et navn',
        Pleaseenterthereason: 'Indtast venligst en årsag',
        Pleaseenterareply: 'Indtast venligst et svar',
        Pleaseenterapositiveinteger: 'Indtast venligst et positivt heltal',
        Pleaseselectaresult: 'Vælg venligst resultatet',
        Pleaseselectparameters: 'Vælg venligst parametre',
        Pleasechoosewhethertocontinueapplying: 'Vælg venligst, om du kan fortsætte med at ansøge',
        Pleaseselecttherecommendedmodule: 'Vælg venligst et anbefalingsmodul',
        Pleaseselectacategory: 'Vælg venligst en kategori',
        Pleaseselectabrand: 'Vælg venligst et brand',
        Pleaseselect: 'Vælg venligst',
        Pleaseselectastatus: 'Vælg venligst status',
        Pleaseselectasource: 'Vælg venligst kilde',
        Pleaseselectatime: 'Vælg venligst et tidspunkt',
        Pleaseselectacategoryfirst1: 'Vælg venligst en kategori først',
        Pleaseentercontent: 'Indtast venligst indhold',
        Hourlystatistics: 'pr. time',
        Portugal: 'Portugal',
        price: 'Pris',
        Currentprice: 'Nuværende pris',
        Combinationprice: 'Kombinationspris',
        Costprice: 'Kostpris',
        DistributionPrice: 'Distributionspris',
        Distributionprice1: 'Distributionspris',
        CollectionPrice: 'Kollektionspris',
        Additionalpurchaseprice: 'Prisen på de varer, der tilføjes til ønskelisten',
        price1: 'Salgspris',
        Sellingprice_unitprice: 'Salgspris (enhedspris)',
        commodityprice: 'Produktpris',
        Fixedprice1: 'Fast pris',
        Lowestprice: 'Laveste pris',
        PromotionPrice: 'Kampagnepris',
        Promotionprice1: 'Kampagnepris',
        Promotionprice_unitprice: 'Kampagnepris (enhedspris)',
        Totalprice: 'Samlet pris',
        Combinedtotalprice: 'Samlet pris for kombinationen',
        Pricing_unitprice: 'Prisfastsættelse (enhedspris)',
        Freeshippingprice: 'Priser for gratis forsendelse',
        LogisticsPricing: 'Priser for logistik',
        Presaleornot: 'Forsalg eller ej',
        Thisisalreadythefirstitemmoveupfailed: 'Første vare allerede, kunne ikke rykke op',
        Youcanonlybatchprocessupto100itemsatatime: 'Batchbehandling af op til 100 ordrer ad gangen',
        handle: 'Proces',
        Applicableproducts: 'Gældende produkter',
        HotSellingZone: 'Hotte produkter',
        ProductPromotion: 'Produktkampagner',
        Comingsoon1: 'Gælder snart',
        ParameterProperties: 'Parameter egenskaber',
        Provideinvitationcode: 'Giv invitationskoden til andre brugere, andre brugere bruger invitationskoden til at registrere sig og derefter gennemføre en ordre (hver inviteret bruger kan kun få 1 bonus)',
        province: 'Provins',
        Comingsoon: 'frigives snart',
        Areyousureyouwanttodeleteit: 'Ønsker du at slette?',
        Weightrange_end_kg: 'Vægtinterval (slut) kg',
        Weightrange_start_kg: 'Vægtinterval (start) kg',
        reason: 'Begrundelse',
        Shoppingrebate: 'Shopping-rabatter',
        Backendrecharge: 'baggrundsopladning',
        Received: 'modtaget',
        Receiveddamagedproductsandproductswithqualityissues: 'Modtag beskadigede produkter og produkter med kvalitetsproblemer',
        Receivedincorrectproduct: 'Modtag det forkerte produkt',
        NewProductZone: 'Nyankomne varer',
        collection: 'kollektion',
        Categoryrecommendation: 'Anbefaling af kategori',
        Homepagerecommendation: 'Anbefaling af startside',
        Productrecommendations: 'Anbefaling af produkt',
        Fullproductrecommendation: 'Anbefaling af alle produkter',
        Recommendedforyou: 'Anbefalet til dig',
        Recommendedforyou1: 'Anbefalet til dig',
        Recommendationmodule: 'Anbefal modul',
        Recommendedlocation: 'Anbefal placering',
        Orderrewards: 'Ordrebelønninger',
        Receivingandshippingreminder: 'Påmindelse om modtagelse og forsendelse',
        Aigostarbrandnetwork: 'aigostar brand netværk',
        Socialmedia: 'Sociale medier',
        Fullreduction: 'Penge tilbage',
        refund: 'Tilbagebetaling',
        Fullreductionoffreight: 'cash back af fragt',
        Orderrebate: 'Ordre-rabat',
        Registrationsuccessfulnot: 'Korrekt registrering (brug ikke invitationskode)',
        Registrationsuccessful: 'Registrering vellykket (brug invitationskode)',
        Usinganinvitationcode: 'korrekt registrering af konto ved at bruge invitationskoden',
        LoginLog: 'Login-log',
        Patrioticcoinusagelog: 'Log over brug af Aigo Coins',
        strip: 'optegnelser',
        Pleasecompletetheproductinformationbeforelistingit: 'Udfyld venligst produktinformationen, før du lægger den på hylden',
        Bankremittance: 'Bankoverførsel',
        Remittanceconfirmation: 'Overførsel bekræftet',
        Evaluation_Shown_total: 'Anmeldelser (vist/total)',
        Replied: 'Besvaret',
        reply: 'Svar',
        Replysuccessful: 'Besvaret korrekt',
        Respondent: 'Besvaret af',
        Reset: 'Nulstil',
        surplus: 'Resterende',
        Purchaserestrictionsforthesameuser: 'købsbegrænsninger for den samme bruger',
        result: 'Resultat',
        Inventorybottomline: 'lagerbeholdningens bundlinje',
        Reviewedby: 'Gennemgået af',
        Auditsuccessful: 'Vellykket gennemgang',
        Batchreview: 'Batch-gennemgang',
        Finalpaymenttobemade: 'saldo, der skal betales',
        Finalpaymentpaid: 'saldo betalt',
        Doyousupporttheuseofcoupons: 'Om brugen af kuponer skal understøttes',
        Istheexchangecodefixed: 'om indløsningskoden skal rettes',
        Downloadsuccessful: 'Download korrekt',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'Der kan maksimalt indtastes fire decimaler',
        Caninput0andpositiveintegers: '0 og positive heltal kan indtastes, symboler kan kun indtastes som % og op til to decimaler',
        Selected: 'Valgt',
        Pleaseselect25items: 'Vælg venligst 2-5 varer',
        Pleaseselectshippingcost: 'Vælg venligst forsendelsesomkostninger',
        SelectDateTime: 'Vælg dato og klokkeslæt',
        Selectupto5: 'Vælg op til 5',
        Selectapictureorvideo: 'Vælg venligst billedet eller videoen i galleriet først!',
        Pleaseselectacategoryfirst: 'Vælg venligst en kategori først',
        Pleasechoosewhethertousecoupons: 'Vælg, om du vil bruge kuponen',
        Pleaseselectaproductthathasbeenlisted: 'Vælg en vare, der er blevet sat på hylderne',
        Pleaseselectadaterange: 'Vælg venligst et datointerval',
        Pleaseselecttheenddate: 'Vælg venligst en slutdato',
        Pleaseselectastartdate: 'Vælg venligst en startdato',
        Pleaseselecttherecommendedlocation: 'Vælg venligst en anbefalet placering',
        Selectionsuccessful: 'Vælg med succes',
        Selectupto50: 'Vælg op til 50',
        SelectNavigation: 'Vælg navigation',
        Selectparameters: 'Vælg parametre',
        Selectvariantparameters: 'Vælg variantparametre',
        Selectproduct: 'Vælg produkt',
        SelectAll: 'Vælg alle',
        Selectvariant: 'Vælg en variant',
        Pleaseselectatleastonecountry: 'Vælg venligst mindst ét land',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: 'Vælg venligst brugerens forbrugshistorik for Aigo-mønter',
        Pleaseselectapresaleorder: 'Vælg ordre før salg',
        Pleaseselectaninformationservice: 'Vælg venligst informationstjenesten',
        Selectfrompicturelibrary: 'Vælg fra billedgalleriet',
        Selectmaincategory: 'Vælg hovedkategori',
        Pleaseselectpaymentmethod: 'Vælg venligst betalingsmetode',
        Pleaseselectaproduct: 'Vælg venligst produkter',
        Pleaseselectaproductfirst: 'Vælg venligst en vare først',
        Pleaseselectatimerange: 'Vælg venligst et tidsinterval',
        Pleasechooseapaymentmethod: 'Vælg venligst en betalingsmetode',
        Pleaseselecttheshippingtime: 'Vælg venligst en leveringstid',
        Pleaseselectatype: 'Vælg venligst type',
        Pleaseselectapricetype: 'Vælg venligst en pristype',
        Pleaseselectacolumn: 'Vælg venligst en kolonne',
        Pleaseselectalogisticscompany: 'Vælg venligst et logistikfirma',
        Pleaseselectadate: 'Vælg venligst en dato',
        SpecialSEO: 'Tematisk SEO',
        informationservice: 'Informationstjenester',
        CanIcontinuetoapply: 'Om du vil fortsætte med at ansøge',
        Unconfirmed: 'ikke bekræftet',
        Thereiscurrentlynodataavailable: 'Ingen tilgængelige data',
        Unpaid: 'ikke betalt',
        Notremitted: 'Ingen pengeoverførsel',
        Noresponse: 'Intet svar',
        Nojump: 'Intet spring',
        Automaticallysynchronizeornot: 'Om der skal synkroniseres automatisk',
        AutomaticallysynchronizeEBP: 'Om EBP skal synkroniseres automatisk',
        Synccompleted: 'Synkronisering gennemført',
        Synchronizetootherlanguages: 'Synkroniser til andre sprog',
        Synchronizetoothertenants: 'Synkronisering til andre lejere',
        SynchronizeEBPproductinformation: 'Synkronisering af EBP-produktoplysninger',
        Doyouwanttoforcesynchronization: 'Tvungen synkronisering eller ej',
        SynchronizeSKU: "Synkronisering af SKU'er",
        SKU_EAN_Encoding: 'SKU/EAN/kode',
        ApplicationDetails: 'Anmod om detaljer',
        Applyforinvoice: 'Anmod om en faktura',
        Returnandexchangeapplication: 'Anmodning om returnering',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Kun overførte ordrer kan bekræftes',
        Onlypendingorderscanbeshipped: 'Kun ventende ordrer kan afsendes',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Kun op til to decimaler kan indtastes',
        Pleaseuploadtheicon: 'Upload venligst et ikon',
        Uploaded: 'Uploadet',
        MoveUp: 'Flyt op',
        Uploadingimagesonlysupports: 'Upload kun billeder i jpg, png, gif, jpeg',
        UploadVideo: 'Upload en video',
        Uploadimages: 'Upload et billede',
        MerchandiseSubtotal: 'Subtotal varer',
        Batchdelisting: 'Masseafnotering',
        Whethertosubscribe: 'Abonner eller ej',
        Subscribing: 'Abonnement i gang',
        size: 'Størrelse',
        Imagesize: 'Billedets størrelse',
        Suggestedsize: 'Foreslået størrelse',
        evaluate: 'Pris',
        Basicfreight: 'Grundlæggende forsendelsespriser',
        ActivityTopic: 'Begivenhedsemne',
        Colortemperature: 'Farvetemperatur',
        Pleasenote2: 'Bemærk venligst',
        Richtextornot: 'Fyldig tekst eller ej',
        Applicationtime: 'Anvendelsestid',
        Combinationtime: 'Kombinationstid',
        Evaluationtime: 'kommentartid',
        Consultationtime: 'Tidspunkt for konsultation',
        Creationtime: 'Oprettelsestid',
        Deliverytime: 'Forsendelsestid',
        Importtime: 'Importtid',
        Rebatetime: 'Rabattid',
        Manualshippingoperationtime: 'Tid til manuel levering',
        Paymenttime: 'betalingstid',
        Collectiontime1: 'Tid til opkrævning',
        Replytime: 'Svartid',
        Audittime: 'Gennemgangstid',
        Usagetime: 'Tidspunkt for brug',
        type: 'Type',
        FreightType: 'Forsendelsestype',
        Discounttype: 'Tilbudstype',
        Originalordertype: 'Oprindelig ordretype',
        Aftersalestype: 'Eftersalgstype',
        JumpType: 'Springtype',
        taxrate: 'Afgiftssats',
        title: 'Titel',
        PageTitle: 'Titel på side',
        ContentTitle: 'Titel på indhold',
        ProductTitle: 'Produktets titel',
        Synchronizeproducttitlesbylanguage: 'Produkttitel: synkroniser titlen på det tilsvarende sprog efter sprog',
        whole: 'Alle sprog',
        total: 'I alt',
        Totalnumber: 'Total',
        Converttoformaldocument: 'overførsel til officiel ordre eller ej',
        Positiveconversionsingle: 'Overførsel til officiel ordre',
        Whethertotransfertoformal: 'overførsel til officiel eller ej',
        freight: 'fragt',
        Deliverytimeprompt: 'Leveringstid tips',
        position: 'Placering',
        Lastlogintime: 'Sidste login-tid',
        Lastweek: 'Seneste uge',
        Thisisalreadythelastitemmovedownfailed: 'Allerede sidste emne, flyt ned mislykkedes',
        Lastmonth1: 'Sidste måned',
        LastThreeMonths: 'De sidste tre måneder',
        PageURL: 'Side URL',
        user: 'Bruger',
        Inviteduserplacesanorder: 'Inviteret bruger, der afgiver ordre',
        Inviteduser: 'Inviterede brugere',
        UsingUsers: 'bruger brugere',
        Useordernumber: 'Brug ordrenummer',
        Used: 'Brugt',
        Effective: 'Gyldig',
        Parametervalue: 'Parameterværdi',
        Productvariants: 'Produktvariant',
        popupnotification: 'Pop op-vindue',
        check: 'Visning',
        ViewInformationServices: 'Vis informationstjeneste',
        video: 'Video',
        Bound: 'Bundet',
        Picturepreview: 'Forhåndsvisning af billede',
        VideoPreview: 'Forhåndsvisning af video',
        BatchCloseDisplay: 'Visning af lukket batch',
        Batchdisplay: 'Batch-visning',
        Isthehomepagedisplayed: 'Visning af startside eller ej',
        volume: 'Mængde',
        Resubscribe: 'Genoptag abonnement',
        Areallclosed: 'Om alle skal lukkes',
        Zloti: 'Zloty',
        SciencePopularizationZone: 'Zone til popularisering af videnskab',
        Lightstripzone: 'Strip Light-zone',
        Prohibitedsalesarea: 'Forbudte salgsområder',

        InvoiceManagement: 'Adminstration af fakturering',

        ReadmeFile: 'Readme-fil',
        HelpCenter: 'Hjælpecenter',
        FileUpload: 'Upload af filer',
        Globalization: 'Globalisering',
        SalespersonProfile: 'Sælgerprofil',
        BatchSyncPage: 'Batch-synkroniseringsside',
        CountryConfigurationPage: 'Landekonfigurationsside',
        UserConsultation: 'Brugerkonsultation',
        PreSaleItems: 'Varer før salg',
        EventManagement: 'Håndtering af begivenheder',
        BlankPage: 'Tom side',
        ThereAreCurrentlyNoProductPromotions: 'Der er i øjeblikket ingen produktkampagner',
        EditInventoryBottomLine: 'Rediger lagerets bundlinje',
        SetAFixedPrice: 'Indstil en fast pris',
        EnterUpTo500Entries: 'Indtast op til 500 poster',
        CantRelateToYourself: 'Kan ikke relatere til dig selv',
        PleaseSelectTheApplicableUserType: 'Vælg venligst den relevante brugertype',
        TheActivityDescriptionLimitIs10000Words: 'Grænsen for aktivitetsbeskrivelsen er 10.000 ord',
        EnabledStatus: 'Aktiveret status',
        Enable: 'Aktiveret',
        OperatingTime: 'Driftstid',
        NewUserPromotionEnabled: 'Ny brugerfremme aktiveret',
        NewUserPromotionDisabled: 'Kampagne for ny bruger deaktiveret',
        OldUserPromotionEnabled: 'Gammel brugeroptimering aktiveret',
        OldUserPromotionDisabled: 'Kampagne for gammel bruger deaktiveret',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'Kampagneprisen kan ikke være lavere end den laveste pris',
        AutomaticallyCalculateAfterEnteringPrice: 'Beregnes automatisk efter indtastning af pris',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'Kampagneprisen for nye brugere er højere end kampagneprisen for gamle brugere. Ønsker du at fortsætte?',
        PleaseSelectPromotionCountry: 'Vælg venligst kampagneland',
        PromotionDiscount: 'Kampagne-rabat',
        PleaseEnterThePromotionalQuantity: 'Indtast venligst kampagnemængden',
        PleaseEnterVirtualQuantity: 'Indtast venligst virtuel mængde',
        NumberOfUserPurchases: 'Antal brugerkøb',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Indtast venligst købsgrænsen for den samme bruger',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Indtast venligst købsgrænsen for en enkelt ordre',
        QuantityGreaterThan0: 'Mængde større end 0',
        GrossProfitMarginCalculationResults: 'Resultater af beregning af bruttofortjenstmargen',
        FixedPriceGrossMargin: 'Fast pris bruttomargin',
        SellingPriceGrossProfitMargin: 'Bruttofortjenstmargen for salgspris',
        PromotionalPriceGrossProfitMargin: 'Bruttofortjenstmargen for kampagnepris',
        PromotionStartTime: 'Starttidspunkt for kampagne',
        PromotionEndTime: 'Sluttidspunkt for kampagne',
        PromotionType: 'Kampagnetype',
        PleaseSetTheSKUToPromotionalProducts: 'Indstil venligst SKU til ${xxx} salgsfremmende produkter',
        DistributionOffers: 'Distributionstilbud',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Når du har indtastet mængden{Any} vil det automatisk blive beregnet ud fra mængden af produktet.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: 'Antallet af brugerkøb skal være større end 0.',
        PleaseSelectCountry: 'Vælg venligst ${typeName} land',

        RequiredField: 'Obligatorisk',
        FileSize3MB: 'Filstørrelse <3MB',
        Upload: 'Upload',
        UploadFailed: 'Upload mislykkedes',
        UploadFailedPDFFormatRequired: 'Upload mislykkedes, PDF-format obligatorisk',
        AuditFailedPleaseUploadInvoiceAttachment: 'Gennemgangen mislykkedes, upload venligst den vedhæftede faktura.',
        PleaseUploadInvoiceAttachment: 'Upload venligst den vedhæftede faktura',
        InvoiceAttachment: 'Vedhæftet faktura',
        PleaseSelectAtLeastTheApplicableUserType: 'Vælg venligst mindst den relevante brugertype',
        PleaseEnterTheRedeemCode: 'Indtast venligst indløsningskoden',
        ApplicableUserRange: 'Anvendeligt brugerområde',
        NoProductIsSelectedWhetherToClose: 'Intet produkt er valgt, om det skal lukkes？',
        DeliveryCountry: 'Leveringsland',
        MemberSettings: 'Medlemsindstillinger',
        PleaseEnterTheAccountName: 'Indtast venligst kontonavnet',
        MembershipEndDateCannotBeLessThanMembershipStartDate: 'Slutdatoen for medlemskabet kan ikke være tidligere end startdatoen for medlemskab',
        CharactersLimited: 'Begrænset til 1 til 30 tegn',
        NumberOfDifferentIPsToAccessTheURL: "Antal forskellige IP-adresser til at tilgå URL'en",
        TheNumberOfDifferentIPAddressesForTheFirstVisitToTheWebsite: 'Antallet af forskellige IP-adresser for det første besøg på hjemmesiden',
        TheTotalOrderAmountPaidAvailableForShipmentShippedCompleted: 'Det samlede ordrebeløb, der er betalt (tilgængelig til forsendelse, afsendt, gennemført)',
        QuantityOfPaidOrderAmountAvailableForShipmentShippedCompleted: 'Mængde af betalt ordrebeløb (tilgængelig til forsendelse, afsendt, afsluttet)',
        BatchRemoval: 'Fjernelse af batch',
        ConfirmBatchDeletionOfSelectedProducts: 'Bekræft batch-sletning af udvalgte produkter',
        RegisterOrNot: 'Registrer eller ej',
        FromSubscriptionTime: 'Fra abonnementstidspunkt',
        EndOfSubscriptionPeriod: 'Udløb af abonnementsperiode',
        SubscriptionManagementExport: 'Eksport af abonnementsadministration',
        FromTheEventStartTime: 'Fra begivenhedens starttidspunkt',
        UntilTheEventStartTime: 'Indtil begivenhedens starttidspunkt',
        EventEndTime: 'Sluttidspunkt for begivenhed',
        EventStartTime: 'Begivenhedens starttidspunkt',

        SignUpForSubscription: 'Tilmeld dig abonnement',
        EnterYourEmailAddressToSubscribe: 'Indtast din e-mailadresse for at abonnere',
        DownloadTheLatestProductInformationSheet: 'Download det seneste informationsark om produktet',

        Full: 'Fuld',
        Reduce: 'Reducer',
        Superimposable: 'Kan lægges ovenpå',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Når du har indtastet kampagnemængden, bliver den automatisk beregnet ud fra mængden af varer.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: 'Ophæv venligst tilknytningen i [Rebate Product Pool] først',
        EditBottomLineInventory: 'Rediger lagerbeholdning på bundlinjen',
        PleaseConfigureFixedPrice: 'Konfigurer venligst fast pris',
        BalancePayment: 'Betaling af saldo',
        Deposit: 'Indbetaling',

        EndofValidity: 'Indtast venligst udløbsdatoen',
        Year: 'År',
        Month: 'Måned',
        Day: 'Dag',
        UnitOfValidity: 'Enhed for gyldighed',
        AIGOCoinDetails: 'AIGO Coin Detaljer',
        AIGOCoinsAreCloseToExpiring: 'AIGO-mønter er tæt på at udløbe (dag)',
        CountryOfShipmentAddress: 'Land for forsendelsesadresse',

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Anslået leveringstidspunkt',
        DataCorrespondingToTheSource: 'Data, der svarer til kilden',
        AccountStatus: 'Status for konto',
        OrderID: 'Ordre-ID',
        RecipientName: 'Modtagerens navn',
        RecipientLastName: 'Modtagerens efternavn',
        MailsOfUser: 'Mails fra brugeren',
        StateProvince: 'Stat/Provins',
        CityTown: 'By/kommune',
        County: 'Amt',
        ReceivingCompany: 'Modtagende virksomhed',
        BatchInput: 'Batch-input',
        InputManually: 'Indtast manuelt',
        ProductCommentsInputTemplate: 'Skabelon til indtastning af produktkommentarer',
        UserName: 'Brugernavn',
        Comments: 'Kommentarer',
        StarRatingOfTheComment: 'Stjernebedømmelse af kommentaren',
        CommentTime: 'Kommentar tid',
        UploadAttachment: 'Upload vedhæftet fil',
        NumberOfAttachments: 'Antal vedhæftede filer',
        FileName: 'Filnavn',
        Appendix: 'Tillæg',
        BatchInputOfProductComments: 'Batch-input af produktkommentarer',
        SoldOut1: 'Udsolgt',
        PreSalePromotionID: 'Kampagne-ID før salg',
        PromotionAbnormal: 'Kampagne unormal',
        Updater: 'Updater',
        AddPreSalePromotion: 'Tilføj forsalgskampagne',
        EditPreSalePromotion: 'Rediger forsalgskampagne',
        Barcode: 'Stregkode',
        PreSaleCountries: 'Lande til forsalg',
        PreSalePrice1: 'Pris før salg',
        PreSaleDeposit: 'Depositum ved forsalg',
        PleaseEnterTheDocumentCode: 'Indtast venligst dokumentkoden',
        BalancePaymentDeadline: 'Deadline for betaling af saldo',
        FixedShippingFee: 'Fast forsendelsesgebyr',
        PreSaleShippingFee: 'Forsendelsesgebyr før salg',
        FreeShippingSubjectToConditions: 'Gratis forsendelse underlagt betingelser',
        OrderPrice: 'Ordrepris',
        PleaseEnterPriceConditions: 'Indtast venligst prisbetingelser',
        TheEndTimeNeedsToBeLaterThanTheStartTime: 'Sluttidspunktet skal være senere end starttidspunktet',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'Der er allerede delvist overlappende kampagner i dette land, venligst modificer',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'Mængden ved forsalg kan kun være et positivt heltal større end 0',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: 'Den forventede leveringstid skal være senere end sluttidspunktet for begivenheden',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'Deadline for betaling af restbeløb skal være tidligere end den forventede leveringstid',
        PromotionID: 'Kampagne-ID',
        DistributionPromotionID: 'ID for distributionskampagne',
        CouponID: 'Kupon-id',
        PromotionManagementID: 'ID for kampagnestyring',
        PortfolioManagementID: 'ID til porteføljestyring',
        ExportForFiltering: 'Eksport til filtrering',
        ListDisplay: 'Visning af liste',
        InventoryItems: 'Varer på lager',
        InStock: 'På lager',
        OutOfStock: 'Ikke på lager',
        FieldName: 'Navn på felt',
        PleaseSelectInventory: 'Vælg venligst lagerbeholdning',
        PreSaleEvent: 'Forudsalg event',
        PerPage: 'visninger pr. side',

        // V1.3.3 end

        // V1.3.4 start
        PleaseProvideTheNameOfTheLandingPageActivity: 'Angiv venligst navnet på landingssideaktiviteten.',
        PleaseIndicateTheNumberOfActivities: 'Angiv venligst antallet af aktiviteter.',
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: 'Aktivitetsmængden skal være større end den, der gives væk.',
        PleaseChooseATimeForTheEvent: 'Vælg venligst en tid til begivenheden.',
        ActivityNameExceedsLength: 'Aktivitetsnavn overskrider længden.',
        ThisItemIsRestrictedToAMaximumOf10PerOrder: 'Dette element er begrænset til 10 pr. bestilling.',
        PleaseChooseTheGiveawayAgain: 'Vælg venligst gaven igen.',
        AnalysisOfGiveawayActivities: 'Analyse af gaveaktiviteter',
        StatisticsOfGiveawayActivities: 'Statistikker over gaveaktiviteter',
        PleaseEnterTheNameOfTheEvent: 'Indtast venligst navnet på begivenheden.',
        PleaseSelectTheActivityType: 'Vælg venligst aktivitetstype.',
        PleaseCompleteTheActivityRating: 'Venligst udfyld aktivitetsvurderingen.',
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Konfigurer venligst {Any} forudsalgs-kampagnen, inden du konfigurerer det land, hvor forudsalgsvarerne vil blive udstillet.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: 'Ordrebelpet og antallet af gratisartikler kan ikke være lavere end den forrige tier.',
        SelectImage: 'Vælg billede',
        GiftInformation: 'Gaveinformation',
        Gifts: 'Gaver',
        GiftsOfEndOfEvent: 'Gaver ved afslutningen af en begivenhed',
        CheckOutTheGifts: 'Tjek gaverne ud',
        GiveawayEvent: 'Giveaway-begivenhed',
        SelectAGift: 'Vælg en gave',
        YouHaveNotSelectedAGift: 'Du har ikke valgt en gave',
        NoNeedForGifts: 'Intet behov for gaver',
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Nogle gaver er udsolgt, vælg venligst gaverne igen',
        ReplaceAGift: 'Udskift en gave',
        ThereAreCurrently3FreeGiftsAvailable: 'Der er i øjeblikket 3 gratis gaver til rådighed',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Der er i øjeblikket 3 gratis gaver til rådighed, og yderligere 4 gaver er tilgængelige for køb af andre 99 €.',
        GiftList: 'Liste over gaver',
        GiftID: 'Gave-ID',
        CurrentInventory: 'Nuværende beholdning',
        NotEnabled: 'Ikke aktiveret',
        GoodsForFree: 'Gratis varer',
        ConfirmToDeleteThisGiftInformation: 'Bekræft for at slette denne gaveinformation?',
        GiftsSortingManagement: 'Administration af sortering af gaver',
        GiftsTopicManagement: 'Administration af gaveemner',
        GiveawayEventID: 'Giveaway-begivenheds-ID',
        GiveawayEventName: 'Giveaway-begivenhedens navn',
        ProductCategory: 'Produktkategori',
        ActivityMode: 'Aktivitetstilstand',
        TotalNumberOfActivities: 'Samlet antal aktiviteter',
        GiftsGivenQuantity: 'Antal gaver givet ',
        ThemeConfiguration: 'Konfiguration af tema',
        GiftSorting: 'Sortering af gaver',
        GlobalConfiguration: 'Global konfiguration',
        ActiveCountry: 'Aktivt land',
        PromotionalPpicture: 'Reklamebillede',
        RecommendedSizeX: 'Anbefalet størrelse: ${XXX*XXX}',
        FileSize1MB: 'Filstørrelse: ≤1MB',
        ActivitySorting: 'Sortering af aktiviteter',
        DragToSort: 'Træk for at sortere',
        ActivityCode: 'Aktivitetskode',
        GiftsForOrderAmount: 'Gaver til ordrebeløb',
        ActivityRating: 'Vurdering af aktivitet',
        GiftsQuantity: 'Antal gaver',
        EventGiftsSorting: 'Sortering af begivenhedsgaver',
        DefaultSelectionOfUsers: 'Standardvalg af brugere',
        LandingPageDisplay: 'Visning af landingsside',
        LandingPageActivityName: 'Landingssidens aktivitetsnavn',
        ConfirmDeleteThisGiftEvent: 'Bekræft sletning af denne gavebegivenhed?',
        IsThereAGiftEvent: 'Er der en gavebegivenhed?',
        OrderQuantity: 'Bestillingsmængde',
        NumberOfGiftsIssued: 'Antal udstedte gaver',
        CustomerPrice: 'Kundepris',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: 'Den virtuelle begivenheds mængdefunktion kan kun indstilles, hvis aktivitetsperioden før salg er mindst mere end 2 dage, og antallet af begivenheder er større end 50.',
        PreSaleSwitch: 'Skift til forsalg',
        PreSaleActivity: 'Aktivitet før salg',
        GiftManagement: "Gavehåndtering",

        // V1.3.4 end

        //V1.3.5 start
        OrderSource: "Kilde til ordre",
        NumberOfNewUserRegistrations: "Antal nye brugerregistreringer",
        RegisteredUsersWhoVsitTheWebsite: "Registrerede brugere, der besøger hjemmesiden",
        SoldDistributionQuantity: "Solgt distributionsmængde",
        DistributionQuantityLock: "Låsning af distributionsmængde",
        InsufficientDstributionQuantityRemaining: "Utilstrækkelig distributionsmængde tilbage",
        Previous: "Forudgående",
        Next: "Næste",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Håndtering af opskrifter",
        RecipeDisplayImage: "Billede til visning af opskrift",
        NewRecipeDisplayImage: "Rediger/opret nyt billede til visning af opskrift",
        CoverPicture: "Forsidebillede",
        PicturesVideos: "Der kan uploades op til 10 billeder + videoer, hvert billede skal være ≤5 MB, og video i .mp4-format understøttes.",
        AddPicturesVideos: "Tilføj billeder/videoer",
        CommenOnPicturesVideos: "Kommentar til billeder/videoer",
        FileSize: "Filstørrelse",
        BarcodeAssociated: "Tilknyttet stregkode",
        FirstUploadTime: "Tid for første upload",
        EditTime: "Redigeringstidspunkt",
        TagType: "Tag-type",
        FileName1: "Filnavn",
        NameLengthISTooLong: "Navnets længde er for lang",
        ProductDoesNoExist: "Produktet findes ikke",
        PleaseUploadImages: "Upload venligst jpg/png/jpeg-billeder under 5MB",
        PleaseUploadTheVideoMP4: "Upload venligst videoen i mp4-format",
        PictureOrVideo: "Billedet eller videoen kan ikke være tom",
        allowed: "Tillad alle",
        cookie1: "Håndtering af cookies",
        collectsordinances: "Aigostar bruger cookies til at forbedre de besøgendes browseroplevelse og til markedsføringsformål. Se og administrer dine cookie-indstillinger nedenfor. Hvis du vil vide mere om, hvordan vi bruger cookies, kan du læse vores politik om beskyttelse af persondata.",
        RequiredCookies: "Nødvendige cookies",
        cookie2: "Disse cookies er nødvendige for kernefunktionalitet som at logge ind, bruge indkøbskurven og foretage betalinger. Nødvendige cookies kan ikke slås fra. Men bare rolig, disse cookies gemmer ikke nogen af dine oplysninger.",
        AnalyticsCookies: "Markedsførings- og analysecookies",
        cookie3: "Disse cookies indsamler oplysninger som f.eks. antallet af besøg på hjemmesiden, eller hvilke sider der er populære, for at hjælpe os med at forbedre vores kundeoplevelse. Du kan vælge at slå disse cookies fra, men så vil vi ikke være i stand til at indsamle de oplysninger, vi har brug for til at forbedre din oplevelse på vores hjemmeside.",
        StoragePreferences: "Gem præferencer",
        BuyItNow: "Køb varen nu",
        PictureVideoSize: "Billed- eller videostørrelse eller -format er ikke korrekt",
        RecipeConfiguration: "Konfiguration af opskrifter",
        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Bekræft synkronisering af understregkoder?",
        SynchronizationEbpEanSUB: "På grund af behovet for at grænseflade med EBP forventes batch-synkronisering at være afsluttet inden for 3 minutter. Efter bekræftelse vil synkroniseringen fortsætte asynkront, og understregkoderne vil blive vist med det samme ved modtagelse. Vær venligst tålmodig.",
        Keywords: "Søgeord",
        SystemAutoUpdate: "System automatisk opdatering",
        IPAddress: "IP-adresse",
        SearchKeywords: "Søg nøgleord",
        LoginEmail: "Login e-mail",
        SearchTime: "Søgetid",
        NumbeOfTimes: "Antal gange",
        MyBrowsing: "Min browsing",
        LoggingOuOfYouAccount: "At logge ud af din konto vil blive betragtet som din frivillige afstandtagen, og du vil ikke længere kunne fortsætte med at bruge denne konto.",
        RequestLogout: "Anmod om logud",
        ProductsYet: "Du har ikke gennemset nogen produkter endnu",
        clearall: "Ryd historik",
        CancellTip1: "Indkøbskurvslister, ordrekøbsposter og favoritposter vil blive permanent slettet og kan ikke gendannes.",
        CancellTip2: "Alle kontoinformationer, serviceposter, forbrugsdata osv. på platformen vil blive permanent slettet og kan ikke gendannes.",
        CancellTip3: "Du vil ikke kunne logge ind eller bruge denne AigoSmart-konto.",
        CancellTip4: "Personlige oplysninger og historiske beskeder (inklusive avatar, kaldenavn, min Aiguo-valuta, platformmeddelelser, mine kommentarer osv.), der er tilknyttet din AigoSmart-konto, vil ikke være gendannelsesmulige.",
        CancellTip5: "Du skal håndtere kontofonde (point, medlemsniveauer, kvalifikationer, ordrer, kuponer, rekvisitter og andre værdifulde tjenester) korrekt, før du logger af, da kontosalg vil blive betragtet som din frivillige afståelse og ikke kan annulleres. Du forstår og accepterer, at AigoSmart ikke kan hjælpe dig med at genoprette de nævnte tjenester.",
        SynchronizeSubBarcodes: "Synkronisér underkoder",
        PleaseEnterKeywords: "Indtast venligst søgeord",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Timebaserede statistikker",
        NumbeOfUsers: "Antal brugere der har afgivet ordrer",
        NumberOfCanceled: "Antal annullerede ordrer",
        RefundAmount: "Refusionsbeløb",
        UsersWhoHavePaid: "Brugere der har betalt (ordrer der kan sendes, sendt, gennemført)",
        TotalNumber: "Samlet antal ordrer annulleret af platformen",
        AmountOfCanceled: "Beløb for annullerede ordrer, der er betalt men ikke sendt, beløb for returnerede ordrer, der er betalt (under transport, leveret)",
        RedemptionCode: "ID/Rabatkuponnavn/Indløsningskode",
        DeleteAll: "Slet",
        AccountIsUnderReview: "Den nuværende konto er under deaktiveringsgennemgang. Fortsat login vil annullere deaktiveringsprocessen. Vil du fortsætte med at bruge den?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "Tiden kan ikke være tom",
        ProductImporTemplate: "Produktimportskabelon",
        NormalOrdersExceed: "Normale ordrer overstiger:",
        OrderSautomaticallyClosed: "Ubetalte ordrer lukkes automatisk",
        OrdeIsShipped: "Efter at ordren er afsendt:",
        AutomaticallyComplete: "Fuldfør automatisk ordren",
        Minutes: "Minutter",
        Days: "Dage",
        OrderSettings: "Bestillingsindstillinger",
        ClearCache: "Ryd cache",
        PleaseEnterNavigationContent: "Indtast venligst navigationsindhold",
        WebsiteNavigationConfiguration: "Konfiguration af webstedsnavigation",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Popup-forsinkelsestid",
        SubscriptionPopup: "Abonnements-popup",
        MeansNoPopupDelay: "Indtast venligst pop-up-vinduets forsinkelsestid",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "UGC-området",

        //V1.5.0 end
        //V1.6.0 start
        uploaFilesInJpg: "Upload kun filer i jpg/png-format på mindre end 2M",
        SynchronizeEbpPrice: "Synkroniser EBP-pris",
        ConfirmToSynchronizeEbp: "Bekræft for at synkronisere EBP-prisen",
        AsynchronousEbp: "Efter bekræftelse er synkroniseringen asynkron. Prisen vises umiddelbart efter, at den er indhentet. Vent venligst.",
        CopywritingDisplay: "Visning af tekstforfattere",
        HomepageCopywriting: "Hjemmesidens tekstforfattere",
        EditHomepageCopywriting: "Rediger tekster på hjemmeside",
        SuperimposedEvents: "Skal der overlejres begivenheder?",
        GiftsEvents: "(Gaverne til denne begivenhed kan ikke samles sammen med andre begivenheder)",
        SmallGiftEvent: "Begivenhed med små gaver",
        SpecialProductEvent: "Særlig produktbegivenhed",
        SpecialGiftEvent: "Gratis gave til lille gavebegivenhed og særlig begivenhed kan ikke indsamles sammen",
        ProductsWithSKU: "Vælg venligst produkter med en SKU",
        LimitWereSelected: "Den nuværende grænse er {limitNum} filer. Denne gang blev der valgt {selectNum} filer.",
        FileUploadFailed: "Filupload mislykkedes!",
        CompleteMultiLanguage: "Udfyld venligst flersproget konfiguration",
        FirstSynchronizeSKU: "Synkroniser venligst SKU for dette produkt først.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Om kuponen skal offentliggøres",
        RedemptionCode1: "Indløsningskode",
        NowOnlyNeed: "Har nu kun brug for 10€",
        WebLogReporting: "Rapportering af weblog",
        PageAddress: "Adresse på side",
        ErrorLogs: "Fejl-logfiler",
        CustomParameter: "Tilpasset Parameter",
        ParameterID: "Parameter-ID",
        // ParameterName: "Parameternavn",
        SearchParameter: "Søgeparameter",
        EnterParameterName: "Indtast parameterens navn",
        ParameterType: "Parametertype",
        AssociatedProductCount: "Antal Associerede Produkter",
        MandatoryPortugueseContent: "Portugisisk indhold er obligatorisk",
        MandatoryEnglishContent: "Engelsk indhold er obligatorisk",
        MandatoryDutchContent: "Hollandsk indhold er obligatorisk",
        MandatoryPolishContent: "Polsk indhold er obligatorisk",
        MandatoryFrenchContent: "Fransk indhold er obligatorisk",
        MandatoryGermanContent: "Tysk indhold er obligatorisk",
        MandatoryItalianContent: "Italiensk indhold er obligatorisk",
        MandatorySpanishContent: "Spansk indhold er obligatorisk",
        MandatoryChineseContent: "Kinesisk indhold er obligatorisk",
        ImportTemplate: "Brugerdefineret produktimportskabelon",
        ConfirmImportFile: " Bekræft importfil, fortsæt?",
        SelectDataRemove: " Vælg data til fjernelse",
        ImportTemplateDownload: "Download importskabelon",
        AssociatedProducts: "Relaterede produkter",

        //V1.7.0 end

        //V1.8.0 start
        Published: "Udgivet",
        CanBeIgnored: "Kan ignoreres",
        Resolved: "Løst",
        ActivityType: "Aktivitetstype",
        PromotionActivity: "Promoveringsaktivitet",
        DistributionActivity: "Distributionsaktivitet",
        InputLink: "Indtast link",
        EnterAddress: "Indtast linkadresse",
        DetailPageAd: "Reklameplads på detaljesiden",

        //V1.8.0 end


        //V1.9.0 start
        ProductPromotionID: "Produktkampagne-ID.",
        PromotionalActivityCollection: "Samling af kampagneaktiviteter.",
        PromotionalActivityName: "Navn på kampagneaktivitet.",
        ProductPromotionBarcode: "Produktkampagne-ID/Stregkode.",
        ProductPromotionIDCopy: "Produktkampagne-ID (dobbeltklik for at kopiere alle ID'er).",
        ProductBarcode: "Produktstregkode (dobbeltklik for at kopiere alle stregkoder).",
        SelectPromotionalActivity: "Vælg kampagneaktivitet.",
        SelectPromotionalList: "Vælg kampagneliste.",
        TypesPerOrder: "Begræns antallet af varetyper pr. ordre.",
        PromotionalActivity: "Vælg venligst en kampagneaktivitet.",
        ProductAlready: "Dette produkt har allerede en kampagneaktivitet. Venligst rediger.",
        PromotionalActivityNameID: "Kampagnenavn/ID",
        PromotionalCollectionName: "Navn på kampagnesamling.",
        SelectedPromotionalActivity: "Valgt kampagneaktivitet.",
        PromotionalCollectionActivit: "En kampagnesamlingsaktivitet findes allerede. Venligst rediger.",


        EnterHholidayTheme: "Indtast et ferietema.",
        HolidayTheme: "Ferietema.",
        HolidayImage: "Feriebillede.",
        AddLogoImage: "Tilføj logo billede.",
        PleaseProceedEdit: "Venligst rediger.",
        SKUBIDActivities: "SKU: {sku}, der er allerede {country} aktiviteter under {activity}.",
        LogImageManagement: "Logo-billedhåndtering",
        LogoManagement: "Logo-håndtering",

    //V1.9.0 end

    }
};

export default da;