import ptLocal from 'element-ui/lib/locale/lang/pt';
const pt = {
    ...ptLocal,
    common: {
        datastatistics: 'Estatisticas',
        Transactionstatistics: 'Estatística de transações',
        LabelOptions: 'Opções de etiqueta',
        Orderdatastatistics: 'Estatísticas de pedidos',
        Hourlystatistics: 'por hora',
        Countbyday: 'por dia',
        Spain: 'Espanha',
        Italy: 'Itália',
        Germany: 'Alemanha',
        France: 'França',
        poland: 'Polónia',
        Netherlands: 'Holanda',
        Britain: 'Grã -Bretanha',
        UnitedStates: 'os Estados Unidos',
        Portugal: 'Portugal',
        SalesCountry: 'País de venda',
        query: 'Investigação',
        Placinganorder: 'Quantidade de pedido',
        Orderamount: 'Valor do pedido',
        Numberofusersplacingorders: 'Número de usuários fazendo pedidos',
        Ordertime: 'Tempo de ordem',
        time: 'Tempo',
        usermanagement: 'Gestão de usuários',
        UserID_loginemail_registrationinviterID_phonenumber: 'ID de usuário / Login Email / ID de convidado registrado / número de telemóvel',
        country: 'País',
        Whethertosubscribe: 'Assine ou não',
        Loginstatus: 'Status de login',
        normal: 'Normal',
        Disabled: 'Desativar',
        Createdon: 'data de criação',
        Lastlogintime: 'Último tempo de login',
        Reset: 'Reiniciar',
        export: 'Exportar',
        UserID: 'ID do usuário',
        Registeredcountry: 'País de registro',
        mailbox: 'E-mail',
        Mobilephonenumber: 'número de telemóvel',
        Patrioticcoin: 'Moedas AIGO',
        RegistrationInviterID: 'ID do convidado Registrado',
        brand: 'Marca',
        Creationtime: 'Tempo de criação',
        operation: 'Operação',
        check: 'Visualizar',
        orderform: 'Ordens',
        consultingservice: 'Investigação',
        address: 'Endereço',
        LoginLog: 'Login login',
        Rechargepatrioticcoins: 'Recarregue moedas AIGO',
        Returnsandexchanges: 'Retorna e trocas',
        evaluate: 'Avaliar',
        Shippingaddress: 'Receber endereço',
        shoppingcart: 'Carrinho',
        collection: 'coleção',
        invoice: 'Fatura',
        nickname: 'Apelido',
        nothing: 'Nenhum',
        close: 'Fechar',
        source: 'Fonte',
        Subscriptiontime: 'Tempo de assinatura',
        Thirdpartylogin: 'Login de terceiros',
        Bound: 'Vinculado',
        Unbound: 'Ilimitado',
        Invitationcode: 'Código de Convite',
        Inviteduser: 'Usuários convidados',
        Invitetoreceivepatrioticcoins: 'Convide para obter moedas AIGO',
        Isthefirstorderfreeofshipping: 'entrega grátis ou não em seu primeiro pedido',
        shopping: 'Compras',
        Collectproducts: 'Itens favoritos',
        ShoppingCartItems: 'Itens de carrinho de compras',
        PaymentOrder: 'Ordens pagas',
        Ordercumulativeamount: 'Valor da ordem total',
        Lastordertime: 'Última hora do pedido',
        Returnandexchangeorders: 'Ordens de devolução e troca',
        Consultationfrequency: 'frequência de consulta',
        Numberofevaluations: 'o número de críticas',
        Numberofapprovedevaluations: 'o número de revisões aprovadas',
        ShowCountry: 'Mostrar país',
        name1: 'Nome',
        TaxID: 'Código de Imposto',
        province: 'Província',
        city: 'Cidade',
        Detailedaddress: 'Endereço detalhado',
        Housenumber: 'Número da casa',
        AddressAdditionalContent: 'Abordar conteúdo adicional',
        RecipientCompany: 'Empresa destinatária',
        Defaultshippingaddress: 'Endereço de envio padrão',
        Defaultinvoiceaddress: 'Endereço da fatura padrão',
        ProductID1: 'ID do produto',
        Productname1: 'Nome do Produto',
        CollectionPrice: 'Preço de cobrança',
        Currentprice: 'Preço atual',
        Collectiontime1: 'Tempo de coleta',
        Additionalpurchaseprice: 'O preço dos itens adicionando à lista de desejos',
        Additionalpurchasetime: 'o tempo dos itens adicionando ao desejamento',
        Listingstatus: 'status da prateleira',
        Listed: 'Coloque na prateleira',
        Notlisted: 'não na prateleira',
        Numberofpatrioticcoins: 'Número de moedas AIGO',
        Enteraquantitybetween0and1000: 'Insira uma quantidade entre 0 e 1000',
        Remarks: 'Observações',
        cancel: 'Cancelar',
        UserDetails: 'Detalhes do usuario',
        Areyousuretoexportthefile: 'Deseja confirmar a exportação do arquivo, deseja continuar?',
        prompt: 'Incitar',
        Exportsuccessful: 'Exportação bem sucedida',
        today: 'hoje',
        Lastweek: 'Última semana',
        Thismonth: 'Este mês',
        Lastmonth1: 'Mês passado',
        thisyear: 'Este ano',
        Inputcannotbeempty: 'A entrada não pode estar vazia',
        Pleaseenterapositiveinteger: 'Por favor, insira um número inteiro positivo',
        Pleaseenterthequantityofpatrioticcoins: 'Por favor, insira o número de moedas AIGO',
        Pleaseenteracomment: 'Por favor, digite uma observação',
        Pleaseselectacountry: 'Por favor, selecione um País: Brasil',
        Successfullysubmitted: 'submetido com sucesso',
        Nopatrioticcoinrulesconfigured: 'Nenhuma regras de moeda aigo configuradas',
        Rechargefailed: 'Falha em recarregar',
        Savefailed: 'falha ao salvar',
        ordermanagement: 'Gestão de pedidos',
        OrderList: 'Lista de pedidos',
        Ordernumber: 'Número do pedido',
        Paymentcode: 'Código de pagamento',
        barcode: 'Código de barras',
        Pleaseselectasource: 'Selecione a fonte',
        OrderStatus: 'Status do pedido',
        Unpaid: 'não remunerado',
        Paid: 'pago',
        Shippable: 'Disponível para entrega',
        Shipped: 'Enviado',
        complete: 'Completo',
        Pleaseselectpaymentmethod: 'Selecione a maneira de pagamento',
        Shipmentstatus: 'Status de envio',
        Notshipped: 'Não enviado',
        Partialshipment: 'Enviado parcialmente',
        Returned: 'Devolvida',
        Remittanceconfirmation: 'Remessa confirmada',
        Notremitted: 'Sem remessa',
        Unconfirmed: 'não confirmado',
        adopt: 'Passar',
        Notpassed: 'não passar',
        Whethertomanuallyship: 'Entrega manual ou não',
        yes: 'Sim',
        no: 'Não',
        Originalordertype: 'Tipo de pedido original',
        Presaleorder: 'Pedido antecipado',
        Officialorder: 'Ordem oficial',
        Preorderstatus: 'status de pré encomenda',
        Deposittobepaid: 'Depósito a ser pago',
        Depositpaid: 'Depósito pago',
        Finalpaymenttobemade: 'saldo a ser pago',
        Finalpaymentpaid: 'saldo pago',
        Presalepaymentmethod: 'o modo de pagamento para a pré venda',
        Finaldeposit: 'Pagamento final do depósito',
        Fullpayment: 'Pagamento integral',
        Whetherthepresaleorderisconvertedtoformal: 'Pré encomendar para oficial ou não',
        Combinedorders: 'Ordem combinada',
        to: 'Para',
        Startdate: 'Data de início',
        Enddate: 'Data de término',
        Paymenttime: 'tempo de pagamento',
        Pleaseenterthesource: 'Por favor, insira a fonte',
        platform: 'Plataforma',
        Positiveconversionsingle: 'Transferência para o pedido oficial',
        Numberofproductmodels: 'Número de ítens',
        activity: 'Atividade',
        money: 'Quantia',
        Paymentmethod: 'o modo de pagamento',
        Deliverytime: 'Prazo de entrega',
        Whethertotransfertoformal: 'Transferência para oficial ou não',
        Tobereplied_consulted: 'a ser respondido/ consultado',
        detailed: 'Detalhes',
        Manualshipping: 'Entrega manual',
        Manuallogisticsshipment: 'Entrega de logística manual',
        logisticscompany: 'Empresa de logística',
        Pleaseselectalogisticscompany: 'Selecione uma empresa de logística',
        Logisticstrackingnumber: 'Número de seguimento de logística',
        SelectDateTime: 'Selecione Data e hora',
        Shippingremarks: 'Observações de transporte',
        Pleasenote: 'Observe',
        confirm1: 'confirme',
        state: 'Status',
        Pleaseselect: 'Por favor selecione',
        Pleaseselectastatus: 'Selecione Status',
        Homepagerecommendation: 'Recomendação da página inicial',
        Pleaseenterthelogisticstrackingnumber: 'Por favor, insira o número de seguimento',
        Pleaseselecttheshippingtime: 'Selecione um tempo de envio',
        edit: 'Editar',
        Successfullyshippedmanually: 'Entrega manual com sucesso',
        Remittanceconfirmationsuccessful: 'Confirmação de remessa bem sucedida',
        confirmdeletion: 'Confirme para excluir',
        Successfullydeleted: 'Excluir com sucesso',
        Pleaseselectapresaleorder: 'Selecione o pedido de pré-venda',
        Confirmtoconverttheorderintoaformalorder: 'Confirmação da conversão de pedidos em ordem oficial',
        success: 'Sucesso',
        OrderData: 'Dados do pedido',
        Areyousuretoexportthefileandcontinue: 'Confirme para exportar arquivo ou não, continue ou não',
        Basicinformationoftheorder: 'Informações básicas sobre pedidos',
        Useremail: 'Email de usuário',
        Documentremarks: 'Notas sobre o pedido',
        combination: 'Combinação',
        PostalCode: 'Código postal',
        RecipientCompanyAddressAdditionalContent: 'Empresa destinatária, abordar conteúdo adicional',
        Invoiceaddress: 'Endereço da fatura',
        InvoiceStatus: 'Status da fatura',
        Paymentinformation: 'Informação de pagamento',
        Paymentstatus: 'Status do pagamento',
        coupon: 'cupão',
        freight: 'entrega',
        CouponName: 'Nome do cupão',
        Couponcode: 'Código do cupão',
        Paymentamount: 'Valor do pagamento',
        Trackingnumber: 'Número da ordem de seguimento',
        Manualshippingoperator: 'Operador de remessa manual',
        Manualshippingoperationtime: 'Tempo de operação de entrega manual',
        Bankremittance: 'Remmitância bancário',
        Remittancestatus: 'Status de remessa',
        Remittanceconfirmationoperator: 'Operador de confirmação de remessa',
        Remittanceconfirmationoperationtime: 'Tempo de confirmação da remessa',
        Preorderinformation: 'Informações sobre pedidos de pré-venda',
        Converttoformaldocument: 'Transferência para o pedido oficial ou não',
        Positiveconversionsinglemode: 'a maneira de se transferir para o pedido oficial',
        automatic: 'automático',
        Manual: 'manual',
        Positiveconversionsingletime: 'o tempo de transferência para o pedido oficial',
        category1: 'categoria',
        Subordernumber: 'Número da subordinidade',
        Paymentdeadline: 'prazo de pagamento',
        Addremarks: 'Adicione comentários',
        ProductInformation: 'informação do produto',
        Serialnumber: 'número de série',
        name: 'nome',
        VariantInformation: 'Informações da variante',
        Pricing_unitprice: 'Preços (preço unitário)',
        Sellingprice_unitprice: 'Preço de venda (preço unitário)',
        quantity: 'Quantidade',
        Totalprice: 'Preço total',
        MerchandiseSubtotal: 'Bens subtotais',
        total: 'Total',
        CancelOrder: 'cancelar o pedido',
        Areyousuretocancelthisorder: 'Tem a certeza de cancelar o pedido?',
        reason: 'Razão',
        Pleaseselectabrand: 'Selecione uma marca',
        Pleaseselectacategory: 'Por favor, selecione uma categoria',
        Successfullyadded1: 'Adicione com sucesso',
        Addfailed: 'Não conseguir adicionar',
        Whethertosubmitdata: 'Enviar dados ou não',
        Canceledsuccessfully: 'Cancelar com sucesso',
        Onlypendingorderscanbeshipped: 'Apenas pedidos pendentes podem ser enviados',
        Existinglogisticstrackingnumber: 'O pedido possui um número de seguimento de logística e não pode ser enviado',
        Thereareonlytworemittancestatuses: 'O status da remessa só pode ser aprovado ou não aprovado',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Somente pedidos remetidos podem ser confirmados',
        Orderdoesnotexist: 'O pedido não existe',
        Thisordercannotbecancelled: 'A ordem não pode ser cancelada',
        Norulesconfigured: 'O atual inquilino não possui regras de moedas AIGO configuradas',
        Parametererror: 'Erro de parâmetros',
        Countrycodecannotbeempty1: 'O código do país não pode estar vazio',
        Thecurrentcountrypricingisnotconfigured: 'O preço atual do país não está configurado',
        Thepriceconfigurationdoesnotexist: 'A configuração de preços não existe, configure o preço',
        Reasonforcancellationoforderisrequired: 'Motivo do cancelamento de pedidos necessário',
        Exportorderinformation: 'Informações sobre pedidos de exportação',
        OrderIDmustbepassed: 'O ID do pedido deve ser passado',
        Youcanonlybatchprocessupto100itemsatatime: 'Processamento em lote de até 100 pedidos por vez',
        Notapresaleorder: 'O pedido número 101 é uma ordem não-ordem, desmarque-o primeiro',
        Transferredtoofficial: 'O pedido número 101 foi transferido para um pedido oficial, desmarcar primeiro',
        Ordernotpaid: 'O pedido número 101 não é um pedido pago, cancele primeiro',
        Nonpresaleorderscannotbeoperated: 'Os pedidos sem venda não estão disponíveis',
        Transferredtoformalformandcannotbeoperated: 'Os pedidos transferidos não estão disponíveis',
        Nonpaidorderscannotbeoperated: 'Não disponível para pedidos não pagos',
        Orderremarksarerequired: 'Notas de pedido são necessárias',
        Upto500wordscanbeentered: 'Máximo de 500 caracteres',
        common: 'no total',
        strip: 'registos',
        goto: 'Vá para',
        page: 'Página',
        Returnandexchangeapplication: 'Solicitação de devolução',
        type: 'Tipo',
        Return: 'Retornar',
        Exchange: 'Intercâmbio',
        Auditstatus: 'Status da revisão',
        Approved: 'Aprovado',
        Auditfailed: 'Fracassado',
        Inprogress: 'está em andamento',
        Auditended: 'Fim da auditoria',
        Processingstatus: 'Status de processamento',
        Completed: 'Concluído',
        Applicationtime: 'Tempo de aplicação',
        Aftersalestype: 'Tipo após vendas',
        ApplicationDescription: 'Descrição da aplicação',
        Numberofexplanatoryimages: 'Descrição do número de fotos',
        Illustrativeimage: 'Descrição de fotos',
        Returnlogisticstrackingcode: 'Retornar o código de seguimento de logística',
        CanIcontinuetoapply: 'Se deve continuar a aplicar',
        Auditing: 'Auditoria',
        details: 'Detalhes',
        handle: 'Processo',
        Add1: 'Adicionar',
        ApplicationDetails: 'Pedir detalhes',
        Exchangeitem: 'Trocar bens',
        Returnedgoods: 'Bens de devolução',
        Audittime: 'Tempo de revisão',
        Reviewedby: 'Revisados ​​pela',
        result: 'Resultado',
        Failed: 'Não aprovado',
        Thereplycontentwillbedisplayedtotheuser: 'A resposta será exibida aos usuários',
        Submit: 'Enviar',
        Replycontent: 'o conteúdo da resposta',
        Operator: 'Operadores',
        Pleaseenterthereason: 'Por favor, insira um motivo',
        Pleasechoosewhethertocontinueapplying: 'Selecione se pode continuar a inscrever-se',
        Pleaseselectaresult: 'Selecione o resultado',
        Successfullymodified: 'Modificação bem -sucedida',
        Verificationfailed: 'Falha na verificação',
        Datadoesnotexist: 'Os dados não existem',
        OrderNumber_UserID: 'Número do pedido/ID do usuário',
        ReplyStatus: 'Status da resposta',
        Replied: 'Respondeu',
        Noresponse: 'Sem resposta',
        MyOrder: 'Meu pedido',
        refund: 'Reembolso',
        Canwedeliverontheagreeddate: 'Pode entregar a data acordada?',
        Modifyshippingaddress: 'Alterar endereço de entrega',
        Otherissuesrelatedtotransportation: 'Outras perguntas sobre o envio',
        Receivedincorrectproduct: 'Receber o produto errado',
        Receiveddamagedproductsandproductswithqualityissues: 'Receber produtos e produtos danificados com problemas de qualidade',
        RegulationsonReturnsandRefunds: 'Regulamentos sobre devoluçãos e reembolsos',
        Applyforinvoice: 'Solicite uma fatura',
        productdetails1: 'Detalhes do produto',
        Thankyouletter: 'Carta de agradecimento',
        Otherissues: 'Outras perguntas',
        Respondent: 'Responder por',
        Consultationdate: 'Data de investigação',
        content: 'Contente',
        explain: 'Descrição',
        Consultationtime: 'Hora da consulta',
        Replytime: 'Tempo de resposta',
        reply: 'Responder',
        OrderInformation: 'Encomendar informações',
        Consultationcontent: 'Conteúdo de consulta',
        Consultationinstructions: 'Instruções de consulta',
        EnterReply: 'Digite uma resposta',
        Consultationrecords: 'História do inquérito',
        Replycontentisrequired: 'O conteúdo da resposta é necessário',
        Replysuccessful: 'Responder com sucesso',
        Successfullyedited: 'Editar com sucesso',
        Ordernumber_UserID_Taxnumber: 'Número do pedido/ID de usuário/código de imposto',
        Unaudited: 'Não auditado',
        Passed: 'Aprovado',
        Notuploaded: 'Não carregado',
        Uploaded: 'Carregado',
        AuditDescription: 'Instruções de revisão',
        download: 'Download',
        Applying: 'Aplicação em andamento',
        Enterthereason: 'Digite o motivo, deve preencher o motivo se a aplicação não for aprovada',
        Thereasonfornotpassingwillbedisplayedtotheuser: 'Razões para não passar serão mostradas ao usuário',
        Pleaseenterareply: 'Por favor, insira uma resposta',
        Auditsuccessful: 'Revisão bem -sucedida',
        ProductManagement: 'Gestão de produtos',
        Categorymanagement: 'Gestão de categoria',
        Categoryname1: 'Nome da Categoria',
        Isthehomepagedisplayed: 'Exibição da página inicial ou não',
        Enableornot: 'Ativar ou não',
        search: 'Procurar',
        Directoryhierarchy: 'Nível de catálogo',
        Category: 'Categoria',
        sort: 'Ordenar por',
        picture: 'Foto',
        Updatetime: 'Tempo de atualização',
        Multilingualconfiguration: 'Configuração multi linguagem',
        Relatedimportantparameters: 'Parâmetros importantes relacionados',
        Associatesearchparameters: 'Parâmetros de pesquisa associados',
        delete: 'Excluir',
        SelectAll: 'Selecionar tudo',
        Imagesize: 'Tamanho da imagem',
        icon: 'Ícones',
        intotal25: '25 itens no total',
        Gotopage1: 'Vá para a página 1',
        Chinese: 'chinês',
        English: 'Inglês',
        German: 'Alemão',
        Polishlanguage: 'polaco',
        Italian: 'italiano',
        French: 'Francês',
        Dutchlanguage: 'Holandês',
        Spanish: 'Espanhol',
        Pleaseenteraname: 'Por favor, insira um nome',
        Areyousureyouwanttodeleteit: 'Quer apagar?',
        Categoryassociation: 'Associação de categoria',
        PrimaryKeyID: 'ID da chave primária',
        CatalogID: 'ID do catálogo',
        Addtime: 'Tempo extra',
        Relatedcategories: 'Categoria associada',
        Selectmaincategory: 'Selecione a categoria principal',
        addassociations: 'Adicionar associação',
        Maincategory: 'categoria principal',
        ProductCatalogID: 'ID do catálogo de produtos',
        RelatedCategoryName: 'Nome da categoria associada',
        AddAssociatedCategory: 'Adicione uma categoria associada',
        Pleaseselectacategoryfirst1: 'Selecione uma categoria primeiro',
        Productvariants: 'Variante do produto',
        ID: 'EU IA',
        Add: 'Adicionar',
        Productquantity: 'Número de produtos',
        Selectvariant: 'Selecione uma variante',
        Selectparameters: 'Selecione parâmetros',
        commodity: 'produtos',
        Selectproduct: 'Selecione Produto',
        Colortemperature: 'Temperatura da cor',
        frequency: 'Frequência',
        power: 'Potência',
        style: 'Estilo',
        MoveDown: 'Descer para baixo',
        MoveUp: 'Subir',
        Selectvariantparameters: 'Selecione parâmetros variantes',
        SKU_EAN_Encoding: 'Sku/ean/código',
        Pleaseselectparameters: 'Selecione parâmetros',
        Countrieslisted: 'países onde os produtos são colocados na prateleira',
        ProductID: 'ID do produto',
        Productimage1: 'Imagem do Produto',
        ProductName: 'Nome do Produto',
        VariantManagement: 'Gestão de variantes',
        Class: 'Categoria',
        Selected: 'Selecionado',
        Thereiscurrentlynodataavailable: 'Nenhum dado disponível',
        Variantparameters: 'Parâmetros variantes',
        VariantName: 'Nome variante',
        CustomName: 'Nome personalizado',
        Batchlisting: 'Lote de colocação de prateleira',
        Pleaseselectaproduct: 'Selecione produtos',
        Batchdelisting: 'A maior parte da retirada',
        Batchsearch: 'Pesquisa em lote',
        Enterproductbarcodeandsubmitforaddition: 'Insira o código de barras do produto para enviar adições, vários códigos de barras devem ser inseridos em uma nova linha',
        Enterproductbarcode: 'Digite códigos de barras do produto, vários códigos de barras devem ser inseridos em uma nova linha de até 500',
        Pleaseenterbarcode: 'Por favor, insira o código de barras',
        Editlistingcountry: 'Editar países onde os produtos são colocados na prateleira',
        Pleasecompletetheproductinformationbeforelistingit: 'Preencha as informações do produto antes de colocar na prateleira',
        ProductTitle: 'Título do produto',
        ProductLargePicture: 'Imagem do Produto',
        commodityprice: 'Preço do produto',
        Confirmtheexecutionofbatchdelisting: 'Confirme para executar a exclusão em massa',
        EditCategory: 'Categoria de edição',
        SynchronizeSKU: "SYNC SKU's",
        ConfirmsynchronizationofSKU: 'Confirme SKU Sync',
        SynchronizationrequiresdockingwithEBP: 'Como a sincronização precisa ser feita com a EBP, para evitar erros, a sincronização em lote pode ser feita uma vez a cada 30 minutos. Por favor, seja paciente, pois a sincronização será realizada de forma assíncrona após a confirmação e a SKUs será exibida assim que estiverem disponíveis.',
        Synccompleted: 'Sincronização completa',
        Setlabel: 'Configure tags',
        label: 'Tag',
        SynchronizeEBPproductinformation: 'Sincronizando as informações do produto EBP',
        Afterturningonsynchronization: 'Quando a sincronização é ativada, as seguintes informações para os produtos EBP são sincronizadas pela SKU do produto',
        Synchronizeproducttitlesbylanguage: 'Título do produto: sincronize o título da linguagem correspondente pela linguagem',
        Synchronizeproductimagesbylanguage: 'Imagem do produto: sincronize a imagem na linguagem correspondente por linguagem, leia a classificação da imagem EBP, a primeira imagem EBP é definida automaticamente como a imagem principal',
        Synchronizeproductimagesandtextbylanguage: 'Detalhes da imagem: sincronizar por linguagem para a linguagem correspondente',
        Productkeywords: 'Palavras -chave do produto',
        Automaticallysynchronizeornot: 'Se deve sincronizar automaticamente',
        Doyouwanttoforcesynchronization: 'Sincronização forçada ou não',
        tenant: 'Inquilinos',
        Noinventorydisplay: 'Sem exibição de inventário',
        Synchronizetoothertenants: 'Sincronizar com outros inquilinos',
        UpdateRecords: 'Atualizar registos',
        Importtime: 'Tempo de importação',
        number: 'Número',
        file: 'Arquivo',
        UpdateResults: 'Resultado da atualização',
        Downloadfiles: '⇬ Fazer download do arquivo',
        Isthereafixedprice: 'Existe um preço fixo',
        Whethertopromoteornot: 'À venda ou não',
        Inpromotion: 'À venda',
        whole: 'Todos',
        Fixedprice1: 'Preço fixo',
        Lowestprice: 'Menor preço',
        fixedprice: 'Preço fixo',
        Promotionprice_unitprice: 'Preço promocional (preço unitário)',
        price1: 'Preço de venda',
        inventory: 'inventário',
        Inventorybottomline: 'Conclusão do inventário',
        AutomaticallysynchronizeEBP: 'Se deve sincronizar automaticamente a EBP',
        ForcesynchronizationofEBP: 'Se deve forçar a sincronização do EBP',
        Presaleornot: 'Pré-venda ou não',
        Evaluation_Shown_total: 'Revisões (exibidas/total)',
        coding: 'Código',
        ProductDetails: 'Detalhes do produto',
        EditProduct: 'Editar produto',
        Basicinformation: 'Informação básica',
        price: 'Preço',
        Pleaseentertheproducttitle: 'Por favor, insira o título do produto',
        Sizeinformation: 'Informações de tamanho',
        weight: 'Peso',
        volume: 'Volume',
        Salesmultiples: 'Multiplicador de vendas',
        Numberofitems: 'Número de ítens',
        EditFixedPrice: 'Editar preço fixo',
        Largeimage: 'Imagem grande',
        Uploadimages: 'Carregue uma foto',
        UploadVideo: 'Faça upload de um vídeo',
        Batchdisplay: 'Exibição em lote',
        BatchCloseDisplay: 'Exibição de fechamento em lote',
        Batchdeletion: 'Excluir lote',
        Synchronizetootherlanguages: 'Sincronizar com outras línguas',
        Selectfrompicturelibrary: 'Selecione na galeria de imagens',
        Pleaseenterthemaintext: 'Por favor, insira o texto do corpo',
        Hideemptyparameters: 'Ocultar parâmetros vazios',
        Detailedcontentofgraphicsandtext: 'Por favor, insira os detalhes gráficos em inglês primeiro',
        parameter: 'Parâmetro',
        Parametervalue: 'Valor do parâmetro',
        ParameterProperties: 'Propriedades dos parâmetros',
        Pleaseenteraparametername: 'Por favor, insira o nome do parâmetro',
        Pagekeywords: 'Palavra -chave da página',
        PageDescription: 'Descrição da página',
        Pleaseentercontent: 'Por favor, insira o conteúdo',
        PictureLanguage: 'Linguagem de imagem',
        Pictures_Videos: 'Imagem/vídeo',
        ImageID: 'ID da imagem',
        language: 'Linguagem',
        size: 'Tamanho',
        Selectapictureorvideo: 'Selecione a imagem ou vídeo na galeria primeiro!',
        Selectionsuccessful: 'Selecione com sucesso',
        Isitthemainimage: 'Imagem principal ou não',
        Displayornot: 'Mostrar ou não',
        Whethertodisplayall: 'Mostrar tudo ou não',
        Areallclosed: 'Se deve fechar tudo',
        Confirmdeletionoftheselectedpicture_video: 'Confirme para excluir imagem/vídeo selecionado',
        ProductReview: 'Comentários do produto',
        PromotionName_ProductID_Barcode: 'Nome da promoção/ID do produto/código de barras',
        PromotionCountry: 'País de promoção',
        Activitystatus: 'Status do evento',
        begininaminute: 'Começa em breve',
        Ended: 'Terminou',
        Isitaflashkill: 'promoção por tempo limitado',
        PromotionName: 'Nome da promoção',
        CommentID: 'ID de comentário',
        RatingStarRating: 'Estrela de classificação',
        star0: 'estrela',
        star1: '1 estrela',
        stars2: '2 estrelas',
        stars3: '3 estrelas',
        stars4: '4 estrelas',
        stars5: '5 estrelas',
        Commentstartdate: 'Data de início dos comentários',
        CommentEndDate: 'Data final dos comentários',
        Batchreview: 'Revisão em lote',
        Productimage: 'Imagens do produto',
        Evaluationcontent: 'Comentário conteúdo',
        video: 'Vídeo',
        ReviewerId: 'ID do revisor',
        user: 'Do utilizador',
        Pendingreview: 'A ser revisado',
        Evaluationtime: 'tempo de comentário',
        ReviewerName: 'Nome do revisor',
        Numberofselectedcomments: 'o número de críticas selecionadas',
        Picturepreview: 'Pré-visualização de imagem',
        VideoPreview: 'Visualização de vídeo',
        Lastmonth: 'Mês passado',
        LastThreeMonths: 'Últimos três meses',
        Areyousuretodelete: 'Confirme para excluir ou não',
        Pleaseselectacomment: 'Selecione uma revisão',
        Amazon: 'Amazon',
        Aigostarbrandnetwork: 'Rede de marca Aigostar',
        marketingmanagement: 'Gestão de Marketing',
        Productrecommendations: 'Recomendação do produto',
        Recommendedlocation: 'Recomenda a localização',
        Categoryrecommendation: 'Recomendação da categoria',
        Recommendationmodule: 'Recomendar módulo',
        NewProductZone: 'Novas chegadas',
        HotSellingZone: 'Produtos quentes',
        Recommendedforyou1: 'Recomendado para si',
        Numberofproducts: 'Número de produtos',
        Addproduct: 'Adicione o produto',
        DirectoryName: 'Nome do catálogo',
        title: 'Título',
        ContentTitle: 'Título de conteúdo',
        EnablePlatform: 'Ativar plataforma',
        DisplayPlatform: 'plataforma de exibição',
        catalogue: 'Catálogo',
        JumpAddress: 'Pular para o endereço',
        PageTitle: 'Título da página',
        CategoryName: 'Nome da Categoria',
        Cancelleddeletion: 'exclusão cancelada',
        Pleaseselectacategoryfirst: 'Selecione uma categoria primeiro',
        TheEnglishcategorynamecannotbeempty: 'O nome da categoria em inglês não pode estar vazio',
        Successfullysaved: 'Salvo com sucesso',
        DeleteError: 'Erro de exclusão',
        Apieceofcontentalreadyexists: 'Um conteúdo já existe',
        Pleaseselecttherecommendedlocation: 'Selecione um local recomendado',
        Pleaseselecttherecommendedmodule: 'Selecione um módulo de recomendação',
        remove: 'Remover',
        Successfullyadded2: 'Adicionado com sucesso',
        Areyousuretoremovethisproduct: 'Tem a certeza de remover este produto?',
        Successfullyremoved: 'Removido com sucesso',
        Dataalreadyexists: 'Dados já existem',
        PortfolioManagement: 'Gestão de portfólio',
        Combinationname: 'Nome de combinação',
        Remainingquantity_combinedquantity: 'Quantidade restante /quantidade combinada restante',
        starttime: 'Hora de início',
        Endtime: 'Termo do tempo',
        Creator: 'Criado por',
        NationalSDPDictionary: 'Dicionário Nacional do SDP',
        Combinationtime: 'Tempo de combinação',
        Combinationquantity: 'Quantidade de combinações',
        CanIusecoupons: 'cupons podem ser usados ou não',
        Mainimage: 'Imagem principal',
        Setcombinationdiscount: 'Conjunto de desconto de combinação',
        Combinationprice: 'Preço de combinação',
        Combinedtotalprice: 'Preço total da combinação',
        Isitontheshelf: 'Está na prateleira',
        Selectupto5: 'Selecione até 5',
        Promotionprice1: 'Preço promocional',
        Pleaseenterthecombinationquantity: 'Por favor, insira o número de combinações',
        Pleaseenteracombinationname: 'Por favor, insira um nome de combinação',
        Upto100wordscanbeentered: 'Digite até 100 caracteres',
        Pleaseselectadaterange: 'Selecione um intervalo de data',
        Pleaseselectastartdate: 'Selecione uma data de início',
        Pleaseselecttheenddate: 'Selecione uma data de término',
        Pleasechoosewhethertousecoupons: 'Selecione se deve usar o cupão',
        Pleaseselect25items: 'Selecione 2-5 itens',
        Pleaseselectaproductthathasbeenlisted: 'Selecione um item que foi colocado nas prateleiras',
        Inventoryofselecteditems: 'O multiplicador de ações/vendas do item selecionado não pode ser menor que a quantidade de atividade',
        Combinationpricecannotbeempty: 'O preço de combinação não pode estar vazio',
        Caninput0andpositiveintegers: '0 e números inteiros positivos podem ser inseridos, os símbolos só podem ser inseridos como % e. , Até dois lugares decimais',
        Presaleproductscannotbeadded: 'Este é um item de pré-venda e não pode ser adicionado como uma combinação',
        Pleaseselectaproductfirst: 'Selecione um item primeiro',
        Freeshippingonfirstorder: 'entrega grátis em seu primeiro pedido',
        Firstfreeshippingitem: 'Artigos de entrega grátis em seu primeiro pedido',
        SelectedProduct: 'Produto selecionado',
        Between1and50charactersinlength: 'Comprimento entre 1 e 50 caracteres',
        Thisoperationwillremovethefile: 'Esta operação removerá o arquivo, deseja continuar?',
        Brandandcountrycannotbeempty: 'Marca e país não podem estar vazios',
        Pleaseselectatime: 'Selecione um horário',
        Editedsuccessfully: 'Edite com sucesso!',
        Shoppingrebate: 'Recursos de compras',
        Rebateratio: 'Porcentagem de desconto',
        Rebatestarttime: 'hora de início de desconto',
        Rebateendtime: 'Terminação de desconto',
        Rebatetime: 'Tempo de desconto',
        doyouwanttodelete2: 'Para excluir ou não excluir',
        ActivityTopic: 'Tópico do evento',
        ActivityName: 'Nome do evento',
        Whethertodisplayonthehomepage: 'Se deve ser exibido na primeira página',
        QuerySearch: 'procurar',
        TopicName: 'Nome do tópico',
        Activitytime: 'Hora do evento',
        Richtextornot: 'Texto rico ou não',
        ActivityDescription: 'Descrição do Evento',
        SpecialSEO: 'SEO temático',
        LogisticsPricing: 'Preços de logística',
        Basicfreight: 'Taxas básicas de envio',
        Freeshippingprice: 'Preços de entrega grátis',
        Deliverytimeprompt: 'Dicas de tempo de envio',
        Receivingtimeprompt: 'Informação do tempo de receção',
        PricingSettings: 'Configurações de preços',
        Freightstandard: 'Padrão de remessa',
        Freightallocation: 'Configuração de envio',
        FreightType: 'Tipo de envio',
        Freeshipping: 'entrega grátis',
        Configureshippingcosts: 'Configure os custos de envio',
        Fullreductionoffreight: 'dinheiro para trás de entrega',
        Orderamountfull: 'O valor do pedido atinge para',
        Receivingandshippingreminder: 'Recebimento e lembrete de remessa',
        link: 'Link',
        confirm: 'confirme',
        Cannotbe0: 'Não pode ser 0',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Apenas até dois lugares decimais podem ser inseridos',
        Pleaseinputthenumbercorrectly: 'Por favor, insira o número correto',
        Pleaseenterthecountry: 'Por favor, entre no país',
        Pleaseselectshippingcost: 'Selecione o custo de envio',
        Pleasecompletetheshippingcostconfiguration: 'Preencha a configuração de remessa',
        Pleaseenteradeliveryprompt: 'Por favor, insira um lembrete de recibo',
        Pleaseenteralink: 'Por favor, insira um link',
        Addlogisticsconfiguration: 'Adicione a configuração de logística',
        Editlogisticsconfiguration: 'Editar configuração de logística',
        Pleaseenterthelogisticscompany: 'Por favor, insira uma empresa de logística',
        Editingfailed: 'Editar falhou',
        Addlogisticscompany: 'Adicionar empresa de logística',
        Pleaseuploadtheicon: 'Faça o upload de um ícone',
        Pleaseenterthelinkcorrectly: 'Por favor, insira o link corretamente',
        Successfullyadded: 'Adicionado com sucesso',
        Editlogisticscompany: 'Editar empresa de logística',
        Thisisalreadythefirstitemmoveupfailed: 'Primeiro item já, não conseguiu subir',
        Thisisalreadythelastitemmovedownfailed: 'Já último item, mover para baixo falhou',
        Costprice: 'Preço de custo',
        taxrate: 'Taxa de imposto',
        commission: 'Comissões',
        Grossmargin: 'Margem bruta',
        doyouwanttodelete: 'O número preenchido é muito grande',
        Pleaseselectapricetype: 'Selecione um tipo de preço',
        Pleasecompletethetaxrateconfiguration: 'Preencha a configuração da taxa de imposto',
        Pleasecompletethecommissionconfiguration: 'Preencha a configuração da Comissão',
        PleasecompleteGrossmarginconfiguration: 'Preencha a configuração da taxa bruta',
        Weightrange_start_kg: 'Faixa de peso (início) kg',
        Weightrange_end_kg: 'Faixa de peso (final) kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'Um máximo de quatro lugares decimais pode ser inserido',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'O valor final deve ser maior ou igual ao valor inicial',
        Pleasecompletetheweightrangeconfiguration: 'Preencha a configuração da faixa de peso',
        Pleaseentertheshippingcost: 'Por favor, insira o custo de envio',
        Addshippingstandard: 'Adicione o padrão de remessa',
        EditFreightStandard: 'Editar padrão de remessa',
        Allocationofpatrioticcoins: 'Configuração de moedas AIGO',
        RuleConfiguration: 'Configuração de regras',
        ObtainingPatrioticCoinObject: 'objetos de obter moedas de AIGO',
        Rewardconditionsobtained: 'Condições para obter recompensas',
        Obtainpatrioticcoinallocation: 'Obtenha configuração de moedas AIGO',
        Inputquantity: 'Digite a quantidade',
        EnterName: 'Insira o nome',
        Registrationsuccessfulnot: 'Registro bem sucedido (não use código de convite)',
        Inviteduserplacesanorder: 'Pedido de colocação do usuário convidado',
        Registrationsuccessful: 'Registro bem sucedido (Use Código de Convite)',
        Registeredaccount: 'Conta registada (país registrado)',
        Registrationsuccessfulnot1: 'Registo de conta bem sucedido sem código de convite',
        Numberoflocalcurrencies: 'As quantidades configuradas abaixo estão na moeda nacional do país correspondente',
        Inviteraccount: 'Conta do convidado (forneça conta do código de convite, Código do país de convite)',
        Provideinvitationcode: 'Forneça o código de convite para outros usuários, outros usuários usam o código de convite para se registrar e, em seguida, conclua com êxito um pedido (cada usuário convidado pode obter apenas 1 bônus)',
        Usinganinvitationcode: 'Registrar conta com sucesso usando o código de convite',
        ProductPromotion: 'Promoções de produtos',
        discount: 'Descontos',
        Freeshippingornot: 'entrega grátis ou não',
        Secondkillsorting: 'promoção por tempo limitado ordenar',
        surplus: 'Restante',
        Totalnumber: 'Total',
        ImportFile: 'Importar arquivos',
        Selectupto50: 'Selecione até 50',
        Setuppromotions: 'Definir promoção',
        Nocountrieslisted: 'Nenhum países onde os produtos são colocados na prateleira',
        Promotiontime: 'Tempo de promoção',
        Displaypromotionalprices: 'Se deve exibir o preço promocional',
        PromotionPrice: 'Preço promocional',
        Promotionquantity: 'Quantidade promocional',
        Doyousupporttheuseofcoupons: 'Se deve apoiar o uso de cupons',
        Purchaserestrictionsforthesameuser: 'Compre restrições para o mesmo usuário',
        Singleorderlimit: 'Limitado a um único pedido',
        CurrentactivitypricesinSpain: 'A margem bruta no preço atual da atividade na {countryCode} é de',
        SubmitConfirmation: 'Enviar confirmação',
        Productdistribution: 'Distribuição de produtos',
        DistributionName: 'Nome de distribuição',
        DistributionCountry: 'País de distribuição',
        Distributionprice1: 'Preço de distribuição',
        Distributiontime: 'Tempo de distribuição',
        DistributionPrice: 'Preço de distribuição',
        Distributionquantity: 'Quantidade de distribuição',
        CopyConnection: 'Link de cópia',
        Successfullycopied: 'Copiado com sucesso',
        Countryofuse: 'País de uso',
        Discounttype: 'Tipo de oferta',
        Fullreduction: 'dinheiro de volta',
        Isthereafixedcode: 'Existe um código fixo',
        Effective: 'Válido',
        Comingsoon1: 'Válido em breve',
        Expired: 'Expirado',
        Overlayornot: 'pilha ou não',
        Istheexchangecodefixed: 'Se deve corrigir o código de resgate',
        Received: 'recebido',
        Used: 'Usado',
        Availableamount: 'quantidade disponível',
        Maximumdeductionamount: 'Quantidade de dedução máxima',
        Canitbestacked: 'Empilhável ou não',
        Redemptioncode: 'Código de redenção',
        Fullreductionamount: 'Valor de volta em dinheiro',
        Enterredemptioncode: 'Digite o código de resgate',
        Numberofredemptioncodes: 'Número de códigos',
        Numberofusesbythesameuser: 'Número de usos para o mesmo usuário',
        Effectivetime: 'Tempo válido',
        Isitpossibletooverlaydistribution: 'Pode ser empilhado com ou sem distribuição',
        Canpromotionsbestacked: 'Pode ser empilhado com ou sem promoção',
        Entercoupondescription: 'Digite instruções de voucher',
        Pleaseenteradescription: 'Por favor, insira uma descrição',
        Pleaseselectadate: 'Selecione uma data',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Por favor, insira o número de usos para o mesmo usuário',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Por favor, insira o valor disponível de 0 para indicar ilimitado',
        Pleaseentertheavailableamount: 'Por favor, insira o valor disponível',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'O número de códigos de resgate pode ser apenas mais do que o original',
        Pleaseenterthenumberofredemptioncodes: 'Por favor, insira o número de códigos de resgate',
        Applicableclassification: 'Categorias aplicáveis',
        Applicableproducts: 'Produtos aplicáveis',
        Useordernumber: 'Use o número do pedido',
        Claimstatus: 'Status da coleção',
        Notclaimed: 'Não coletado',
        notused: 'Não usado',
        Usagestatus: 'Usar status',
        Collectiontime: 'Tempo de coleta',
        Usagetime: 'Hora de uso',
        UsingUsers: 'usando usuários',
        BannerManagement: 'Gestão de banners',
        position: 'Localização',
        JumpType: 'Tipo de salto',
        Nojump: 'Sem salto',
        Homepagerecommendationposition: 'Página inicial apresentada',
        Homebanner: 'Banner em casa',
        Displaytime: 'Tempo de exibição',
        JumpLink: 'Jump link',
        Suggestedsize: 'Tamanho sugerido',
        Enterjumplink: 'Digite o link do salto',
        Uploadingimagesonlysupports: 'Carregue imagens em JPG, PNG, GIF, JPEG apenas',
        Topwithlargenumbers: 'Padrão é 0, maior o número, maior será a classificação',
        Pleaseselectatleastonecountry: 'Selecione pelo menos um país',
        Boutiquerecommendationspace: 'Espaço de recomendação da boutique',
        Fullproductrecommendation: 'Recomendação completa de produtos',
        popupnotification: 'Janela de pop-up',
        Addbanner: 'Adicione Banner',
        subscriptionmanagement: 'Gestão de assinatura',
        Emailaddress: 'Endereço de email',
        Subscribing: 'Assinatura em andamento',
        Canceled: 'Cancelado',
        SubscriptionSource: 'Fonte de subscrição',
        Unsubscribe: 'cancelar assinatura',
        Resubscribe: 'Assinando',
        Pleaseselectatimerange: 'Selecione um intervalo de tempo',
        WebsiteManagement: 'Gestão de site',
        informationservice: 'Serviços de informação',
        New: 'Criar',
        Isitdisplayedatthebottomofthewebsite: 'Para exibir ou não na parte inferior do site',
        Pleaseselectaninformationservice: 'Selecione o serviço de informações',
        deleteconfirmation: 'Se deve confirmar a exclusão do nó e todas as informações sob o nó',
        Pleaseenteracolumnname: 'Por favor, insira um nome de colum',
        Thelengthcannotexceed50characters: 'O comprimento não pode exceder 50 caracteres',
        ThelengthcannotexceedXcharacters: 'O comprimento não pode exceder {num} caracteres',
        Pleaseentersorting: 'Por favor, insira o pedido',
        Selectedcountrycontentisrequired: 'O conteúdo do país selecionado é necessário',
        TitleName: 'Nome do título',
        Chinesetitleisrequired: 'O título chinês é necessário',
        Selectedcountrytitleisrequired: 'O título de país selecionado é necessário',
        EditingInformationServices: 'Editar Serviço de Informação',
        ColumnName: 'Nome da coluna',
        Pleaseenterthecreationtime: 'Por favor, digite o tempo de criação',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Por favor, digite se for exibido na parte inferior do site',
        ViewInformationServices: 'Exibir serviço de informação',
        Modificationtime: 'Tempo de modificação',
        Countrycannotbeempty: 'País não pode estar vazio',
        Titlecannotbeempty: 'O título não pode estar vazio',
        Richtextcontentonthepagecannotbeempty: 'O conteúdo de texto rico em página não pode estar vazio',
        Nationalconfiguration: 'Configuração do país',
        currency: 'Moeda',
        allocation: 'Configuração',
        Statuseditedsuccessfully: 'Status editado com sucesso',
        Areyousuretocontinuedeletingdata: 'Está superando continuar excluindo dados',
        WebsiteAddress: 'Endereço do website',
        Contactinformation: 'Informações de contato',
        Customerserviceemail: 'Email de atendimento ao cliente',
        Socialmedia: 'Mídia social',
        Socialmediainformation: 'Informações sobre mídias sociais',
        Pleasechooseapaymentmethod: 'Selecione um método de pagamento',
        Lengthgreaterthan20characters: 'Comprimento superior a 20 caracteres',
        Lengthgreaterthan100characters: 'Comprimento superior a 100 caracteres',
        Successfullydeletedinformation: 'Exclua mensagem com sucesso',
        Iconmustbeuploaded: 'O ícone deve ser carregado',
        Successfullyaddedinformation: 'Adicione mensagem com sucesso',
        Successfullyeditedinformation: 'Editar mensagem com sucesso',
        Pleaseentertheemailcorrectly: 'Por favor, digite seu e -mail corretamente',
        Thesizeoftheuploadedimagecannotexceed: 'O tamanho da imagem carregada não deve exceder',
        Countryname: 'Nome do país',
        Pleaseuploadpictures: 'Por favor, carregue uma foto',
        NewCountryInformation: 'Crie novas informações do país',
        EditCountryInformation: 'Editar informações do país',
        Picturesmustbetransmitted: 'As imagens devem ser carregadas',
        NewCountryInformationTableImageAddress: 'Criar uma folha de informações do país ， Imagem e endereço',
        Operationsuccessful: 'Operação bem -sucedida',
        Euro: 'Euro',
        pound: 'Libra',
        Zloti: 'Zloty',
        Brandcannotbeempty: 'Marca não pode estar vazia',
        Thewebsiteaddresscannotbeempty: 'O endereço do site não pode estar vazio',
        Contactinformationcannotbeempty: 'As informações de contato não podem estar vazias',
        Thecustomerserviceemailaddresscannotbeempty: 'O endereço de e -mail do atendimento ao cliente não deve estar vazio',
        Medianamecannotbeempty: 'O nome da mídia não pode estar vazio',
        Themediaiconcannotbeempty: 'O ícone da mídia não pode estar vazio',
        Medialinkcannotbeempty: 'O link da mídia não pode estar vazio',
        Thecountryiconcannotbeempty: 'O ícone do país não pode estar vazio',
        Languagecannotbeempty: 'A linguagem não pode estar vazia',
        Currencycannotbeempty: 'Moeda não pode estar vazia',
        Pagedescriptioncannotbeempty: 'Descrição da página não pode estar vazia',
        Statusenable_disablecannotbeempty: 'UserID_IP_LoginDevice',
        SEOManagement: 'Gestão de SEO',
        Import: 'Importar',
        DownloadImportTemplate: 'Baixe o modelo de importação',
        PageURL: 'URL da página',
        SEOdata: 'Dados de SEO',
        SEOConfigurationTemplate: 'Modelo de configuração de SEO',
        Downloadsuccessful: 'Baixar com sucesso',
        SEOManagementImport: 'Importação de Gestão de SEO',
        DragtheExcelfilehere_orclickonupload: 'Arraste e solte o arquivo do Excel aqui ou clique no upload',
        Fileimportedsuccessfully: 'Arquivo importado com sucesso',
        Fileimportfailed_pleasecheckthefilecontent: 'A importação de arquivos falhou, verifique o conteúdo do arquivo',
        Pleaseenterthelanguage: 'Por favor, digite o idioma',
        Thelengthcannotexceed2characters: 'O comprimento não pode exceder 2 caracteres',
        PleaseenterthepageURL: 'Por favor, insira o URL da página',
        Thelengthcannotexceed100characters: 'O comprimento não deve exceder 100 caracteres',
        Thelengthcannotexceed20characters: 'O comprimento não deve exceder 20 caracteres',
        No_Illegalrowdata: 'O ? linha de dados é ilegal',
        Failedtouploadfile_Pleasecheckthefilecontent: 'O upload do arquivo falhou, verifique o conteúdo do arquivo',
        Namecannotbeempty: 'Nome não deve estar vazio',
        PageURLcannotbeempty: 'URL da página não deve estar vazio',
        Thepagetitlecannotbeempty: 'Título da página não deve estar vazio',
        SEOManagementImportTemplate: 'Modelo de importação de Gestão de SEO',
        Donotmodify: 'Por favor, não modifique as três primeiras linhas do título do modelo',
        Required: 'Os campos marcados com ' * ' são necessários',
        Brandandlanguagemustbefullycapitalized: 'Marca e linguagem devem estar em todas as letras maiúsculas',
        SEOManagementExport: 'Exportação de gestão de SEO',
        Pagekeywordscannotbeempty: 'As palavras -chave da página não podem estar vazias',
        Prohibitedsalesarea: 'Áreas de vendas proibidas',
        Pleaseenterthepostalcodecorrectly: 'Por favor, insira seu código postal corretamente',
        Pleasefillinthepostalcode: 'Por favor, preencha seu código postal',
        Countrycodecannotbeempty: 'O código do país não deve estar vazio',
        Postalcodecannotbeempty: 'Código postal não deve estar vazio',
        Postalcodealreadyexists: 'Código postal já existe',
        Homepagearticle: 'Artigos da página inicial',
        BrandStory: 'Histórias de marca',
        SciencePopularizationZone: 'Zona de popularização científica',
        Lightstripzone: 'Zona leve de tira',
        Pictureandtext: 'imagem e texto',
        Comingsoon: 'libere em breve',
        Publishinginprogress: 'liberando em andamento',
        column: 'Coluna',
        Releasetime: 'Data de lançamento',
        Pleaseenteratitle: 'Por favor, insira um título',
        Pleaseselectacolumn: 'Selecione uma coluna',
        Pleaseselectatype: 'Selecione o tipo',
        Patrioticcoinusagelog: 'AIGO moedas de uso do registro de uso',
        income: 'Renda',
        expenditure: 'Despesa',
        mode: 'Método',
        Orderexpenses: 'Pedidos de gastos',
        Orderrewards: 'Pedidos recompensas',
        OrderCloseReturn: 'Encerramento e devolução do pedido',
        other: 'Outro',
        Backendrecharge: 'recarga de fundo',
        Orderrebate: 'Encomendar desconto',
        Numberofchangesinpatrioticcoins: 'Número de moedas AIGO mudou',
        Numberofpatrioticcoinsafterchanges: 'Número de moedas AIGO após mudança',
        CountryCode: 'Código do país',
        Changetime: 'Tempo de mudança',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: 'Selecione o histórico de consumo de moedas AIGO do usuário',
        WebsiteNavigation: 'Navegação do site',
        HomepageConfiguration: 'Configuração da página inicial',
        Navigationcontent: 'Conteúdo de navegação',
        Recommendedforyou: 'Recomendado para si',
        SelectNavigation: 'Selecione navegação',
        Pleasecheckfirst: 'Por favor, marque primeiro',
        Logmanagement: 'Gestão de log',
        UserID_IP_LoginDevice: 'ID de usuário/dispositivo IP/login',
        logintime: 'Tempo de login',
        Loginmethod: 'Método de login',
        IPaddress: 'endereço de IP',
        Logindevice: 'Dispositivo de login',

        //11-22
        AssociatedOrderNumber: 'Número do pedido associado',
        recordId: 'ID do registo',
        UserAccess: 'Acesso ao usuário',
        PleaseSelectTheStatisticalType: 'Selecione o tipo estatístico',
        StatisticalByDate: 'Estatístico por data',
        StatisticalBySource: 'Estatística por fonte',
        Date: 'Data',
        Visits: 'Visitas',
        NewVisitors: 'Novos visitantes',
        OrderStatistics: 'Estatísticas de pedidos',
        Sales: 'Vendas',
        TotalOrders: 'Pedidos totais',
        AverageOrderAmount: 'Valor da ordem média',
        SynchronizeEBP: 'Sincronize EBP',
        ParameterName: 'Nome do parâmetro',
        OnTheShelf: 'Na prateleira',
        OfflineShoppingCart: 'Carrinho de compras offline',
        AddToCart: 'Adicionar ao carrinho',
        EnterCheckout: 'Digite checkout',
        UserLogin: 'Login de usuário',
        UserRegistration: 'Registo do usuário',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Números IP diferentes para adicionar itens ao carrinho de compras',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Diferentes números de IP para inserir a página de confirmação do pedido',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Diferentes números de IP para quem concluiu o processo de login após ser redirecionado do carrinho de compras offline para a página de login.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Diferentes números de IP para quem concluiu o processo de Registo após ser redirecionado do carrinho de compras offline para a página de Registo.',
        UserBehavior: 'Comportamento do usuário',
        Display: 'Mostrar',
        DoNotDisplay: 'Não mostrar',
        AddToPresale: 'Adicione à pré-venda',
        CancelPresale: 'Cancelar a pré-venda',
        PresaleConfiguration: 'Configuração de pré-venda',
        OffTheShelf: 'Da prateleira',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Preencha as informações do produto antes de configurar a pré-venda',
        SynchronizedEBPPrompts: 'Devido à necessidade de sincronização com a EBP, a sincronização em lote só pode ser realizada uma vez dentro de x minuto para evitar erros. Após a confirmação, a sincronização é realizada de forma assíncrona e o SKU é exibido imediatamente após obtê -lo. Por favor, seja paciente.',
        PleaseWait: 'Por favor, aguarde',
        PleaseSelectATag: 'Selecione uma tag',
        Updating: 'Atualização',
        Abortive: 'Fracassado',
        DefaultRule: 'Regra padrão',
        BottomInventory: 'Inventário inferior',
        SynchronisedAlertsForGraphicDetails: 'Detalhes gráficos: sincronize as [palavras -chave do produto correspondentes] de acordo com o idioma',
        SynchronisedAlertsForLargeProductImages: 'Imagem do produto: sincronize a imagem da linguagem correspondente de acordo com o idioma, leia a classificação da imagem EBP e defina automaticamente a primeira imagem EBP como a imagem principal',
        Account: 'Conta',
        Account1: 'Conta',
        EnterUpToNumItems: 'Insira até {num} itens',
        SynchronizedSuccessfully: 'Sincronizado com sucesso',
        SuccessfullyListed: 'Listado com sucesso',
        SearchSuccessful: 'Pesquise bem -sucedido',
        EnterNewPassword: 'Insira a nova senha',
        OnlyNumbersAndLettersAreAllowed: 'Apenas números e letras são permitidos',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: 'Preencha a configuração de pré-venda antes de listar o item de pré-venda',
        ProductWeight: 'Peso do Produto',
        ConfirmExecution: 'Confirmar a execução?',
        ConfirmBatchListing: 'Confirmar lista em lote?',
        Template: 'Modelo',
        PleaseSelectTheProductsToBeSynchronized: 'Selecione os produtos a serem sincronizados',
        ForcedSynchronisationDescription: 'A sincronização forçada excluirá os dados modificados e os substituirá por dados sincronizados. A sincronização comum não sincronizará os dados modificados do produto.',
        ConfirmSynchronizationExecution: 'Confirme a execução da sincronização',
        SynchronisationHasBeenSentPleaseWait: 'A mensagem da tarefa de sincronização foi enviada, aguarde pacientemente a sincronização de dados para concluir',
        ProhibitLogin: 'Proibir login',
        IsTheUserLoggedIn: 'O usuário está conectado?',
        Prohibit: 'Proibir',
        ProhibitedSuccessfully: 'Proibido com sucesso',
        EnableLogin: 'Ativar login',
        EnabledSuccessfully: 'Ativado com sucesso',
        OperationCanceled: 'Operação cancelada',
        UserData: 'Data.xlsx do usuário',
        GraphicDetails: 'Detalhes gráficos',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'Este produto foi definido como um produto combinado e não pode ser definido para pré-venda. Remova o produto da combinação primeiro?',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'Este produto é um produto promocional e não pode ser definido para pré-venda. Cancele primeiro a promoção.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'Este produto é um produto de distribuição e não pode ser definido para pré-venda. Cancele primeiro a distribuição.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'Este produto já tem uma atividade de redução total e não pode ser adicionada',
        ESNoPriceNotAvailableForPresale: 'Este produto não tem um preço no {Any} e não pode adicionar pré-venda. Primeiro, remova o país correspondente ou sincronize o preço do país correspondente antes de adicionar produtos de pré-venda!',
        ConfirmSettingThisProductAsAPresaleItem: 'Confirmar a configuração deste produto como um item de pré-venda?',
        PresaleAddedSuccessfully: 'Pré-venda adicionada com sucesso',
        ConfirmSettingThisProductAsANonpresaleItem: 'Confirmar a configuração deste produto como um item que não está vendido?',
        CancelPresaleSuccessful: 'Cancelar a pré-venda bem-sucedida',
        Preview: 'Visualização',
        TheInventoryOfCurrentProducts0Continue: 'O inventário do produto atual {stock} é 0, continue?',
        TheInputContentCannotExceedCharacters: 'O conteúdo de entrada não pode exceder {x} caracteres',
        PleaseSelectTheActivity: 'Selecione a atividade',
        OrderDetails: 'Detalhes do pedido',
        LogisticsInformation: 'Informações de logística',
        CancelRemark: 'Cancelar observação',
        DepositAmount: 'Valor do depósito',
        FinalPaymentAmount: 'Valor do pagamento final',
        FullReductionDiscount: 'Desconto total da redução',
        YourBrowserDoesNotSupportVideoPlayback: 'Seu navegador não suporta reprodução de vídeo',
        EditBanner: 'Editar banner',
        AddBanner: 'Adicione Banner',
        UpdateTime: 'Tempo de atualização',
        HomepageRecommendationPosition: 'Posição de recomendação da página inicial',
        ActivityID: 'ID da atividade',
        PleaseSelectThePosition: 'Selecione a posição',
        PleaseSelectTheJumpType: 'Selecione o tipo de salto',
        PleaseEnterTheAssociatedID: 'Por favor, insira o ID associado',
        TheUploadedImageSizeCannotExceed5MB: 'O tamanho da imagem carregado não pode exceder {num} MB!',
        OnlyMp4FormatIsSupportedForVideoUpload: 'Somente o formato MP4 é suportado para upload de vídeo',
        TheUploadedVideoSizeCannotExceed500MB: 'O tamanho do vídeo carregado não pode exceder {num} MB!',
        PleaseUploadTheVideo: 'Faça o upload do vídeo',
        PleaseEnterTheJumpLink: 'Por favor, insira o link de salto',
        PleaseEnterTheCorrectLink: 'Por favor, insira o link correto',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'A verificação falhou, insira os campos necessários primeiro',
        AddFailed: 'Adicionar falhou',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: 'Já é o primeiro, e o movimento ascendente falhou',
        PleaseEnterTheRebateRatio: 'Por favor, insira a proporção de desconto',
        EditCoupon: 'Editar cupom',
        AddCoupon: 'Adicione cupom',
        IDCouponName: 'Nome de identificação/cupom/Código de redenção',
        Random: 'Aleatório',
        Fixed: 'Fixo',
        AboutToTakeeffect: 'Prestes a entrar em vigor',
        NotStackable: 'Não empilháveis',
        Stackable: 'Empilhável',
        Frozen: 'Congeladas',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Por favor, insira o valor máximo de dedução, 0 significa ilimitado',
        UseUserID: 'Use ID de usuário',
        UseOrderNumberExchangeCodeUserID: 'Use o número do pedido/código de troca/ID do usuário',
        PleaseEnterTheDiscount: 'Por favor, insira o desconto',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Somente números de 0 a 100 podem ser inseridos, até 1 lugar decimal',
        PleaseEnterTheFullReductionAmount: 'Por favor, insira o valor total da redução',
        PleaseEnterTheExchangeCode: 'Por favor, insira o código do Exchange',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Somente números/letras podem ser inseridas, pelo menos 3 dígitos, até 20 dígitos',
        PleaseSelectAFixedExchangeCode: 'Selecione um código de troca fixo',
        PleaseEnterTheMaximumDeductionAmount: 'Por favor, insira o valor máximo de dedução',
        PleaseSelectWhetherItCanbeStacked: 'Selecione se pode ser empilhado',
        WarehousingTime: 'Tempo de armazenamento',
        EnterActivityDescription: 'Digite a descrição da atividade',
        ActivityTitleCannotBeEmpty: 'O título da atividade não pode estar vazio',
        TheTitleCannotExceed200Characters: 'O título não pode exceder {num} caracteres',
        PleaseEnterTheActivityDescription: 'Por favor, insira a descrição da atividade',
        ActivityTimeCannotBeEmpty: 'O tempo de atividade não pode estar vazio',
        ActivityDescriptionLimitOf10000Words: 'Descrição da atividade Limite de {num} palavras',
        ActivityRecommendation: 'Recomendação de atividades',
        Promotion: 'Promoção',
        EveryFull: 'Cada {num}cheio',
        EnterAmount: 'Insira o valor',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Somente números positivos podem ser inseridos, até 2 lugares decimais, não podem começar com 0',
        PleaseEnterTheCorrectAmount: 'Por favor, insira a quantidade correta',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Selecione categorias aplicáveis ou produtos aplicáveis',
        Rules: 'Regras',
        RemainingTotal: 'Restante/total',
        CopyLink: 'Link de cópia',
        DistributionNameProductIDBarcode: 'Nome da distribuição/ID do produto/código de barras',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'Esta sub -função não está disponível neste país por enquanto e estará disponível posteriormente',
        PleaseEnterThePrice: 'Por favor, insira o preço',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Calcule automaticamente após inserir o desconto',
        PleaseEnterTheQuantity: 'Por favor, insira a quantidade',
        OrderRemarkLabel: 'Rótulo de observação do pedido',
        OrdersWorkWithInfluencers: 'Ordens trabalham com influenciadores',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: 'A atual margem de lucro bruta do preço atual da atividade é',
        ThePriceCannotBeLowerThanTheLowestPrice: 'O preço não pode ser menor que o preço mais baixo:',
        Distribution: 'Distribuição',
        IDName: 'Id/nome',
        ProductSelected: 'Produto selecionado',
        ProductHasNoPricing: 'Produto não tem preços',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: 'A margem de lucro bruta atual do preço atual da atividade é{rate}%, certamente enviará?',
        SubscriptionStatus: 'Status de inscrição',
        URLClickToCopyAddress: 'URL (clique para copiar o endereço)',
        URLSourceManagement: 'Gestão de fonte de URL',
        PleaseEnterTheURL: 'Por favor, insira o URL',
        PleaseEnterEnglishOrNumbers: 'Por favor, insira inglês ou números',
        LengthCannotExceed1000Characters: 'O comprimento não pode exceder 1000 caracteres',
        URLFixedAddressIsEmpty: 'O endereço fixo de URL está vazio',
        cover: 'Cobrir',
        PleaseEnterOrPasteTheVideoScript: 'Por favor, insira ou cole o script de vídeo',
        VideoScript: 'Script de vídeo',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Faça o upload de um arquivo de vídeo ou preencha o script de vídeo',
        PleaseEnterTheBrand: 'Por favor, digite a marca',
        Portuguese: 'Português',
        Register: 'Registo',
        InviteUsers: 'Convide usuários',
        OrderPayment: 'Ordem de pagamento',
        SupportsMP4Forma500MB: 'Suporta formato MP4, ≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'Nenhum produto está selecionado atualmente, se deve fechá -lo?',
        ExitFullScreen: 'Saia de tela cheia',
        FullScreen: 'Tela cheia',
        Logout: 'Sair',
        OnlySupportsFilesInFormat: 'Suporta apenas arquivos no formato {xxx}',
        PleaseSetAllPromotionalProducts: 'Defina todos os produtos promocionais',
        PleaseEnterTheWeight: 'Por favor, insira o peso',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'O preço fixo não pode ser menor que o preço mais baixo',
        CloseOthers: 'Fechar outros',
        CloseAll: 'Feche tudo',
        ImportSuccessful: 'Importação bem -sucedida',
        BatchRecharge: 'Recarga em lote',
        RechargeEmail: 'Recarregue o email',
        RechargeCountry: 'Recarregar o país',
        RechargeSuccessful: 'Recarregue bem -sucedido',
        RechargeInformation: 'Recarregar informações',
        PleaseDownloadTheRechargeInformationFirst: 'Faça o download das informações de recarga primeiro',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'Os dados de recarga em lote da moeda da AIGO não podem estar vazios',
        TheBatchRechargeDataCannotExceed1000Records: 'Os dados de recarga em lote não podem exceder 1000 Registos',
        ConfirmSubmission: 'Confirme o envio',
        ExportFailed: 'A exportação falhou',
        PackingQuantity: 'Quantidade de embalagem',
        Activity: 'Atividade',
        PleaseSelectWhetherToEnable: 'Selecione se deve ativar',
        OnlyOneProductCanBeSynchronized: 'Apenas um produto pode ser sincronizado',
        SKUWithAQuantityOfNumDoesNotExist: 'Sku com uma quantidade de{num} não existe',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU com uma quantidade de {num} não atende ao múltiplo de vendas',
        SalesMultipleDoesNotMatchCannotBeListed: 'Vendas múltiplas não corresponde, não pode ser listado',
        SalesMultipleIsEmptyInSpain: 'Vendas múltiplas está vazia na Espanha',
        SynchronizationOfPackageQuantityIsAbnormal: 'A sincronização da quantidade do pacote é anormal',
        CannotContinueToMoveUp: 'Não pode continuar a subir',
        CannotContinueToMoveDown: 'Não pode continuar a descer',
        SynchronizeThePackageQuantity: 'Sincronizar a quantidade do pacote',
        ProductAssociationIDCannotBeEmpty: 'O ID da associação de produtos não pode estar vazio',
        PackageQuantityCannotBeEmpty: 'A quantidade do pacote não pode estar vazia',
        PleaseEnterTheCorrectQuantityFormat: 'Por favor, insira o formato de quantidade correta',
        PleaseEnterSeparatedBy: 'Por favor, digite (separado por #)',
        PackageQuantity: 'Quantidade no pacote',
        LengthBetween1And140Characters: 'Comprimento entre 1 e 140 caracteres',
        PleaseSelectTheVariantParametersFirst: 'Selecione os parâmetros da variante primeiro',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'O parâmetro está associado à variante, tenha cuidado',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Selecione os dados da mesma categoria, selete',
        UpTo5VariantsCanBeSelected: 'Até 5 variantes podem ser selecionadas',
        NoVariant: 'Nenhuma variante',
        VerificationFailedPleaseSelectTheVariant: 'Verificação falhou, selecione a variante',
        VerificationFailedPleaseSelectTheProduct: 'Falha na verificação, selecione o produto',
        VerificationFailedTheProductParameterValueIsEmpty: 'Verificação falhou, o valor do parâmetro do produto está vazio',
        SetSuccessfully: 'Definido com sucesso',
        UploadingVideo: 'Upload de vídeo',
        SearchParameters: 'Parâmetros de pesquisa',
        ImportantParameters: 'Parâmetros importantes',
        Select: 'Selecione',
        ConfirmSettingThisImageAsTheMainImage: 'Confirme a definição desta imagem como a imagem principal',
        ConfirmDisplayDoNotDisplay: 'Confirme a exibição/não exibir',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'O limite atual é selecionar {num1} arquivos, desta vez selecionou {num2} arquivos, um total de {num3} arquivos foram selecionados',
        ConfirmToRemoveAny: 'Confirmar para remover {Any}?',
        TheTestEnvironmentCannotUploadFiles: 'O ambiente de teste não pode fazer upload de arquivos',
        PleaseEnterTheEnglishTitle: 'Por favor, insira o título em inglês',
        CancelSettingAsTheMainImage: 'Cancelar a configuração como a imagem principal?',
        SetAsTheMainImage: 'Definido como a imagem principal?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'Não é possível enviar imagens ou vídeos que já existem',
        SynchronizeAnyImagesToOtherLanguages: 'Sincronizar imagens {Any} com outros idiomas?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Selecione o produto a ser sincronizado primeiro',
        PleaseSelectTheProductFirst: 'Selecione o produto primeiro',
        ConfirmWhetherToDeleteTheSelectedImageVideo: 'Confirmar se é necessário excluir a imagem/vídeo selecionado?',
        TheMainPictureCannotBeTurnedOff: 'A imagem principal não pode ser desligada',
        CancelDisplay: 'Cancelar exibição?',
        ConfirmWhetherToDelete: 'Confirmar se deve excluir?',
        PleaseUploadTheEnglishImage: 'Envie a imagem em inglês',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Falha, insira o título do produto para todos os países de vendas',
        PresaleTime: 'Tempo de pré-venda',
        PresalePrice: 'Preço de pré-venda',
        PleaseEnter: 'Por favor, insira',
        PresaleQuantity: 'Quantidade de pré-venda',
        DepositRatio: 'Relação depósito',
        AssociatedAGBSOnthewayOrders: 'Pedidos AGBs associados on-the-way',
        EstimatedDeliveryTime: 'Tempo de entrega estimado',
        FinalPaymentDeadline: 'Prazo final de pagamento',
        SelectTime: 'Selecione o tempo',
        FixedPrice: 'Preço fixo',
        ByProportion: 'Por proporção',
        ByFullPayment: 'Por pagamento integral',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Apenas números maiores que 0 podem ser inseridos, até dois lugares decimais',
        PresaleTimeCannotBeEmpty: 'O tempo de pré-venda não pode estar vazio',
        PresalePriceTypeCannotBeEmpty: 'O tipo de preço de pré-venda não pode estar vazio',
        PresaleQuantityCannotBeEmpty: 'A quantidade de pré-venda não pode estar vazia',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Somente números inteiros positivos podem ser inseridos, não pode começar com 0',
        DepositRatioTypeCannotBeEmpty: 'Tipo de relação de depósito não pode estar vazio',
        DepositRatioCannotBeEmpty: 'A taxa de depósito não pode estar vazia',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Somente números inteiros entre 0 e 100 podem ser inseridos, excluindo 0 e 100',
        EstimatedDeliveryTimeCannotBeEmpty: 'O tempo de entrega estimado não pode estar vazio',
        FinalPaymentDeadlineCannotBeEmpty: 'O prazo final de pagamento não pode estar vazio',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'O pagamento final deve ser feito antes de 5 horas antes do final da promoção de pré -venda',
        TheRemainingAmount: 'A quantidade restante',
        PresaleStatus: 'Status de pré-venda',
        PresaleStartTime: 'Horário de início da pré-venda',
        PresaleEndTime: 'Horário de término da pré-venda',
        OnPresale: 'Na pré-venda',
        DirectVisit: 'Visita direta',
        BackendManagementSystem: 'Sistema de gestão de back -end',
        Login: 'Conecte-se',
        TipsFillInTheUsernameAndPasswordCasually: 'Dicas: Preencha o nome de usuário e a senha casualmente.',
        PleaseEnterUserName: 'Por favor, insira o nome de usuário',
        PleaseEnterPassword: 'Por favor, digite a senha',
        LoginSuccessful: 'Login bem -sucedido',
        PleaseEnterYourAccountNumberAndPassword: 'Por favor, insira o número da sua conta e a senha',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'O mesmo produto só pode ser devolvido ou trocado',
        B2CBrandWebsite: 'Site da marca B2C',
        CommentManagement: 'Gestão de comentários',
        AIGOCoins: 'Moedas AIGO',
        PleaseEnterThePackageQuantity: 'Por favor, insira a quantidade do pacote',
        PresalePriceCannotBeEmpty: 'Preço de pré-venda não pode estar vazio',
        Product: 'produtos',
        HomePageColumn: 'Coluna da página inicial',
        ColumnDescription: 'Descrição da coluna',
        FlashSaleArea: 'Área de venda flash',

        //1.2.8之后
        SoldOutOrNot: 'Esgotado ou não',
        VirtualQuantity: 'Quantidade virtual',
        VirtualQuantityPackageQuantity: 'Quantidade virtual/quantidade do pacote',
        CombinationIcon: 'Ícone de combinação',
        UploadFailedFileSizeShouldBe3MB: 'Falha no upload. O tamanho do arquivo deve ser <3 MB',
        PromotionQuantityPackageQuantity: 'Quantidade de promoção/quantidade do pacote',
        RecommendedSize100: 'Tamanho recomendado: 100*100, ≤ 20 kb',
        TotalRemainingTotal: 'Total - restante/total',
        Verification: 'Verificação',
        VirtualSalesVolume: 'Volume de vendas virtual',
        VirtualSalesVolumePackageQuantity: 'Volume de vendas virtual Quantidade de pacote',
        DeleteOrNot: 'Excluir ou não?',
        EnabledOrNot: 'Habilitado ou não',
        Cancel: 'Cancelar',
        Code: 'Código',
        Confirm: 'confirme',
        RebateDimension: 'Dimensão de desconto',
        InProgress: 'Em andamento',
        ActivityStatus: 'Status da atividade',
        RebateRatioManagement: 'Gestão de taxa de desconto',
        StartTime: 'Hora de início',
        EndTime: 'Fim do tempo',
        RebateAmount: 'Valor do desconto',
        NewUser: 'Novo usuário',
        RebateObjectNumber: 'Número do objeto de desconto',
        NumberOfCompletedOrders: 'Número de pedidos concluídos',
        RebateObject: 'Objeto de desconto',
        Operation: 'Operação',
        RecentOperator: 'Operador recente',
        Country: 'País',
        Prompt: 'Incitar',
        RebateProducts: 'Produtos com desconto',
        NewUserPromotion: 'Promoção para novos usuários',
        RegularUserPromotion: 'Promoção regular do usuário',
        Upcoming: 'Por vir',
        RebateRatio: 'Taxa de desconto',
        RebateOrNot: 'Desconto ou não',
        Rebate: 'Desconto',
        PleaseSelectARebateDimension: 'Selecione uma dimensão de desconto',
        PleaseSelectARebateObject: 'Selecione um objeto de desconto',
        PleaseSelectACountry: 'Por favor, selecione um País',
        PleaseSelectARebateRatio: 'Selecione uma taxa de desconto',
        RecentOperationTime: 'Tempo de operação recente',
        PromotionObjectType: 'Tipo de objeto de promoção',
        ProductPromotionType: 'Tipo de promoção de produto',
        UserType: 'Tipo de usuário',
        ApplicableUserType: 'Tipo de usuário aplicável',
        RegularUser: 'Usuário normal',
        UseAigoCoinsOrNot: 'use moedas Aigo ou não',
        EndDateOfMembership: 'data final da adesão',
        StartDateOfMembership: 'data de início da adesão',
        memberOrNot: 'membro ou não',
        No: 'Não',
        Yes: 'Sim',
        LowInventory: 'Inventário baixo',
        RechargeEmailbox: 'Recarregue a caixa de e-mail',
        SubmittedSuccessfully: 'submetido com sucesso',
        Importing: 'importação',
        InformationOfTheRechargeFailed: 'Informações da recarga falharam',
        RechargeFailed: 'A recarga falhou',
        RechargeInBatch: 'Recarregue em lote',
        RechargeSuccessfully: 'Recarregue com sucesso',
        RechargeAigoCoins: 'Recarregue moedas AIGO',
        RequiredField: 'Campo obrigatório',
        PDFFormat: 'formato PDF',
        FileSize3MB: 'Tamanho do ficheiro <3MB',
        UploadFailedPDFFormatRequired: 'Falha no upload. Formato PDF necessário',
        AuditFailedPleaseUploadInvoiceAttachment: 'A auditoria falhou, por favor carregue o anexo da fatura',
        PleaseUploadInvoiceAttachment: 'Por favor carregue o anexo da fatura',
        InvoiceAttachment: 'Anexo da fatura',
        Upload: 'Upload',
        UploadFailed: 'Falha no upload',

        HaveAnAccount: 'Não tem uma conta? Clique aqui para se cadastrar',
        SoldOut: 'Vendido',
        HaveAnInvitationCode: 'Opcional, deixe em branco se não tiver um código de convite.',
        RestockedItems: 'Itens reabastecidos',

        NotMeetPleaseEnterAgain: 'O apelido não atende aos requisitos, digite novamente.',
        ThisCouponIsOnlyAvailableForNewUsers: 'Este cupom está disponível apenas para novos usuários.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'Este cupom está disponível apenas para usuários existentes.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Somente devoluções ou trocas do mesmo produto são permitidos.',
        With365DaysRemaining: '（Expires on 9/ 25/2024） with 365 days remaining',

        TheNicknameDoesNotMeet: 'The nickname does not meet the requirements, please enter a new one.',

        GoToView: 'Ir para ver',
        DoNotRegisterRepeatedly: 'Não registe repetidamente',
        ValidityPeriod: 'Período de validade',
        RegisterSuccessfully: 'Cadastre-se com sucesso',
        MemberRegistration: 'Registo de membro',
        RegistrationFailed: 'Registo falhou',
        OneYear: 'Um ano',
        AddToShoppingCart: 'Adicionar ao Carrinho',
        AddANewAddress: 'Adicione um novo endereço',
        ZIPCode: 'Código postal*',

        EditYourAddress: 'Edite seu endereço',
        Remove: 'Remover',
        SetAsDefault: 'Definir como padrão',
        FailedChangeDefaultAddress: 'Falha ao alterar o endereço padrão, tente novamente mais tarde',
        Fullname: 'Nome completo (primeiro e sobrenome)*',
        PhoneNumber: 'Número de telefone',
        DefaultAddressHasBeenChanged: 'O endereço padrão foi alterado',
        ViewComments: 'Ver comentários',
        ClickToCheckLogisticsInfo: 'Clique para verificar informações de logística.',
        RestockingOfOutOfStockItems: 'Reabastecer de itens fora de stock',
        ThisWeekRestockedItems: 'Itens reabastecidos desta semana',
        ProductListInTheShoppingCart: 'Lista de produtos no carrinho de compras',
        WarmReminderVideoResources: 'Lembrete: os recursos de vídeo estão disponíveis apenas para download único.',
        PleaseLeaveYourMessage: 'Por favor, deixe a sua mensagem',
        InvoiceManagement: 'Gestão de fatura',

        ReadmeFile: 'Arquivo de leitura',
        HelpCenter: 'Centro de ajuda',
        FileUpload: 'Upload de arquivo',
        Globalization: 'Globalização',
        SalespersonProfile: 'Perfil do vendedor',
        BatchSyncPage: 'Página de sincronização em lote',
        CountryConfigurationPage: 'Página de configuração do país',
        UserConsultation: 'Consulta do usuário',
        PreSaleItems: 'Itens de pré-venda',
        EventManagement: 'Gerenciamento de eventos',
        BlankPage: 'Página em branco',
        ThereAreCurrentlyNoProductPromotions: 'Atualmente não há promoções de produto',
        EditInventoryBottomLine: 'Editar o resultado final do inventário',
        SetAFixedPrice: 'Defina um preço fixo',
        EnterUpTo500Entries: 'Insira até 500 entradas',
        CantRelateToYourself: 'Não posso relacionar-se consigo mesmo',
        PleaseSelectTheApplicableUserType: 'Selecione o tipo de usuário aplicável',
        TheActivityDescriptionLimitIs10000Words: 'O limite de descrição da atividade é de 10.000 palavras',
        EnabledStatus: 'Status ativado',
        Enable: 'Habilitar',
        OperatingTime: 'Tempo operacional',
        NewUserPromotionEnabled: 'Nova promoção do usuário habilitada',
        NewUserPromotionDisabled: 'Nova promoção do usuário desativada',
        OldUserPromotionEnabled: 'Promoção antiga do usuário habilitada',
        OldUserPromotionDisabled: 'Promoção antiga do usuário desativada',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'O preço promocional não pode ser menor que o preço mais baixo',
        AutomaticallyCalculateAfterEnteringPrice: 'Calcule automaticamente após a entrada de preço',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'O preço promocional para novos usuários é maior que o preço promocional para usuários antigos. Você quer continuar?',
        PleaseSelectPromotionCountry: 'Selecione o país de promoção',
        PromotionDiscount: 'Desconto de promoção',
        PleaseEnterThePromotionalQuantity: 'Por favor, insira a quantidade promocional',
        PleaseEnterVirtualQuantity: 'Por favor, insira a quantidade virtual',
        NumberOfUserPurchases: 'Número de compras de usuários',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Por favor, insira o limite de compra para o mesmo usuário',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Por favor, insira o limite de compra para um único pedido',
        QuantityGreaterThan0: 'Quantidade maior que 0',
        GrossProfitMarginCalculationResults: 'Resultados de cálculo da margem de lucro bruto',
        FixedPriceGrossMargin: 'Margem bruta de preço fixo',
        SellingPriceGrossProfitMargin: 'Preço de venda de Lucro bruto Margem',
        PromotionalPriceGrossProfitMargin: 'Margem de lucro bruto de preço promocional',
        PromotionStartTime: 'Promoção Horário de início',
        PromotionEndTime: 'Promoção Termal Hora',
        PromotionType: 'Tipo de promoção',
        PleaseSetTheSKUToPromotionalProducts: 'Defina o SKU como $ {xxx} produtos promocionais',
        DistributionOffers: 'Ofertas de distribuição',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Depois de inserir a quantidade  {qualquer}, ele será calculado automaticamente com base na quantidade do produto.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: 'O número de compras de usuários deve ser maior que 0',
        PleaseSelectCountry: 'Selecione $ {escreva o nome} país',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Depois de entrar na quantidade promocional, será calculado automaticamente com base na quantidade de mercadorias.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: 'Cancele a associação em [Pool de produtos de desconto] primeiro',
        EditBottomLineInventory: 'Editar o inventário final',
        PleaseConfigureFixedPrice: 'Configure o preço fixo',
        BalancePayment: 'Saldo de Pagamentos',
        Deposit: 'Depósito',

        EndofValidity: 'Introduzir a data de expiração',
        Year: 'Ano',
        Month: 'Mês',
        Day: 'Dia',
        UnitOfValidity: 'Unidade de validade',
        AIGOCoinDetails: 'Detalhes da moeda AIGO',
        AIGOCoinsAreCloseToExpiring: 'As moedas AIGO estão quase a expirar(Dia)',
        CountryOfShipmentAddress: 'País do endereço de expedição',

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Tempo estimado de entrega',
        DataCorrespondingToTheSource: 'Dados correspondentes à fonte',
        AccountStatus: 'Status da Conta',
        OrderID: 'ID do pedido',
        RecipientName: 'Nome do Destinatário',
        RecipientLastName: 'Sobrenome do destinatário',
        MailsOfUser: 'E -mails de usuário',
        StateProvince: 'Estado/Província',
        CityTown: 'Cidade',
        County: 'Condado',
        ReceivingCompany: 'Empresa receptora',
        BatchInput: 'Entrada em lote',
        InputManually: 'Entrada manualmente',
        ProductCommentsInputTemplate: 'Comentários do produto Modelo de entrada',
        UserName: 'Nome de usuário',
        Comments: 'Comentários',
        StarRatingOfTheComment: 'Classificação de estrelas do comentário',
        CommentTime: 'Tempo de comentário',
        UploadAttachment: 'Upload de anexo',
        NumberOfAttachments: 'Número de anexos',
        FileName: 'Nome do arquivo',
        Appendix: 'Apêndice',
        BatchInputOfProductComments: 'Entrada em lote dos comentários do produto',
        PreSalePromotionID: 'ID de promoção de pré-venda',
        PromotionAbnormal: 'Promoção anormal',
        Updater: 'Atualizador',
        AddPreSalePromotion: 'Adicione a promoção de pré-venda',
        EditPreSalePromotion: 'Edite promoção de pré-venda',
        Barcode: 'Código de barras',
        PreSaleCountries: 'Países de pré-venda',
        PreSaleDeposit: 'Depósito de pré-venda',
        PleaseEnterTheDocumentCode: 'Por favor, insira o código do documento',
        BalancePaymentDeadline: 'Prazo para pagamento do saldo',
        FixedShippingFee: 'Taxa de envio fixa',
        PreSaleShippingFee: 'Taxa de envio de pré-venda',
        FreeShippingSubjectToConditions: 'Frete grátis sujeito a condições',
        OrderPrice: 'Preço do pedido',
        PleaseEnterPriceConditions: 'Por favor, insira condições de preço',
        TheEndTimeNeedsToBeLaterThanTheStartTime: 'O horário de término precisa ser posterior ao horário de início',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'Já existem promoções parcialmente sobrepostas neste país, modifique',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'A quantidade de pré-venda só pode ser um número inteiro positivo maior que 0',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: 'O tempo estimado de entrega precisa ser posterior ao horário de término do evento',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'O prazo de pagamento do saldo precisa ser mais cedo do que o tempo estimado de entrega',
        PromotionID: 'ID da promoção',
        DistributionPromotionID: 'ID da promoção da distribuição',
        CouponID: 'ID do cupom',
        PromotionManagementID: 'Id de gerenciamento de promoção',
        PortfolioManagementID: 'Id de gerenciamento de portfólio',
        ExportForFiltering: 'Exportação para filtragem',
        ListDisplay: 'Exibição da lista',
        InventoryItems: 'Itens de inventário',
        InStock: 'Em stock',
        OutOfStock: 'Fora de stock',
        FieldName: 'Nome do campo',
        PleaseSelectInventory: 'Por favor selecione estoque',
        PreSaleEvent: 'Evento de pré-venda',
        PerPage: 'Cada página mostra',

        // V1.3.3 end

        // V1.3.4 start

        PleaseProvideTheNameOfTheLandingPageActivity: 'Por favor, forneça o nome da atividade da página de destino.',
        PleaseIndicateTheNumberOfActivities: 'Por favor, indique o número de atividades.',
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: 'A quantidade de atividade deve ser maior do que a quantidade dada.',
        PleaseChooseATimeForTheEvent: 'Por favor, selecione um horário para o evento.',
        ActivityNameExceedsLength: 'O nome da atividade excede o comprimento máximo.',
        ThisItemIsRestrictedToAMaximumOf10PerOrder: 'Este item está limitado a 10 por pedido.',
        PleaseChooseTheGiveawayAgain: 'Por favor, selecione novamente o brinde.',
        AnalysisOfGiveawayActivities: 'Análise de atividades de brindes',
        StatisticsOfGiveawayActivities: 'Estatísticas de atividades de oferta',
        PleaseEnterTheNameOfTheEvent: 'Por favor, insira o nome do evento.',
        PleaseSelectTheActivityType: 'Por favor, escolha o tipo de atividade.',
        PleaseCompleteTheActivityRating: 'Por favor, preencha a avaliação da atividade.',
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Configure a campanha de pré-venda {Any} antes de configurar o país onde os itens de pré-venda serão colocados.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: 'O valor do pedido e o número de brindes não podem ser inferiores ao nível anterior.',
        SelectImage: 'Selecionar imagem',
        GiftInformation: 'Informações de presente',
        Gifts: 'Presentes',
        GiftsOfEndOfEvent: 'Presentes de final de evento',
        CheckOutTheGifts: 'Confira os presentes',
        GiveawayEvent: 'Evento de oferta',
        SelectAGift: 'Selecione um presente',
        YouHaveNotSelectedAGift: 'Não selecionou um presente',
        NoNeedForGifts: 'Não há necessidade de presentes',
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Alguns presentes estão fora de estoque, selete os presentes',
        ReplaceAGift: 'Substitua um presente',
        ThereAreCurrently3FreeGiftsAvailable: 'Atualmente, existem 3 presentes gratuitos disponíveis',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Atualmente, 3 presentes gratuitos estão disponíveis e mais 4 presentes estão disponíveis para comprar outros 99 €',
        GiftList: 'Lista de presentes',
        GiftID: 'ID do presente',
        CurrentInventory: 'Inventário atual',
        NotEnabled: 'Não habilitado',
        GoodsForFree: 'Bens gratuitamente',
        ConfirmToDeleteThisGiftInformation: 'Confirme para excluir essas informações de presente?',
        GiftsSortingManagement: 'Gestão de classificação de presentes',
        GiftsTopicManagement: 'Gestão de tópicos para presentes',
        GiveawayEventID: 'Id de evento de oferta',
        GiveawayEventName: 'Nome do evento de oferta',
        ProductCategory: 'Categoria de Produto',
        ActivityMode: 'Modo de atividade',
        TotalNumberOfActivities: 'Número total de atividades',
        GiftsGivenQuantity: 'Presentes que receberam quantidade',
        ThemeConfiguration: 'Configuração do tema',
        GiftSorting: 'Classificação de presentes',
        GlobalConfiguration: 'Configuração global',
        ActiveCountry: 'País ativo',
        PromotionalPpicture: 'Imagem promocional',
        RecommendedSizeX: 'Tamanho recomendado: {XXX*XXX}',
        FileSize1MB: 'Tamanho do arquivo: ≤1mb',
        ActivitySorting: 'Classificação de atividades',
        DragToSort: 'Arraste para classificar',
        ActivityCode: 'Código de atividade',
        GiftsForOrderAmount: 'Presentes para o valor do pedido',
        ActivityRating: 'Classificação de atividade',
        GiftsQuantity: 'Quantidade de presentes',
        EventGiftsSorting: 'Classificação de presentes de eventos',
        DefaultSelectionOfUsers: 'Seleção padrão de usuários',
        LandingPageDisplay: 'Exibição da página de destino',
        LandingPageActivityName: 'Nome da atividade da página de destino',
        ConfirmDeleteThisGiftEvent: 'Confirmar Excluir este evento de presente?',
        IsThereAGiftEvent: 'Existe um evento de presente?',
        OrderQuantity: 'Quantidade de pedido',
        NumberOfGiftsIssued: 'Número de presentes emitidos',
        CustomerPrice: 'Preço do cliente',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: 'A função de quantidade do evento virtual só pode ser definido se o tempo de atividade de pré-venda for pelo menos mais de 2 dias e o número de eventos for maior que 50',
        PreSaleSwitch: 'Interruptor de pré-venda',
        PreSaleActivity: 'Atividade de pré-venda',
        GiftManagement: "Gestão de brindes",

        // V1.3.4 end

        //V1.3.5 start
        OrderSource: "Ordem fonte",
        NumberOfNewUserRegistrations: "Número de novos registos de usuário",
        RegisteredUsersWhoVsitTheWebsite: "Usuários registados que visitam o site",
        SoldDistributionQuantity: "Quantidade de distribuição vendida",
        DistributionQuantityLock: "Bloqueio de quantidade de distribuição",
        InsufficientDstributionQuantityRemaining: "Quantidade insuficiente de distribuição restante",
        Previous: "Anterior",
        Next: "Próximo",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Gestão das receitas",
        RecipeDisplayImage: "Imagem de apresentação da receita",
        NewRecipeDisplayImage: "Editar/criar nova imagem de apresentação da receita",
        CoverPicture: "Imagem de capa",
        PicturesVideos: "Podem ser carregadas até 10 imagens + vídeos, cada imagem deve ter ≤5MB e o vídeo em formato.mp4 é suportado",
        AddPicturesVideos: "Adicionar imagens/vídeos",
        CommenOnPicturesVideos: "Comentar imagens/vídeos",
        FileSize: "Tamanho do ficheiro",
        BarcodeAssociated: "Código de barras associado",
        FirstUploadTime: "Hora da primeira transferência",
        EditTime: "Hora de edição",
        TagType: "Tipo de etiqueta",
        FileName1: "Nome do ficheiro",
        NameLengthISTooLong: "O comprimento do nome é demasiado longo",
        ProductDoesNoExist: "O produto não existe",
        PleaseUploadImages: "Por favor carregue imagens jpg/png/jpeg com menos de 5MB",
        PleaseUploadTheVideoMP4: "Carregue o vídeo em formato mp4",
        PictureOrVideo: "A imagem ou o vídeo não pode estar vazio",
        allowed: "Permitir tudo",
        cookie1: "Gestão de cookies",
        collectsordinances: "A Aigostar utiliza cookies para melhorar a experiência de navegação dos visitantes e para efeitos de marketing. Veja e gira as suas definições de cookies abaixo. Para saber mais sobre a forma como utilizamos os cookies, consulte a nossa Política de Privacidade.",
        RequiredCookies: "Cookies necessários",
        cookie2: "Estes cookies são necessários para funcionalidades essenciais, como iniciar sessão, utilizar o carrinho de compras e efetuar pagamentos. Os cookies necessários não podem ser desactivados. Mas não se preocupe, estes cookies não guardam nenhuma das suas informações.",
        AnalyticsCookies: "Cookies de marketing e de análise",
        cookie3: "Estes cookies recolhem informações, como o número de visitas ao sítio Web ou as páginas mais populares, para nos ajudar a melhorar a experiência do cliente. Pode optar por desativar estes cookies, mas nesse caso não poderemos recolher as informações de que necessitamos para melhorar a sua experiência no nosso sítio Web.",
        StoragePreferences: "Guardar preferências",
        BuyItNow: "Comprar agora",
        PictureVideoSize: "O tamanho ou formato da imagem ou do vídeo não está correto",
        RecipeConfiguration: "Configuração da receita",

        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Confirmar a sincronização dos subcódigos de barras?",
        SynchronizationEbpEanSUB: "Devido à necessidade de interface com o EBP, a sincronização em lote deverá ser concluída em 3 minutos. Após a confirmação, a sincronização prosseguirá de forma assíncrona, e os subcódigos de barras serão exibidos imediatamente após o recebimento. Por favor, seja paciente.\n",
        Keywords: "Palavras-chave",
        SystemAutoUpdate: "Atualização automática do sistema",
        IPAddress: "Endereço IP",
        SearchKeywords: "Pesquisar palavras-chave",
        LoginEmail: "E-mail de login",
        SearchTime: "Tempo de pesquisa",
        NumbeOfTimes: "Número de vezes",
        MyBrowsing: "Minha navegação",
        LoggingOuOfYouAccount: "Fazer logout da sua conta será considerado como sua renúncia voluntária e você não poderá mais continuar usando esta conta.",
        RequestLogout: "Solicitar logout",
        ProductsYet: "Você ainda não navegou por nenhum produto",
        clearall: "Limpar histórico",
        CancellTip1: "As listas de carrinho de compras, os registros de compra de pedidos e os registros de favoritos serão permanentemente excluídos e não poderão ser recuperados.",
        CancellTip2: "Todas as informações da conta, registros de serviço, dados de consumo, etc., na plataforma serão permanentemente excluídos e não poderão ser recuperados.",
        CancellTip3: "Você não poderá mais fazer login ou usar esta conta AigoSmart.",
        CancellTip4: "As informações pessoais e mensagens históricas (incluindo avatar, apelido, minha moeda Aiguo, notificações da plataforma, meus comentários, etc.) associadas à sua conta AigoSmart não serão recuperáveis.",
        CancellTip5: "Você deve gerenciar corretamente os fundos da conta (pontos, níveis de associação, qualificações, pedidos, cupons, adereços e outros serviços valiosos) antes de fazer logout, pois o cancelamento da conta será considerado como uma renúncia voluntária e não poderá ser revertido. Você entende e concorda que a AigoSmart não pode ajudá-lo a restaurar os serviços acima mencionados.",
        SynchronizeSubBarcodes: "Sincronizar subcódigos de barras",
        PleaseEnterKeywords: "Por favor, insira palavras-chave",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Estatísticas por hora",
        NumbeOfUsers: "Número de usuários que fizeram pedidos",
        NumberOfCanceled: "Número de pedidos cancelados",
        RefundAmount: "Valor reembolsado",
        UsersWhoHavePaid: "Usuários que pagaram (pedidos que podem ser enviados, enviados, concluídos)",
        TotalNumber: "Número total de pedidos cancelados pela plataforma",
        AmountOfCanceled: "Valor dos pedidos cancelados que foram pagos mas não enviados, valor dos pedidos devolvidos que foram pagos (em trânsito, entregues)",
        RedemptionCode: "ID/Nome do cupom/Código de resgate",
        DeleteAll: "Excluir",
        AccountIsUnderReview: "A conta atual está em período de revisão para desativação. Continuar a fazer login cancelará o processo de desativação. Deseja continuar a usá-la?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "O tempo não pode estar vazio",
        ProductImporTemplate: "Modelo de importação de produtos",
        NormalOrdersExceed: "Pedidos normais excedem:",
        OrderSautomaticallyClosed: "Pedidos não pagos serão automaticamente fechados",
        OrdeIsShipped: "Após o envio do pedido:",
        AutomaticallyComplete: "Concluir automaticamente o pedido",
        Minutes: "Minutos",
        Days: "Dias",
        OrderSettings: "Configurações de pedidos",
        ClearCache: "Limpar cache",
        PleaseEnterNavigationContent: "Por favor, insira o conteúdo da navegação",
        WebsiteNavigationConfiguration: "Configuração de navegação do site",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Tempo de atraso do pop-up",
        SubscriptionPopup: "Pop-up de assinatura",
        MeansNoPopupDelay: "Por favor, insira o tempo de atraso da janela pop-up",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "Área UGC",

        //V1.5.0 end

        //V1.6.0 start
        uploaFilesInJpg: "Apenas carregar ficheiros em formato jpg/png com menos de 2M",
        SynchronizeEbpPrice: "Sincronizar o preço do EBP",
        ConfirmToSynchronizeEbp: "Confirmar a sincronização do preço EBP",
        AsynchronousEbp: "Após a confirmação, a sincronização é assíncrona. O preço será apresentado imediatamente após a sua obtenção. Por favor, aguarde pacientemente.",
        CopywritingDisplay: "Apresentação do copywriting",
        HomepageCopywriting: "Redação da página inicial",
        EditHomepageCopywriting: "Editar o copywriting da página inicial",
        SuperimposedEvents: "Se se trata de eventos sobrepostos?",
        GiftsEvents: "(Os presentes para este evento não podem ser recolhidos juntamente com outros eventos)",
        SmallGiftEvent: "Evento de pequenas prendas",
        SpecialProductEvent: "Evento de produto especial",
        SpecialGiftEvent: "A oferta gratuita do evento de oferta pequena e do evento especial não pode ser coleccionada em conjunto",
        ProductsWithSKU: "Selecionar produtos com um SKU",
        LimitWereSelected: "O limite atual é de {limitNum} arquivos. Desta vez, foram selecionados {selectNum} arquivos.",
        FileUploadFailed: "Falha no upload do ficheiro!",
        CompleteMultiLanguage: "Conclua a configuração multilingue",
        FirstSynchronizeSKU: "Por favor, sincronize primeiro o SKU deste produto.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Se o cupão deve ser tornado público",
        RedemptionCode1: "Código de resgate",
        NowOnlyNeed: "Agora só precisa de 10 euros",
        WebLogReporting: "Relatório do registo Web",
        PageAddress: "Endereço da página",
        ErrorLogs: "Registos de erros",
        CustomParameter: "Parâmetro Personalizado",
        ParameterID: "ID do Parâmetro",
        // ParameterName: "Nome do Parâmetro",
        SearchParameter: "Parâmetro de Pesquisa",
        EnterParameterName: "Insira o nome do parâmetro",
        ParameterType: "Tipo de Parâmetro",
        AssociatedProductCount: "Contagem de Produtos Associados",
        MandatoryPortugueseContent: "Conteúdo obrigatório em português",
        MandatoryEnglishContent: "Conteúdo obrigatório em inglês",
        MandatoryDutchContent: "Conteúdo obrigatório em holandês",
        MandatoryPolishContent: "Conteúdo obrigatório em polonês",
        MandatoryFrenchContent: "Conteúdo obrigatório em francês",
        MandatoryGermanContent: "Conteúdo obrigatório em alemão",
        MandatoryItalianContent: "Conteúdo obrigatório em italiano",
        MandatorySpanishContent: "Conteúdo obrigatório em espanhol",
        MandatoryChineseContent: "Conteúdo obrigatório em chinês",
        ImportTemplate: "Modelo de importação de produtos com parâmetros personalizados",
        ConfirmImportFile: "Confirmar arquivo de importação, continuar?",
        SelectDataRemove: "Selecionar dados para remover",
        ImportTemplateDownload: "Baixar modelo de importação",
        AssociatedProducts: "Produtos associados",

        //V1.7.0 end

        //V1.8.0 start
        Published: "Publicado",
        CanBeIgnored: "Pode ser ignorado",
        Resolved: "Resolvido",
        ActivityType: "Tipo de atividade",
        PromotionActivity: "Atividade promocional",
        DistributionActivity: "Atividade de distribuição",
        InputLink: "Inserir link",
        EnterAddress: "Por favor, insira o endereço do link",
        DetailPageAd: "Espaço publicitário na página de detalhes",

        //V1.8.0 end

        //V1.9.0 start
        ProductPromotionID: "ID de promoção do produto.",
        PromotionalActivityCollection: "Coleção de atividades promocionais.",
        PromotionalActivityName: "Nome da atividade promocional.",
        ProductPromotionBarcode: "ID de promoção do produto/Código de barras.",
        ProductPromotionIDCopy: "ID de promoção do produto (clique duas vezes para copiar todos os IDs).",
        ProductBarcode: "Código de barras do produto (clique duas vezes para copiar todos os códigos de barras).",
        SelectPromotionalActivity: "Selecionar atividade promocional.",
        SelectPromotionalList: "Selecionar lista promocional.",
        TypesPerOrder: "Limite o número de tipos de itens por pedido.",
        PromotionalActivity: "Por favor, selecione uma atividade promocional.",
        ProductAlready: "Este produto já tem uma atividade promocional. Por favor, prossiga para editar.",
        PromotionalActivityNameID: "Nome/ID da Promoção",
        PromotionalCollectionName: "Nome da coleção promocional.",
        SelectedPromotionalActivity: "Atividade promocional selecionada.",
        PromotionalCollectionActivit: "Já existe uma atividade de coleção promocional. Por favor, prossiga para editar.",

        EnterHholidayTheme: "Por favor, insira um tema festivo.",
        HolidayTheme: "Tema festivo.",
        HolidayImage: "Imagem festiva.",
        AddLogoImage: "Adicionar imagem do logo.",
        PleaseProceedEdit: "Por favor, prossiga para editar.",
        SKUBIDActivities: "SKU: {sku}, já existem {country} atividades sob {activity}.",
        LogImageManagement: "Gerenciamento de imagens do logo",
        LogoManagement: "Gerenciamento do logo",

    //V1.9.0 end

    }
};

export default pt;