import nlLocal from 'element-ui/lib/locale/lang/nl';
const nl = {
    ...nlLocal,
    common: {
        datastatistics: 'Statistieken',
        Transactionstatistics: 'Transactiestatistieken',
        LabelOptions: 'Labelopties',
        Orderdatastatistics: 'Bestelstatistieken',
        Hourlystatistics: 'per uur',
        Countbyday: 'per dag',
        Spain: 'Spanje',
        Italy: 'Italië',
        Germany: 'Duitsland',
        France: 'Frankrijk',
        poland: 'Polen',
        Netherlands: 'Nederland',
        Britain: 'VK',
        UnitedStates: 'VS',
        Portugal: 'Portugal',
        SalesCountry: 'Land van verkoop',
        query: 'Navraag',
        Placinganorder: 'Aantal bestellingen',
        Orderamount: 'Bestelbedrag',
        Numberofusersplacingorders: 'Aantal gebruikers dat een bestelling heeft geplaatst',
        Ordertime: 'Tijd van bestelling',
        time: 'Tijd',
        usermanagement: 'Gebruikersbeheer',
        UserID_loginemail_registrationinviterID_phonenumber: 'Gebruikers-ID / inlog-e-mailadres /registratie-ID van de uitnodiging / mobiel telefoonnummer',
        country: 'Land',
        Whethertosubscribe: 'Wilt u zich abonneren?',
        Loginstatus: 'Inlogstatus',
        normal: 'Normaal',
        Disabled: 'Uitzetten',
        Createdon: 'Aanmaakdatum',
        Lastlogintime: 'Laatste inlogtijd',
        Reset: 'Resetten',
        export: 'Exporteren',
        UserID: 'Gebruikersnaam',
        Registeredcountry: 'Land van registratie',
        mailbox: 'E-mail',
        Mobilephonenumber: 'Mobiele telefoonnummer',
        Patrioticcoin: 'Aigo-munten',
        RegistrationInviterID: 'Registratie-ID van de uitnodiging',
        brand: 'Merk',
        Creationtime: 'Creatie tijd',
        operation: 'Verrichting',
        check: 'Weergave',
        orderform: 'Bestellingen',
        consultingservice: 'Navraag',
        address: 'Adres',
        LoginLog: 'login logboek',
        Rechargepatrioticcoins: 'Aigo-munten opladen',
        Returnsandexchanges: 'Retourneren en ruilen',
        evaluate: 'Evalueren',
        Shippingaddress: 'Afleveradres',
        shoppingcart: 'Winkelwagen',
        collection: 'Collectie',
        invoice: 'Factuur',
        nickname: 'Bijnaam',
        nothing: 'Geen',
        close: 'Sluiten',
        source: 'Bron',
        Subscriptiontime: 'Abonnementsperiode',
        Thirdpartylogin: 'Inloggen door derden',
        Bound: 'Gebonden',
        Unbound: 'Ongebonden',
        Invitationcode: 'Uitnodigingscode',
        Inviteduser: 'Uitgenodigde gebruikers',
        Invitetoreceivepatrioticcoins: 'Nodig uit om Aigo-munten te krijgen',
        Isthefirstorderfreeofshipping: 'Gratis verzending of niet bij uw eerste bestelling',
        shopping: 'Winkelen',
        Collectproducts: 'Favoriete artikelen',
        ShoppingCartItems: 'Artikelen in winkelwagen',
        PaymentOrder: 'Betaalde bestellingen',
        Ordercumulativeamount: 'Totaal bestelbedrag',
        Lastordertime: 'Laatste besteltijd',
        Returnandexchangeorders: 'Bestellingen retourneren en ruilen',
        Consultationfrequency: 'Aantal navragen',
        Numberofevaluations: 'Aantal recensies',
        Numberofapprovedevaluations: 'Aantal goedgekeurde beoordelingen',
        ShowCountry: 'Land tonen',
        name1: 'Naam',
        TaxID: 'btw-code',
        province: 'Provincie',
        city: 'Stad',
        Detailedaddress: 'Adres',
        Housenumber: 'Huisnummer',
        AddressAdditionalContent: 'Aanvullende adresgegevens',
        RecipientCompany: 'Ontvangend bedrijf',
        Defaultshippingaddress: 'Standaard verzendadres',
        Defaultinvoiceaddress: 'Standaard factuuradres',
        ProductID1: 'Product-ID',
        Productname1: 'Productnaam',
        CollectionPrice: 'Ophaalprijs',
        Currentprice: 'Huidige prijs',
        Collectiontime1: 'Ophaaltijd',
        Additionalpurchaseprice: 'Extra aankoopprijs',
        Additionalpurchasetime: 'Extra aankooptijd',
        Listingstatus: 'Voorraadstatus',
        Listed: 'Op voorraad',
        Notlisted: 'Niet op voorraad',
        Numberofpatrioticcoins: 'Aantal Aigo-munten',
        Enteraquantitybetween0and1000: 'Voer een aantal in tussen 0 en 1000',
        Remarks: 'Opmerkingen',
        cancel: 'Annuleren',
        UserDetails: 'Gebruikersdetails',
        Areyousuretoexportthefile: 'Weet u zeker dat u het bestand wilt exporteren? Doorgaan?',
        prompt: 'Snel',
        Exportsuccessful: 'Exporteren is gelukt',
        today: 'Vandaag',
        Lastweek: 'Vorige week',
        Thismonth: 'Deze maand',
        Lastmonth1: 'Vorige maand',
        thisyear: 'Dit jaar',
        Inputcannotbeempty: 'Invoer mag niet leeg zijn',
        Pleaseenterapositiveinteger: 'Voer een positief geheel getal in',
        Pleaseenterthequantityofpatrioticcoins: 'Voer het aantal Aigo-munten in',
        Pleaseenteracomment: 'Voer een opmerking in',
        Pleaseselectacountry: 'Selecteer een land',
        Successfullysubmitted: 'Succesvol ingediend',
        Nopatrioticcoinrulesconfigured: 'Er zijn geen Aigo-muntregels geconfigureerd',
        Rechargefailed: 'Kan niet opladen',
        Savefailed: 'Kan niet opslaan',
        ordermanagement: 'Orderbeheer',
        OrderList: 'Bestellijst',
        Ordernumber: 'Bestellingsnummer',
        Paymentcode: 'Betalingscode',
        barcode: 'Streepjescode',
        Pleaseselectasource: 'Selecteer de bron',
        OrderStatus: 'Bestelstatus',
        Unpaid: 'onbetaald',
        Paid: 'betaald',
        Shippable: 'Beschikbaar voor levering',
        Shipped: 'verzonden',
        complete: 'Compleet',
        Pleaseselectpaymentmethod: 'Selecteer de betaalmethode',
        Shipmentstatus: 'Verzendstatus',
        Notshipped: 'Niet verzonden',
        Partialshipment: 'Gedeeltelijk Verzonden',
        Returned: 'Geretourneerd',
        Remittanceconfirmation: 'Overmaking bevestigd',
        Notremitted: 'Niet overgemaakt',
        Unconfirmed: 'niet bevestigd',
        adopt: 'Geslaagd',
        Notpassed: 'Mislukt',
        Whethertomanuallyship: 'Wilt u handmatige verzending?',
        yes: 'Ja',
        no: 'Nee',
        Originalordertype: 'Origineel besteltype',
        Presaleorder: 'Vooruitbestelling',
        Officialorder: 'Officiële bestelling',
        Preorderstatus: 'Status van de vooruitbestelling',
        Deposittobepaid: 'Te betalen voorschot',
        Depositpaid: 'Voorschot is betaald',
        Finalpaymenttobemade: 'Te betalen saldo',
        Finalpaymentpaid: 'Saldo is betaald',
        Presalepaymentmethod: 'Betalingswijze voorverkoop',
        Finaldeposit: 'Laatste betaling van de aanbetaling',
        Fullpayment: 'Volledige betaling',
        Whetherthepresaleorderisconvertedtoformal: 'Wilt u dat de vooruitbestelling officieel wordt?',
        Combinedorders: 'Gecombineerde bestelling',
        to: 'Naar',
        Startdate: 'Begindatum',
        Enddate: 'Einddatum',
        Paymenttime: 'Betalingstermijn',
        Pleaseenterthesource: 'Voer de bron in',
        platform: 'Platform',
        Positiveconversionsingle: 'Overdracht naar officiële bestelling',
        Numberofproductmodels: 'Aantal items',
        activity: 'Activiteit',
        money: 'Aantal',
        Paymentmethod: 'Betalingsmethode',
        Deliverytime: 'Verzendtijd',
        Whethertotransfertoformal: 'Wilt u dat het officieel wordt?',
        Tobereplied_consulted: 'Moet worden beantwoord/geraadpleegd',
        detailed: 'Details',
        Manualshipping: 'Handmatige levering',
        Manuallogisticsshipment: 'Handmatige logistieke levering',
        logisticscompany: 'Logistiek bedrijf',
        Pleaseselectalogisticscompany: 'Selecteer een logistiek bedrijf',
        Logisticstrackingnumber: 'Logistiek trackingnummer',
        SelectDateTime: 'Selecteer datum en tijd',
        Shippingremarks: 'Verzending opmerkingen',
        Pleasenote: 'Houd er rekening mee dat',
        confirm1: 'Bevestigen',
        state: 'Status',
        Pleaseselect: 'Selecteer',
        Pleaseselectastatus: 'Selecteer status',
        Homepagerecommendation: 'Aanbeveling startpagina',
        Pleaseenterthelogisticstrackingnumber: 'Voer het trackingnummer in',
        Pleaseselecttheshippingtime: 'Selecteer een verzendtijd',
        edit: 'Bewerken',
        Successfullyshippedmanually: 'Handmatige levering is gelukt',
        Remittanceconfirmationsuccessful: 'Overmaking succesvol bevestigd',
        confirmdeletion: 'Bevestig om te verwijderen',
        Successfullydeleted: 'Succesvol verwijderd',
        Pleaseselectapresaleorder: 'Selecteer voorverkoop bestelling',
        Confirmtoconverttheorderintoaformalorder: 'Bevestig om de bestelling om te zetten in een officiële bestelling',
        success: 'Succes',
        OrderData: 'Bestelgegevens',
        Areyousuretoexportthefileandcontinue: 'Wilt u het bestand bevestigen om te exporteren of niet? Wilt u doorgaan of niet?',
        Basicinformationoftheorder: 'Basis bestelinformatie',
        Useremail: 'E-mailadres gebruiker',
        Documentremarks: 'Opmerkingen over de bestelling',
        combination: 'Combinatie',
        PostalCode: 'Postcode',
        RecipientCompanyAddressAdditionalContent: 'Ontvangend bedrijf, aanvullende adresgegevens',
        Invoiceaddress: 'Factuuradres',
        InvoiceStatus: 'Factuurstatus',
        Paymentinformation: 'Betalingsinformatie',
        Paymentstatus: 'Betalingsstatus',
        coupon: 'Coupon',
        freight: 'Vracht',
        CouponName: 'Naam coupon',
        Couponcode: 'Code coupon',
        Paymentamount: 'Te betalen bedrag',
        Trackingnumber: 'Bestelnummer volgen',
        Manualshippingoperator: 'Handmatige Expediteur',
        Manualshippingoperationtime: 'Handmatige bezorgtijd',
        Bankremittance: 'Banktransfer',
        Remittancestatus: 'Overmakingsstatus',
        Remittanceconfirmationoperator: 'Overmakingsbevestiging Operator',
        Remittanceconfirmationoperationtime: 'Bevestigingstijd van de overmaking',
        Preorderinformation: 'Voorverkoop bestelinformatie',
        Converttoformaldocument: 'Wilt u omzetten naar officiële bestelling?',
        Positiveconversionsinglemode: 'Manier van omzetten naar de officiële bestelling',
        automatic: 'automatisch',
        Manual: 'handmatig',
        Positiveconversionsingletime: 'Tijdstip van omzetten naar de officiële bestelling',
        category1: 'categorie',
        Subordernumber: 'sub-ordernummer',
        Paymentdeadline: 'Betalingsdeadline',
        Addremarks: 'Opmerkingen toevoegen',
        ProductInformation: 'Productinformatie',
        Serialnumber: 'Serienummer',
        name: 'Naam',
        VariantInformation: 'Variant informatie',
        Pricing_unitprice: 'Prijs (eenheidsprijs)',
        Sellingprice_unitprice: 'Verkoopprijs (eenheidsprijs)',
        quantity: 'Aantal',
        Totalprice: 'Totale prijs',
        MerchandiseSubtotal: 'Subtotaal goederen',
        total: 'Totaal',
        CancelOrder: 'De bestelling annuleren',
        Areyousuretocancelthisorder: 'Weet u zeker dat u de bestelling wilt annuleren?',
        reason: 'Reden',
        Pleaseselectabrand: 'Selecteer een merk',
        Pleaseselectacategory: 'Selecteer een categorie',
        Successfullyadded1: 'Succesvol toegevoegd',
        Addfailed: 'Kan niet toevoegen',
        Whethertosubmitdata: 'Wilt u dat de gegevens worden ingediend?',
        Canceledsuccessfully: 'Succesvol geannuleerd',
        Onlypendingorderscanbeshipped: 'Alleen lopende bestellingen kunnen worden verzonden',
        Existinglogisticstrackingnumber: 'De bestelling heeft een logistiek trackingnummer en kan niet worden ingediend',
        Thereareonlytworemittancestatuses: 'De status van de overmaking kan alleen worden goedgekeurd of niet goedgekeurd',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Alleen overgemaakte bestellingen kunnen worden bevestigd',
        Orderdoesnotexist: 'De bestelling bestaat niet',
        Thisordercannotbecancelled: 'De bestelling kan niet worden geannuleerd',
        Norulesconfigured: 'De huidige bezitter heeft geen regels voor Aigo-munten geconfigureerd',
        Parametererror: 'Parameterfout',
        Countrycodecannotbeempty1: 'Landcode mag niet leeg zijn',
        Thecurrentcountrypricingisnotconfigured: 'De huidige landprijzen zijn niet geconfigureerd',
        Thepriceconfigurationdoesnotexist: 'De prijsconfiguratie bestaat niet, configureer de prijs',
        Reasonforcancellationoforderisrequired: 'Reden voor annulering van bestelling is vereist',
        Exportorderinformation: 'Bestelinformatie exporteren',
        OrderIDmustbepassed: 'Bestellings-ID moet worden doorgegeven',
        Youcanonlybatchprocessupto100itemsatatime: 'Er kunnen maximaal 100 batches tegelijk worden verwerkt',
        Notapresaleorder: 'Bestelnummer 101 is geen vooruitbestelling, annuleer eerst de selectie',
        Transferredtoofficial: 'Bestelnummer 101 is overgezet naar een officiële bestelling, annuleer eerst de selectie',
        Ordernotpaid: 'Bestelnummer 101 is geen betaalde bestelling, gelieve eerst te annuleren',
        Nonpresaleorderscannotbeoperated: 'Niet-pre-sale bestellingen zijn niet beschikbaar',
        Transferredtoformalformandcannotbeoperated: 'De omgezette bestellingen zijn niet beschikbaar',
        Nonpaidorderscannotbeoperated: 'Niet beschikbaar voor niet-betaalde bestellingen',
        Orderremarksarerequired: 'Opmerkingen over de bestelling zijn vereist',
        Upto500wordscanbeentered: 'Maximaal 500 tekens',
        common: 'in totaal',
        strip: 'records',
        goto: 'Ga naar',
        page: 'Bladzijde',
        Returnandexchangeapplication: 'Retourverzoek',
        type: 'Type',
        Return: 'Retourneren',
        Exchange: 'Vervangen',
        Auditstatus: 'Beoordelingsstatus',
        Approved: 'Goedgekeurd',
        Auditfailed: 'Niet goedgekeurd',
        Inprogress: 'in uitvoering',
        Auditended: 'Einde beoordeling',
        Processingstatus: 'Verwerkingsstatus',
        Completed: 'Voltooid',
        Applicationtime: 'Aanvraagtijd',
        Aftersalestype: 'Type naverkoop',
        ApplicationDescription: 'Beschrijving van de aanvraag',
        Numberofexplanatoryimages: "Beschrijving van het aantal foto's",
        Illustrativeimage: "Beschrijving van de foto's",
        Returnlogisticstrackingcode: 'Logistieke trackingcode terugsturen',
        CanIcontinuetoapply: 'Wilt u verder aanvragen?',
        Auditing: 'Beoordelen',
        details: 'Details',
        handle: 'Verwerken',
        Add1: 'Toevoegen',
        ApplicationDetails: 'Vraag details aan',
        Exchangeitem: 'Goederen ruilen',
        Returnedgoods: 'Goederen retourneren',
        Audittime: 'Beoordelingstijd',
        Reviewedby: 'Beoordeeld door',
        result: 'Resultaat',
        Failed: 'Niet goedgekeurd',
        Thereplycontentwillbedisplayedtotheuser: 'Het antwoord wordt weergegeven aan de gebruiker',
        Replycontent: 'de inhoud van het antwoord',
        Operator: 'Exploitanten',
        Pleaseenterthereason: 'Voer een reden in',
        Pleasechoosewhethertocontinueapplying: 'Selecteer of u verder wilt aanvragen',
        Pleaseselectaresult: 'Selecteer het resultaat',
        Successfullymodified: 'Wijziging gelukt',
        Verificationfailed: 'Verificatie mislukt',
        Datadoesnotexist: 'De gegevens bestaan niet',
        OrderNumber_UserID: 'Bestelnummer/gebruikers-ID',
        ReplyStatus: 'Antwoordstatus',
        Replied: 'Beantwoord',
        Noresponse: 'Geen antwoord',
        MyOrder: 'Mijn bestelling',
        refund: 'Terugbetaling',
        Canwedeliverontheagreeddate: 'Kunt u op de afgesproken datum leveren?',
        Modifyshippingaddress: 'Verzendadres wijzigen',
        Otherissuesrelatedtotransportation: 'Andere vragen over verzending',
        Receivedincorrectproduct: 'Verkeerd product ontvangen',
        Receiveddamagedproductsandproductswithqualityissues: 'Beschadigde producten en producten met kwaliteitsproblemen ontvangen',
        RegulationsonReturnsandRefunds: 'Beleid inzake retourzendingen en terugbetalingen',
        Applyforinvoice: 'Vraag een factuur aan',
        productdetails1: 'Productdetails',
        Thankyouletter: 'Bedankbrief',
        Otherissues: 'Overige vragen',
        Respondent: 'Reageer via',
        Consultationdate: 'Datum van navraag',
        content: 'Inhoud',
        explain: 'Beschrijving',
        Consultationtime: 'Tijd van raadpleging',
        Replytime: 'Tijd van beantwoording',
        reply: 'Antwoord',
        OrderInformation: 'Bestelgegevens',
        Consultationcontent: 'Inhoud navraag',
        Consultationinstructions: 'Instructies navraag',
        EnterReply: 'Voer een antwoord in',
        Consultationrecords: 'Geschiedenis navraag',
        Replycontentisrequired: 'Antwoord is vereist',
        Replysuccessful: 'Succesvol geantwoord',
        Successfullyedited: 'Succesvol bewerkt',
        Ordernumber_UserID_Taxnumber: 'Bestelnummer/gebruikers-ID/btw-code',
        Unaudited: 'Niet gecontroleerd',
        Passed: 'Goedgekeurd',
        Notuploaded: 'Niet geüpload',
        Uploaded: 'Geüpload',
        AuditDescription: 'Bekijk de instructies',
        download: 'Downloaden',
        Applying: 'Aanvraag wordt uitgevoerd',
        Enterthereason: 'Vul de reden in, de reden moet u invullen als de aanvraag niet wordt goedgekeurd',
        Thereasonfornotpassingwillbedisplayedtotheuser: 'Redenen voor het niet goedkeuren worden aan de gebruiker getoond',
        Pleaseenterareply: 'Voer een antwoord in',
        Auditsuccessful: 'Succesvolle beoordeling',
        ProductManagement: 'Productmanagement',
        Categorymanagement: 'Categoriemanagement',
        Categoryname1: 'categorienaam',
        Isthehomepagedisplayed: 'Wilt u de startpagina weergeven of niet',
        Enableornot: 'Wilt u inschakelen of niet',
        search: 'Zoekopdracht',
        Directoryhierarchy: 'Catalogusniveau',
        Category: 'Categorie',
        sort: 'Sorteren op',
        picture: 'Afbeelding',
        Updatetime: 'Update tijd',
        Multilingualconfiguration: 'Meertalige configuratie',
        Relatedimportantparameters: 'Verwante belangrijke parameters',
        Associatesearchparameters: 'Bijbehorende zoekparameters',
        delete: 'Verwijderen',
        SelectAll: 'Selecteer alles',
        Imagesize: 'Afbeeldingsgrootte',
        icon: 'Pictogrammen',
        intotal25: '25 artikelen in totaal',
        Gotopage1: 'Ga naar pagina 1',
        Chinese: 'Chinees',
        English: 'Engels',
        German: 'Duits',
        Polishlanguage: 'Pools',
        Italian: 'Italiaans',
        French: 'Frans',
        Dutchlanguage: 'Nederlands',
        Spanish: 'Spaans',
        Pleaseenteraname: 'Voer een naam in',
        Areyousureyouwanttodeleteit: 'Weet u zeker dat u wilt verwijderen?',
        Categoryassociation: 'Categorie vereniging',
        PrimaryKeyID: 'Primaire sleutel-ID',
        CatalogID: 'Catalogus-ID',
        Addtime: 'Voeg tijd toe',
        Relatedcategories: 'Bijbehorende categorie',
        Selectmaincategory: 'Selecteer de hoofdcategorie',
        addassociations: 'Vereniging toevoegen',
        Maincategory: 'Hoofdcategorie',
        ProductCatalogID: 'Productcatalogus-ID',
        RelatedCategoryName: 'Bijbehorende categorienaam',
        AddAssociatedCategory: 'Voeg een bijbehorende categorie toe',
        Pleaseselectacategoryfirst1: 'Selecteer eerst een categorie',
        Productvariants: 'Productvariant',
        ID: 'ID',
        Add: 'Toevoegen',
        Productquantity: 'Aantal producten',
        Selectvariant: 'Selecteer een variant',
        Selectparameters: 'Selecteer parameters',
        commodity: 'Product',
        Selectproduct: 'Selecteer product',
        Colortemperature: 'Kleurtemperatuur',
        frequency: 'Frequentie',
        power: 'Stroom',
        style: 'Stijl',
        MoveDown: 'Naar beneden verplaatsen',
        MoveUp: 'Naar omhoog verplaatsen',
        Selectvariantparameters: 'Selecteer variantparameters',
        SKU_EAN_Encoding: 'SKU/EAN/code',
        Pleaseselectparameters: 'Selecteer parameters',
        Countrieslisted: 'landen waar producten in het schap liggen',
        ProductID: 'Product-ID',
        Productimage1: 'Productafbeelding',
        ProductName: 'Productnaam',
        VariantManagement: 'Variant Beheer',
        Class: 'Categorie',
        Selected: 'Geselecteerd',
        Thereiscurrentlynodataavailable: 'Geen gegevens beschikbaar',
        Variantparameters: 'Variante parameters',
        VariantName: 'Variante naam',
        CustomName: 'Aangepaste naam',
        Batchlisting: 'Batch op het schap zetten',
        Pleaseselectaproduct: 'Selecteer producten',
        Batchdelisting: 'Bulkverwijdering',
        Batchsearch: 'Batch zoeken',
        Enterproductbarcodeandsubmitforaddition: 'Voer de streepjescode van het product in om toevoegingen in te dienen, meerdere streepjescodes moeten op een nieuwe regel worden ingevoerd',
        Enterproductbarcode: 'Voer de streepjescode van het product in, meerdere streepjescodes moeten worden ingevoerd in een nieuwe regel tot 500',
        Pleaseenterbarcode: 'Voer de streepjescode in',
        Editlistingcountry: 'Bewerk de lijst met landen waar producten in het schap liggen',
        Pleasecompletetheproductinformationbeforelistingit: 'Vul de productinformatie in voordat u het product in het schap legt',
        ProductTitle: 'Producttitel',
        ProductLargePicture: 'Productafbeelding',
        commodityprice: 'Productprijs',
        Confirmtheexecutionofbatchdelisting: 'Bevestig om bulkverwijdering uit te voeren',
        EditCategory: 'Categorie bewerken',
        SynchronizeSKU: "SKU's synchroniseren",
        ConfirmsynchronizationofSKU: 'Bevestig SKU-synchronisatie',
        SynchronizationrequiresdockingwithEBP: 'Aangezien de synchronisatie met EBP moet worden verbonden, kan om fouten te voorkomen elke 30 minuten een batchsynchronisatie worden uitgevoerd. Na bevestiging is de synchronisatie asynchroon en wordt deze weergegeven onmiddellijk nadat de SKU is verkregen. Even geduld hiervoor.',
        Synccompleted: 'Synchronisatie voltooid',
        Setlabel: 'Labels instellen',
        label: 'Labels',
        SynchronizeEBPproductinformation: 'Synchroniseren van EBP-productinformatie',
        Afterturningonsynchronization: 'Wanneer synchronisatie is ingeschakeld, wordt de volgende informatie voor EBP-producten gesynchroniseerd per product-SKU',
        Synchronizeproducttitlesbylanguage: 'Producttitel: synchroniseer de titel van de overeenkomstige taal per taal',
        Synchronizeproductimagesbylanguage: 'Productafbeelding: synchroniseer de afbeelding in de overeenkomstige taal per taal, lees de EBP-afbeeldingssortering, de eerste EBP-afbeelding wordt automatisch ingesteld als de hoofdafbeelding',
        Synchronizeproductimagesandtextbylanguage: 'Afbeeldingsdetails: synchroniseer per taal voor de overeenkomstige taal',
        Productkeywords: 'Product trefwoorden',
        Automaticallysynchronizeornot: 'Of er automatisch moet worden gesynchroniseerd',
        Doyouwanttoforcesynchronization: 'Geforceerde synchronisatie of niet',
        tenant: 'Houders',
        Noinventorydisplay: 'Geen inventarisweergave',
        Synchronizetoothertenants: 'Synchroniseren met andere houders',
        UpdateRecords: 'Records bijwerken',
        Importtime: 'Tijd importeren',
        number: 'Nummer',
        file: 'Bestand',
        UpdateResults: 'Resultaat bijwerken',
        Downloadfiles: 'Download bestand',
        Isthereafixedprice: 'Is er een vaste prijs',
        Whethertopromoteornot: 'Staan het in aanbieding',
        Inpromotion: 'In aanbieding',
        whole: 'Alle',
        Fixedprice1: 'Vaste prijs',
        Lowestprice: 'Laagste prijs',
        fixedprice: 'Vaste prijs',
        Promotionprice_unitprice: 'Actieprijs (eenheidsprijs)',
        price1: 'Verkoopprijs',
        inventory: 'inventaris',
        Inventorybottomline: 'inventaris onder de streep',
        AutomaticallysynchronizeEBP: 'Of EBP automatisch moet worden gesynchroniseerd',
        ForcesynchronizationofEBP: 'Of EBP-synchronisatie geforceerd moet worden',
        Presaleornot: 'Voorverkoop of niet',
        Evaluation_Shown_total: 'Recensies (weergegeven/totaal)',
        coding: 'Code',
        ProductDetails: 'Productdetails',
        EditProduct: 'Product bewerken',
        Basicinformation: 'Basisinformatie',
        price: 'Prijs',
        Pleaseentertheproducttitle: 'Voer de producttitel in',
        Sizeinformation: 'Maat Informatie',
        weight: 'Gewicht',
        volume: 'Volume',
        Salesmultiples: 'Verkoop vermenigvuldiger',
        Numberofitems: 'Aantal items',
        EditFixedPrice: 'Bewerk vaste prijs',
        Largeimage: 'Grote afbeelding',
        Uploadimages: 'Upload een foto',
        UploadVideo: 'Upload een video',
        Batchdisplay: 'Batchweergave',
        BatchCloseDisplay: 'Batchweergave sluiten',
        Batchdeletion: 'Batch verwijderen',
        Synchronizetootherlanguages: 'Synchroniseer met andere talen',
        Selectfrompicturelibrary: 'Maak een selectie uit de galerij',
        Pleaseenterthemaintext: 'Voer de hoofdtekst in',
        Hideemptyparameters: 'Verberg lege parameters',
        Detailedcontentofgraphicsandtext: 'Voer eerst de grafische details in het Engels in',
        parameter: 'Parameter',
        Parametervalue: 'Parameterwaarde',
        ParameterProperties: 'Parametereigenschappen',
        Pleaseenteraparametername: 'Voer de parameternaam in',
        Pagekeywords: 'Pagina trefwoord',
        PageDescription: 'pagina beschrijving',
        Pleaseentercontent: 'Voer de inhoud in',
        PictureLanguage: 'Taal afbeelding',
        Pictures_Videos: 'Afbeelding/Video',
        ImageID: 'Afbeelding-ID',
        language: 'Taal',
        size: 'Maat',
        Selectapictureorvideo: 'Selecteer eerst de afbeelding of video in de galerij!',
        Selectionsuccessful: 'Selecteer succesvol',
        Isitthemainimage: 'Hoofdafbeelding of niet',
        Displayornot: 'Wilt u tonen of niet',
        Whethertodisplayall: 'Alles tonen of niet',
        Areallclosed: 'Wil u alles afsluiten',
        Confirmdeletionoftheselectedpicture_video: 'Bevestig om de geselecteerde afbeelding/video te verwijderen',
        ProductReview: 'Productopmerkingen',
        PromotionName_ProductID_Barcode: 'Actienaam/product-ID/streepjescode',
        PromotionCountry: 'Land met promoties',
        Activitystatus: 'Status van evenement',
        begininaminute: 'Begint binnenkort',
        Ended: 'Is beëindigd',
        Isitaflashkill: 'Is het een tijdelijk aanbod?',
        PromotionName: 'Promotienaam',
        CommentID: 'Reactie-ID',
        RatingStarRating: 'Sterbeoordeling',
        star0: 'ster',
        star1: '1 ster',
        stars2: '2 sterren',
        stars3: '3 sterren',
        stars4: '4 sterren',
        stars5: '5 sterren',
        Commentstartdate: 'begindatum van de reacties',
        CommentEndDate: 'einddatum van de reacties',
        Batchreview: 'Batchoverzicht',
        Productimage: 'Productafbeeldingen',
        Evaluationcontent: 'commentaar inhoud',
        video: 'Video',
        ReviewerId: 'Recensent-ID',
        user: 'Gebruiker',
        Pendingreview: 'Wordt beoordeeld',
        Evaluationtime: 'Evaluatietijd',
        ReviewerName: 'Naam van de recensent',
        Numberofselectedcomments: 'Aantal geselecteerde reacties',
        Picturepreview: 'Afbeeldingsvoorbeeld',
        VideoPreview: 'Videovoorbeeld',
        Lastmonth: 'Vorige maand',
        LastThreeMonths: 'Afgelopen drie maanden',
        Areyousuretodelete: 'Weet u zeker dat u dit wilt verwijderen?',
        Pleaseselectacomment: 'Selecteer een beoordeling',
        Amazon: 'Amazon',
        Aigostarbrandnetwork: 'aigostar merk netwerk',
        marketingmanagement: 'Marketing Management',
        Productrecommendations: 'Productaanbeveling',
        Recommendedlocation: 'Locatie aanbevelen',
        Categoryrecommendation: 'Categorie aanbeveling',
        Recommendationmodule: 'Module aanbevelen',
        NewProductZone: 'Nieuwkomers',
        HotSellingZone: 'Promotieproducten',
        Recommendedforyou1: 'Aanbevolen voor u',
        Numberofproducts: 'Aantal producten',
        Addproduct: 'Product toevoegen',
        DirectoryName: 'Catalogus naam',
        title: 'Titel',
        ContentTitle: 'Titel van de inhoud',
        EnablePlatform: 'Platform inschakelen',
        DisplayPlatform: 'platform weergeven',
        catalogue: 'Catalogus',
        JumpAddress: 'Spring naar adres',
        PageTitle: 'Pagina titel',
        CategoryName: 'Categorienaam',
        Cancelleddeletion: 'Ongedaan gemaakt',
        Pleaseselectacategoryfirst: 'Selecteer eerst een categorie',
        TheEnglishcategorynamecannotbeempty: 'Engelse categorienaam mag niet leeg zijn',
        Successfullysaved: 'Met succes opgeslagen',
        DeleteError: 'Fout verwijderen',
        Apieceofcontentalreadyexists: 'Er is al een inhoud',
        Pleaseselecttherecommendedlocation: 'Selecteer een aanbevolen locatie',
        Pleaseselecttherecommendedmodule: 'Selecteer een aanbevelingsmodule',
        remove: 'Verwijderen',
        Successfullyadded2: 'Succesvol toegevoegd',
        Areyousuretoremovethisproduct: 'Weet u zeker dat u dit product wilt verwijderen?',
        Successfullyremoved: 'Succesvol verwijderd',
        Dataalreadyexists: 'Gegevens bestaan al',
        PortfolioManagement: 'Portefeuillebeheer',
        Combinationname: 'Combinatienaam',
        Remainingquantity_combinedquantity: 'Resterende hoeveelheid/resterende gecombineerde hoeveelheid',
        starttime: 'Starttijd',
        Endtime: 'Eindtijd',
        Creator: 'Gemaakt door',
        NationalSDPDictionary: 'nationaal sdp-woordenboek',
        Combinationtime: 'Combinatietijd',
        Combinationquantity: 'Aantal combinaties',
        CanIusecoupons: 'Zijn er coupons beschikbaar?',
        Mainimage: 'Hoofdafbeelding',
        Setcombinationdiscount: 'Stel combinatiekorting in',
        Combinationprice: 'Combinatieprijs',
        Combinedtotalprice: 'Totale prijs van de combinatie',
        Isitontheshelf: 'Ligt het in de schappen?',
        Selectupto5: 'Selecteer maximaal 5',
        Promotionprice1: 'Promotionele prijs',
        Pleaseenterthecombinationquantity: 'Vul het aantal combinaties in',
        Pleaseenteracombinationname: 'Voer een combinatienaam in',
        Upto100wordscanbeentered: 'Voer maximaal 100 tekens in',
        Pleaseselectadaterange: 'Selecteer een datumbereik',
        Pleaseselectastartdate: 'Selecteer een startdatum',
        Pleaseselecttheenddate: 'Selecteer een einddatum',
        Pleasechoosewhethertousecoupons: 'Selecteer of u de coupon wilt gebruiken',
        Pleaseselect25items: 'Selecteer 2-5 items',
        Pleaseselectaproductthathasbeenlisted: 'Selecteer een item dat in de schappen is geplaatst',
        Inventoryofselecteditems: 'De voorraad-/verkoopvermenigvuldiger van het geselecteerde artikel kan niet kleiner zijn dan de actieve hoeveelheid',
        Combinationpricecannotbeempty: 'De combinatieprijs mag niet leeg zijn',
        Caninput0andpositiveintegers: '0 en positieve gehele getallen kunnen worden ingevoerd, symbolen kunnen alleen worden ingevoerd als % en . , tot twee decimalen',
        Presaleproductscannotbeadded: 'Dit is een voorverkoopartikel en kan niet als combo worden toegevoegd',
        Pleaseselectaproductfirst: 'Selecteer eerst een artikel',
        Freeshippingonfirstorder: 'Gratis verzending bij de eerste bestelling',
        Firstfreeshippingitem: 'Gratis verzending van uw goederen bij de eerste bestelling',
        SelectedProduct: 'Geselecteerd product',
        Between1and50charactersinlength: 'Lengte tussen 1 en 50 karakters',
        Thisoperationwillremovethefile: 'Met deze bewerking wordt het bestand verwijderd. Wilt u doorgaan?',
        Brandandcountrycannotbeempty: 'De velden Merk en land mogen niet leeg staan',
        Pleaseselectatime: 'Selecteer een tijd',
        Editedsuccessfully: 'Succesvol bewerkt!',
        Shoppingrebate: 'Winkelkortingen',
        Rebateratio: 'Kortingspercentage',
        Rebatestarttime: 'begintijd van de korting',
        Rebateendtime: 'eindtijd van de korting',
        Rebatetime: 'Kortingstijd',
        doyouwanttodelete2: 'Verwijderen of niet verwijderen?',
        ActivityTopic: 'Onderwerp van evenement',
        ActivityName: 'Naam van evenement',
        Whethertodisplayonthehomepage: 'Of het op de voorpagina moet worden weergegeven',
        QuerySearch: 'Zoekopdracht',
        TopicName: 'Naam van onderwerp',
        Activitytime: 'Tijd van evenement',
        Richtextornot: 'Wilt u gebruikmaken van Rich tekst?',
        ActivityDescription: 'Beschrijving van het evenement',
        SpecialSEO: 'Thematische SEO',
        LogisticsPricing: 'Logistieke prijzen',
        Basicfreight: 'Basis Verzendtarieven',
        Freeshippingprice: 'Gratis verzending prijzen',
        Deliverytimeprompt: 'Herinnering verzendtijd',
        Receivingtimeprompt: 'Herinnering levertijd',
        PricingSettings: 'Prijsinstellingen',
        Freightstandard: 'Standaardverzending',
        Freightallocation: 'Verzending configuratie',
        FreightType: 'Verzendtype',
        Freeshipping: 'Geen verzendkosten',
        Configureshippingcosts: 'Verzendkosten configureren',
        Fullreductionoffreight: 'Cashback voor verzending',
        Orderamountfull: 'Bestelbedrag reikt tot',
        Receivingandshippingreminder: 'Ontvangst- en verzendherinnering',
        link: 'Koppeling',
        confirm: 'Bevestigen',
        Cannotbe0: 'Kan geen 0 zijn',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Er kunnen maximaal twee decimalen worden ingevoerd',
        Pleaseinputthenumbercorrectly: 'Vul a.u.b. het juiste nummer in',
        Pleaseenterthecountry: 'Voer het land in',
        Pleaseselectshippingcost: 'Selecteer verzendkosten',
        Pleasecompletetheshippingcostconfiguration: 'Voltooi de verzendconfiguratie',
        Pleaseenteradeliveryprompt: 'Voer een bezorgherinnering in',
        Pleaseenteralink: 'Voer een link in',
        Addlogisticsconfiguration: 'Logistieke configuratie toevoegen',
        Editlogisticsconfiguration: 'Logistieke configuratie bewerken',
        Pleaseenterthelogisticscompany: 'Voer een logistiek bedrijf in',
        Editingfailed: 'Bewerken is mislukt',
        Addlogisticscompany: 'Logistiek bedrijf toevoegen',
        Pleaseuploadtheicon: 'Upload een pictogram',
        Pleaseenterthelinkcorrectly: 'Voer de link correct in',
        Successfullyadded: 'Succesvol toegevoegd',
        Editlogisticscompany: 'Logistiek bedrijf bewerken',
        Thisisalreadythefirstitemmoveupfailed: 'Al eerste item, kan niet omhoog',
        Thisisalreadythelastitemmovedownfailed: 'Al laatste item, kan niet naar beneden',
        Costprice: 'Kostprijs',
        taxrate: 'Belastingtarief',
        commission: 'Commissies',
        Grossmargin: 'Bruto winstmarge',
        doyouwanttodelete: 'Het ingevulde getal is te groot',
        Pleaseselectapricetype: 'Selecteer een prijssoort',
        Pleasecompletethetaxrateconfiguration: 'Voltooi de configuratie van het belastingtarief',
        Pleasecompletethecommissionconfiguration: 'Voltooi de commissieconfiguratie',
        PleasecompleteGrossmarginconfiguration: 'Vul de configuratie van het brutotarief in',
        Weightrange_start_kg: 'Gewichtsbereik (begin) kg',
        Weightrange_end_kg: 'Gewichtsbereik (eind) kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'Er kunnen maximaal vier decimalen worden ingevoerd',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'De eindwaarde moet groter zijn dan of gelijk zijn aan de startwaarde',
        Pleasecompletetheweightrangeconfiguration: 'Voltooi de configuratie van het gewichtsbereik',
        Pleaseentertheshippingcost: 'Vul de verzendkosten in',
        Addshippingstandard: 'Voeg standaard verzendtarief toe',
        EditFreightStandard: 'Bewerk standaard verzendtarief',
        Allocationofpatrioticcoins: 'Aigomunten-configuratie',
        RuleConfiguration: 'Configuratie van regels',
        ObtainingPatrioticCoinObject: 'Voorwerpen om Aigo-munten te krijgen',
        Rewardconditionsobtained: 'Voorwaarden voor het verkrijgen van beloningen',
        Obtainpatrioticcoinallocation: 'Download Aigo-muntenconfiguratie',
        Inputquantity: 'Voer het aantal in',
        EnterName: 'Voer naam in',
        Registrationsuccessfulnot: 'Succesvolle registratie (zonder uitnodigingscode)',
        Inviteduserplacesanorder: 'Uitgenodigde gebruiker plaatst bestelling',
        Registrationsuccessful: 'Registratie succesvol (met gebruik van uitnodigingscode)',
        Registeredaccount: 'Geregistreerd account (geregistreerd land)',
        Registrationsuccessfulnot1: 'Account succesvol geregistreerd zonder uitnodigingscode',
        Numberoflocalcurrencies: 'De hieronder geconfigureerde hoeveelheden zijn in de nationale valuta van het overeenkomstige land',
        Inviteraccount: 'Inviter-account (het account dat de uitnodigingscode heeft verstrekt, het land van de uitnodigingscode)',
        Provideinvitationcode: 'Geef de uitnodigingscode door aan andere gebruikers, andere gebruikers gebruiken de uitnodigingscode om zich te registreren en vervolgens een bestelling succesvol af te ronden (elke uitgenodigde gebruiker kan slechts 1 bonus krijgen)',
        Usinganinvitationcode: 'Gebruik de uitnodigingscode om succesvol een account te registreren',
        ProductPromotion: 'Productpromoties',
        discount: 'Kortingen',
        Freeshippingornot: 'Gratis verzending of niet',
        Secondkillsorting: 'Sortering tijdelijk aanbod',
        surplus: 'Overig',
        Totalnumber: 'Totaal',
        ImportFile: 'Bestanden importeren',
        Selectupto50: 'Selecteer maximaal 50',
        Setuppromotions: 'Promotie instellen',
        Nocountrieslisted: 'Geen landen waar producten in het schap liggen',
        Promotiontime: 'Promotietijd',
        Displaypromotionalprices: 'Wilt u dat de actieprijs wordt weergegeven?',
        PromotionPrice: 'Promotionele prijs',
        Promotionquantity: 'Promotionele hoeveelheid',
        Doyousupporttheuseofcoupons: 'Wilt u gebruikmaken van kortingsbonnen?',
        Purchaserestrictionsforthesameuser: 'Aankooplimiet voor dezelfde gebruiker',
        Singleorderlimit: 'Limiet voor één bestelling',
        CurrentactivitypricesinSpain: 'De brutomarge op de huidige activiteitsprijs in {countryCode} is',
        SubmitConfirmation: 'Bevestiging doorgeven',
        Productdistribution: 'Productdistributie',
        DistributionName: 'Distributienaam',
        DistributionCountry: 'Land van distributie',
        Distributionprice1: 'Distributieprijs',
        Distributiontime: 'Distributietijd',
        DistributionPrice: 'Distributieprijs',
        Distributionquantity: 'Aantal distributies',
        CopyConnection: 'Kopieer link',
        Successfullycopied: 'Succesvol gekopieerd',
        Countryofuse: 'Land van gebruik',
        Discounttype: 'Soort aanbieding',
        Fullreduction: 'Cashback',
        Isthereafixedcode: 'Is er een vaste code',
        Effective: 'Geldig',
        Comingsoon1: 'Binnenkort geldig',
        Expired: 'Verlopen',
        Overlayornot: 'Wilt u opstapelen?',
        Istheexchangecodefixed: 'Moet de inwisselcode worden gecorrigeerd',
        Received: 'ontvangen',
        Used: 'Gebruikt',
        Availableamount: 'beschikbaar bedrag',
        Maximumdeductionamount: 'Maximaal aftrekbaar bedrag',
        Canitbestacked: 'Is het stapelbaar?',
        Redemptioncode: 'Inwisselcode',
        Fullreductionamount: 'Cashback-bedrag',
        Enterredemptioncode: 'Voer de inwisselcode in',
        Numberofredemptioncodes: 'Aantal codes',
        Numberofusesbythesameuser: 'Aantal keer gebruikt door dezelfde gebruiker',
        Effectivetime: 'Geldige tijd',
        Isitpossibletooverlaydistribution: 'Kan het gestapeld worden met of zonder distributie?',
        Canpromotionsbestacked: 'Kan het met of zonder promoties gestapeld worden?',
        Entercoupondescription: 'Voer de couponbeschrijving in',
        Pleaseenteradescription: 'Voer een beschrijving in',
        Pleaseselectadate: 'Selecteer een datum',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Voer het aantal keren in dat door dezelfde gebruiker is gebruikt',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Voer het beschikbare bedrag van 0 in om onbeperkt aan te geven',
        Pleaseentertheavailableamount: 'Vul een beschikbaar bedrag in',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'Het aantal inwisselcodes kan alleen groter zijn dan het origineel',
        Pleaseenterthenumberofredemptioncodes: 'Voer het aantal inwisselcodes in',
        Applicableclassification: 'Toepasselijke categorieën',
        Applicableproducts: 'Toepasselijke producten',
        Useordernumber: 'Gebruik het bestelnummer',
        Claimstatus: 'Ophaalstatus',
        Notclaimed: 'Niet opgehaald',
        notused: 'Niet gebruikt',
        Usagestatus: 'Gebruiksstatus',
        Collectiontime: 'Ophaaltijd',
        Usagetime: 'Tijd van gebruik',
        UsingUsers: 'Gebruikers',
        BannerManagement: 'Bannerbeheer',
        position: 'Locatie',
        JumpType: 'Spring type',
        Nojump: 'Geen sprong',
        Homepagerecommendationposition: 'Startpagina uitgelicht',
        Homebanner: 'Thuisbanner',
        Displaytime: 'Tijd weergeven',
        JumpLink: 'Spring link',
        Suggestedsize: 'Aanbevolen maat',
        Enterjumplink: 'Voer spring link in',
        Uploadingimagesonlysupports: 'Upload afbeeldingen alleen in jpg, png, gif, jpeg',
        Topwithlargenumbers: 'De standaardwaarde is 0, hoe groter het getal, hoe hoger de sortering',
        Pleaseselectatleastonecountry: 'Selecteer ten minste één land',
        Boutiquerecommendationspace: 'Boutique-aanbevelingsruimte',
        Fullproductrecommendation: 'Volledige productaanbeveling',
        popupnotification: 'Pop-up venster',
        Addbanner: 'Banner toevoegen',
        subscriptionmanagement: 'Abonnementenbeheer',
        Emailaddress: 'E-mailadres',
        Subscribing: 'Abonnement in uitvoering',
        Canceled: 'Geannuleerd',
        SubscriptionSource: 'Abonnementsbron',
        Unsubscribe: 'Abonnement annuleren',
        Resubscribe: 'Opnieuw abonneren',
        Pleaseselectatimerange: 'Selecteer een tijdbereik',
        WebsiteManagement: 'Websitebeheer',
        informationservice: 'Informatieservices',
        New: 'Creëren',
        Isitdisplayedatthebottomofthewebsite: 'Wilt u dit onderaan de website tonen?',
        Pleaseselectaninformationservice: 'Selecteer de informatieservice',
        deleteconfirmation: 'Weet u zeker dat u deze node en alle informatie onder de node wilt verwijderen?',
        Pleaseenteracolumnname: 'Voer een kolomnaam in',
        Thelengthcannotexceed50characters: 'De lengte mag niet langer zijn dan 50 tekens',
        ThelengthcannotexceedXcharacters: 'De lengte mag niet langer zijn dan {num} tekens',
        Pleaseentersorting: 'Voer de bestelling in',
        Selectedcountrycontentisrequired: 'De geselecteerde landinhoud is vereist',
        TitleName: 'Titel',
        Chinesetitleisrequired: 'Chinese titel is vereist',
        Selectedcountrytitleisrequired: 'Titel van geselecteerd land is vereist',
        EditingInformationServices: 'Informatieservice bewerken',
        ColumnName: 'Kolomnaam',
        Pleaseenterthecreationtime: 'Voer de aanmaaktijd in',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Geef aan of u deze onderaan de site wilt weergeven',
        ViewInformationServices: 'Informatieservice bekijken',
        Modificationtime: 'Tijd wijzigen',
        Countrycannotbeempty: 'Het veld Land mag niet leeg zijn',
        Titlecannotbeempty: 'Het veld Titel mag niet leeg zijn',
        Richtextcontentonthepagecannotbeempty: 'RTF-inhoud van de pagina mag niet leeg zijn',
        Nationalconfiguration: 'Configuratie van land',
        currency: 'Munteenheid',
        allocation: 'Configuratie',
        Statuseditedsuccessfully: 'Status is succesvol bewerkt',
        Areyousuretocontinuedeletingdata: 'Weet u zeker dat u door wilt gaan met het verwijderen van gegevens?',
        WebsiteAddress: 'URL-adres',
        Contactinformation: 'Contactgegevens',
        Customerserviceemail: 'E-mail klantenservice',
        Socialmedia: 'Sociale media',
        Socialmediainformation: 'Informatie over sociale media',
        Pleasechooseapaymentmethod: 'Selecteer een betaalmethode',
        Lengthgreaterthan20characters: 'De lengte is groter dan 20 tekens',
        Lengthgreaterthan100characters: 'De lengte is groter dan 100 tekens',
        Successfullydeletedinformation: 'Het bericht is succesvol verwijderd',
        Iconmustbeuploaded: 'Pictogram moet worden geüpload',
        Successfullyaddedinformation: 'Het bericht is succesvol toegevoegd',
        Successfullyeditedinformation: 'Het bericht is succesvol bewerkt',
        Pleaseentertheemailcorrectly: 'Voer uw e-mailadres correct in',
        Thesizeoftheuploadedimagecannotexceed: 'De geüploade afbeeldingsgrootte mag niet groter zijn dan',
        Countryname: 'Naam van het land',
        Pleaseuploadpictures: 'Upload een foto',
        NewCountryInformation: 'Maak nieuwe landgegevens aan',
        EditCountryInformation: 'Landgegevens bewerken',
        Picturesmustbetransmitted: 'Afbeeldingen moeten worden geüpload',
        NewCountryInformationTableImageAddress: 'Aanmaken van nieuw informatieblad van land, afbeelding en adres',
        Operationsuccessful: 'Succesvolle verrichting',
        Euro: 'Euro',
        pound: 'Pond',
        Zloti: 'Zloty',
        Brandcannotbeempty: 'Het veld Merk mag niet leeg staan',
        Thewebsiteaddresscannotbeempty: 'Het veld Websiteadres mag niet leeg staan',
        Contactinformationcannotbeempty: 'Het veld Contactgegevens mag niet leeg staan',
        Thecustomerserviceemailaddresscannotbeempty: 'Het veld E-mailadres van de klantenservice mag niet leeg staan',
        Medianamecannotbeempty: 'Het veld Medianaam mag niet leeg staan',
        Themediaiconcannotbeempty: 'Het veld Mediapictogram mag niet leeg staan',
        Medialinkcannotbeempty: 'Het veld Medialink mag niet leeg staan',
        Thecountryiconcannotbeempty: 'Het veld Landpictogram mag niet leeg staan',
        Languagecannotbeempty: 'Het veld Taal mag niet leeg staan',
        Currencycannotbeempty: 'Het Valuta mag niet leeg staan',
        Pagedescriptioncannotbeempty: 'Het veld Paginabeschrijving mag niet leeg staan',
        Statusenable_disablecannotbeempty: 'UserID_IP_LoginDevice',
        SEOManagement: 'SEO-beheer',
        Import: 'Importeren',
        DownloadImportTemplate: 'Importeer sjabloon downloaden',
        PageURL: 'pagina URL',
        SEOdata: 'SEO-gegevens',
        SEOConfigurationTemplate: 'SEO-configuratiesjabloon',
        Downloadsuccessful: 'Downloaden is gelukt',
        SEOManagementImport: 'SEO-beheer importeren',
        DragtheExcelfilehere_orclickonupload: 'Sleep het Excel-bestand hierheen of klik op uploaden',
        Fileimportedsuccessfully: 'Bestand is geïmporteerd',
        Fileimportfailed_pleasecheckthefilecontent: 'Het importeren van het bestand is mislukt. Controleer de inhoud van het bestand',
        Pleaseenterthelanguage: 'Voer de taal in',
        Thelengthcannotexceed2characters: 'De lengte mag niet langer zijn dan 2 tekens',
        PleaseenterthepageURL: 'Voer de pagina-URL in',
        Thelengthcannotexceed100characters: 'De lengte mag niet langer zijn dan 100 tekens',
        Thelengthcannotexceed20characters: 'De lengte mag niet langer zijn dan 20 tekens',
        No_Illegalrowdata: 'De ? lijn van gegevens is illegaal',
        Failedtouploadfile_Pleasecheckthefilecontent: 'Het uploaden van het bestand is mislukt. Controleer de inhoud van het bestand',
        Namecannotbeempty: 'Naam mag niet leeg zijn',
        PageURLcannotbeempty: 'Pagina-URL mag niet leeg zijn',
        Thepagetitlecannotbeempty: 'Paginatitel mag niet leeg zijn',
        SEOManagementImportTemplate: 'Importeer sjabloon voor SEO-beheer',
        Donotmodify: 'Wijzig de eerste drie regels van de sjabloontitel niet',
        Required: 'Velden gemarkeerd met ' * ' zijn verplicht',
        Brandandlanguagemustbefullycapitalized: 'Merk en taal moeten in hoofdletters zijn',
        SEOManagementExport: 'SEO-beheer exporteren',
        Pagekeywordscannotbeempty: 'Het veld Pagina trefwoorden mag niet leeg zijn',
        Prohibitedsalesarea: 'Verboden verkoopgebieden',
        Pleaseenterthepostalcodecorrectly: 'Vul uw postcode correct in',
        Pleasefillinthepostalcode: 'Vul uw postcode in',
        Countrycodecannotbeempty: 'Landcode mag niet leeg zijn',
        Postalcodecannotbeempty: 'Postcode mag niet leeg zijn',
        Postalcodealreadyexists: 'Postcode bestaat al',
        Homepagearticle: 'Artikelen op de startpagina',
        BrandStory: 'Merk Verhalen',
        SciencePopularizationZone: 'Wetenschappelijk gebied',
        Lightstripzone: 'Lichtstrip-zone',
        Pictureandtext: 'Afbeelding en tekst',
        Comingsoon: 'binnenkort beschikbaar',
        Publishinginprogress: 'Bezig met vrijgeven',
        column: 'Kolom',
        Releasetime: 'Datum van vrijgave',
        Pleaseenteratitle: 'Voer een titel in',
        Pleaseselectacolumn: 'Selecteer een kolom',
        Pleaseselectatype: 'Selecteer type',
        Patrioticcoinusagelog: 'Gebruikslogboek Aigomunten',
        income: 'Inkomen',
        expenditure: 'Uitgaven',
        mode: 'Methode',
        Orderexpenses: 'Bestelling uitbetaling',
        Orderrewards: 'Beloningen bestellen',
        OrderCloseReturn: 'Bestelling sluiten en retourneren',
        other: 'Ander',
        Backendrecharge: 'Achtergrond opladen',
        Orderrebate: 'Korting bestellen',
        Numberofchangesinpatrioticcoins: 'Aantal Aigo-munten gewijzigd',
        Numberofpatrioticcoinsafterchanges: 'Aantal Aigo-munten na wijziging',
        CountryCode: 'Landcode',
        Changetime: 'Tijd van verandering',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: 'Selecteer het overzicht van besteedde Aigo-munten van de gebruiker',
        WebsiteNavigation: 'Sitenavigatie',
        HomepageConfiguration: 'Configuratie van de startpagina',
        Navigationcontent: 'Navigatie-inhoud',
        Recommendedforyou: 'Aanbevolen voor jou',
        SelectNavigation: 'Selecteer navigatie',
        Pleasecheckfirst: 'Gelieve eerst aan te vinken',
        Logmanagement: 'Logboekbeheer',
        UserID_IP_LoginDevice: 'Gebruikers-ID/IP/inlogapparaat',
        logintime: 'Inlogtijd',
        Loginmethod: 'Inlogmethode',
        IPaddress: 'IP-adres',
        Logindevice: 'Inlogapparaat',

        //11-22
        AssociatedOrderNumber: 'Bijbehorend bestelnummer',
        recordId: 'Record-ID',
        UserAccess: 'Gebruikerstoegang',
        PleaseSelectTheStatisticalType: 'Selecteer het type statistiek',
        StatisticalByDate: 'Statistiek per datum',
        StatisticalBySource: 'Statistiek per bron',
        Date: 'Datum',
        Visits: 'Bezoeken',
        NewVisitors: 'Nieuwe bezoekers',
        OrderStatistics: 'Bestellingsstatistieken',
        Sales: 'Verkoop',
        TotalOrders: 'Totaal aantal bestellingen',
        AverageOrderAmount: 'Gemiddelde grootte van bestelling',
        SynchronizeEBP: 'Elektronische facturering synchroniseren',
        ParameterName: 'Naam van de parameter',
        OnTheShelf: 'Op het schap',
        OfflineShoppingCart: 'Offline winkelkar',
        AddToCart: 'Toevoegen aan winkelkar',
        EnterCheckout: 'Afrekenen',
        UserLogin: 'Log in als gebruiker',
        UserRegistration: 'Registreer als gebruiker',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Verschillend IP-nummer bij het toevoegen van items aan winkelkar',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Verschillend IP-nummer bij bevestigen van de bestelling',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Verschillend IP-nummer voor wie het loginproces volbracht heeft na van de offline winkelkar naar de loginpagina gestuurd te worden.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Verschillend IP-nummer voor wie de registratieprocedure volbracht heeft na van de offline winkelkar doorgestuurd te worden naar de registratiepagina.',
        UserBehavior: 'Gebruikersgedrag',
        Display: 'Weergeven',
        DoNotDisplay: 'Niet weergeven',
        AddToPresale: 'Toevoegen aan voorverkoop',
        CancelPresale: 'Voorverkoop annuleren',
        PresaleConfiguration: 'Samenstelling voorverkoop',
        OffTheShelf: 'Van het rek',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Vul de productinformatie aan voor u de voorverkoop aanmaakt',
        SynchronizedEBPPrompts: 'Om goed te synchroniseren met de e-facturatie, kan de synchronisatie van de hoeveelheid slechts een keer om de X minuten gebeuren om fouten te vermijden. Na de bevestiging gebeurt de synchronisatie asynchroon, en het bestelnummer (SKU) wordt meteen weergegeven nadat het verkregen is. Even geduld aub.',
        PleaseWait: 'Wacht even aub',
        PleaseSelectATag: 'Selecteer een label',
        Updating: 'Bijwerken',
        Abortive: 'Mislukt',
        DefaultRule: 'Standaardregel',
        BottomInventory: 'Voorraad uitgeput',
        SynchronisedAlertsForGraphicDetails: 'Grafische details: synchroniseer de [trefwoorden voor het product] per taal',
        SynchronisedAlertsForLargeProductImages: "Productafbeelding: Synchroniseer de afbeelding die bij de taal hoort, druk op 'EBP afbeelding sorteren' en stel de eerste afbeelding in als hoofdafbeelding",
        Account: 'Account',
        Account1: 'Account',
        EnterUpToNumItems: 'Voer tot {num} items in',
        SynchronizedSuccessfully: 'Succesvol gesynchroniseerd',
        SuccessfullyListed: 'Succesvol opgenomen',
        SearchSuccessful: 'Zoeken succesvol',
        EnterNewPassword: 'Voer uw nieuwe wachtwoord in',
        OnlyNumbersAndLettersAreAllowed: 'Enkel cijfers en letters zijn toegelaten',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: 'Vervolledig eerst de configuratie van de voorverkoop voor u het item oplijst voor voorverkoop',
        ProductWeight: 'Gewicht',
        ConfirmExecution: 'Uitvoering bevestigen?',
        ConfirmBatchListing: 'Batchoverzicht bevestigen?',
        Template: 'Sjabloon',
        PleaseSelectTheProductsToBeSynchronized: 'Selecteer de producten die u wilt synchroniseren',
        ForcedSynchronisationDescription: 'Geforceerde synchronisatie zorgt ervoor dat de aangepaste gegevens verwijderd worden en vervangen door gesynchroniseerde gegevens. Gewone synchronisatie zorgt er niet voor dat de aangepaste productgegevens gesynchroniseerd worden.',
        ConfirmSynchronizationExecution: 'Uitvoering synchronisatie bevestigen',
        SynchronisationHasBeenSentPleaseWait: 'Het bericht voor synchronisatie is verzonden, even geduld a.u.b. tot de gegevens volledig gesynchroniseerd zijn',
        ProhibitLogin: 'Login geweigerd',
        IsTheUserLoggedIn: 'Is de gebruiker ingelogd?',
        Prohibit: 'Weigeren',
        ProhibitedSuccessfully: 'Succesvol geweigerd',
        EnableLogin: 'Login activeren',
        EnabledSuccessfully: 'Succesvol geactiveerd',
        OperationCanceled: 'Bewerking geannuleerd',
        UserData: 'Gebruikersgegevens.xlsx',
        GraphicDetails: 'Grafische details',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'Dit product is ingesteld als combinatieproduct en kan niet voorbereid worden voor voorverkoop Verwijder het product eerst uit de combinatie',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'Dit product is in promotie en kan niet voorbereid worden voor voorverkoop. Annuleer eerst de promotie.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'Dit product is bestemd voor distributie en kan niet voorbereid worden voor voorverkoop. Annuleer de distributie eerst.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'Dit product is deel van een actie en kan niet toegevoegd worden',
        ESNoPriceNotAvailableForPresale: 'Dit product heeft geen prijs in dit land en kan niet toegevoegd worden voor voorverkoop. Haal eerst het bijhorende land weg, of synchroniseer de prijs van het land voor producten toe te voegen voor voorverkoop.',
        ConfirmSettingThisProductAsAPresaleItem: 'Plaatsing van dit product als voorverkoopitem bevestigen?',
        PresaleAddedSuccessfully: 'Voorverkoop succesvol toegevoegd',
        ConfirmSettingThisProductAsANonpresaleItem: 'Plaatsing van dit product als niet-voorverkoopitem bevestigen?',
        CancelPresaleSuccessful: 'Voorverkoop succesvol geannuleerd',
        Preview: 'Voorvertoning',
        TheInventoryOfCurrentProducts0Continue: 'De inventaris van het huidige product {stock} is 0, doorgaan?',
        TheInputContentCannotExceedCharacters: 'De ingevoerde inhoud mag niet langer zijn dan {x} tekens',
        PleaseSelectTheActivity: 'Selecteer de activiteit',
        OrderDetails: 'Andere details',
        LogisticsInformation: 'Logistieke informatie',
        CancelRemark: 'Opmerking annuleren',
        DepositAmount: 'Bedrag storten',
        FinalPaymentAmount: 'Uieindelijke bedrag van de betaling',
        FullReductionDiscount: 'Volledige korting',
        YourBrowserDoesNotSupportVideoPlayback: 'Uw browser ondersteunt het afspelen van deze video niet',
        EditBanner: 'Banner bewerken',
        AddBanner: 'Banner toevoegen',
        UpdateTime: 'Tijd bijwerken',
        HomepageRecommendationPosition: 'Aangeraden positie startpagina',
        ActivityID: 'Activiteiten ID',
        PleaseSelectThePosition: 'Selecteer de positie',
        PleaseSelectTheJumpType: 'Selecteer het soort sprong',
        PleaseEnterTheAssociatedID: 'Voer de bijhorende ID in',
        TheUploadedImageSizeCannotExceed5MB: 'De afbeelding mag niet groter zijn dan {num}MB.',
        OnlyMp4FormatIsSupportedForVideoUpload: "Enkel mp4 wordt ondersteund voor het uploaden van video's",
        TheUploadedVideoSizeCannotExceed500MB: 'De video mag niet groter zijn dan {num}MB.',
        PleaseUploadTheVideo: 'Upload de video',
        PleaseEnterTheJumpLink: 'Voer de springlink in',
        PleaseEnterTheCorrectLink: 'Voer de correcte link in',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'Verificatie mislukt, vul eerst de vereiste velden in',
        AddFailed: 'Toevoegen mislukt',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: 'Dit is al de eerste, en de verplaatsing naar boven is mislukt',
        PleaseEnterTheRebateRatio: 'Voer de kortingsratio in',
        EditCoupon: 'Bewerk coupon',
        AddCoupon: 'Voeg coupon toe',
        IDCouponName: 'ID/naam coupon/Inwisselcode',
        Random: 'Willekeurig',
        Fixed: 'Vast',
        AboutToTakeeffect: 'Gaat bijna in',
        NotStackable: 'Niet stapelbaar',
        Stackable: 'Stapelbaar',
        Frozen: 'Bevroren',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Voer het maximale kortingsbedrag in, 0 betekent ongelimiteerd',
        UseUserID: 'Gebruik gebruikers-ID',
        UseOrderNumberExchangeCodeUserID: 'Gebruik bestellingsnummer/beurscode/gebruikers-ID',
        PleaseEnterTheDiscount: 'Vul de korting in',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Enkel getallen van 0 tot 100 kunnen ingevuld worden, tot 1 cijfer na de komma',
        PleaseEnterTheFullReductionAmount: 'Vul het volledige kortingsbedrag in',
        PleaseEnterTheExchangeCode: 'Vul de beurscode in',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Enkel cijfers/letters kunnen ingevuld worden, minstens 3 tekens, tot 20 tekens',
        PleaseSelectAFixedExchangeCode: 'Selecteer een vaste beurscode',
        PleaseEnterTheMaximumDeductionAmount: 'Vul het maximale kortingsbedrag in',
        PleaseSelectWhetherItCanbeStacked: 'Selecteer of de korting gestapeld kan worden',
        WarehousingTime: 'Opslagtijd',
        EnterActivityDescription: 'Vul beschrijving van de activiteit in',
        ActivityTitleCannotBeEmpty: 'Titel van de activiteit mag niet leeg zijn',
        TheTitleCannotExceed200Characters: 'De titel mag niet langer zijn dan {num} tekens',
        PleaseEnterTheActivityDescription: 'Vul de beschrijving van de activiteit in',
        ActivityTimeCannotBeEmpty: 'Tijd activiteit mag niet leeg zijn',
        ActivityDescriptionLimitOf10000Words: 'Beschrijving van de activiteit mag maximaal {num} woorden tellen',
        ActivityRecommendation: 'Aanbeveling activiteit',
        Promotion: 'Promotie',
        EveryFull: 'Elke {num} vol',
        EnterAmount: 'Vul bedrag in',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Enkel positieve getallen kunnen ingevuld worden, tot twee cijfers na de komma, niet beginnend met 0',
        PleaseEnterTheCorrectAmount: 'Vul de correcte hoeveelheid in',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Selecteer geldige categorieën of producten',
        Rules: 'Regels',
        CopyLink: 'Kopieer link',
        DistributionNameProductIDBarcode: 'Distributienaam/product-ID/barcode',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'Deze functie is voorlopig niet beschikbaar in dit land, en zal later beschikbaar worden',
        PleaseEnterThePrice: 'Vul de prijs in',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Bereken automatisch na het ingeven van de korting',
        PleaseEnterTheQuantity: 'Vul het aantal in',
        OrderRemarkLabel: 'Label opmerkingen bij de bestelling',
        OrdersWorkWithInfluencers: 'Bestellingen werken met influencers',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: 'De huidige bruto winstmarge van de huidige actieprijs is',
        ThePriceCannotBeLowerThanTheLowestPrice: 'De prijs kan niet lager zijn dan de laagste prijs',
        Distribution: 'Distributie',
        IDName: 'ID/naam',
        ProductSelected: 'Geselecteerde product',
        ProductHasNoPricing: 'Product heeft geen prijs',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: 'De huidige bruto winstmarge van de huidige actieprijs is {rate} %, bent u zeker dat u wilt verzenden?',
        SubscriptionStatus: 'Status abonnement',
        URLClickToCopyAddress: 'URL (klik om het adres te kopiëren)',
        URLSourceManagement: 'URL Bronbeheer',
        PleaseEnterTheURL: 'Vul de URL in',
        PleaseEnterEnglishOrNumbers: 'Vul in het Engels of met cijfers in',
        LengthCannotExceed1000Characters: 'Niet meer dan 1000 tekens',
        URLFixedAddressIsEmpty: 'Vast URL-adres is leeg',
        cover: 'Cover',
        PleaseEnterOrPasteTheVideoScript: 'Plak of voeg het script van de video in',
        VideoScript: 'Videoscript',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Upload een videobestand of voeg het videoscript in',
        PleaseEnterTheBrand: 'Vul het merk in',
        Portuguese: 'Portugees',
        Register: 'Register',
        InviteUsers: 'Nodig gebruikers uit',
        OrderPayment: 'Bestelling betalen',
        SupportsMP4Forma500MB: 'Ondersteunt mp4-formaat, ≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'Er is geen product geselecteerd. Sluiten?',
        ExitFullScreen: 'Schermvullende weergave uitschakelen',
        FullScreen: 'Schermvullende weergave',
        Logout: 'Uitloggen',
        OnlySupportsFilesInFormat: 'Ondersteunt enkel bestanden in {xxx}-formaat',
        PleaseSetAllPromotionalProducts: 'Plaats alle producten in promotie',
        PleaseEnterTheWeight: 'Vul het gewicht in',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'Vaste prijs kan niet lager zijn dan de laagste prijs',
        CloseOthers: 'Sluit andere',
        CloseAll: 'Sluit alles',
        ImportSuccessful: 'Importeren succesvol',
        BatchRecharge: 'Batch herladen',
        RechargeEmail: 'E-mail herladen',
        RechargeCountry: 'Land herladen',
        Submit: 'Verzenden',
        RechargeSuccessful: 'Herladen succesvol',
        RechargeInformation: 'Informatie herladen',
        PleaseDownloadTheRechargeInformationFirst: 'Download eerst de herlaad-informatie',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'De gegevens voor batch herladen van de AIGO-munt kunnen niet leeg zijn',
        TheBatchRechargeDataCannotExceed1000Records: 'De gegevens voor batch herladen kunnen niet meer dan 1000 gegevens bevatten',
        ConfirmSubmission: 'Verzenden bevestigen',
        ExportFailed: 'Exporteren mislukt',
        PackingQuantity: 'Verpakkingshoeveelheid',
        Activity: 'Actie',
        PleaseSelectWhetherToEnable: 'Selecteer of dit toegelaten moet worden',
        OnlyOneProductCanBeSynchronized: 'Slechts één product kan gesynchroniseerd worden',
        SKUWithAQuantityOfNumDoesNotExist: 'SKU bestaat niet met hoeveelheid {num}',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU met hoeveelheid {num} komt niet overeen met de omzet-multiple',
        SalesMultipleDoesNotMatchCannotBeListed: 'Omzet-multiple komt niet overeen, kan niet opgelijst worden',
        SalesMultipleIsEmptyInSpain: 'Omzet-multiple is leeg in Spanje',
        SynchronizationOfPackageQuantityIsAbnormal: 'De synchronisatie van de verpakkingshoeveelheid is abnormaal',
        CannotContinueToMoveUp: 'Kan niet verder naar boven geplaatst worden',
        CannotContinueToMoveDown: 'Kan niet verder naar beneden geplaatst worden',
        SynchronizeThePackageQuantity: 'Synchroniseer de verpakkingshoeveelheid',
        ProductAssociationIDCannotBeEmpty: 'Productassociatie-ID mag niet leeg zijn',
        PackageQuantityCannotBeEmpty: 'Verpakkingshoeveelheid mag niet leeg zijn',
        PleaseEnterTheCorrectQuantityFormat: 'Vul het correcte hoeveelheidsformaat in',
        PleaseEnterSeparatedBy: 'Vul in (gescheiden door #)',
        PackageQuantity: 'Aantal per verpakking',
        LengthBetween1And140Characters: 'Lengte tussen 1 en 140 tekens',
        PleaseSelectTheVariantParametersFirst: 'Selecteer eerst de parameters van de versie',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'De parameter is verbonden met de versie, wees aandachtig',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Selecteer gegevens van dezelfde categorie, herselecteer',
        UpTo5VariantsCanBeSelected: 'Er kunnen tot 5 versies geselecteerd worden',
        NoVariant: 'Geen versie',
        VerificationFailedPleaseSelectTheVariant: 'Verificatie mislukt, selecteer de versie',
        VerificationFailedPleaseSelectTheProduct: 'Verificatie mislukt, selecteer het product',
        VerificationFailedTheProductParameterValueIsEmpty: 'Verificatie mislukt, de parameterwaarde van het product is leeg',
        SetSuccessfully: 'Succesvol geplaatst',
        UploadingVideo: 'Video uploaden',
        SearchParameters: 'Parameters zoeken',
        ImportantParameters: 'Belangrijke parameters',
        Select: 'Selecteer',
        ConfirmSettingThisImageAsTheMainImage: 'Bevestig de instelling van deze afbeelding als hoofdafbeelding',
        ConfirmDisplayDoNotDisplay: 'Bevestig weergeven/niet weergeven',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'De huidige limiet is {num1} bestanden. U hebt {num2} bestanden geselecteerd. Er zijn in totaal {num3} bestanden geselecteerd',
        ConfirmToRemoveAny: 'Bevestigen om {Any} te verwijderen?',
        TheTestEnvironmentCannotUploadFiles: 'De testomgeving kan geen bestanden uploaden',
        PleaseEnterTheEnglishTitle: 'Vul de Engelse titel in',
        CancelSettingAsTheMainImage: 'Instelling als hoofdafbeelding annuleren?',
        SetAsTheMainImage: 'Instellen als hoofdafbeelding?',
        CannotSubmitImagesOrVideosThatAlreadyExist: "Kan geen bestaande afbeeldingen of video's verzenden",
        SynchronizeAnyImagesToOtherLanguages: '{Any} afbeeldingen synchroniseren naar andere talen?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Selecteer een product om als eerste te synchroniseren',
        PleaseSelectTheProductFirst: 'Selecteer eerst het product',
        ConfirmWhetherToDeleteTheSelectedImageVideo: 'Geselecteerde afbeelding/video verwijderen?',
        TheMainPictureCannotBeTurnedOff: 'De hoofdafbeelding kan niet uitgezet worden',
        CancelDisplay: 'Vertoning annuleren?',
        ConfirmWhetherToDelete: 'Verwijderen bevestigen?',
        PleaseUploadTheEnglishImage: 'Upload de Engelse afbeelding',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Mislukt, vul de producttitel in voor alle verkooplanden',
        PresaleTime: 'Tijd voorverkoop',
        PresalePrice: 'Prijs voorverkoop',
        PleaseEnter: 'Vul in',
        PresaleQuantity: 'Hoeveelheid voorverkoop',
        DepositRatio: 'Stortingsratio',
        AssociatedAGBSOnthewayOrders: 'Geassocieerde AGBS-bestellingen onderweg',
        EstimatedDeliveryTime: 'Geschatte leveringstermijn',
        FinalPaymentDeadline: 'Deadline betaling',
        SelectTime: 'Selecteer moment',
        FixedPrice: 'Vaste prijs',
        ByProportion: 'Volgens verhouding',
        ByFullPayment: 'Volgens volledige betaling',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Enkel getallen groter dan 0 kunnen ingevoerd worden, tot twee cijfers na de komma',
        PresaleTimeCannotBeEmpty: 'Tijd voorverkoop mag niet leeg zijn',
        PresalePriceTypeCannotBeEmpty: 'Type prijs voorverkoop mag niet leeg zijn',
        PresaleQuantityCannotBeEmpty: 'Hoeveelheid voorverkoop mag niet leeg zijn.',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Enkel positieve getallen kunnen ingevoerd worden, niet beginnend met 0',
        DepositRatioTypeCannotBeEmpty: 'Type stortingsratio mag niet leeg zijn',
        DepositRatioCannotBeEmpty: 'Stortingsratio mag niet leeg zijn',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Enkel waarden tussen 0 en 100 mogen ingevoerd worden, uitgezonderd 0 en 100',
        EstimatedDeliveryTimeCannotBeEmpty: 'Geschatte leveringstermijn mag niet leeg zijn',
        FinalPaymentDeadlineCannotBeEmpty: 'Deadline betaling mag niet leeg zijn ',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'De betaling moet minstens 5 uur voor het einde van de voorverkoop-promotie gebeuren',
        TheRemainingAmount: 'Resterend bedrag',
        PresaleStatus: 'Voorverkoopstatus',
        PresaleStartTime: 'Start voorverkoop',
        PresaleEndTime: 'Einde voorverkoop',
        OnPresale: 'In voorverkoop',
        DirectVisit: 'Direct bezoek',
        BackendManagementSystem: 'Back-end beheersysteem',
        Login: 'Login',
        TipsFillInTheUsernameAndPasswordCasually: 'Tips: Vul de gebruiksersnaam en het wachtwoord willekeurig in.',
        PleaseEnterUserName: 'Vul gebruikersnaam in',
        PleaseEnterPassword: 'Vul uw wachtwoord in',
        LoginSuccessful: 'Login succesful',
        PleaseEnterYourAccountNumberAndPassword: 'Vul uw accountnummer en wachtwoord in',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'Enkel teruggave of omruilen van hetzelfde product is mogelijk.',
        B2CBrandWebsite: 'B2C Merkwebsite',
        CommentManagement: 'Opmerkingenbeheer',
        AIGOCoins: 'AIGO-munten',
        PleaseEnterThePackageQuantity: 'Voeg het aantal pakketten toe',
        PresalePriceCannotBeEmpty: 'Voorverkoopprijs mag niet leeg zijn',
        Product: 'Product',
        HomePageColumn: 'Kolom startpagina',
        ColumnDescription: 'Kolom beschrijving',
        FlashSaleArea: 'Zone voor bijzondere aanbiedingen',

        //1.2.8之后
        SoldOutOrNot: 'Uitverkocht of niet',
        VirtualQuantity: 'Aantal bij benadering',
        VirtualQuantityPackageQuantity: 'Aantal bij benadering/aantal per verpakking',
        CombinationIcon: 'Combinatiepictogram',
        UploadFailedFileSizeShouldBe3MB: 'Uploaden mislukt, het bestand moet <1MB zijn',
        PromotionQuantityPackageQuantity: 'Promotie-aantal/aantal per verpakking',
        RemainingTotal: 'Resterend/Totaal',
        RecommendedSize100: 'Aanbevolen afmetingen 100*100, ≤ 20 kB',
        TotalRemainingTotal: 'Totaal - Resterend/Totaal',
        Verification: 'Verificatie',
        VirtualSalesVolume: 'Verkoopvolume bij benadering',
        VirtualSalesVolumePackageQuantity: 'Verkoopvolume bij benadering/aantal per verpakking',
        DeleteOrNot: 'Verwijderen of niet?',
        EnabledOrNot: 'Ingeschakeld of niet',
        Cancel: 'Annuleren',
        Code: 'Code',
        Confirm: 'Bevestigen',
        RebateDimension: 'Korting dimensie',
        InProgress: 'Bezig',
        ActivityStatus: 'Activiteitsstatus',
        RebateRatioManagement: 'Beheer van kortingspercentages',
        StartTime: 'Starttijd',
        EndTime: 'Eindtijd',
        RebateAmount: 'Kortingsbedrag',
        NewUser: 'Nieuwe gebruiker',
        RebateObjectNumber: 'Kortingsartikelnummer',
        NumberOfCompletedOrders: 'Aantal voltooide bestellingen',
        RebateObject: 'Kortingsartikel',
        Operation: 'Bediening',
        RecentOperator: 'Recente exploitant',
        Country: 'Land',
        Prompt: 'Hint',
        RebateProducts: 'Kortingsproducten',
        NewUserPromotion: 'Nieuwe gebruikerspromotie',
        RegularUserPromotion: 'Regelmatige gebruikerspromotie',
        Upcoming: 'Aankomend',
        RebateRatio: 'Kortingspercentage',
        RebateOrNot: 'Korting of geen korting?',
        Rebate: 'Korting',
        PleaseSelectARebateDimension: 'Selecteer een korting dimensie',
        PleaseSelectARebateObject: 'Selecteer een kortingsartikel',
        PleaseSelectACountry: 'Selecteer alstublieft een land',
        PleaseSelectARebateRatio: 'Selecteer een kortingspercentage',
        RecentOperationTime: 'Recente bedrijfstijd',
        PromotionObjectType: 'Promotie artikeltype',
        ProductPromotionType: 'Type productpromotie',
        UserType: 'Gebruikerstype',
        ApplicableUserType: 'Toepasselijke gebruikerstype',
        RegularUser: 'Regelmatige gebruiker',
        UseAigoCoinsOrNot: ' Aigo-munten gebruiken of niet',
        EndDateOfMembership: 'Einddatum lidmaatschap',
        StartDateOfMembership: 'Ingangsdatum lidmaatschap',
        memberOrNot: 'lid of niet',
        No: 'Nee',
        Yes: 'Ja',
        LowInventory: 'Weinig voorraad',
        RechargeEmailbox: 'Postvak opnieuw laden',
        SubmittedSuccessfully: 'Succesvol ingediend',
        Importing: 'Importeren',
        InformationOfTheRechargeFailed: 'Storingsmelding',
        RechargeFailed: 'Opnieuw laden mislukt',
        RechargeInBatch: 'Batch opladen',
        RechargeSuccessfully: 'Opnieuw laden gelukt',
        RechargeAigoCoins: 'Aigo munten opnieuw laden',
        RequiredField: 'Verplicht veld',
        PDFFormat: 'PDF-indeling',
        FileSize3MB: 'Bestandsgrootte <3MB',
        UploadFailedPDFFormatRequired: 'Uploaden mislukt, PDF-indeling vereist',
        AuditFailedPleaseUploadInvoiceAttachment: 'Controle mislukt, upload factuurbijlage',
        PleaseUploadInvoiceAttachment: 'Upload factuurbijlage',
        InvoiceAttachment: 'Factuurbijlage',
        Upload: 'Uploaden',
        UploadFailed: 'Uploaden mislukt',

        HaveAnAccount: 'Nog geen account? Klik hier om te registreren',
        SoldOut: 'Uitverkocht',
        HaveAnInvitationCode: 'Optioneel, niet invullen als u geen uitnodigingscode heeft.',
        RestockedItems: 'Aanvullen van koopwaar',

        NotMeetPleaseEnterAgain: 'Uw gebruikersnaam voldoet niet aan de voorwaarden, vul opnieuw in.',
        ThisCouponIsOnlyAvailableForNewUsers: 'Deze coupon is enkel beschikbaar voor nieuwe gebruikers.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'Deze coupon is enkel beschikbaar voor bestaande gebruikers.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Enkel teruggave of omruilen van hetzelfde product is toegestaan.',
        With365DaysRemaining: '（Expires on 9/ 25/2024） with 365 days remaining',

        TheNicknameDoesNotMeet: 'The nickname does not meet the requirements, please enter a new one.',

        GoToView: 'Ga naar bekijken',
        DoNotRegisterRepeatedly: 'Gelieve niet herhaaldelijk te registreren',
        ValidityPeriod: 'Geldigheidsduur',
        RegisterSuccessfully: 'Succesvol geregistreerd',
        MemberRegistration: 'Ledenregistratie',
        RegistrationFailed: 'Registratie mislukt',
        OneYear: 'Een jaar',
        AddToShoppingCart: 'Toevoegen aan de winkelwagen',
        AddANewAddress: 'Voeg een nieuw adres toe',
        ZIPCode: 'Postcode*',

        EditYourAddress: 'Uw adres bewerken',
        Remove: 'Verwijderen',
        SetAsDefault: 'Instellen als standaard',
        FailedChangeDefaultAddress: 'Wijziging van standaardadres niet gelukt, probeer het later opnieuw',
        Fullname: 'Naam (voor- en achternaam)*',
        PhoneNumber: 'Telefoonnummer',
        DefaultAddressHasBeenChanged: 'Het standaardadres is gewijzigd',
        ViewComments: 'Bekijk de reacties',
        ClickToCheckLogisticsInfo: 'Klik om de logistieke info te bekijken',
        RestockingOfOutOfStockItems: 'Aanvullen van items die niet meer op voorraad zijn',
        ThisWeekRestockedItems: 'De aangevulde items van deze week',
        ProductListInTheShoppingCart: 'Productlijst winkelwagen',
        WarmReminderVideoResources: 'Opgelet: de videobronnen kunnen slechts één keer gedownload worden',
        PleaseLeaveYourMessage: 'Laat hier uw bericht achter',
        InvoiceManagement: 'Factuurbeheer',

        ReadmeFile: 'README-bestand',
        HelpCenter: 'Hulpcentrum',
        FileUpload: 'Bestand uploaden',
        Globalization: 'Globalisering',
        SalespersonProfile: 'Profiel verkoper',
        BatchSyncPage: 'Voorraadsynchronisatiepagina',
        CountryConfigurationPage: 'Landconfiguratiepagina',
        UserConsultation: 'Raadpleging van de gebruiker',
        PreSaleItems: 'Items in voorverkoop',
        EventManagement: 'Evenementbeheer',
        BlankPage: 'Lege pagina',
        ThereAreCurrentlyNoProductPromotions: 'Er zijn op dit moment geen producten in promotie',
        EditInventoryBottomLine: 'Bewerk het resultaat van de inventaris',
        SetAFixedPrice: 'Bepaal een vaste prijs',
        EnterUpTo500Entries: 'Voeg tot 500 dingen toe',
        CantRelateToYourself: 'Dit kan niet over jezelf gaan',
        PleaseSelectTheApplicableUserType: 'Selecteer het juiste soort gebruiker',
        TheActivityDescriptionLimitIs10000Words: 'De beschrijving van de activiteit mag maximaal 10 000 woorden lang zijn',
        EnabledStatus: 'Ingeschakelde status',
        Enable: 'Inschakelen',
        OperatingTime: 'Werkingstijd',
        NewUserPromotionEnabled: 'Promotie voor nieuwe gebruiker ingeschakeld',
        NewUserPromotionDisabled: 'Promotie voor nieuwe gebruiker uitgeschakeld',
        OldUserPromotionEnabled: 'Promotie voor oude gebruiker ingeschakeld',
        OldUserPromotionDisabled: 'Promotie voor oude gebruiker uitgeschakeld',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'De promotieprijs mag niet lager zijn dan de laagste prijs',
        AutomaticallyCalculateAfterEnteringPrice: 'Automatisch berekenen na het invoeren van de prijs',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'De promotieprijs voor nieuwe gebruikers is groter dan de promotieprijs voor oude gebruikers. Wilt u verdergaan?',
        PleaseSelectPromotionCountry: 'Selecteer een land voor de promotie',
        PromotionDiscount: 'Promotiekorting',
        PleaseEnterThePromotionalQuantity: 'Vul de hoeveelheid van de korting in',
        PleaseEnterVirtualQuantity: 'Vul de virtuele hoeveelheid in',
        NumberOfUserPurchases: 'Aantal aankopen door gebruikers',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Vul de aankooplimiet per gebruiker in',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Vul de aankooplimiet per bestelling in',
        QuantityGreaterThan0: 'Hoeveelheid groter dan 0',
        GrossProfitMarginCalculationResults: 'Uitkomst van de berekening van de bruto winstmarge',
        FixedPriceGrossMargin: 'Bruto winstmarge voor vaste prijs',
        SellingPriceGrossProfitMargin: 'Bruto winstmarge voor verkoopprijs',
        PromotionalPriceGrossProfitMargin: 'Bruto winstmarge voor promotieprijs',
        PromotionStartTime: 'Start van de promotie',
        PromotionEndTime: 'Einde van de promotie',
        PromotionType: 'Soort promotie',
        PleaseSetTheSKUToPromotionalProducts: 'Zet de SKU tot ${xxx} promotionele producten',
        DistributionOffers: 'Verdelingsaanbiedingen',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Nadat u het aantal {Any} ingevuld hebt, zal het automatisch berekend worden op basis van de hoeveelheid van het product.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: 'Het aantal aankopen moet groter zijn dan 0',
        PleaseSelectCountry: 'Selecteer een land ${typeName}',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Nadat u de promotiehoeveelheid ingevuld hebt, zal het berekend worden op basis van de hoeveelheid goederen.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: 'Annuleer eerst de associatie in [Kortingsproductenpool]',
        EditBottomLineInventory: 'Bewerk de inventarisresultaat',
        PleaseConfigureFixedPrice: 'Stel een vaste prijs in',
        BalancePayment: 'Saldo',
        Deposit: 'Storting',

        EndofValidity: 'Voer de vervaldatum in',
        Year: 'Jaar',
        Month: 'Maand',
        Day: 'Dag',
        UnitOfValidity: 'Eenheid van geldigheid',
        AIGOCoinDetails: 'AIGO munt details',
        AIGOCoinsAreCloseToExpiring: 'AIGO-munten zijn bijna verlopen(Dag)',
        CountryOfShipmentAddress: 'Land van het verzendadres',

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Verwachte levertijd',
        DataCorrespondingToTheSource: 'Data die overeenkomen met de bron',
        AccountStatus: 'Accountstatus',
        OrderID: 'Bestellings-ID',
        RecipientName: 'Naam ontvanger',
        RecipientLastName: 'Achternaam ontvanger',
        MailsOfUser: 'E-mail van gebruiker',
        StateProvince: 'Staat/Provincie',
        CityTown: 'Stad/Dorp',
        County: 'Gemeente',
        ReceivingCompany: 'Ontvangend bedrijf',
        BatchInput: 'Batchinvoer',
        InputManually: 'Manuele invoer',
        ProductCommentsInputTemplate: 'Sjabloon voor de invoer van opmerkingen over een product',
        UserName: 'Gebruikersnaam',
        Comments: 'Opmerkingen',
        StarRatingOfTheComment: 'Beoordeling van de opmerking',
        CommentTime: 'Moment van de opmerking',
        UploadAttachment: 'Voeg bijlage toe',
        NumberOfAttachments: 'Aantal bijlagen',
        FileName: 'Bestandsnaam',
        Appendix: 'Appendix',
        BatchInputOfProductComments: 'Batchinvoer van opmerkingen over een product',
        SoldOut1: 'Uitverkocht',
        PreSalePromotionID: 'Promotie-ID van voorverkoop',
        PromotionAbnormal: 'Abnormale promotie',
        Updater: 'Updater',
        AddPreSalePromotion: 'Voeg een voorverkooppromotie toe',
        EditPreSalePromotion: 'Bewerk de voorverkooppomotie',
        Barcode: 'Barcode',
        PreSaleCountries: 'Landen van voorverkoop',
        PreSaleDeposit: 'Storting voorverkoop',
        PleaseEnterTheDocumentCode: 'Vul de documentcode in',
        BalancePaymentDeadline: 'Deadline voor uitstaand saldo',
        FixedShippingFee: 'Vaste verzendkosten',
        PreSaleShippingFee: 'Verzendkosten in voorverkoop',
        FreeShippingSubjectToConditions: 'Gratis verzending onder voorwaarden',
        OrderPrice: 'Prijs van de bestelling',
        PleaseEnterPriceConditions: 'Vul de prijsvoorwaarden in',
        TheEndTimeNeedsToBeLaterThanTheStartTime: 'De eindtijd moet later zijn dan de begintijd',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'Er zijn al deels overlappende promoties voor dit land. Pas aan.',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'De voorverkoophoeveelheid kan enkel een positief geheel getal zijn groter dan 0',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: 'De geschatte levertijd moet later zijn dan de eindtijd van het evenement',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: "De deadline voor uitstaande saldo's moet vroeger zijn dan de geschatte levertijd",
        PromotionID: 'Promotie-ID',
        DistributionPromotionID: 'Verdelingspromotie-ID',
        CouponID: 'Coupon-ID',
        PromotionManagementID: 'Beheerpromotie-ID',
        PortfolioManagementID: 'Beheersportfolio-ID',
        ExportForFiltering: 'Uitvoer om te filteren',
        ListDisplay: 'Lijstweergave',
        InventoryItems: 'Producten in voorraad',
        InStock: 'Op voorraad',
        OutOfStock: 'Niet meer op voorraad',
        FieldName: 'Veldnaam',
        PleaseSelectInventory: 'Selecteer alstublieft voorraad',
        PreSaleEvent: 'Voorverkoop evenement',
        PerPage: 'Elke pagina toont',

        // V1.3.3 end

        // V1.3.4 start
        PleaseProvideTheNameOfTheLandingPageActivity: 'Vul de naam van de landingspagina-activiteit in.',
        PleaseIndicateTheNumberOfActivities: 'Vul het aantal activiteiten in.',
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: 'De hoeveelheid activiteit moet groter zijn dan de hoeveelheid weggegeven.',
        PleaseChooseATimeForTheEvent: 'Selecteer alstublieft een tijdstip voor het evenement.',
        ActivityNameExceedsLength: 'Activiteitennaam overschrijdt de maximale lengte.',
        ThisItemIsRestrictedToAMaximumOf10PerOrder: 'Dit artikel is beperkt tot 10 per bestelling.',
        PleaseChooseTheGiveawayAgain: 'Selecteer alstublieft opnieuw het weggevertje.',
        AnalysisOfGiveawayActivities: 'Analyse van weggeefacties',
        StatisticsOfGiveawayActivities: 'Statistieken van weggeefacties',
        PleaseEnterTheNameOfTheEvent: 'Vul de naam van het evenement in.',
        PleaseSelectTheActivityType: 'Kies het type activiteit.',
        PleaseCompleteTheActivityRating: 'Vul alstublieft de activiteitenbeoordeling in.',
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Configureer alstublieft de {Any} pre-sale-campagne voordat u het land configureert waar de pre-sale-items zullen worden geplaatst.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: 'Het bestelbedrag en het aantal gratis cadeautjes mag niet lager zijn dan de vorige categorie.',
        SelectImage: 'Selecteer afbeelding',
        GiftInformation: 'Geschenkeninformatie',
        Gifts: 'Geschenken',
        GiftsOfEndOfEvent: 'Afscheidsgeschenken na een evenement',
        CheckOutTheGifts: 'Bekijk de geschenken',
        GiveawayEvent: 'Giveaway-evenement',
        SelectAGift: 'Selecteer een geschenk',
        YouHaveNotSelectedAGift: 'U hebt nog geen geschenk geselecteerd',
        NoNeedForGifts: 'Geen behoefte aan geschenken',
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Sommige geschenken zijn niet meer in voorraad, selecteer andere geschenken',
        ReplaceAGift: 'Vervang een geschenk',
        ThereAreCurrently3FreeGiftsAvailable: 'Er zijn op dit moment 3 gratis geschenken beschikbaar',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Er zijn op dit moment 3 gratis geschenken beschikbaar, en 4 andere geschenken zijn beschikbaar voor een aankoop van €99',
        GiftList: 'Geschenkenlijst',
        GiftID: 'Geschenken-ID',
        CurrentInventory: 'Huidige inventaris',
        NotEnabled: 'Niet toegestaan',
        GoodsForFree: 'Gratis voorwerpen',
        ConfirmToDeleteThisGiftInformation: 'Deze geschenkeninformatie verwijderen?',
        GiftsSortingManagement: 'Beheer voor het sorteren van geschenken',
        GiftsTopicManagement: 'Beheer voor de onderwerpen van geschenken',
        GiveawayEventID: 'Giveaway-evenement-ID',
        GiveawayEventName: 'Giveaway-evenementnaam',
        ProductCategory: 'Productcategorie',
        ActivityMode: 'Activiteitenstand',
        TotalNumberOfActivities: 'Totaal aantal activiteiten',
        GiftsGivenQuantity: 'Hoeveelheid gegeven geschenken ',
        ThemeConfiguration: 'Thema configureren',
        GiftSorting: 'Geschenken sorteren',
        GlobalConfiguration: 'Algemene configuratie',
        ActiveCountry: 'Actief land',
        PromotionalPpicture: 'Promotie-afbeelding',
        RecommendedSizeX: 'Aanbevolen grootte: {XXX*XXX}',
        FileSize1MB: 'Bestandsgrootte: ≤1MB',
        ActivitySorting: 'Activiteiten sorteren',
        DragToSort: 'Slepen om te sorteren',
        ActivityCode: 'Activiteitencode',
        GiftsForOrderAmount: 'Hoeveelheid geschenken voor bestelling',
        ActivityRating: 'Beoordeling van de activiteit',
        GiftsQuantity: 'Hoeveelheid geschenken',
        EventGiftsSorting: 'Geschenken voor het evenement sorteren',
        DefaultSelectionOfUsers: 'Standaardgebruikersselectie',
        LandingPageDisplay: 'Afbeelding voor de landingspagina',
        LandingPageActivityName: 'Activiteitennaam voor de landingspagina',
        ConfirmDeleteThisGiftEvent: 'Dit geschenkenevenement verwijderen?',
        IsThereAGiftEvent: 'Is er een geschenkevenement?',
        OrderQuantity: 'Bestellingshoeveelheid',
        NumberOfGiftsIssued: 'Aantal gegeven geschenken ',
        CustomerPrice: 'Klantenprijs',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: 'De hoeveelheidsfunctie voor vituele evenementen kan enkel ingesteld worden als de tijd voor de voorverkoopactiviteit meer dan 2 dagen is en het aantal evenementen groter dan 50',
        PreSaleSwitch: 'Voorverkoopschakelaar',
        PreSaleActivity: 'Voorverkoopactiviteit',
        GiftManagement: "Cadeau beheer",

        // V1.3.4 end

        //V1.3.5 start
        OrderSource: "Bestellingsbron",
        NumberOfNewUserRegistrations: "Aantal nieuwe gebruikersregistraties",
        RegisteredUsersWhoVsitTheWebsite: "Geregistreerde gebruikers die de website bezoeken",
        SoldDistributionQuantity: "Verkochte distributiehoeveelheid",
        DistributionQuantityLock: "Distributiehoeveelheidsslot",
        InsufficientDstributionQuantityRemaining: "Onvoldoende resterende distributiehoeveelheid",
        Previous: "Terug",
        Next: "Volgende",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Receptbeheer",
        RecipeDisplayImage: "Receptafbeelding",
        NewRecipeDisplayImage: "Bewerk/creëer en nieuwe receptafbeelding",
        CoverPicture: "Omslagafbeelding",
        PicturesVideos: "Er kunnen tot 10 afbeeldingen + video's geüpload worden. Elke afbeelding moet ≤5MB zijn, en video's in .mp4-formaat worden ondersteund",
        AddPicturesVideos: "Voeg afbeeldingen/video's toe",
        CommenOnPicturesVideos: "Zet een opmerking bij afbeeldingen/video's",
        FileSize: "Bestandsgrootte",
        BarcodeAssociated: "Bijhorende barcode",
        FirstUploadTime: "Moment van eerste upload",
        EditTime: "Moment van bewerken",
        TagType: "Type tag",
        FileName1: "Bestandsnaam",
        NameLengthISTooLong: "De naam is te lang",
        ProductDoesNoExist: "Het product bestaat niet",
        PleaseUploadImages: "Upload afbeeldingen kleiner dan 5MB in jpg/png/jpeg-formaat",
        PleaseUploadTheVideoMP4: "Upload de video in mp4-formaat",
        PictureOrVideo: "Afbeelding of video mag niet leeg zijn",
        allowed: "Alles toestaan",
        cookie1: "Cookiesbeheer",
        collectsordinances: "Aigostar gebruikt cookies om de gebruikservaring voor bezoekers te verbeteren en voor marketingdoeleinden. Bekijk en beheer uw cookie-instellingen onderaan. Voor meer informatie over hoe we cookies gebruiken, kan u onze privacyverklaring lezen.",
        RequiredCookies: "Noodzakelijke cookies",
        cookie2: "Deze cookies zijn vereist voor de basisfuncties, zoals inloggen, de winkelkar gebruiken en betalen. Noodzakelijke cookies kunnen niet uitgeschakeld worden. Maar maak u geen zorgen, deze cookies bewaren geen gegevens van u.",
        AnalyticsCookies: "Marketing- en analytische cookies",
        cookie3: "Deze cookies verzamelen informatie, zoals het aantal bezoeken aan de website, of welke pagina's er populair zijn, om ons te helpen onze klantenervaring te verbeteren. U kan ervoor kiezen om deze cookies uit te schakelen, maar dan kunnen we niet de informatie verzamelen die we nodig hebben om uw ervaring op onze site te verbeteren.",
        StoragePreferences: "Voorkeuren opslaan",
        BuyItNow: "Nu kopen",
        PictureVideoSize: "Beeld- of videogrootte of -formaat is niet correct",
        RecipeConfiguration: "Recept Configuratie",
        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Bevestig synchronisatie van subbarcodes?",
        SynchronizationEbpEanSUB: "Vanwege de noodzaak om te interfacen met EBP, wordt verwacht dat batch-synchronisatie binnen 3 minuten is voltooid. Na bevestiging zal de synchronisatie asynchroon verlopen en worden de sub-barcodes onmiddellijk na ontvangst weergegeven. Gelieve geduld te hebben.",
        Keywords: "Trefwoorden",
        SystemAutoUpdate: "Systeem automatische update",
        IPAddress: "IP-adres",
        SearchKeywords: "Zoek trefwoorden",
        LoginEmail: "Inlog e-mail",
        SearchTime: "Zoektijd",
        NumbeOfTimes: "Aantal keren",
        MyBrowsing: "Mijn browsegeschiedenis",
        LoggingOuOfYouAccount: "Uitloggen uit uw account wordt beschouwd als uw vrijwillige afstand en u zult dit account niet langer kunnen blijven gebruiken.",
        RequestLogout: "Verzoek om uit te loggen",
        ProductsYet: "Je hebt nog geen producten bekeken",
        clearall: "Geschiedenis wissen",
        CancellTip1: "Winkelwagenlijsten, bestelrecords en favorietenrecords worden permanent verwijderd en kunnen niet worden hersteld.",
        CancellTip2: "Alle accountinformatie, servicegegevens, consumptiegegevens, etc., op het platform worden permanent verwijderd en kunnen niet worden hersteld.",
        CancellTip3: "U kunt niet meer inloggen of dit AigoSmart-account gebruiken.",
        CancellTip4: "Persoonlijke informatie en historische berichten (inclusief avatar, bijnaam, mijn Aiguo-valuta, platformmeldingen, mijn opmerkingen, enz.) die zijn gekoppeld aan uw AigoSmart-account zijn niet herstelbaar.",
        CancellTip5: "U moet accountfondsen (punten, lidmaatschapsniveaus, kwalificaties, bestellingen, coupons, rekwisieten en andere waardevolle diensten) correct beheren voordat u uitlogt, aangezien accountannulering wordt beschouwd als uw vrijwillige afstand en niet kan worden teruggedraaid. U begrijpt en stemt ermee in dat AigoSmart u niet kan helpen bij het herstellen van de hierboven genoemde diensten.",
        SynchronizeSubBarcodes: "Sub-barcodes synchroniseren",
        PleaseEnterKeywords: "Voer alstublieft zoekwoorden in",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Uurlijkse statistieken",
        NumbeOfUsers: "Aantal gebruikers die bestellingen hebben geplaatst",
        NumberOfCanceled: "Aantal geannuleerde bestellingen",
        RefundAmount: "Restitutiebedrag",
        UsersWhoHavePaid: "Gebruikers die hebben betaald (bestellingen die kunnen worden verzonden, verzonden, voltooid)",
        TotalNumber: "Totaal aantal geannuleerde bestellingen door het platform",
        AmountOfCanceled: "Bedrag van geannuleerde bestellingen die zijn betaald maar niet verzonden, bedrag van geretourneerde bestellingen die zijn betaald (in transit, geleverd)",
        RedemptionCode: "ID/Couponnaam/Inwisselcode",
        DeleteAll: "Verwijderen",
        AccountIsUnderReview: "Het huidige account bevindt zich in de deactiveringscontrole. Als u blijft inloggen, wordt het deactiveringsproces geannuleerd. Wilt u het blijven gebruiken?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "De tijd mag niet leeg zijn",
        ProductImporTemplate: "Productimporttemplate",
        NormalOrdersExceed: "Normale bestellingen overschrijden:",
        OrderSautomaticallyClosed: "Onbetaalde bestellingen worden automatisch gesloten",
        OrdeIsShipped: "Nadat de bestelling is verzonden:",
        AutomaticallyComplete: "Bestelling automatisch voltooien",
        Minutes: "Minuten",
        Days: "Dagen",
        OrderSettings: "Bestelinstellingen",
        ClearCache: "Cache wissen",
        PleaseEnterNavigationContent: "Voer navigatie-inhoud in",
        WebsiteNavigationConfiguration: "Website-navigatieconfiguratie",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Pop-up vertragingstijd",
        SubscriptionPopup: "Abonnement pop-up",
        MeansNoPopupDelay: "Voer de vertragingstijd van het pop-upvenster in",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "UGC-gebied",

        //V1.5.0 end
        //V1.6.0 start
        uploaFilesInJpg: "Upload alleen bestanden in jpg/png-formaat kleiner dan 2 M",
        SynchronizeEbpPrice: "Synchroniseer EBP-prijs",
        ConfirmToSynchronizeEbp: "Bevestig de gesynchroniseerde EBP-prijs?",
        AsynchronousEbp: "Na bevestiging verloopt de synchronisatie asynchroon. De prijs wordt direct weergegeven nadat deze is verkregen. Wacht even geduldig.",
        CopywritingDisplay: "Copywriting weergeven",
        HomepageCopywriting: "Copywriting op startpagina",
        EditHomepageCopywriting: "Copywriting op startpagina bewerken",
        SuperimposedEvents: "Of evenementen overlappen?",
        GiftsEvents: "(De geschenken voor dit evenement kunnen niet samen met andere evenementen worden verzameld)",
        SmallGiftEvent: "Klein geschenkevenement",
        SpecialProductEvent: "Speciaal productevenement",
        SpecialGiftEvent: "Gratis geschenken van een klein geschenkevenement en speciaal evenement kunnen niet samen worden verzameld",
        ProductsWithSKU: "Selecteer producten met een SKU",
        LimitWereSelected: "De huidige limiet is {limitNum} bestanden. Deze keer zijn er {selectNum} bestanden geselecteerd.",
        FileUploadFailed: "Bestandsupload mislukt!",
        CompleteMultiLanguage: "Voltooi de meertalige configuratie",
        FirstSynchronizeSKU: "Synchroniseer eerst de SKU van dit product.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Moet de coupon openbaar worden gemaakt?",
        RedemptionCode1: "Inwisselcode",
        NowOnlyNeed: "Nu alleen nog €10 nodig",
        WebLogReporting: "Weblograpportage",
        PageAddress: "Pagina-adres",
        ErrorLogs: "Foutenlogboek",
        CustomParameter: "Aangepaste Parameter",
        ParameterID: "Parameter-ID",
        // ParameterName: "Parameternaam",
        SearchParameter: "Zoekparameter",
        EnterParameterName: "Voer de parameternaam in",
        ParameterType: "Parametertype",
        AssociatedProductCount: "Aantal Geassocieerde Producten",
        MandatoryPortugueseContent: "Verplichte Portugese inhoud",
        MandatoryEnglishContent: "Verplichte Engelse inhoud",
        MandatoryDutchContent: "Verplichte Nederlandse inhoud",
        MandatoryPolishContent: "Verplichte Poolse inhoud",
        MandatoryFrenchContent: "Verplichte Franse inhoud",
        MandatoryGermanContent: "Verplichte Duitse inhoud",
        MandatoryItalianContent: "Verplichte Italiaanse inhoud",
        MandatorySpanishContent: "Verplichte Spaanse inhoud",
        MandatoryChineseContent: "Verplichte Chinese inhoud",
        ImportTemplate: "Sjabloon voor productimport met aangepaste parameters",
        ConfirmImportFile: "Bevestig importbestand, doorgaan?",
        SelectDataRemove: "Selecteer te verwijderen gegevens",
        ImportTemplateDownload: "Importsjabloon downloaden",
        AssociatedProducts: "Gerelateerde producten",


        //V1.7.0 end

        //V1.8.0 start
        Published: "Gepubliceerd",
        CanBeIgnored: "Kan worden genegeerd",
        Resolved: "Opgelost",
        ActivityType: "Activiteitstype",
        PromotionActivity: "Promotieactiviteit",
        DistributionActivity: "Distributieactiviteit",
        InputLink: "Voer link in",
        EnterAddress: "Voer het linkadres in",
        DetailPageAd: "Advertentieplek op de detailpagina",

        //V1.8.0 end


        //V1.9.0 start
        ProductPromotionID: "Productpromotie-ID.",
        PromotionalActivityCollection: "Collectie van promotionele activiteiten.",
        PromotionalActivityName: "Naam van promotionele activiteit.",
        ProductPromotionBarcode: "Productpromotie-ID/Barcode.",
        ProductPromotionIDCopy: "Productpromotie-ID (dubbelklik om alle ID's te kopiëren).",
        ProductBarcode: "Productbarcode (dubbelklik om alle barcodes te kopiëren).",
        SelectPromotionalActivity: "Selecteer promotionele activiteit.",
        SelectPromotionalList: "Selecteer promotielijst.",
        TypesPerOrder: "Beperk het aantal artikeltypen per bestelling.",
        PromotionalActivity: "Selecteer een promotionele activiteit.",
        ProductAlready: "Dit product heeft al een promotionele activiteit. Ga verder met bewerken.",
        PromotionalActivityNameID: "Promotienaam/ID",
        PromotionalCollectionName: "Naam van de promotionele collectie.",
        SelectedPromotionalActivity: "Geselecteerde promotionele activiteit.",
        PromotionalCollectionActivit: "Er bestaat al een promotionele verzamelactiviteit. Ga verder met bewerken.",

        EnterHholidayTheme: "Voer een feestthema in.",
        HolidayTheme: "Feestthema.",
        HolidayImage: "Feestafbeelding.",
        AddLogoImage: "Voeg logo-afbeelding toe.",
        PleaseProceedEdit: "Ga verder met bewerken.",
        SKUBIDActivities: "SKU: {sku}, er zijn al {country} activiteiten onder {activity}.",
        LogImageManagement: "Logo-afbeeldingsbeheer",
        LogoManagement: "Logobeheer",

    //V1.9.0 end

    }
};

export default nl;