import nbLocal from 'element-ui/lib/locale/lang/nb-NO';
const nb = {
    ...nbLocal,
    common: {
        //11-22
        AssociatedOrderNumber: '关联订单号',
        recordId: '记录id',

        UserAccess: 'Brukertilgang',
        PleaseSelectTheStatisticalType: 'Vennligst velg type statistikk',
        StatisticalByDate: 'Statistikk etter dato',
        StatisticalBySource: 'Statistikk etter kilde',
        Date: 'Dato',
        Visits: 'Besøk',
        NewVisitors: 'Nye besøkende',
        OrderStatistics: 'Bestillingsstatistikk',
        Sales: 'Salg',
        TotalOrders: 'Totalt antall bestillinger',
        AverageOrderAmount: 'Gjennomsnittlig ordrebeløp',
        SynchronizeEBP: 'Synkronisere EBP',
        ParameterName: 'Navn på parameter',
        OnTheShelf: 'På hyllen',
        OfflineShoppingCart: 'Offline handlekurv',
        AddToCart: 'Legg i handlekurv',
        EnterCheckout: 'Gå til kassen',
        UserLogin: 'Brukerinnlogging',
        UserRegistration: 'Brukerregistrering',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Ulike IP-nummer for å legge varer i handlekurven',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Ulike IP-nummer for å gå inn på ordrebekreftelsessiden',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Ulike IP-nummer for de som fullførte påloggingsprosessen etter å ha blitt omdirigert fra den frakoblede handlekurven til påloggingssiden.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Ulike IP-nummer for de som fullførte registreringsprosessen etter å ha blitt omdirigert fra den frakoblede handlekurven til registreringssiden.',
        UserBehavior: 'Brukeratferd',
        Display: 'Visning',
        DoNotDisplay: 'Ikke vis',
        AddToPresale: 'Legg til i forhåndssalget',
        CancelPresale: 'Avbryt forhåndssalg',
        PresaleConfiguration: 'Konfigurasjon av forhåndssalg',
        OffTheShelf: 'Fra hyllen',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Vennligst fyll ut produktinformasjonen før du konfigurerer forhåndssalget',
        SynchronizedEBPPrompts: 'På grunn av behovet for synkronisering med EBP kan batch-synkronisering bare utføres én gang i løpet av X minutter for å unngå feil. Etter bekreftelse utføres synkroniseringen asynkront, og SKU-en vises umiddelbart etter at den er innhentet. Vennligst vær tålmodig.',
        PleaseWait: 'Vennligst vent',
        PleaseSelectATag: 'Vennligst velg en tagg',
        Updating: 'Oppdatering',
        Abortive: 'Mislykket',
        DefaultRule: 'Standardregel',
        BottomInventory: 'Lagerbeholdning nederst',
        SynchronisedAlertsForGraphicDetails: 'Grafiske detaljer: Synkroniser [produktnøkkelord] for det aktuelle språket i henhold til språket',
        SynchronisedAlertsForLargeProductImages: 'Produktbilde: Synkroniser det tilsvarende språkets bilde i henhold til språket, les EBP-bildesorteringen og angi automatisk det første EBP-bildet som hovedbilde',
        Account: 'Konto',
        Account1: 'Konto',
        EnterUpToNumItems: 'Legg inn opptil {num} artikler',
        SynchronizedSuccessfully: 'Vellykket synkronisering',
        SuccessfullyListed: 'Vellykket oppført',
        SearchSuccessful: 'Vellykket søk',
        EnterNewPassword: 'Angi nytt passord',
        OnlyNumbersAndLettersAreAllowed: 'Bare tall og bokstaver er tillatt',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: 'Vennligst fullfør forhåndssalgskonfigurasjonen før du fører opp forhåndssalgsvaren.',
        ProductWeight: 'Produktets vekt',
        ConfirmExecution: 'Bekrefte utførelse?',
        ConfirmBatchListing: 'Bekrefte batch-oppføring?',
        Template: 'Mal',
        PleaseSelectTheProductsToBeSynchronized: 'Velg produktene som skal synkroniseres',
        ForcedSynchronisationDescription: 'Tvungen synkronisering vil slette de endrede dataene og erstatte dem med synkroniserte data. Vanlig synkronisering synkroniserer ikke de endrede produktdataene.',
        ConfirmSynchronizationExecution: 'Bekreft utførelse av synkronisering',
        SynchronisationHasBeenSentPleaseWait: 'Meldingen om synkroniseringsoppgaven er sendt, vent tålmodig til datasynkroniseringen er fullført.',
        ProhibitLogin: 'Forby innlogging',
        IsTheUserLoggedIn: 'Er brukeren logget inn?',
        Prohibit: 'Forby',
        ProhibitedSuccessfully: 'Vellykket forbudt',
        EnableLogin: 'Aktiver innlogging',
        EnabledSuccessfully: 'Vellykket aktivering',
        OperationCanceled: 'Operasjon avbrutt',
        UserData: 'Brukerdata.xlsx',
        GraphicDetails: 'Grafiske detaljer',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'Dette produktet er angitt som et kombinasjonsprodukt og kan ikke konfigureres til forhåndssalg. Kan du fjerne produktet fra kombinasjonen først?',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'Dette produktet er et kampanjeprodukt og kan ikke konfigureres til forhåndssalg. Vennligst avbryt kampanjen først.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'Dette produktet er et distribusjonsprodukt og kan ikke konfigureres til forhåndssalg. Vennligst avbryt distribusjonen først.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'Dette produktet har allerede en full reduksjonsaktivitet og kan ikke legges til.',
        ESNoPriceNotAvailableForPresale: 'Dette produktet har ikke en pris i {Any} og kan ikke legges til som forhåndssalg. Fjern først det aktuelle landet eller synkroniser prisen for det aktuelle landet før du legger til forhåndssalgsprodukter!',
        ConfirmSettingThisProductAsAPresaleItem: 'Bekrefte at dette produktet konfigureres som forhåndssalg?',
        PresaleAddedSuccessfully: 'Forhåndssalg lagt til vellykket',
        ConfirmSettingThisProductAsANonpresaleItem: 'Bekrefte at dette produktet ikke konfigureres som forhåndssalg?',
        CancelPresaleSuccessful: 'Avbryt forhåndssalg vellykket',
        Preview: 'Forhåndsvisning',
        TheInventoryOfCurrentProducts0Continue: 'Lagerbeholdningen for nåværende produkt {stock} er 0. Fortsette?',
        TheInputContentCannotExceedCharacters: 'Innholdet kan ikke overstige {x} tegn',
        PleaseSelectTheActivity: 'Vennligst velg aktivitet',
        OrderDetails: 'Bestillingsinformasjon',
        LogisticsInformation: 'Logistikkinformasjon',
        CancelRemark: 'Merknad om avbestilling',
        DepositAmount: 'Innskuddsbeløp',
        FinalPaymentAmount: 'Endelig betalingsbeløp',
        FullReductionDiscount: 'Full rabatt',
        YourBrowserDoesNotSupportVideoPlayback: 'Nettleseren din støtter ikke videoavspilling',
        EditBanner: 'Rediger banner',
        AddBanner: 'Legg til banner',
        UpdateTime: 'Oppdater tid',
        HomepageRecommendationPosition: 'Anbefalt posisjon på hjemmesiden',
        ActivityID: 'Aktivitets-ID',
        PleaseSelectThePosition: 'Vennligst velg posisjon',
        PleaseSelectTheJumpType: 'Vennligst velg hopptype',
        PleaseEnterTheAssociatedID: 'Vennligst skriv inn den tilknyttede ID-en',
        TheUploadedImageSizeCannotExceed5MB: 'Størrelsen på det opplastede bildet kan ikke overstige {num} MB!',
        OnlyMp4FormatIsSupportedForVideoUpload: 'Kun mp4-format støttes for opplasting av video',
        TheUploadedVideoSizeCannotExceed500MB: 'Den opplastede videostørrelsen kan ikke overstige {num} MB!',
        PleaseUploadTheVideo: 'Vennligst last opp videoen',
        PleaseEnterTheJumpLink: 'Vennligst skriv inn hopplenken',
        PleaseEnterTheCorrectLink: 'Vennligst skriv inn riktig lenke',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'Verifisering mislyktes. Vennligst fyll inn de obligatoriske feltene først',
        AddFailed: 'Tillegging mislyktes',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: 'Det er allerede den første, og oppadgående bevegelse mislyktes',
        PleaseEnterTheRebateRatio: 'Vennligst skriv inn rabattforholdet',
        EditCoupon: 'Rediger kupong',
        AddCoupon: 'Legg til kupong',
        IDCouponName: 'ID/kupongnavn/Innløsningskode',
        Random: 'Tilfeldig',
        Fixed: 'Fast',
        AboutToTakeeffect: 'I ferd med å tre i kraft',
        NotStackable: 'Kan ikke stables',
        Stackable: 'Kan stables',
        Frozen: 'Frosset',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Vennligst skriv inn det maksimale fradragsbeløpet. 0 betyr ubegrenset.',
        UseUserID: 'Bruk bruker-ID',
        UseOrderNumberExchangeCodeUserID: 'Bruk ordrenummer/byttekode/bruker-ID',
        PleaseEnterTheDiscount: 'Vennligst skriv inn rabatten',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Bare tall fra 0 til 100 kan angis, med opptil 1 desimal.',
        PleaseEnterTheFullReductionAmount: 'Vennligst oppgi hele rabattbeløpet',
        PleaseEnterTheExchangeCode: 'Vennligst skriv inn byttekoden',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Bare tall/bokstaver kan angis, minst 3 sifre, opptil 20 sifre.',
        PleaseSelectAFixedExchangeCode: 'Vennligst velg en fast vekslingskode',
        PleaseEnterTheMaximumDeductionAmount: 'Vennligst oppgi maksimalt fradragsbeløp',
        PleaseSelectWhetherItCanbeStacked: 'Vennligst velg om det kan stables',
        WarehousingTime: 'Lagringstid',
        EnterActivityDescription: 'Legg inn aktivitetsbeskrivelse',
        ActivityTitleCannotBeEmpty: 'Aktivitetens tittel kan ikke være tom',
        TheTitleCannotExceed200Characters: 'Tittelen kan ikke overstige {num} tegn',
        PleaseEnterTheActivityDescription: 'Vennligst skriv inn aktivitetsbeskrivelsen',
        ActivityTimeCannotBeEmpty: 'Aktivitetstiden kan ikke være tom',
        ActivityDescriptionLimitOf10000Words: 'Aktivitetsbeskrivelsen kan ikke overstige {num} ord',
        ActivityRecommendation: 'Anbefaling av aktivitet',
        Promotion: 'Reklame',
        EveryFull: 'Hver {num} hele',
        EnterAmount: 'Angi beløp',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Kun positive tall kan legges inn, opptil 2 desimaler, kan ikke starte med 0.',
        PleaseEnterTheCorrectAmount: 'Vennligst skriv inn riktig beløp',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Velg aktuelle kategorier eller aktuelle produkter',
        Rules: 'Regler',
        CopyLink: 'Kopier lenke',
        DistributionNameProductIDBarcode: 'Distribusjonsnavn/produkt-ID/strekkode',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'Denne underfunksjonen er foreløpig ikke tilgjengelig i dette landet, men vil bli tilgjengelig senere',
        PleaseEnterThePrice: 'Vennligst skriv inn prisen',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Beregn automatisk etter at rabatten er angitt',
        PleaseEnterTheQuantity: 'Vennligst skriv inn antall',
        OrderRemarkLabel: 'Etikett for bestillingsanmerkning',
        OrdersWorkWithInfluencers: 'Ordrer fungerer med påvirkere',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: 'Den nåværende bruttofortjenestemarginen for den nåværende aktivitetsprisen er',
        ThePriceCannotBeLowerThanTheLowestPrice: 'Prisen kan ikke være lavere enn den laveste prisen:',
        Distribution: 'Distribusjon',
        IDName: 'ID/navn',
        ProductSelected: 'Produkt valgt',
        SelectedProduct: 'Valgt produkt',
        ProductHasNoPricing: 'Produktet har ingen priser',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: 'Den nåværende bruttofortjenestemarginen for den nåværende aktivitetsprisen er {rate}%, er du sikker på at du vil sende inn?',
        SubscriptionStatus: 'Status for abonnement',
        URLClickToCopyAddress: 'URL (klikk for å kopiere adressen)',
        URLSourceManagement: 'URL-kildeadministrasjon',
        PleaseEnterTheURL: 'Vennligst skriv inn URL-adressen',
        PleaseEnterEnglishOrNumbers: 'Vennligst skriv inn engelsk eller tall',
        LengthCannotExceed1000Characters: 'Lengden kan ikke overstige 1000 tegn',
        URLFixedAddressIsEmpty: 'URL-adressen er tom',
        cover: 'Omslag',
        PleaseEnterOrPasteTheVideoScript: 'Vennligst skriv inn eller lim inn videoskriptet',
        VideoScript: 'Videomanus',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Last opp en videofil eller fyll ut videoskriptet',
        PleaseEnterTheBrand: 'Vennligst skriv inn varemerket',
        Portuguese: 'Portugisisk',
        Chinese: 'Kinesisk',
        Register: 'Registrer deg',
        InviteUsers: 'Inviter brukere',
        OrderPayment: 'Bestill betaling',
        SupportsMP4Forma500MB: 'Støtter mp4-format, ≤500 MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'Det er ikke valgt noe produkt for øyeblikket. Skal det lukkes?',
        ExitFullScreen: 'Avslutt fullskjerm',
        FullScreen: 'Full skjerm',
        OnlySupportsFilesInFormat: 'Støtter kun filer i {xxx}-format',
        PleaseSetAllPromotionalProducts: 'Vennligst angi alle kampanjeprodukter',
        PleaseEnterTheWeight: 'Vennligst oppgi vekt',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'Fast pris kan ikke være lavere enn laveste pris',
        CloseOthers: 'Lukk andre',
        CloseAll: 'Lukk alle',
        ImportSuccessful: 'Import vellykket',
        BatchRecharge: 'Batch-lading',
        RechargeEmail: 'E-post for opplading',
        RechargeCountry: 'Land for opplading',
        Submit: 'Send inn',
        RechargeSuccessful: 'Opplading vellykket',
        RechargeInformation: 'Informasjon om opplading',
        PleaseDownloadTheRechargeInformationFirst: 'Vennligst last ned oppladningsinformasjonen først',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'Batch-oppladningsdataene for AIGO-mynten kan ikke være tomme',
        TheBatchRechargeDataCannotExceed1000Records: 'Batch-oppladningsdataene kan ikke overstige 1000 poster',
        ConfirmSubmission: 'Bekreft innsending',
        ExportFailed: 'Eksporten mislyktes',
        PackingQuantity: 'Emballasjekvantitet',
        Activity: 'Aktivitet',
        PleaseSelectWhetherToEnable: 'Velg om du vil aktivere',
        OnlyOneProductCanBeSynchronized: 'Bare ett produkt kan synkroniseres',
        SKUWithAQuantityOfNumDoesNotExist: 'SKU med et antall på {num} finnes ikke',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU med et antall på {num} oppfyller ikke salgsmultiplikatoren',
        SalesMultipleDoesNotMatchCannotBeListed: 'Salgsmultiplum stemmer ikke overens, kan ikke vises',
        SalesMultipleIsEmptyInSpain: 'Salgsmultiplum er tomt i Spania',
        SynchronizationOfPackageQuantityIsAbnormal: 'Synkroniseringen av pakkeantall er unormal',
        CannotContinueToMoveUp: 'Kan ikke fortsette å flytte opp',
        CannotContinueToMoveDown: 'Kan ikke fortsette å gå nedover',
        SynchronizeThePackageQuantity: 'Synkroniser pakkeantallet',
        ProductAssociationIDCannotBeEmpty: 'Produkttilknytnings-ID kan ikke være tom',
        PackageQuantityCannotBeEmpty: 'Pakkeantallet kan ikke være tomt',
        PleaseEnterTheCorrectQuantityFormat: 'Vennligst skriv inn riktig antallsformat',
        PleaseEnterSeparatedBy: 'Vennligst skriv inn (atskilt med #)',
        PackageQuantity: 'Antall i pakken',
        LengthBetween1And140Characters: 'Lengde mellom 1 og 140 tegn',
        PleaseSelectTheVariantParametersFirst: 'Vennligst velg variantparametrene først',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'Parameteren er knyttet til varianten, vær forsiktig',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Vennligst velg data i samme kategori, velg på nytt',
        UpTo5VariantsCanBeSelected: 'Du kan velge opptil 5 varianter',
        NoVariant: 'Ingen variant',
        VerificationFailedPleaseSelectTheVariant: 'Verifisering mislyktes, vennligst velg varianten',
        VerificationFailedPleaseSelectTheProduct: 'Verifisering mislyktes, vennligst velg produktet',
        VerificationFailedTheProductParameterValueIsEmpty: 'Verifisering mislyktes, produktparameterverdien er tom',
        SetSuccessfully: 'Innstilling vellykket',
        UploadingVideo: 'Laster opp video',
        SearchParameters: 'Søkeparametere',
        ImportantParameters: 'Viktige parametere',
        Select: 'Velg',
        ConfirmSettingThisImageAsTheMainImage: 'Bekreft innstilling av dette bildet som hovedbilde',
        ConfirmDisplayDoNotDisplay: 'Bekreft visning/ikke visning',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'Den nåværende grensen er å velge {num1} filer, denne gangen har du valgt {num2} filer, totalt {num3} filer er valgt',
        ConfirmToRemoveAny: 'Bekreft for å fjerne {Any}?',
        TheTestEnvironmentCannotUploadFiles: 'Testmiljøet kan ikke laste opp filer',
        PleaseEnterTheEnglishTitle: 'Vennligst skriv inn den engelske tittelen',
        CancelSettingAsTheMainImage: 'Avbryt innstilling som hovedbilde?',
        SetAsTheMainImage: 'Angi som hovedbilde?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'Kan ikke sende inn bilder eller videoer som allerede finnes',
        SynchronizeAnyImagesToOtherLanguages: 'Synkronisere {Any}-bilder til andre språk?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Velg produktet som skal synkroniseres først',
        PleaseSelectTheProductFirst: 'Vennligst velg produktet først',
        ConfirmWhetherToDeleteTheSelectedImageVideo: 'Bekrefte at det valgte bildet/videoen skal slettes?',
        TheMainPictureCannotBeTurnedOff: 'Hovedbildet kan ikke slås av',
        CancelDisplay: 'Avbryte visning?',
        ConfirmWhetherToDelete: 'Bekreft om du vil slette?',
        PleaseUploadTheEnglishImage: 'Vennligst last opp det engelske bildet',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Mislyktes. Vennligst skriv inn produkttittelen for alle salgsland',
        PresaleTime: 'Tidspunkt for forhåndssalg',
        PresalePrice: 'Pris før salg',
        PleaseEnter: 'Vennligst skriv inn',
        PresaleQuantity: 'Antall før salg',
        DepositRatio: 'Innskuddsandel',
        AssociatedAGBSOnthewayOrders: 'Tilknyttede AGBS-ordrer på vei',
        EstimatedDeliveryTime: 'Anslått leveringstid',
        FinalPaymentDeadline: 'Frist for endelig betaling',
        SelectTime: 'Velg tid',
        FixedPrice: 'Fast pris',
        ByProportion: 'Etter andel',
        ByFullPayment: 'Ved full betaling',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Bare tall større enn 0 kan legges inn, med opptil to desimaler',
        PresaleTimeCannotBeEmpty: 'Tidspunkt for forhåndssalg kan ikke være tomt',
        PresalePriceTypeCannotBeEmpty: 'Pristype for forhåndssalg kan ikke være tom',
        PresaleQuantityCannotBeEmpty: 'Antall ved forhåndssalg kan ikke være tomt',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Kun positive heltall kan legges inn, kan ikke starte med 0.',
        DepositRatioTypeCannotBeEmpty: 'Type innskuddsforhold kan ikke være tomt',
        DepositRatioCannotBeEmpty: 'Innskuddsforhold kan ikke være tomt',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Bare heltall mellom 0 og 100 kan legges inn, unntatt 0 og 100.',
        EstimatedDeliveryTimeCannotBeEmpty: 'Estimert leveringstid kan ikke være tom',
        FinalPaymentDeadlineCannotBeEmpty: 'Frist for sluttbetaling kan ikke være tom ',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'Sluttbetalingen må gjøres innen 5 timer før kampanjen avsluttes.',
        TheRemainingAmount: 'Det gjenværende beløpet',
        PresaleStatus: 'Status for forhåndssalg',
        PresaleStartTime: 'Starttidspunkt for forhåndssalg',
        PresaleEndTime: 'Sluttidspunkt for forhåndssalget',
        OnPresale: 'På forhåndssalg',
        DirectVisit: 'Direkte besøk',
        BackendManagementSystem: 'Backend-administrasjonssystem',
        Login: 'Logg inn',
        TipsFillInTheUsernameAndPasswordCasually: 'Tips! Fyll ut brukernavnet og passordet uten å bruke det.',
        PleaseEnterUserName: 'Vennligst skriv inn brukernavn',
        PleaseEnterPassword: 'Vennligst skriv inn passord',
        LoginSuccessful: 'Innlogging vellykket',
        PleaseEnterYourAccountNumberAndPassword: 'Vennligst skriv inn kontonummer og passord',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'Det samme produktet kan bare returneres eller byttes',
        B2CBrandWebsite: 'Nettsted for B2C-merkevarer',
        CommentManagement: 'Administrasjon av kommentarer',
        AIGOCoins: 'AIGO-mynter',
        PleaseEnterThePackageQuantity: 'Vennligst skriv inn pakkeantallet',
        PresalePriceCannotBeEmpty: 'Pris før salg kan ikke være tom',
        Product: 'Produkt',
        HomePageColumn: 'Kolonne på hjemmesiden',
        ColumnDescription: 'Beskrivelse av kolonnen',
        FlashSaleArea: 'Område for lynsalg',

        //1.2.8之后
        SoldOutOrNot: 'Utsolgt eller ikke',
        VirtualQuantity: 'Virtuelt antall',
        VirtualQuantityPackageQuantity: 'Virtuelt antall/pakkeantall',
        CombinationIcon: 'Kombinasjonsikon',
        UploadFailedFileSizeShouldBe3MB: 'Opplasting mislyktes, filstørrelsen skal være <3 MB',
        PromotionQuantityPackageQuantity: 'Kampanjekvantum/pakkeantall',
        RemainingTotal: 'Gjenstående/Totalt',
        RecommendedSize100: 'Anbefalt størrelse: 100*100, ≤ 20 KB',
        TotalRemainingTotal: 'Totalt - Gjenværende/Totalt',
        Verification: 'Bekreftelse',
        VirtualSalesVolume: 'Virtuelt salgsvolum',
        VirtualSalesVolumePackageQuantity: 'Virtuelt salgsvolum/pakkeantall',
        DeleteOrNot: 'Slett eller ikke?',
        EnabledOrNot: 'Aktivert eller ikke',
        Cancel: 'Avbryt',
        Code: 'Kode',
        Confirm: 'Bekreft',
        RebateDimension: 'Rabattdimensjon',
        InProgress: 'Pågående',
        ActivityStatus: 'Aktivitetsstatus',
        Ended: 'Avsluttet',
        RebateRatioManagement: 'Håndtering av rabattforhold',
        StartTime: 'Starttidspunkt',
        EndTime: 'Sluttidspunkt',
        ID: 'ID',
        RebateAmount: 'Rabattbeløp',
        NewUser: 'Ny bruker',
        RebateObjectNumber: 'Nummer på rabattobjektet',
        NumberOfCompletedOrders: 'Antall fullførte bestillinger',
        RebateObject: 'Rabattobjekt',
        Operation: 'Operasjon',
        RecentOperator: 'Siste operatør',
        Country: 'Land',
        Prompt: 'Spør',
        RebateProducts: 'Rabattprodukter',
        NewUserPromotion: 'Kampanje for nye brukere',
        RegularUserPromotion: 'Vanlig brukerkampanje',
        Upcoming: 'Kommende',
        RebateRatio: 'Rabatteringsgrad',
        RebateOrNot: 'Rabatt eller ikke',
        Rebate: 'Rabatt',
        PleaseSelectARebateDimension: 'Vennligst velg en rabattdimensjon',
        PleaseSelectARebateObject: 'Vennligst velg et rabattobjekt',
        PleaseSelectACountry: 'Vennligst velg et land',
        PleaseSelectARebateRatio: 'Vennligst velg et rabattforhold',
        RecentOperationTime: 'Siste driftstid',
        PromotionObjectType: 'Type kampanjeobjekt',
        ProductPromotionType: 'Type produktkampanje',
        UserType: 'Type bruker',
        ApplicableUserType: 'Gjeldende brukertype',
        RegularUser: 'Vanlig bruker',
        UseAigoCoinsOrNot: 'Bruke Aigo-mynter eller ikke',
        EndDateOfMembership: 'Sluttdato for medlemskap',
        StartDateOfMembership: 'Startdato for medlemskap',
        memberOrNot: 'Medlem eller ikke',
        No: 'Nei (ikke)',
        Yes: 'Ja',
        LowInventory: 'Lav lagerbeholdning',
        RechargeEmailbox: 'Lad opp e-postkasse',
        SubmittedSuccessfully: 'Vellykket innsendt',
        Importing: 'Import',
        InformationOfTheRechargeFailed: 'Informasjon om oppladningen mislyktes',
        Remarks: 'Merknader',
        RechargeFailed: 'Opplading mislyktes',
        RechargeInBatch: 'Etterfylling i batch',
        RechargeSuccessfully: 'Opplading vellykket',
        RechargeAigoCoins: 'Lad opp Aigo-mynter',

        HaveAnAccount: "Don't have an account? Click here to register",
        SoldOut: 'Sold out',
        HaveAnInvitationCode: "Optional, leave blank if you don't have an invitation code.",
        RestockedItems: 'Restocked items',
        NotMeetPleaseEnterAgain: 'Nickname does not meet the requirements, please enter again.',
        ThisCouponIsOnlyAvailableForNewUsers: 'This coupon is only available for new users.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'This coupon is only available for existing users.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Only returns or exchanges of the same product are allowed.',
        With365DaysRemaining: '（Expires on 9/ 25/2024） with 365 days remaining',

        TheNicknameDoesNotMeet: 'The nickname does not meet the requirements, please enter a new one.',

        GoToView: 'Go to view',
        DoNotRegisterRepeatedly: 'Do not register repeatedly',
        ValidityPeriod: 'Validity period',
        RegisterSuccessfully: 'Register successfully',
        MemberRegistration: 'Member registration',
        RegistrationFailed: 'Registration failed',
        OneYear: 'One year',
        AddToShoppingCart: 'Add to shopping cart',
        AddANewAddress: 'Add a new address',
        ZIPCode: 'ZIP Code*',

        EditYourAddress: 'Edit your address',
        SetAsDefault: 'Set as Default',
        FailedChangeDefaultAddress: 'Failed to change default address, please try again later',
        Fullname: 'Full name(First and Last name)*',
        PhoneNumber: 'Phone number',
        DefaultAddressHasBeenChanged: 'default address has been changed',
        ViewComments: 'View comments',
        ClickToCheckLogisticsInfo: 'Click to check logistics info.',
        RestockingOfOutOfStockItems: 'Restocking of out-of-stock items',
        ThisWeekRestockedItems: "This week's restocked items",
        ProductListInTheShoppingCart: 'product list in the shopping cart',
        WarmReminderVideoResources: 'warm reminder: Video resources are only available for single download.',
        PleaseLeaveYourMessage: 'Please leave your message',

        //1.2.5 之前
        Isitaflashkill: 'Er det en lynkampanje?',
        Isitontheshelf: 'Er det på hyllen',
        Areyousuretocontinuedeletingdata: 'Er du sikker på å fortsette å slette data?',
        Isthereafixedcode: 'Er det faste koder?',
        Isthereafixedprice: 'Er det en fast pris?',
        Areyousuretoexportthefile: 'Vil du bekrefte eksporten av filen? Vil du fortsette?',
        Canwedeliverontheagreeddate: 'Kan du levere på avtalt dato?',
        star1: '1 stjerne',
        stars2: '2 stjerner',
        intotal25: '25 artikler totalt',
        stars3: '3 stjerner',
        stars4: '4 stjerner',
        stars5: 'Kampanjepris',
        to: 'Til',
        Inpromotion: 'På salg',
        Whethertopromoteornot: 'På salg eller ikke',
        Tobereplied_consulted: 'skal besvares/konsulteres',
        UpdateRecords: 'Oppdatere poster',
        UpdateResults: 'Oppdater resultat',
        Isitpossibletooverlaydistribution: 'Kan stables med eller uten distribusjon',
        Canpromotionsbestacked: 'Kan stables med eller uten markedsføring',
        German: 'Tysk',
        Germany: 'Tyskland',
        Amazon: 'Amazon',
        Successfullyadded: 'Lagt til vellykket',
        Successfullyadded1: 'Lagt til vellykket',
        Successfullyadded2: 'Lagt til vellykket',
        Add: 'Legg til',
        Add1: 'Legg til',
        addassociations: 'Legg til assosiasjon',
        Addremarks: 'legg til kommentarer',
        Addlogisticscompany: 'Legg til logistikkselskap',
        Addlogisticsconfiguration: 'Legg til logistikkonfigurasjon',
        Addshippingstandard: 'Legg til fraktstandard',
        Addproduct: 'Legg til produkt',
        Addtime: 'Legg til tid',
        AddAssociatedCategory: 'Legg til en tilknyttet kategori',
        Homebanner: 'Hjemmebsideanner',
        Kitchenappliance: 'Kjøkkenutstyr',
        Canitbestacked: 'Kan det stables eller ikke',
        Overlayornot: 'stables eller ikke',
        Applying: 'Pågående søknad',
        Approved: 'Godkjent',
        Passed: 'Godkjent',
        file: 'Fil',
        Fileimportedsuccessfully: 'Filimport var vellykket',
        DragtheExcelfilehere_orclickonupload: 'Dra og slipp Excel-filen her eller klikk på Last opp',
        ShoppingCartItems: 'Varer i handlekurven',
        Homepagearticle: 'Artikler på hjemmesiden',
        Collectproducts: 'Favorittartikler',
        Categoryassociation: 'Kategoritilknytning',
        Auditing: 'Revisjon',
        automatic: 'automatisk',
        Cancelleddeletion: 'avbrutt sletting',
        Batchdeletion: 'Batch-sletting',
        QuerySearch: 'søk',
        search: 'Søk',
        Batchsearch: 'Batch-søk',
        Expired: 'Utløpt',
        Modifyshippingaddress: 'Endre leveringsadresse',
        Canceled: 'Avbrutt',
        Canceledsuccessfully: 'Avbryt vellykket',
        CancelOrder: 'avbryte bestillingen',
        Unsubscribe: 'avslutte abonnementet',
        quantity: 'Antall',
        Combinationquantity: 'antall kombinasjoner',
        Distributionquantity: 'Antall distribusjon',
        Placinganorder: 'bestillingsmengde',
        Promotionquantity: 'Kampanjekvantum',
        Remainingquantity_combinedquantity: 'gjenværende antall / gjenværende kombinert antall',
        Pleaseuploadpictures: 'Vennligst last opp et bilde',
        Thankyouletter: 'takkebrev',
        catalogue: 'Katalog',
        CatalogID: 'Katalog-ID',
        Category: 'Kategori',
        category1: 'kategori',
        Class: 'Kategori',
        Relatedcategories: 'Tilhørende kategori',
        Maincategory: 'Hovedkategori',
        Applicableclassification: 'Gjeldende kategorier',
        close: 'Lukk',
        shoppingcart: 'Handlekurv',
        OrderCloseReturn: 'Avslutning og retur av ordre',
        city: 'By',
        Secondkillsorting: 'sortering av lynkampanje',
        PrimaryKeyID: 'Primærnøkkel-ID',
        barcode: 'Strekkode',
        Redemptioncode: 'Innløsningskode',
        Invitationcode: 'Invitasjonskode',
        CountryCode: 'Landskode',
        Returnlogisticstrackingcode: 'Sporingskode for returlogistikk',
        Couponcode: 'Kupongkode',
        Paymentcode: 'Betalingskode',
        TaxID: 'Organisasjonsnummer',
        PostalCode: 'Postnummer',
        Batchlisting: 'Parti som legges på hyllen',
        column: 'Kolonne',
        combination: 'Kombinasjon',
        Evaluationcontent: 'kommentarinnhold',
        ProductReview: 'Produktkommentarer',
        commission: 'Bestillinger',
        Pleasecompletetheshippingcostconfiguration: 'Vennligst fullfør fraktkonfigurasjonen',
        Completed: 'Fullført',
        complete: 'Fullført',
        shopping: 'Shopping',
        Rewardconditionsobtained: 'Betingelser for å få belønninger',
        allocation: 'Konfigurasjon',
        Freightallocation: 'Konfigurasjon av frakt',
        HomepageConfiguration: 'Konfigurasjon av hjemmesiden',
        RuleConfiguration: 'Konfigurasjon av regler',
        Allocationofpatrioticcoins: 'Konfigurasjon av Aigo-mynter',
        PricingSettings: 'Prisinnstillinger',
        Nationalconfiguration: 'Konfigurasjon av land',
        Multilingualconfiguration: 'Konfigurasjon av flere språk',
        Setlabel: 'Konfigurere tagger',
        Configureshippingcosts: 'Konfigurere fraktkostnader',
        Setuppromotions: 'Angi kampanje',
        Remittanceconfirmationsuccessful: 'Bekreftelse på vellykket overføring',
        Confirmtoconverttheorderintoaformalorder: 'Bekreftelse av ordrekonvertering til offisiell ordre',
        Confirm1: 'Bekreft',
        Areyousuretodelete: 'bekreft for å slette eller ikke',
        Areyousuretoexportthefileandcontinue: 'bekreftelse på eksport av fil eller ikke, fortsett eller ikke',
        deleteconfirmation: 'Hvorvidt du vil bekrefte sletting av noden og all informasjon under noden',
        Confirmdeletionoftheselectedpicture_video: 'Bekreft for å slette valgt bilde/video',
        Confirmtheexecutionofbatchdelisting: 'Bekreft for å utføre masse-sletting',
        ConfirmsynchronizationofSKU: 'Bekreft SKU-synkronisering',
        Obtainpatrioticcoinallocation: 'Få konfigurasjon av Aigo-mynter',
        consultingservice: 'Forespørsel',
        query: 'Forespørsel',
        content: 'Innhold',
        Consultationcontent: 'Innhold i forespørselen',
        Navigationcontent: 'Innhold i navigasjonen',
        Replycontent: 'innholdet i svaret',
        Successfullycopied: 'Kopiert vellykket',
        CopyConnection: 'Kopier lenke',
        Emailaddress: 'E-postadresse',
        mailbox: 'E-postadresse',
        Customerserviceemail: 'E-post til kundeservice',
        Useremail: 'Brukerens e-post',
        Creator: 'Opprettet av',
        New: 'Opprett',
        NewCountryInformationTableImageAddress: 'Opprett bildeadresse for informasjonstabell om land',
        NewCountryInformation: 'Opprett ny landinformasjon',
        Afterturningonsynchronization: 'Når synkronisering er aktivert, synkroniseres følgende informasjon for EBP-produkter per produkt-SKU',
        Inviteraccount: 'Invitasjonskonto (oppgi konto for invitasjonskode, land for invitasjonskode)',
        Registeredaccount: 'Registrert konto (registrert land)',
        coupon: 'Kupong',
        SynchronizationrequiresdockingwithEBP: 'Ettersom synkronisering må gjøres med EBP, kan batch-synkronisering gjøres hvert 30. minutt for å unngå feil. Vær tålmodig, ettersom synkroniseringen utføres asynkront etter bekreftelse, og SKU-ene vises så snart de er tilgjengelige.',
        OrderData: 'Bestillingsdata',
        SEOdata: 'SEO-data',
        OrderIDmustbepassed: 'Ordre-ID må oppgis',
        Picturesmustbetransmitted: 'Bilder må lastes opp',
        Deposittobepaid: 'Depositum som skal betales',
        Depositpaid: 'Innskudd betalt',
        Disabled: 'Deaktiver',
        download: 'Last ned',
        Downloadfiles: 'Last ned fil',
        DownloadImportTemplate: 'Last ned importmal',
        explain: 'Beskrivelse av programmet',
        ApplicationDescription: 'Beskrivelse av søknaden',
        Illustrativeimage: 'Beskrivelse av bilder',
        PageDescription: 'Beskrivelse av siden',
        ActivityDescription: 'Beskrivelse av hendelsen',
        Numberofexplanatoryimages: 'Beskrivelse av antall bilder',
        discount: 'Rabatter',
        MoveDown: 'Flytt ned',
        Unbound: 'Ubundet',
        Detailed: 'Detaljer',
        Details: 'Detaljer',
        Synchronizeproductimagesandtextbylanguage: 'Bildedetaljer: synkroniser etter språk for det aktuelle språket',
        UserDetails: 'Brukerdetaljer',
        ProductDetails: 'Produktdetaljer',
        ProductDetails1: 'Produktdetaljer',
        Return: 'Returner',
        Returnedgoods: 'Returner varer',
        Returnsandexchanges: 'Retur og bytte',
        Returnandexchangeorders: 'Retur og bytte av varer',
        Returned: 'Returnerte varer',
        NationalSDPDictionary: 'nasjonal sdp-ordbok',
        Address: 'Adresse',
        AddressAdditionalContent: 'Adresse tilleggsinnhold',
        Detailedaddress: 'detaljert adresse',
        Defaultshippingaddress: 'Standard leveringsadresse',
        Invoiceaddress: 'Fakturaadresse',
        Defaultinvoiceaddress: 'Standard fakturaadresse',
        Shippingaddress: 'Mottaksadresse',
        WebsiteAddress: 'Nettstedsadresse',
        IPaddress: 'ip-adresse',
        Shippable: 'Tilgjengelig for levering',
        Logindevice: 'Innloggingsenhet',
        Productdistribution: 'Produktdistribusjon',
        Currency: 'Valuta',
        Editedsuccessfully: 'Redigering vellykket!',
        Successfullyedited: 'Redigering vellykket',
        Edit: 'Rediger',
        EditCategory: 'Rediger kategori',
        Editlogisticsconfiguration: 'Rediger logistikkonfigurasjon',
        Editlogisticscompany: 'Rediger logistikkselskap',
        EditCountryInformation: 'Rediger landinformasjon',
        Editlistingcountry: 'rediger land der produktene legges på hyllen',
        EditFreightStandard: 'Rediger fraktstandard',
        EditFixedPrice: 'Rediger fast pris',
        EditProduct: 'Rediger produkt',
        EditingInformationServices: 'Rediger informasjonstjeneste',
        Countrycodecannotbeempty: 'Landkode må ikke være tom',
        Countrycodecannotbeempty1: 'Landskoden kan ikke være tom',
        Postalcodecannotbeempty: 'Postnummeret må ikke være tomt',
        Postalcodealreadyexists: 'Postnummer finnes allerede',
        Replycontentisrequired: 'Svarinnhold er påkrevd',
        Richtextcontentonthepagecannotbeempty: 'Innholdet i rik tekst på siden kan ikke være tomt',
        Apieceofcontentalreadyexists: 'Det finnes allerede et innhold',
        Medialinkcannotbeempty: 'Mediekoblingen kan ikke være tom',
        Statusenable_disablecannotbeempty: 'Statusen Aktivering/Deaktivering kan ikke være tom',
        Thereareonlytworemittancestatuses: 'Overføringens status kan bare være godkjent eller ikke godkjent.',
        Iconmustbeuploaded: 'Ikonet må være lastet opp',
        Themediaiconcannotbeempty: 'Medieikonet kan ikke være tomt',
        Thecountryiconcannotbeempty: 'Land-ikonet kan ikke være tomt',
        Languagecannotbeempty: 'Språk kan ikke være tomt',
        Norulesconfigured: 'Den aktuelle leietakeren har ingen Aigo-myntregler konfigurert',
        CurrentactivitypricesinSpain: 'Bruttomarginen på den nåværende aktivitetsprisen i Spania er 0,29 %',
        Lastmonth: 'Siste måned',
        Positiveconversionsingletime: 'tidspunktet for overføring til den offisielle bestillingen',
        Inventoryofselecteditems: 'Lager-/salgsmultiplikatoren for den valgte varen kan ikke være mindre enn aktivitetsmengden',
        TheEnglishcategorynamecannotbeempty: 'Engelsk kategorinavn kan ikke være tomt',
        Medianamecannotbeempty: 'Navnet på mediet kan ikke være tomt',
        Namecannotbeempty: 'Navnet må ikke være tomt',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'Antall innløsningskoder kan bare være flere enn det opprinnelige antallet.',
        Transferredtoofficial: 'Ordrenummer 101 har blitt overført til en offisiell ordre, vennligst fjern merket først',
        Numberofselectedcomments: 'antall valgte anmeldelser',
        Doyouwanttodelete: 'antallet som er fylt ut er for stort',
        Countrycannotbeempty: 'Land kan ikke være tomt',
        Orderdoesnotexist: 'Bestillingen eksisterer ikke',
        Thisordercannotbecancelled: 'Bestillingen kan ikke avbrytes',
        Notapresaleorder: 'Bestillingsnummer 101 er ikke en forhåndsbestilling. Vennligst fjern markeringen først',
        Ordernotpaid: 'Ordrenummer 101 er ikke en betalt ordre. Vennligst avbryt først',
        Existinglogisticstrackingnumber: 'Bestillingen har et logistikksporingsnummer og kan ikke sendes inn.',
        Thecurrentcountrypricingisnotconfigured: 'Gjeldende landspris er ikke konfigurert',
        Combinationpricecannotbeempty: 'Kombinasjonsprisen kan ikke være tom',
        Thesizeoftheuploadedimagecannotexceed: 'Størrelsen på det opplastede bildet må ikke overstige',
        Thepagetitlecannotbeempty: 'Sidetittelen må ikke være tom',
        Chinesetitleisrequired: 'Kinesisk tittel er påkrevd',
        Selectedcountrytitleisrequired: 'Tittel for valgt land er påkrevd',
        Titlecannotbeempty: 'Tittelen kan ikke være tom',
        Orderamountfull: 'Bestillingsbeløpet er tilstrekkelig',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'Sluttverdien må være større enn eller lik startverdien',
        Successfullyremoved: 'Fjerning vellykket',
        Delete: 'Slett',
        Remove: 'Fjern',
        Logisticscompany: 'Logistikkfirma',
        RecipientCompany: 'Mottakende selskap',
        RecipientCompanyAddressAdditionalContent: 'Mottakende selskap, adresse tilleggsinnhold',
        Common: 'totalt',
        Link: 'Lenke',
        JumpLink: 'Hoppelenke',
        Manuallogisticsshipment: 'Manuell logistikkleveranse',
        Manualshipping: 'Manuell levering',
        Successfullyshippedmanually: 'Vellykket manuell levering',
        Whethertomanuallyship: 'Manuell levering eller ikke?',
        Shipped: 'Sendt',
        SubmitConfirmation: 'Send inn bekreftelse',
        Whethertosubmitdata: 'Sende inn data eller ikke?',
        Freeshipping: 'Gratis frakt',
        Firstfreeshippingitem: 'gratis frakt på din første bestilling',
        Freeshippingonfirstorder: 'Gratis frakt på din første bestilling',
        Freeshippingornot: 'Gratis frakt eller ikke?',
        Isthefirstorderfreeofshipping: 'Gratis frakt eller ikke på din første bestilling',
        Failedtouploadfile_Pleasecheckthefilecontent: 'Filopplasting mislykte. Vennligst sjekk innholdet i filen',
        Fileimportfailed_pleasecheckthefilecontent: 'Filimporten mislyktes. Vennligst sjekk filens innhold',
        DeleteError: 'Feil ved sletting',
        Parametererror: 'parameterfeil',
        Selectedcountrycontentisrequired: 'Innholdet i det valgte landet er påkrevd',
        Pleaseenterthelanguage: 'Vennligst skriv inn språket',
        Pleaseentertheavailableamount: 'Vennligst oppgi tilgjengelig beløp',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Vennligst oppgi antall ganger for samme bruker',
        Pleaseentersorting: 'Vennligst skriv inn bestillingen',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Angi tilgjengelig beløp på 0 for å indikere ubegrenset bruk',
        Pleaseenterthecreationtime: 'Vennligst skriv inn opprettelsestidspunktet',
        Entercoupondescription: 'Skriv inn instruksjonene for kupongen',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Vennligst skriv inn om det vises nederst på nettsiden',
        Pleasefillinthepostalcode: 'Vennligst fyll inn postnummeret ditt',
        Pleaseenterthepostalcodecorrectly: 'Vennligst skriv inn postnummeret ditt riktig',
        Pleaseentertheemailcorrectly: 'Vennligst skriv inn riktig e-postadresse',
        Pleaseenteracomment: 'Vennligst skriv inn en kommentar',
        Pleaseenteracolumnname: 'Vennligst skriv inn et kolonnenavn',
        Pleaseenteratitle: 'Vennligst skriv inn en tittel',
        Pleaseenteradescription: 'Vennligst skriv inn en beskrivelse',
        Inputquantity: 'Angi antall',
        Enterjumplink: 'Angi hopplenke',
        EnterName: 'Skriv inn navn',
        Boutiquerecommendationspace: 'Boutique-anbefalingsrom',
        Spain: 'Spania',
        Spanish: 'Spansk',
        Thisoperationwillremovethefile: 'Denne operasjonen vil fjerne filen. Vil du fortsette?',
        Areyousuretoremovethisproduct: 'Er du sikker på at du vil fjerne dette produktet?',
        datastatistics: 'Statistikk',
        Orderdatastatistics: 'Bestillingsstatistikk',
        Transactionstatistics: 'Transaksjonsstatistikk',
        State: 'Status',
        Loginstatus: 'Innloggingsstatus',
        Listingstatus: 'hyllestatus',
        InvoiceStatus: 'Fakturastatus',
        Claimstatus: 'Hentestatus',
        ReplyStatus: 'Status for svar',
        Auditstatus: 'Status for gjennomgang',
        Remittancestatus: 'Utbetalingsstatus',
        Preorderstatus: 'status for forhåndsbestilling',
        Processingstatus: 'Status for behandling',
        Usagestatus: 'Status for bruk',
        Shipmentstatus: 'Status for forsendelse',
        Paymentstatus: 'Betalingsstatus',
        OrderStatus: 'Status for bestilling',
        Statuseditedsuccessfully: 'Status redigert vellykket',
        UnitedStates: 'USA',
        Areyousuretocancelthisorder: 'Er du sikker på at du vil avbryte bestillingen?',
        thisyear: 'I år',
        Presaleproductscannotbeadded: 'Dette er et forhåndssalgsprodukt og kan ikke legges til som en kombinasjon',
        Thismonth: 'Denne måneden',
        style: 'Stil',
        RatingStarRating: 'Rating Star',
        label: 'Merkelapper',
        Euro: 'Euro',
        success: 'Suksess',
        SEOManagementExport: 'Eksport av SEO-administrasjon',
        export: 'Eksport',
        Exportsuccessful: 'Vellykket eksport',
        invoice: 'Faktura',
        Auditfailed: 'Mislykket',
        Savefailed: 'lagring mislyktes',
        Editingfailed: 'Redigering mislyktes',
        Verificationfailed: 'Verifisering mislyktes',
        Createdon: 'opprettelsesdato',
        Rebateendtime: 'sluttidspunkt for rabatt',
        CommentEndDate: 'sluttdato for kommentarene',
        Startdate: 'Startdato',
        Rebatestarttime: 'starttidspunkt for rabatten',
        Commentstartdate: 'startdato for kommentarene',
        Consultationdate: 'Dato for forespørsel',
        Additionalpurchasetime: 'tidspunktet for når varene ble lagt til i ønskelisten',
        Releasetime: 'Utgivelsesdato',
        Lastordertime: 'Tidspunkt for siste bestilling',
        Enddate: 'Sluttdato',
        Paymentdeadline: 'Betalingsfrist',
        Setcombinationdiscount: 'Angi kombinasjonsrabatt',
        Auditended: 'Avslutning av revisjon',
        Paymentmethod: 'betalingsmåte',
        Presalepaymentmethod: 'betalingsmåte for forhåndssalg',
        ForcesynchronizationofEBP: 'Skal EBP-synkronisering tvinges frem?',
        French: 'Fransk',
        France: 'Frankrike',
        frequency: 'Hyppighet',
        Consultationfrequency: 'Forespørselsfrekvens',
        source: 'Kilde',
        SubscriptionSource: 'Kilde for abonnement',
        expenditure: 'Utgifter',
        Orderexpenses: 'Ordreutgifter',
        PortfolioManagement: 'Porteføljeforvaltning',
        Categorymanagement: 'Kategoristyring',
        marketingmanagement: 'Markedsføringsstyring',
        ordermanagement: 'Ordrehåndtering',
        ProductManagement: 'Administrasjon av produkt',
        Logmanagement: 'Logghåndtering',
        subscriptionmanagement: 'Abonnementshåndtering',
        usermanagement: 'Brukeradministrasjon',
        VariantManagement: 'Administrasjon av varianter',
        BannerManagement: 'Banneradministrasjon',
        WebsiteManagement: 'Administrasjon av nettsteder',
        SEOManagement: 'SEO-administrasjon',
        Britain: 'Storbritannia',
        Successfullysaved: 'Vellykket lagring',
        EnablePlatform: 'Aktiver plattform',
        Consultationrecords: 'Forespørselshistorikk',
        BrandStory: 'Merkevarehistorier',
        Dutchlanguage: 'Nederlandsk',
        time: 'Tid',
        Remittanceconfirmationoperationtime: 'Tidspunkt for bekreftelse av overføring',
        logintime: 'Tidspunkt for pålogging',
        Subscriptiontime: 'Tidspunkt for abonnement',
        Modificationtime: 'Tidspunkt for endring',
        Receivingtimeprompt: 'Melding for mottakstid',
        Collectiontime: 'Tidspunkt for innsamling',
        Displaytime: 'Tidspunkt for visning',
        Changetime: 'Tidspunkt for endring',
        Activitytime: 'Tidspunkt for hendelse',
        Ordertime: 'Tidspunkt for bestilling',
        today: 'i dag',
        icon: 'Ikoner',
        CommentID: 'Kommentar-ID',
        RegistrationInviterID: 'ID for registrert innbyder',
        UserID: 'Bruker-ID',
        UserID_loginemail_registrationinviterID_phonenumber: 'Bruker-ID / innloggings-e-post / ID for registrerte inviterte / mobiltelefonnummer',
        ProductCatalogID: 'ID for produktkatalog',
        ProductID1: 'Produkt-ID',
        ReviewerId: 'Anmelder-ID',
        UserID_IP_LoginDevice: 'Bruker-ID/ip/innloggingsenhet',
        ProductID: 'Produkt-ID',
        language: 'Språk',
        PictureLanguage: 'Språk for bilder',
        picture: 'Bilde',
        Productimage1: 'Produktbilde',
        ProductLargePicture: 'Produktbilde',
        Synchronizeproductimagesbylanguage: 'Produktbilde: synkroniser bildet på tilsvarende språk etter språk, les EBP-bildesorteringen, det første EBP-bildet settes automatisk som hovedbilde',
        Largeimage: 'Stort bilde',
        ImageID: 'Bilde-ID',
        Mainimage: 'Hovedbilde',
        Isitthemainimage: 'Hovedbilde eller ikke',
        Pictureandtext: 'bilde og tekst',
        Pictures_Videos: 'Bilde/Video',
        Productimage: 'Produktbilder',
        SEOManagementImport: 'Import av SEO-administrasjon',
        Import: 'Importere',
        ImportFile: 'Importer filer',
        money: 'Beløp',
        Paymentamount: 'Betalingsbeløp',
        Orderamount: 'Ordrebeløp',
        Fullreductionamount: 'tilbakebetalingsbeløp',
        Availableamount: 'tilgjengelig beløp',
        Maximumdeductionamount: 'Maksimalt fradragsbeløp',
        Ordercumulativeamount: 'Totalt ordrebeløp',
        Basicinformation: 'Grunnleggende informasjon',
        Basicinformationoftheorder: 'Grunnleggende bestillingsinformasjon',
        Contactinformation: 'Kontaktinformasjon',
        OrderInformation: 'Informasjon om bestillingen',
        Preorderinformation: 'Forhåndsbestillingsinformasjon',
        ProductInformation: 'Produktinformasjon',
        Paymentinformation: 'Betalingsinformasjon',
        Sizeinformation: 'Informasjon om størrelse',
        VariantInformation: 'Informasjon om varianter',
        Exportorderinformation: 'informasjon om eksportordre',
        Socialmediainformation: 'Informasjon om sosiale medier',
        English: 'Engelsk',
        Pleaseenteradeliveryprompt: 'Vennligst skriv inn en kvitteringspåminnelse',
        income: 'Inntekt',
        Thirdpartylogin: 'Innlogging for tredjepart',
        tenant: 'Leietakere',
        AuditDescription: 'Gjennomgå instruksjoner',
        Exchangeitem: 'Bytte varer',
        Exchange: 'Bytte',
        Pleaseenteracombinationname: 'Vennligst skriv inn et kombinasjonsnavn',
        Pleaseenteraparametername: 'Vennligst skriv inn navnet på parameteren',
        Pleaseinputthenumbercorrectly: 'Vennligst skriv inn riktig antall',
        Pleaseenterthecombinationquantity: 'Vennligst skriv inn antall kombinasjoner',
        Pleaseenterthecountry: 'Vennligst skriv inn land',
        Upto100wordscanbeentered: 'Skriv inn opptil 100 tegn',
        Pleaseentertheshippingcost: 'Vennligst skriv inn fraktkostnaden',
        Detailedcontentofgraphicsandtext: 'Vennligst skriv inn de grafiske detaljene på engelsk først',
        Pleaseenteralink: 'Vennligst skriv inn en lenke',
        Pleaseenterthelogisticscompany: 'Vennligst oppgi et logistikkselskap',
        EnterReply: 'Skriv inn et svar',
        Enterredemptioncode: 'Angi innløsningskode',
        Enteraquantitybetween0and1000: 'Angi et antall mellom 0 og 1000',
        Pleaseenterbarcode: 'Vennligst skriv inn strekkode',
        Enterproductbarcodeandsubmitforaddition: 'Skriv inn produktets strekkode for å sende inn tillegg. Flere strekkoder skal skrives inn på en ny linje.',
        Pleaseenterthemaintext: 'Vennligst skriv inn brødtekst',
        Enterthereason: 'Skriv inn årsaken. Du må fylle ut årsaken hvis søknaden ikke godkjennes.',
        Pleaseenterthenumberofredemptioncodes: 'Vennligst oppgi antall innløsningskoder',
        Pleaseenterthequantityofpatrioticcoins: 'Vennligst skriv inn antall Aigo-mynter',
        PleaseenterthepageURL: 'Vennligst skriv inn sidens URL',
        Enterproductbarcode: 'Skriv inn strekkoder for produktet. Flere strekkoder skal legges inn i en ny linje opp til 500',
        Pleaseenterthelogisticstrackingnumber: 'Vennligst skriv inn sporingsnummeret',
        Consultationinstructions: 'Instruksjoner for forespørsler',
        inventory: 'lagerbeholdning',
        Invitetoreceivepatrioticcoins: 'Inviter til å få Aigo-mynter',
        goto: 'Gå til',
        JumpAddress: 'Gå til adresse',
        Gotopage1: 'Gå til side 1',
        Italy: 'Italia',
        Italian: 'Italia',
        Thepriceconfigurationdoesnotexist: 'Priskonfigurasjon finnes ikke. Vennligst konfigurer pris',
        Registrationsuccessfulnot1: 'Vellykket kontoregistrering uten invitasjonskode',
        Pagedescriptioncannotbeempty: 'Sidebeskrivelsen kan ikke være tom',
        Thecustomerserviceemailaddresscannotbeempty: 'E-postadressen til kundeservice må ikke være tom',
        Thewebsiteaddresscannotbeempty: 'Nettstedsadressen kan ikke være tom',
        Currencycannotbeempty: 'Valutaen kan ikke være tom',
        Inputcannotbeempty: 'Inndata kan ikke være tomme',
        Positiveconversionsinglemode: 'måten å overføre til den offisielle bestillingen på',
        Contactinformationcannotbeempty: 'Kontaktinformasjonen kan ikke være tom',
        No_Illegalrowdata: '? linjen med data er ulovlig',
        Thelengthcannotexceed2characters: 'Lengden kan ikke overstige 2 tegn',
        Thelengthcannotexceed50characters: 'Lengden kan ikke overstige 50 tegn',
        ThelengthcannotexceedXcharacters: 'Lengden kan ikke overstige {num} tegn',
        Brandcannotbeempty: 'Varemerke kan ikke være tomt',
        Brandandlanguagemustbefullycapitalized: 'Varemerke og språk må skrives med store bokstaver',
        Brandandcountrycannotbeempty: 'Merke og land kan ikke være tomme',
        Operationsuccessful: 'Vellykket operasjon',
        Thereplycontentwillbedisplayedtotheuser: 'Svaret vil bli vist til brukerne',
        PageURLcannotbeempty: 'URL-adressen til siden må ikke være tom',
        Publishinginprogress: 'frigjøring pågår',
        Numberoflocalcurrencies: 'Mengdene som er konfigurert nedenfor, er i den nasjonale valutaen i det aktuelle landet.',
        Orderremarksarerequired: 'Ordrenotater er påkrevd',
        Pagekeywordscannotbeempty: 'Sidens nøkkelord kan ikke være tomme',
        Thereasonfornotpassingwillbedisplayedtotheuser: 'Årsaker til at det ikke ble godkjent vil bli vist til brukeren',
        pound: 'Pund',
        Singleorderlimit: 'Begrenset til én enkelt bestilling',
        OrderList: 'Bestillingsliste',
        Between1and50charactersinlength: 'Lengde mellom 1 og 50 tegn',
        Lengthgreaterthan100characters: 'Lengde på mer enn 100 tegn',
        Lengthgreaterthan20characters: 'Lengde større enn 20 tegn',
        Required: 'Felter merket med ' * ' er obligatoriske',
        CanIusecoupons: 'kuponger kan brukes eller ikke?',
        Datadoesnotexist: 'Dataene finnes ikke',
        Dataalreadyexists: 'Data finnes allerede',
        Nonpresaleorderscannotbeoperated: 'Bestillinger som ikke er forhåndssalg er ikke tilgjengelige',
        Transferredtoformalformandcannotbeoperated: 'Overførte bestillinger er ikke tilgjengelige',
        Manual: 'manuell',
        brand: 'Merkevare',
        Grossmargin: 'Bruttomargin',
        Pleasecheckfirst: 'Vennligst kryss av først',
        Upto500wordscanbeentered: 'Maksimalt 500 tegn',
        Successfullyaddedinformation: 'Melding lagt til vellykket',
        Successfullydeletedinformation: 'Sletting av melding vellykket',
        Successfullyeditedinformation: 'Redigering av melding vellykket',
        mode: 'Metode',
        Loginmethod: 'Metode for innlogging',
        MyOrder: 'Ordren min',
        Successfullymodified: 'Endring vellykket',
        Patrioticcoin: 'Aigo-mynter',
        Displaypromotionalprices: 'Skal kampanjeprisen vises eller ikke?',
        Isitdisplayedatthebottomofthewebsite: 'Vise eller ikke nederst på nettstedet?',
        Displayornot: 'Vise eller ikke vise?',
        Whethertodisplayonthehomepage: 'Skal det vises på forsiden?',
        ShowCountry: 'Vis land',
        Whethertodisplayall: 'Vise alle eller ikke?',
        Reasonforcancellationoforderisrequired: 'Årsak til avbryting av bestilling kreves',
        Salesmultiples: 'Salgsmultiplikator',
        WebsiteNavigation: 'Navigasjon på nettstedet',
        nickname: 'Kallenavn',
        nothing: 'Ingen',
        Directoryhierarchy: 'Katalognivå',
        Failed: 'Ikke godkjent',
        Unaudited: 'Ikke gjennomgått',
        Notuploaded: 'Ikke lastet opp',
        Thelengthcannotexceed100characters: 'Lengden må ikke overstige 100 tegn',
        Thelengthcannotexceed20characters: 'Lengden må ikke overstige 20 tegn',
        Nonpaidorderscannotbeoperated: 'Ikke tilgjengelig for ikke-betalte bestillinger',
        Notshipped: 'Ikke sendt',
        Nocountrieslisted: 'Ingen land der produktene er satt på hyllen',
        Donotmodify: 'Vennligst ikke endre de tre første linjene i malens tittel',
        Notpassed: 'ikke godkjent',
        Notlisted: 'ikke på hyllen',
        Cannotbe0: 'Kan ikke være 0',
        Notclaimed: 'Ikke samlet inn',
        Nopatrioticcoinrulesconfigured: 'Ingen Aigo-myntregler konfigurert',
        Noinventorydisplay: 'Ingen visning av lagerbeholdning',
        notused: 'Ikke brukt',
        name: 'navn',
        name1: 'Navn',
        ColumnName: 'Kolonnenavn',
        DistributionName: 'Navn på distribusjon',
        CategoryName: 'Navn på kategori',
        Categoryname1: 'Kategorinavn',
        RelatedCategoryName: 'Tilhørende kategorinavn',
        Combinationname: 'Kombinasjonsnavn',
        PromotionName: 'Kampanjenavn',
        PromotionName_ProductID_Barcode: 'Kampanjenavn/produkt-ID/strekkode',
        DirectoryName: 'Navn på katalog',
        CouponName: 'Kupongnavn',
        ActivityName: 'Navn på arrangement',
        Countryname: 'Navn på land',
        ProductName: 'Produktnavn',
        Productname1: 'Produktnavn',
        TopicName: 'Navn på emne',
        TitleName: 'Navn på tittel',
        CustomName: 'Tilpasset navn',
        VariantName: 'Navn på variant',
        normal: 'Normal',
        Freightstandard: 'Standard for frakt',
        RegulationsonReturnsandRefunds: 'Regler for retur og refusjon',
        Documentremarks: 'Merknader om bestillingen',
        number: 'Nummer',
        Numberofitems: 'Antall varer',
        Numberofproductmodels: 'Antall varer',
        Housenumber: 'husnummer',
        Numberofredemptioncodes: 'Antall koder',
        Numberofpatrioticcoins: 'Antall Aigo-mynter',
        Numberofchangesinpatrioticcoins: 'Antall endringer i Aigo-mynter',
        Numberofpatrioticcoinsafterchanges: 'Antall Aigo-mynter etter endring',
        Ordernumber: 'Ordrenummer',
        OrderNumber_UserID: 'Ordrenummer/bruker-ID',
        Ordernumber_UserID_Taxnumber: 'Ordrenummer/bruker-ID/avgiftskode',
        Numberofproducts: 'Antall produkter',
        Productquantity: 'Antall produkter',
        Numberofevaluations: 'antall anmeldelser',
        Numberofapprovedevaluations: 'antall godkjente anmeldelser',
        Trackingnumber: 'Sporing av ordrenummer',
        Logisticstrackingnumber: 'Sporingsnummer for logistikk',
        Serialnumber: 'serienummer',
        Subordernumber: 'underordrenummer',
        Mobilephonenumber: 'Mobiltelefonnummer',
        Numberofusesbythesameuser: 'Antall anvendelser for samme bruker',
        Numberofusersplacingorders: 'Antall brukere som legger inn bestillinger',
        ObtainingPatrioticCoinObject: 'objekter for å få Aigo-mynter',
        Shippingremarks: 'Merknader om frakt',
        Hideemptyparameters: 'Skjul tomme parametere',
        LabelOptions: 'Alternativer for etiketter',
        Remittanceconfirmationoperator: 'Operatør for betalingsbekreftelse',
        Manualshippingoperator: 'Operatør for manuell forsendelse',
        Operator: 'Operatører',
        sort: 'Sortere etter',
        Otherissues: 'Andre spørsmål',
        Otherissuesrelatedtotransportation: 'Andre spørsmål om forsendelse',
        other: 'Andre spørsmål',
        Paid: 'betalt',
        page: 'Side',
        Fullpayment: 'Full betaling',
        Finaldeposit: 'Endelig betaling av depositum',
        DistributionCountry: 'Land for distribusjon',
        PromotionCountry: 'Land for markedsføring',
        Registeredcountry: 'Registrert land',
        Countryofuse: 'Land for bruk',
        SalesCountry: 'Salgsland',
        Netherlands: 'Nederland',
        Countrieslisted: 'land der produktene er satt på hyllen',
        Pagekeywords: 'Nøkkelord for siden',
        Productkeywords: 'Nøkkelord for produktet',
        Pendingreview: 'Skal anmeldes',
        ReviewerName: 'Navn på anmelder',
        parameter: 'Parameter',
        Associatesearchparameters: 'Tilknyttede søkeparametere',
        Relatedimportantparameters: 'Relaterte viktige parametere',
        Variantparameters: 'Variant-parametere',
        Partialshipment: 'Delvis levert',
        adopt: 'Godkjent',
        Whetherthepresaleorderisconvertedtoformal: 'Forhåndsbestilling til formell eller ikke?',
        Combinedorders: 'Kombinert bestilling',
        Officialorder: 'Formell bestilling',
        orderform: 'Bestillinger',
        Presaleorder: 'Forhåndsbestilling',
        PaymentOrder: 'Betalte bestillinger',
        Distributiontime: 'Distribusjonstid',
        Promotiontime: 'Kampanjetid',
        Effectivetime: 'Gyldig tid',
        weight: 'Vekt',
        SEOConfigurationTemplate: 'Mal for SEO-konfigurasjon',
        SEOManagementImportTemplate: 'Mal for import av SEO-administrasjon',
        platform: 'Plattform',
        DisplayPlatform: 'Visningsplattform',
        power: 'Kraft',
        Polishlanguage: 'Polsk',
        poland: 'Polen',
        Listed: 'lagt på hylle',
        Topwithlargenumbers: 'Standardverdien er 0, jo større tallet er, desto høyere er rangeringen',
        Countbyday: 'etter dag',
        Pleaseselectacomment: 'Vennligst velg en anmeldelse',
        Pleasecompletethecommissionconfiguration: 'Vennligst fullfør provisjonskonfigurasjonen',
        PleasecompleteGrossmarginconfiguration: 'Fullfør konfigurasjonen av bruttosats',
        Pleasecompletetheweightrangeconfiguration: 'Fullfør konfigurasjonen av vektintervallet',
        Pleasecompletethetaxrateconfiguration: 'Fullfør konfigurasjon av avgiftssats',
        Pleaseentertheproducttitle: 'Vennligst skriv inn produkttittel',
        Pleaseenterthelinkcorrectly: 'Vennligst skriv inn riktig lenke',
        Pleaseenterthesource: 'Vennligst skriv inn kilden',
        Pleaseenteraname: 'Vennligst skriv inn et navn',
        Pleaseenterthereason: 'Vennligst oppgi en grunn',
        Pleaseenterareply: 'Vennligst skriv inn et svar',
        Pleaseenterapositiveinteger: 'Vennligst skriv inn et positivt heltall',
        Pleaseselectaresult: 'Vennligst velg resultat',
        Pleaseselectparameters: 'Vennligst velg parametere',
        Pleasechoosewhethertocontinueapplying: 'Velg om du kan fortsette å søke',
        Pleaseselecttherecommendedmodule: 'Velg en anbefalingsmodul',
        Pleaseselectacategory: 'Velg en kategori',
        Pleaseselectabrand: 'Vennligst velg et merke',
        Pleaseselect: 'Vennligst velg',
        Pleaseselectastatus: 'Vennligst velg status',
        Pleaseselectasource: 'Vennligst velg kilde',
        Pleaseselectatime: 'Vennligst velg et klokkeslett',
        Pleaseselectacategoryfirst1: 'Vennligst velg en kategori først',
        Pleaseentercontent: 'Vennligst skriv inn innhold',
        Hourlystatistics: 'etter time',
        Portugal: 'Portugal',
        price: 'Pris',
        Currentprice: 'Gjeldende pris',
        Combinationprice: 'Kombinasjonspris',
        Costprice: 'Kostpris',
        DistributionPrice: 'Distribusjonspris',
        Distributionprice1: 'Distribusjonspris',
        CollectionPrice: 'Samlingspris',
        Additionalpurchaseprice: 'prisen på varene som legges til i ønskelisten',
        price1: 'Salgspris',
        Sellingprice_unitprice: 'Salgspris (enhetspris)',
        commodityprice: 'Produktpris',
        Fixedprice1: 'Fast pris',
        Lowestprice: 'Laveste pris',
        PromotionPrice: 'Kampanjepris',
        Promotionprice1: 'Kampanjepris',
        Promotionprice_unitprice: 'Kampanjepris (enhetspris)',
        Totalprice: 'Totalpris',
        Combinedtotalprice: 'Totalpris for kombinasjonen',
        Pricing_unitprice: 'Priser (enhetspris)',
        Freeshippingprice: 'Priser for fri frakt',
        LogisticsPricing: 'Logistikkpriser',
        Presaleornot: 'Forhåndssalg eller ikke',
        Thisisalreadythefirstitemmoveupfailed: 'Første vare allerede. Klarte ikke å flytte opp',
        Youcanonlybatchprocessupto100itemsatatime: 'Batchbehandling av opptil 100 bestillinger om gangen',
        handle: 'Behandle',
        Applicableproducts: 'Gjeldende produkter',
        HotSellingZone: 'Populære produkter',
        ProductPromotion: 'Produktkampanjer',
        Comingsoon1: 'Gyldig snart',
        ParameterProperties: 'Parameteregenskaper',
        Provideinvitationcode: 'Gi invitasjonskoden til andre brukere. Andre brukere bruker invitasjonskoden til å registrere seg og deretter fullføre en bestilling (hver inviterte bruker kan bare få 1 bonus)',
        province: 'Provins',
        Comingsoon: 'slippes snart',
        Areyousureyouwanttodeleteit: 'Ønsker du å slette?',
        Weightrange_end_kg: 'Vektområde (slutt) kg',
        Weightrange_start_kg: 'Vektområde (start) kg',
        reason: 'Begrunnelse',
        Shoppingrebate: 'Shopping-rabatter',
        Backendrecharge: 'bakgrunnslading',
        Received: 'mottatt',
        Receiveddamagedproductsandproductswithqualityissues: 'Mottatt skadede produkter og produkter med kvalitetsproblemer',
        Receivedincorrectproduct: 'Mottatt feil produkt',
        NewProductZone: 'Nye ankomster',
        collection: 'samling',
        Categoryrecommendation: 'Anbefaling av kategori',
        Homepagerecommendation: 'Anbefaling av hjemmeside',
        Productrecommendations: 'Anbefaling av produkt',
        Fullproductrecommendation: 'Anbefaling av alle produkter',
        Recommendedforyou: 'Anbefalt for deg',
        Recommendedforyou1: 'Anbefalt for deg',
        Recommendationmodule: 'Anbefale modul',
        Recommendedlocation: 'Anbefale sted',
        Orderrewards: 'Ordrebelønning',
        Receivingandshippingreminder: 'påminnelse om mottak og forsendelse',
        Aigostarbrandnetwork: 'Aigostar merkevarenettverk',
        Socialmedia: 'Sosiale medier',
        Fullreduction: 'tilbakebetaling',
        refund: 'Refusjon',
        Fullreductionoffreight: 'tilbakebetaling av frakt',
        Orderrebate: 'Bestillingsrabatt',
        Registrationsuccessfulnot: 'Vellykket registrering (ikke brukt invitasjonskode)',
        Registrationsuccessful: 'Vellykket registrering (brukt invitasjonskode)',
        Usinganinvitationcode: 'registrering av konto vellykket ved å bruke invitasjonskoden',
        LoginLog: 'Påloggingslogg',
        Patrioticcoinusagelog: 'Aigo-mynter brukslogg',
        strip: 'poster',
        Pleasecompletetheproductinformationbeforelistingit: 'Vennligst fyll ut produktinformasjonen før du legger den på hyllen',
        Bankremittance: 'Bankoverføring',
        Remittanceconfirmation: 'Utbetaling bekreftet',
        Evaluation_Shown_total: 'Anmeldelser (vist/totalt)',
        Replied: 'Besvart',
        reply: 'Svar',
        Replysuccessful: 'Svar vellykket',
        Respondent: 'Besvart av',
        Reset: 'Tilbakestill',
        surplus: 'Gjenværende',
        Purchaserestrictionsforthesameuser: 'kjøpsbegrensninger for samme bruker',
        result: 'Resultat',
        Inventorybottomline: 'lagerbeholdning på bunnlinjen',
        Reviewedby: 'Gjennomgått av',
        Auditsuccessful: 'Vellykket gjennomgang',
        Batchreview: 'Gjennomgang av batch',
        Finalpaymenttobemade: 'saldo som skal betales',
        Finalpaymentpaid: 'saldo betalt',
        Doyousupporttheuseofcoupons: 'Skal bruk av kuponger skal støttes?',
        Istheexchangecodefixed: 'Er innløsningskoden fast?',
        Downloadsuccessful: 'Nedlasting vellykket',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'Maksimalt fire desimaler kan angis',
        Caninput0andpositiveintegers: '0 og positive heltall kan angis. Symboler kan bare angis som % og . , Opptil to desimalplasser',
        Selected: 'Valgt',
        Pleaseselect25items: 'Vennligst velg 2-5 varer',
        Pleaseselectshippingcost: 'Vennligst velg fraktkostnad',
        SelectDateTime: 'Velg dato og klokkeslett',
        Selectupto5: 'Velg opptil 5',
        Selectapictureorvideo: 'Velg bilde eller video i galleriet først!',
        Pleaseselectacategoryfirst: 'Velg en kategori først',
        Pleasechoosewhethertousecoupons: 'Velg om du vil bruke kupongen',
        Pleaseselectaproductthathasbeenlisted: 'Velg en vare som allerede er satt i hyllene',
        Pleaseselectadaterange: 'Velg et datointervall',
        Pleaseselecttheenddate: 'Velg en sluttdato',
        Pleaseselectastartdate: 'Velg en startdato',
        Pleaseselecttherecommendedlocation: 'Velg et anbefalt utsalgssted',
        Selectionsuccessful: 'Valget var vellykket',
        Selectupto50: 'Velg opptil 50',
        SelectNavigation: 'Velg navigasjon',
        Selectparameters: 'Velg parametere',
        Selectvariantparameters: 'Velg variantparametere',
        Selectproduct: 'Velg produkt',
        SelectAll: 'Velg alle',
        Selectvariant: 'Velg en variant',
        Pleaseselectatleastonecountry: 'Velg minst ett land',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: 'Vennligst velg brukerens forbrukshistorikk for Aigo-mynter',
        Pleaseselectapresaleorder: 'Vennligst velg forhåndsbestilling',
        Pleaseselectaninformationservice: 'Vennligst velg informasjonstjeneste',
        Selectfrompicturelibrary: 'Velg fra bildegalleriet',
        Selectmaincategory: 'Velg hovedkategori',
        Pleaseselectpaymentmethod: 'Vennligst velg betalingsmåte',
        Pleaseselectaproduct: 'Vennligst velg produkter',
        Pleaseselectaproductfirst: 'Velg en vare først',
        Pleaseselectatimerange: 'Vennligst velg et tidsrom',
        Pleasechooseapaymentmethod: 'Velg betalingsmåte',
        Pleaseselecttheshippingtime: 'Velg leveringstidspunkt',
        Pleaseselectatype: 'Vennligst velg type',
        Pleaseselectapricetype: 'Velg en pristype',
        Pleaseselectacolumn: 'Vennligst velg en kolonne',
        Pleaseselectalogisticscompany: 'Velg et logistikkselskap',
        Pleaseselectadate: 'Vennligst velg en dato',
        SpecialSEO: 'Tematisk SEO',
        informationservice: 'Informasjonstjenester',
        CanIcontinuetoapply: 'Vil du fortsette å søke?',
        Unconfirmed: 'ubekreftet',
        Thereiscurrentlynodataavailable: 'Ingen data tilgjengelig',
        Unpaid: 'ubetalt',
        Notremitted: 'Ingen overføring',
        Noresponse: 'Ingen svar',
        Nojump: 'Ingen hopp',
        Automaticallysynchronizeornot: 'Skal synkronisering skje automatisk?',
        AutomaticallysynchronizeEBP: 'Skal EBP synkroniseres automatisk?',
        Synccompleted: 'Synkronisering fullført',
        Synchronizetootherlanguages: 'Synkronisere til andre språk',
        Synchronizetoothertenants: 'Synkronisere til andre leietakere',
        SynchronizeEBPproductinformation: 'Synkronisering av EBP-produktinformasjon',
        Doyouwanttoforcesynchronization: 'Tvungen synkronisering eller ikke?',
        SynchronizeSKU: 'Synkronisere SKU-er',
        SKU_EAN_Encoding: 'SKU/EAN/kode',
        ApplicationDetails: 'Detaljer om søknaden',
        Applyforinvoice: 'Be om en faktura',
        Returnandexchangeapplication: 'Forespørsel om retur',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Kun overførte bestillinger kan bekreftes',
        Onlypendingorderscanbeshipped: 'Kun ventende bestillinger kan sendes',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Det er kun mulig å angi opptil to desimaler',
        Pleaseuploadtheicon: 'Vennligst last opp et ikon',
        Uploaded: 'Lastet opp',
        MoveUp: 'Flytt opp',
        Uploadingimagesonlysupports: 'Last bare opp bilder i jpg, png, gif, jpeg',
        UploadVideo: 'Last opp en video',
        Uploadimages: 'Last opp et bilde',
        MerchandiseSubtotal: 'Delsum varer',
        Batchdelisting: 'Masseavnotering',
        Whethertosubscribe: 'Abonner eller ikke',
        Subscribing: 'Pågående abonnement',
        size: 'Størrelse',
        Imagesize: 'Størrelse på bildet',
        Suggestedsize: 'Foreslått størrelse',
        evaluate: 'Pris',
        Basicfreight: 'Grunnleggende fraktpriser',
        ActivityTopic: 'Emne for hendelse',
        Colortemperature: 'Fargetemperatur',
        Pleasenote2: 'Vennligst merk',
        Richtextornot: 'Rik tekst eller ikke',
        Applicationtime: 'Tidspunkt for søknaden',
        Combinationtime: 'Kombinasjonstid',
        Evaluationtime: 'tidspunktkommentar',
        Consultationtime: 'Tidspunkt for konsultasjon',
        Creationtime: 'Tidspunkt for opprettelse',
        Deliverytime: 'Tidspunkt for forsendelse',
        Importtime: 'Tidspunkt for import',
        Rebatetime: 'Tidspunkt for refusjon',
        Manualshippingoperationtime: 'Operasjonstid for manuell levering',
        Paymenttime: 'Tidspunkt for betaling',
        Collectiontime1: 'Tidspunkt for innkreving',
        Replytime: 'Tidspunkt for svar',
        Audittime: 'Tidspunkt for gjennomgang',
        Usagetime: 'Tidspunkt for bruk',
        type: 'Type',
        FreightType: 'Type forsendelse',
        Discounttype: 'Tilbudstype',
        Originalordertype: 'Opprinnelig ordretype',
        Aftersalestype: 'Type ettersalg',
        JumpType: 'Type hopp',
        taxrate: 'Avgiftssats',
        title: 'Tittel',
        PageTitle: 'Tittel på siden',
        ContentTitle: 'Innholdstittel',
        ProductTitle: 'Produkttittel',
        Synchronizeproducttitlesbylanguage: 'Produkttittel: Synkroniser tittelen på det aktuelle språket etter språk',
        whole: 'Alle språk',
        total: 'Totalt',
        Totalnumber: 'Totalt',
        Converttoformaldocument: 'Overfør til formell bestilling eller ikke',
        Positiveconversionsingle: 'Overføring til formell bestilling',
        Whethertotransfertoformal: 'overføre til formell eller ikke?',
        freight: 'frakt',
        Deliverytimeprompt: 'Leveringstid Tips',
        position: 'Sted',
        Lastlogintime: 'Siste innloggingstid',
        Lastweek: 'Siste uke',
        Thisisalreadythelastitemmovedownfailed: 'Allerede siste vare. Flytting ned mislyktes',
        Lastmonth1: 'Siste måned',
        LastThreeMonths: 'De siste tre månedene',
        PageURL: 'Side-URL',
        user: 'Bruker',
        Inviteduserplacesanorder: 'Inviterte brukere som legger inn bestilling',
        Inviteduser: 'Inviterte brukere',
        UsingUsers: 'brukende brukere',
        Useordernumber: 'Bruk ordrenummer',
        Used: 'Brukt',
        Effective: 'Gyldig',
        Parametervalue: 'Parameterverdi',
        Productvariants: 'Produktvariant',
        popupnotification: 'Popup-vindu',
        check: 'Visning',
        ViewInformationServices: 'Vis informasjonstjeneste',
        video: 'Video',
        Bound: 'Bundet',
        Picturepreview: 'Forhåndsvisning av bilde',
        VideoPreview: 'Forhåndsvisning av video',
        BatchCloseDisplay: 'Lukk batch-visning',
        Batchdisplay: 'Batch-visning',
        Isthehomepagedisplayed: 'Visning av hjemmeside eller ikke?',
        volume: 'Volum',
        Resubscribe: 'Abonnere på nytt',
        Areallclosed: 'Skal alle lukkes?',
        Zloti: 'Zloty',
        SciencePopularizationZone: 'Populærvitenskapelig område',
        Lightstripzone: 'Område for LED-lysstriper',
        Prohibitedsalesarea: 'Forbudte salgsområder',

        ReadmeFile: 'Les mer-fil',
        HelpCenter: 'Hjelpesenter',
        FileUpload: 'Filopplasting',
        Globalization: 'Globalisering',
        SalespersonProfile: 'Profil for selger',
        BatchSyncPage: 'Side for batch-synkronisering',
        CountryConfigurationPage: 'Konfigurasjonsside for land',
        UserConsultation: 'Brukerkonsultasjon',
        InvoiceManagement: 'Fakturahåndtering',
        PreSaleItems: 'Varer før salg',
        EventManagement: 'Håndtering av arrangementer',
        BlankPage: 'Tom side',
        ThereAreCurrentlyNoProductPromotions: 'Det er for øyeblikket ingen produktkampanjer',
        EditInventoryBottomLine: 'Redigere lagerbeholdningens bunnlinje',
        SetAFixedPrice: 'Angi en fast pris',
        EnterUpTo500Entries: 'Legg inn opptil 500 oppføringer',
        CantRelateToYourself: 'Kan ikke relateres til deg selv',
        PleaseSelectTheApplicableUserType: 'Vennligst velg den aktuelle brukertypen',
        TheActivityDescriptionLimitIs10000Words: 'Aktivitetsbeskrivelsen har en grense på 10 000 ord',
        EnabledStatus: 'Aktivert status',
        Enable: 'Aktivere',
        OperatingTime: 'Driftstid',
        NewUserPromotionEnabled: 'Aktivering av kampanje for nye brukere',
        NewUserPromotionDisabled: 'Kampanje for nye brukere deaktiver',
        OldUserPromotionEnabled: 'Kampanje for eksisterende bruker aktivert',
        OldUserPromotionDisabled: 'Kampanje for eksisterende bruker deaktivert',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'Kampanjeprisen kan ikke være lavere enn den laveste prisen.',
        AutomaticallyCalculateAfterEnteringPrice: 'Beregnes automatisk etter at prisen er angitt',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'Kampanjeprisen for nye brukere er høyere enn kampanjeprisen for gamle brukere. Vil du fortsette?',
        PleaseSelectPromotionCountry: 'Vennligst velg kampanjeland',
        PromotionDiscount: 'Kampanjerabatt',
        PleaseEnterThePromotionalQuantity: 'Vennligst skriv inn kampanjekvantumet',
        PleaseEnterVirtualQuantity: 'Vennligst skriv inn virtuelt antall',
        NumberOfUserPurchases: 'Antall brukerkjøp',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Angi kjøpsgrensen for samme bruker',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Angi kjøpsgrensen for en enkelt bestilling',
        QuantityGreaterThan0: 'Antall større enn 0',
        GrossProfitMarginCalculationResults: 'Resultater for beregning av bruttofortjenestemargin',
        FixedPriceGrossMargin: 'Bruttofortjenestemargin for fastpris',
        SellingPriceGrossProfitMargin: 'Bruttofortjenestemargin for salgspris',
        PromotionalPriceGrossProfitMargin: 'Bruttofortjenestemargin for kampanjepris',
        PromotionStartTime: 'Starttidspunkt for kampanjen',
        PromotionEndTime: 'Slutttidspunkt for kampanjen',
        PromotionType: 'Type kampanje',
        PleaseSetTheSKUToPromotionalProducts: 'Vennligst sett SKU-en til ${xxx} kampanjeprodukter',
        DistributionOffers: 'Distribusjonstilbud',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Når du har angitt antall{Any} vil det automatisk bli beregnet basert på antallet av produktet.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: 'Antall brukerkjøp må være større enn 0.',
        PleaseSelectCountry: 'Vennligst velg ${typeName} land',

        RequiredField: 'Påkrevd',
        PDFFormat: 'PDF-format',
        FileSize3MB: 'Filstørrelse <3 MB',
        Upload: 'Last opp',
        UploadFailed: 'Opplasting mislyktes',
        UploadFailedPDFFormatRequired: 'Opplasting mislyktes, PDF-format kreves',
        AuditFailedPleaseUploadInvoiceAttachment: 'Gjennomgangen mislyktes, vennligst last opp fakturavedlegget.',
        PleaseUploadInvoiceAttachment: 'Vennligst last opp fakturavedlegget',
        InvoiceAttachment: 'Vedlegg til faktura',
        PleaseSelectAtLeastTheApplicableUserType: 'Velg minst den aktuelle brukertypen',
        PleaseEnterTheRedeemCode: 'Vennligst skriv inn innløsningskoden',
        ApplicableUserRange: 'Gjeldende brukerområde',
        NoProductIsSelectedWhetherToClose: 'Ingen produkt er valgt. Vil du lukke？',
        DeliveryCountry: 'Leveringsland',
        MemberSettings: 'Medlemsinnstillinger',
        PleaseEnterTheAccountName: 'Vennligst skriv inn kontonavnet',
        MembershipEndDateCannotBeLessThanMembershipStartDate: 'Sluttdato for medlemskap kan ikke være mindre enn startdato for medlemskap',
        CharactersLimited: 'Begrenset til 1 til 30 tegn',
        NumberOfDifferentIPsToAccessTheURL: 'Antall forskjellige IP-adresser som besøker URL-adressen',
        TheNumberOfDifferentIPAddressesForTheFirstVisitToTheWebsite: 'Antall forskjellige IP-adresser for det første besøket på nettstedet',
        TheTotalOrderAmountPaidAvailableForShipmentShippedCompleted: 'Totalt betalt ordrebeløp (tilgjengelig for forsendelse, sendt, fullført)',
        QuantityOfPaidOrderAmountAvailableForShipmentShippedCompleted: 'Antall betalte ordrebeløp (tilgjengelig for forsendelse, sendt, fullført)',
        BatchRemoval: 'Massefjerning',
        ConfirmBatchDeletionOfSelectedProducts: 'Bekreft massefjerning av valgte produkter',
        RegisterOrNot: 'Registrere eller ikke?',
        FromSubscriptionTime: 'Fra abonnementsperiode',
        EndOfSubscriptionPeriod: 'Slutten av abonnementsperioden',
        SubscriptionManagementExport: 'Eksport av abonnementsadministrasjon',
        FromTheEventStartTime: 'Fra hendelsens starttidspunkt',
        UntilTheEventStartTime: 'Frem til hendelsens starttidspunkt',
        EventEndTime: 'Hendelsens sluttidspunkt',
        EventStartTime: 'Starttidspunkt for hendelse',

        SignUpForSubscription: 'Registrer deg for abonnement',
        EnterYourEmailAddressToSubscribe: 'Skriv inn e-postadressen din for å abonnere',
        DownloadTheLatestProductInformationSheet: 'Last ned det nyeste produktinformasjonsarket',

        Full: 'For hver',
        Reduce: 'Reduser',
        Superimposable: 'Overlagringsbar',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Etter at du har angitt kampanjekvantumet, beregnes det automatisk basert på varekvantumet.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: 'Opphev først tilknytningen i [Produktpool for rabatterte produkter].',
        EditBottomLineInventory: 'Rediger lagerbeholdningens bunnlinje',
        PleaseConfigureFixedPrice: 'Vennligst konfigurer fast pris',
        BalancePayment: 'Restbetaling',
        Deposit: 'Innskudd',

        EndofValidity: 'Vennligst oppgi utløpsdato',
        Year: 'År',
        Month: 'Måned',
        Day: 'Dag',
        UnitOfValidity: 'Gyldighetsenhet',
        AIGOCoinDetails: 'Informasjon om AIGO-mynter',
        AIGOCoinsAreCloseToExpiring: 'AIGO-mynter er nær ved å utløpe(Dag)',
        CountryOfShipmentAddress: 'Land for forsendelsesadresse',

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Anslått leveringstidspunkt',
        DataCorrespondingToTheSource: 'Data som tilsvarer kilden',
        AccountStatus: 'Kontostatus',
        OrderID: 'Ordre-ID',
        RecipientName: 'Navn på mottaker',
        RecipientLastName: 'Mottakerens etternavn',
        MailsOfUser: 'Brukerens e-post',
        StateProvince: 'Stat/provins',
        CityTown: 'By/tettsted',
        County: 'Fylke',
        ReceivingCompany: 'Mottakende selskap',
        BatchInput: 'Batch-inndata',
        InputManually: 'Legg inn manuelt',
        ProductCommentsInputTemplate: 'Mal for innlegging av produktkommentarer',
        UserName: 'Brukernavn',
        Comments: 'Kommentarer',
        StarRatingOfTheComment: 'Stjernevurdering av kommentaren',
        CommentTime: 'Tid for kommentar',
        UploadAttachment: 'Last opp vedlegg',
        NumberOfAttachments: 'Antall vedlegg',
        FileName: 'Filens navn',
        Appendix: 'Vedlegg',
        BatchInputOfProductComments: 'Batchinnlegging av produktkommentarer',
        SoldOut1: 'Utsolgt',
        PreSalePromotionID: 'ID for forhåndssalgskampanje',
        PromotionAbnormal: 'Unormal kampanje',
        Updater: 'Oppdaterer',
        AddPreSalePromotion: 'Legg til forhåndssalgskampanje',
        EditPreSalePromotion: 'Rediger forhåndssalgskampanje',
        Barcode: 'Strekkode',
        PreSaleCountries: 'Land for forhåndssalg',
        PreSalePrice: 'Pris ved forhåndssalg',
        PreSaleDeposit: 'Depositum ved forhåndssalg',
        PleaseEnterTheDocumentCode: 'Vennligst skriv inn dokumentkoden',
        BalancePaymentDeadline: 'Frist for betaling av saldo',
        FixedShippingFee: 'Fast fraktgebyr',
        PreSaleShippingFee: 'Fraktgebyr ved forhåndssalg',
        FreeShippingSubjectToConditions: 'Gratis frakt på visse betingelser',
        OrderPrice: 'Pris ved bestilling',
        PleaseEnterPriceConditions: 'Vennligst oppgi prisbetingelser',
        TheEndTimeNeedsToBeLaterThanTheStartTime: 'Slutttidspunktet må være senere enn starttidspunktet',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'Det er allerede delvis overlappende kampanjer i dette landet, vennligst endre',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'Forhåndssalgsantallet kan bare være et positivt heltall større enn 0',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: 'Den estimerte leveringstiden må være senere enn sluttidspunktet for arrangementet',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'Betalingsfristen for restbeløpet må være tidligere enn den estimerte leveringstiden',
        PromotionID: 'Kampanje-ID',
        DistributionPromotionID: 'ID for distribusjonskampanje',
        CouponID: 'Kupong-ID',
        PromotionManagementID: 'ID for kampanjeadministrasjon',
        PortfolioManagementID: 'ID for porteføljeadministrasjon',
        ExportForFiltering: 'Eksport for filtrering',
        ListDisplay: 'Visning av liste',
        InventoryItems: 'Varer på lager',
        InStock: 'På lager',
        OutOfStock: 'Ikke på lager',
        FieldName: 'Navn på felt',
        PleaseSelectInventory: 'Vennligst velg lager',
        PreSaleEvent: 'Forhåndssalg hendelse',
        PerPage: 'inntrykk per side',

        // V1.3.3 end

        // V1.3.4 start
        PleaseProvideTheNameOfTheLandingPageActivity: 'Vennligst fyll inn navnet på landingssideaktiviteten.',
        PleaseIndicateTheNumberOfActivities: 'Vennligst fyll inn antall aktiviteter.',
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: 'Aktivitetsmengden må være større enn mengden som gis bort.',
        PleaseChooseATimeForTheEvent: 'Vennligst velg en tid for arrangementet.',
        ActivityNameExceedsLength: 'Aktivitetsnavnet overskrider lengden.',
        ThisItemIsRestrictedToAMaximumOf10PerOrder: 'Dette elementet er begrenset til 10 per bestilling.',
        PleaseChooseTheGiveawayAgain: 'Vennligst velg utdelingsgave på nytt.',
        AnalysisOfGiveawayActivities: 'Analyse av giveaway-aktiviteter',
        StatisticsOfGiveawayActivities: 'Statistikk for gavebortaktiviteter',
        PleaseEnterTheNameOfTheEvent: 'Vennligst fyll inn navnet på arrangementet.',
        PleaseSelectTheActivityType: 'Vennligst velg aktivitetstype.',
        PleaseCompleteTheActivityRating: 'Vennligst fyll ut aktivitetsvurderingen.',
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Vennligst konfigurer {Any} forhåndssalgskampanjen før du konfigurerer landet der forhåndssalgsartiklene skal lagres.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: 'Ordrebeløpet og antall gratisartikler kan ikke være lavere enn forrige tier.',
        SelectImage: 'Velg bilde',
        GiftInformation: 'Gaveinformasjonen',
        Gifts: 'Gaver',
        GiftsOfEndOfEvent: 'Gaver ved slutten av arrangementet',
        CheckOutTheGifts: 'Sjekk ut gavene',
        GiveawayEvent: 'Giveaway-arrangement',
        SelectAGift: 'Velg en gave',
        YouHaveNotSelectedAGift: 'Du har ikke valgt en gave',
        NoNeedForGifts: 'Ikke behov for gaver',
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Noen gaver er utsolgt – vennligst velg gave på nytt',
        ReplaceAGift: 'Bytt ut en gave',
        ThereAreCurrently3FreeGiftsAvailable: 'Det er for øyeblikket 3 gratis gaver tilgjengelig',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Det er for øyeblikket 3 gratis gaver tilgjengelig, men du mangler 99 € for å få fire gratis gaver.',
        GiftList: 'Liste over gaver',
        GiftID: 'Gave-ID',
        CurrentInventory: 'Nåværende beholdning',
        NotEnabled: 'Ikke aktivert',
        GoodsForFree: 'Gratis varer',
        ConfirmToDeleteThisGiftInformation: 'Bekrefter du at du sletter denne gaveinformasjonen?',
        GiftsSortingManagement: 'Administrasjon av gavesortering',
        GiftsTopicManagement: 'Administrasjon av gavetemaer',
        GiveawayEventID: 'ID for giveaway-arrangement',
        GiveawayEventName: 'Navn på giveaway-arrangement',
        ProductCategory: 'Produktkategori',
        ActivityMode: 'Aktivitetsmodus',
        TotalNumberOfActivities: 'Totalt antall aktiviteter',
        GiftsGivenQuantity: 'Antall gaver gitt ',
        ThemeConfiguration: 'Temakonfigurasjon',
        GiftSorting: 'Sortering av gaver',
        GlobalConfiguration: 'Global konfigurasjon',
        ActiveCountry: 'Aktivt land',
        PromotionalPpicture: 'Reklamebilde',
        RecommendedSizeX: 'Anbefalt størrelse: {XXX*XXX}',
        FileSize1MB: 'Filstørrelse: ≤1 MB',
        ActivitySorting: 'Sortering av aktiviteter',
        DragToSort: 'Dra for å sortere',
        ActivityCode: 'Aktivitetskode',
        GiftsForOrderAmount: 'Gaver for bestillingsbeløp',
        ActivityRating: 'Vurdering av aktivitet',
        GiftsQuantity: 'Antall gaver',
        EventGiftsSorting: 'Sortering av arrangementsgaver',
        DefaultSelectionOfUsers: 'Standardvalg av brukere',
        LandingPageDisplay: 'Visning av landingsside',
        LandingPageActivityName: 'Navn på landingssideaktivitet',
        ConfirmDeleteThisGiftEvent: 'Bekrefte sletting av dette gavearrangementet?',
        IsThereAGiftEvent: 'Finnes det et gavearrangement?',
        OrderQuantity: 'Antall bestillinger',
        NumberOfGiftsIssued: 'Antall utstedte gaver',
        CustomerPrice: 'Kundepris',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: 'Funksjonen for antall virtuelle arrangementer kan bare angis hvis forhåndssalgsaktiviteten varer i minst 2 dager og antallet arrangementer er større enn 50.',
        PreSaleSwitch: 'Bryter for forhåndssalg',
        PreSaleActivity: 'Forhåndssalgsaktivitet',
        GiftManagement: "Gavehåndtering",

        // V1.3.4 end

        //V1.3.5 start
        OrderSource: "Bestillingskilde",
        NumberOfNewUserRegistrations: "Antall nye brukerregistreringer",
        RegisteredUsersWhoVsitTheWebsite: "Registrerte brukere som besøker nettstedet",
        SoldDistributionQuantity: "Solgt distribusjonsmengde",
        DistributionQuantityLock: "Låsing av distribusjonsmengde",
        InsufficientDstributionQuantityRemaining: "Utilstrekkelig gjenværende distribusjonsmengde",
        Previous: "Forrige",
        Next: "Neste",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Håndtering av oppskrifter",
        RecipeDisplayImage: "Bilde for visning av oppskrift",
        NewRecipeDisplayImage: "Rediger/opprett nytt bilde for visning av oppskrift",
        CoverPicture: "Forsidebilde",
        PicturesVideos: "Opptil 10 bilder + videoer kan lastes opp, hvert bilde bør være ≤5 MB, og video i .mp4-format støttes",
        AddPicturesVideos: "Legg til bilder/videoer",
        CommenOnPicturesVideos: "Kommenter bilder/videoer",
        FileSize: "Filstørrelse",
        BarcodeAssociated: "Tilhørende strekkode",
        FirstUploadTime: "Tid for første opplasting",
        EditTime: "Tidspunkt for redigering",
        TagType: "Type tagg",
        FileName1: "Filnavn",
        NameLengthISTooLong: "Navnet er for langt",
        ProductDoesNoExist: "Produktet finnes ikke",
        PleaseUploadImages: "Vennligst last opp jpg/png/jpeg-bilder under 5 MB",
        PleaseUploadTheVideoMP4: "Vennligst last opp videoen i mp4-format",
        PictureOrVideo: "Bildet eller videoen kan ikke være tom",
        allowed: "Tillat alle",
        cookie1: "Håndtering av informasjonskapsler",
        collectsordinances: "Aigostar bruker informasjonskapsler for å forbedre brukeropplevelsen og for markedsføringsformål. Se og administrer innstillingene for informasjonskapsler nedenfor. Hvis du vil vite mer om hvordan vi bruker informasjonskapsler, kan du lese personvernerklæringen vår.",
        RequiredCookies: "Nødvendige informasjonskapsler",
        cookie2: "Disse informasjonskapslene er nødvendige for kjernefunksjonalitet som innlogging, bruk av handlekurven og betaling. Nødvendige informasjonskapsler kan ikke slås av. Men slapp av, disse informasjonskapslene lagrer ingen av opplysningene dine.",
        AnalyticsCookies: "Informasjonskapsler for markedsføring og analyse",
        cookie3: "Disse informasjonskapslene samler inn informasjon som antall besøk på nettstedet eller hvilke sider som er populære, for å hjelpe oss med å forbedre kundeopplevelsen. Du kan velge å slå av disse informasjonskapslene, men da vil vi ikke kunne samle inn den informasjonen vi trenger for å forbedre opplevelsen din på nettstedet vårt.",
        StoragePreferences: "Lagre preferanser",
        BuyItNow: "Kjøp nå",
        PictureVideoSize: "Størrelsen eller formatet på bildet eller videoen er ikke riktig",
        RecipeConfiguration: "Oppskriftskonfigurasjon",

        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Bekreft synkronisering av understrekkoder?",
        SynchronizationEbpEanSUB: "På grunn av behovet for å grensesnitt med EBP, forventes batch-synkronisering å være fullført innen 3 minutter. Etter bekreftelse vil synkroniseringen fortsette asynkront, og understrekkodene vil bli vist umiddelbart ved mottak. Vennligst vær tålmodig.",
        Keywords: "Nøkkelord",
        SystemAutoUpdate: "System auto-oppdatering",
        IPAddress: "IP-adresse",
        SearchKeywords: "Søk nøkkelord",
        LoginEmail: "Påloggings e-post",
        SearchTime: "Søketid",
        NumbeOfTimes: "Antall ganger",
        MyBrowsing: "Min surfing",
        Logout: "Logg ut",
        LoggingOuOfYouAccount: "Å logge ut fra kontoen din vil bli betraktet som din frivillige avståelse, og du vil ikke lenger kunne fortsette å bruke denne kontoen.",
        RequestLogout: "Be om å logge ut",
        ProductsYet: "Du har ikke surfet på noen produkter ennå",
        clearall: "Tøm historikk",
        CancellTip1: "Handlekurvlistene, ordrekjøpspostene og favorittlistene vil bli permanent slettet og kan ikke gjenopprettes.",
        CancellTip2: "All kontoinformasjon, tjenesteposter, forbrukdata osv., på plattformen vil bli permanent slettet og kan ikke gjenopprettes.",
        CancellTip3: "Du vil ikke kunne logge inn eller bruke denne AigoSmart-kontoen.",
        CancellTip4: "Personlig informasjon og historiske meldinger (inkludert avatar, kallenavn, min Aiguo-valuta, plattformvarsler, mine kommentarer, osv.) assosiert med din AigoSmart-konto vil ikke være gjenopprettbare.",
        CancellTip5: "Du må håndtere kontoens midler (poeng, medlemsnivåer, kvalifikasjoner, bestillinger, kuponger, rekvisitter og andre verdifulle tjenester) riktig før du logger av, siden kontoavslutning vil bli betraktet som din frivillige avkall og kan ikke reverseres. Du forstår og samtykker i at AigoSmart ikke kan hjelpe deg med å gjenopprette de nevnte tjenestene.",
        SynchronizeSubBarcodes: "Synkroniser underkoder",
        PleaseEnterKeywords: "Vennligst skriv inn nøkkelord",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Timebasert statistikk",
        NumbeOfUsers: "Antall brukere som har lagt inn bestillinger",
        NumberOfCanceled: "Antall kansellerte bestillinger",
        RefundAmount: "Refusjonsbeløp",
        UsersWhoHavePaid: "Brukere som har betalt (bestillinger som kan sendes, sendt, fullført)",
        TotalNumber: "Totalt antall kansellerte bestillinger fra plattformen",
        AmountOfCanceled: "Beløp på kansellerte bestillinger som er betalt, men ikke sendt, beløp på returnerte bestillinger som er betalt (under transport, levert)",
        RedemptionCode: "ID/Kupongnavn/Innløsningskode",
        DeleteAll: "Slett",
        AccountIsUnderReview: "Den nåværende kontoen er under deaktiveringsgjennomgang. Fortsatt innlogging vil avbryte deaktiveringsprosessen. Vil du fortsette å bruke den?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "Tid kan ikke være tom",
        ProductImporTemplate: "Produktimportmal",
        NormalOrdersExceed: "Normale bestillinger overstiger:",
        OrderSautomaticallyClosed: "Ubetalte bestillinger vil automatisk bli stengt",
        OrdeIsShipped: "Etter at bestillingen er sendt:",
        AutomaticallyComplete: "Fullfør bestillingen automatisk",
        Minutes: "Minutter",
        Days: "Dager",
        OrderSettings: "Bestillingsinnstillinger",
        ClearCache: "Tøm cache",
        PleaseEnterNavigationContent: "Vennligst skriv inn navigasjonsinnhold",
        WebsiteNavigationConfiguration: "Nettstednavigasjonskonfigurasjon",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Forsinkelsestid for popup",
        SubscriptionPopup: "Abonnerings-popup",
        MeansNoPopupDelay: "Vennligst skriv inn popup-vinduets forsinkelsestid",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "UGC-området",

        //V1.5.0 end

        //V1.6.0 start
        SynchronizeEbpPrice: "Synkroniser EBP-pris",
        ConfirmToSynchronizeEbp: "Bekreft for å synkronisere EBP-pris",
        AsynchronousEbp: "Etter bekreftelse er synkroniseringen asynkron. Prisen vises umiddelbart etter at den er innhentet. Vennligst vent.",
        CopywritingDisplay: "Visning av tekstforfattere",
        HomepageCopywriting: "Tekst til hjemmesiden",
        EditHomepageCopywriting: "Rediger hjemmesidetekst",
        SuperimposedEvents: "Vil du legge til arrangementer?",
        GiftsEvents: "(Gavene for dette arrangementet kan ikke samles sammen med andre arrangementer)",
        SmallGiftEvent: "Liten gavebegivenhet",
        SpecialProductEvent: "Spesiell produktbegivenhet",
        SpecialGiftEvent: "Gratis gave fra liten gavebegivenhet og spesiell begivenhet kan ikke samles sammen",
        ProductsWithSKU: "Vennligst velg produkter med en SKU",
        LimitWereSelected: "Gjeldende grense er {limitNum} filer. Denne gangen ble {selectNum} filer valgt.",
        FileUploadFailed: "Filopplasting mislyktes!",
        CompleteMultiLanguage: "Fullfør flerspråklig konfigurasjon",
        FirstSynchronizeSKU: "Vennligst synkroniser SKU-en for dette produktet først.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Om kupongen skal offentliggjøres",
        RedemptionCode1: "Innløsningskode",
        NowOnlyNeed: "Nå trenger du bare €10",
        WebLogReporting: "Weblogg-rapportering",
        PageAddress: "Adresse til siden",
        ErrorLogs: "Feillogger",
        CustomParameter: "Egendefinert Parameter",
        ParameterID: "Parameter-ID",
        // ParameterName: "Parameternavn",
        SearchParameter: "Søkparameter",
        EnterParameterName: "Vennligst skriv inn parameterens navn",
        ParameterType: "Parametertype",
        AssociatedProductCount: "Antall Tilknyttede Produkter",
        MandatoryPortugueseContent: "Obligatorisk portugisisk innhold",
        MandatoryEnglishContent: "Obligatorisk engelsk innhold",
        MandatoryDutchContent: "Obligatorisk nederlandsk innhold",
        MandatoryPolishContent: "Obligatorisk polsk innhold",
        MandatoryFrenchContent: "Obligatorisk fransk innhold",
        MandatoryGermanContent: "Obligatorisk tysk innhold",
        MandatoryItalianContent: "Obligatorisk italiensk innhold",
        MandatorySpanishContent: "Obligatorisk spansk innhold",
        MandatoryChineseContent: "Obligatorisk kinesisk innhold",
        ImportTemplate: "Importmal for tilpassede parametere for produkter",
        ConfirmImportFile: "Bekreft importfil, fortsette?",
        SelectDataRemove: "Velg data som skal fjernes",
        ImportTemplateDownload: "Last ned importmal",
        AssociatedProducts: "Tilknyttede produkter",


        //V1.7.0 end

        //V1.8.0 start
        Published: "Publisert",
        CanBeIgnored: "Kan ignoreres",
        Resolved: "Løst",
        ActivityType: "Aktivitetstype",
        PromotionActivity: "Promoteringsaktivitet",
        DistributionActivity: "Distribusjonsaktivitet",
        InputLink: "Skriv inn lenke",
        EnterAddress: "Vennligst skriv inn lenkeadressen",
        DetailPageAd: "Annonseplass på detaljsiden",

        //V1.8.0 end

        //V1.9.0 start
        ProductPromotionID: "Produktkampanje-ID.",
        PromotionalActivityCollection: "Samling av kampanjeaktiviteter.",
        PromotionalActivityName: "Navn på kampanjeaktivitet.",
        ProductPromotionBarcode: "Produktkampanje-ID/Strekode.",
        ProductPromotionIDCopy: "Produktkampanje-ID (dobbeltklikk for å kopiere alle ID-er).",
        ProductBarcode: "Produktstrekode (dobbeltklikk for å kopiere alle strekoder).",
        SelectPromotionalActivity: "Velg kampanjeaktivitet.",
        SelectPromotionalList: "Velg kampanjeliste.",
        TypesPerOrder: "Begrens antall varetyper per ordre.",
        PromotionalActivity: "Vennligst velg en kampanjeaktivitet.",
        ProductAlready: "Dette produktet har allerede en kampanjeaktivitet. Vennligst rediger.",
        PromotionalActivityNameID: "Kampanjenavn/ID",
        PromotionalCollectionName: "Navn på kampanjesamlingen.",
        SelectedPromotionalActivity: "Valgt kampanjeaktivitet.",
        PromotionalCollectionActivit: "En kampanjesamlingsaktivitet finnes allerede. Vennligst rediger.",

        EnterHholidayTheme: "Vennligst skriv inn et høytidstema.",
        HolidayTheme: "Høytidstema.",
        HolidayImage: "Høytidsbilde.",
        AddLogoImage: "Legg til logo bilde.",
        PleaseProceedEdit: "Vennligst rediger.",
        SKUBIDActivities: "SKU: {sku}, det er allerede {country} aktiviteter under {activity}.",
        LogImageManagement: "Logo-bildeadministrasjon",
        LogoManagement: "Logo-administrasjon",

    //V1.9.0 end

    }
};
export default nb;