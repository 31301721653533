import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取产品列表
    getLogoConfigPage({ state, commit }, params) {
        let promise = httpV2.post(`/b2c/v1.0/logo/config/page/search?page=${params.page}&limit=${params.limit}`, params, this);
        return promise;
    },
    addLogoConfig({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/logo/config", params, this);
        return promise;
    },
    putLogoConfig({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/logo/config/" + params.logoConfigId, params, this);
        return promise;
    },
    deleteLogoConfig({ state, commit }, params) {
        let promise = httpV2.delete("/b2c/v1.0/logo/config", params, this);
        return promise;
    },

    
};

export default {
    getters,
    state,
    mutations,
    actions
};