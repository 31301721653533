import zhCNLocal from 'element-ui/lib/locale/lang/zh-CN';
const cn = {
    ...zhCNLocal,
    common: {
        datastatistics: '数据统计',
        Transactionstatistics: '交易统计',
        LabelOptions: '标签选项',
        Orderdatastatistics: '订单数据统计',
        Hourlystatistics: '按小时统计',
        Countbyday: '按天统计',
        Spain: '西班牙',
        Italy: '意大利',
        Germany: '德国',
        France: '法国',
        poland: '波兰',
        Netherlands: '荷兰',
        Britain: '英国',
        UnitedStates: '美国',
        Portugal: '葡萄牙',
        SalesCountry: '销售国家',
        query: '查询',
        Placinganorder: '下单数',
        Orderamount: '订单金额',
        Numberofusersplacingorders: '下单用户数',
        Ordertime: '订单时间',
        time: '时间',
        usermanagement: '用户管理',
        UserID_loginemail_registrationinviterID_phonenumber: '用户ID/登录邮箱/注册邀请人ID/手机号',
        country: '国家',
        Whethertosubscribe: '是否订阅',
        Loginstatus: '登录状态',
        normal: '正常',
        Disabled: '禁用',
        Createdon: '创建日期',
        Lastlogintime: '最后登录时间',
        Reset: '重置',
        export: '导出',
        UserID: '用户ID',
        Registeredcountry: '注册国家',
        mailbox: '邮箱',
        Mobilephonenumber: '手机号',
        Patrioticcoin: '爱国币',
        RegistrationInviterID: '注册邀请人ID',
        brand: '品牌',
        Creationtime: '创建时间',
        operation: '操作',
        check: '查看',
        orderform: '订单',
        consultingservice: '咨询',
        address: '地址',
        LoginLog: '登录日志',
        Rechargepatrioticcoins: '充值爱国币',
        Returnsandexchanges: '退换货',
        evaluate: '评价',
        Shippingaddress: '收货地址',
        shoppingcart: '购物车',
        collection: '收藏',
        invoice: '发票',
        nickname: '昵称',
        nothing: '无',
        close: '关闭',
        source: '来源',
        Subscriptiontime: '订阅时间',
        Thirdpartylogin: '第三方登录',
        Bound: '已绑定',
        Unbound: '未绑定',
        Invitationcode: '邀请码',
        Inviteduser: '已邀请用户',
        Invitetoreceivepatrioticcoins: '邀请获得爱国币',
        Isthefirstorderfreeofshipping: '首单是否免运费',
        shopping: '购物',
        Collectproducts: '收藏商品',
        ShoppingCartItems: '购物车商品',
        PaymentOrder: '支付订单',
        Ordercumulativeamount: '订单累计金额',
        Lastordertime: '最后下单时间',
        Returnandexchangeorders: '退换货订单',
        Consultationfrequency: '咨询次数',
        Numberofevaluations: '评价数',
        Numberofapprovedevaluations: '审核通过评价数',
        ShowCountry: '展示国家',
        name1: '姓名',
        TaxID: '税号',
        province: '省份',
        city: '城市',
        Detailedaddress: '详细地址',
        Housenumber: '门牌号',
        AddressAdditionalContent: '地址附加内容',
        RecipientCompany: '收件人公司',
        Defaultshippingaddress: '默认收货地址',
        Defaultinvoiceaddress: '默认发票地址',
        ProductID1: '商品ID',
        Productname1: '商品名称',
        CollectionPrice: '收藏价格',
        Currentprice: '当前价格',
        Collectiontime1: '收藏时间',
        Additionalpurchaseprice: '加购价格',
        Additionalpurchasetime: '加购时间',
        Listingstatus: '上架状态',
        Listed: '已上架',
        Notlisted: '未上架',
        Numberofpatrioticcoins: '爱国币数量',
        Enteraquantitybetween0and1000: '输入0~1000之间的数量',
        Remarks: '备注',
        cancel: '取消',
        UserDetails: '用户详情',
        Areyousuretoexportthefile: '是否确认导出文件, 是否继续?',
        prompt: '提示',
        Exportsuccessful: '导出成功',
        today: '今天',
        Lastweek: '最近一周',
        Thismonth: '这个月',
        Lastmonth1: '上一个月',
        thisyear: '今年',
        Inputcannotbeempty: '输入不可以为空',
        Pleaseenterapositiveinteger: '请输入正整数',
        Pleaseenterthequantityofpatrioticcoins: '请输入爱国币数量',
        Pleaseenteracomment: '请输入备注',
        Pleaseselectacountry: '请选择国家',
        Successfullysubmitted: '提交成功',
        Nopatrioticcoinrulesconfigured: '未配置爱国币规则',
        Rechargefailed: '充值失败',
        Savefailed: '保存失败',
        ordermanagement: '订单管理',
        OrderList: '订单列表',
        Ordernumber: '订单号',
        Paymentcode: '支付编码',
        barcode: '条码',
        Pleaseselectasource: '请选择来源',
        OrderStatus: '订单状态',
        Unpaid: '未支付',
        Paid: '已支付',
        Shippable: '可发货',
        Shipped: '已发货',
        complete: '完成',
        Pleaseselectpaymentmethod: '请选择支付方式',
        Shipmentstatus: '发货状态',
        Notshipped: '未发货',
        Partialshipment: '部分发货',
        Returned: '已退货',
        Remittanceconfirmation: '汇款确认',
        Notremitted: '未汇款',
        Unconfirmed: '未确认',
        adopt: '通过',
        Notpassed: '不通过',
        Whethertomanuallyship: '是否手动发货',
        yes: '是',
        no: '否',
        Originalordertype: '原始订单类型',
        Presaleorder: '预售单',
        Officialorder: '正式单',
        Preorderstatus: '预售单状态',
        Deposittobepaid: '定金待支付',
        Depositpaid: '定金已支付',
        Finalpaymenttobemade: '尾款待支付',
        Finalpaymentpaid: '尾款已支付',
        Presalepaymentmethod: '预售付款方式',
        Finaldeposit: '定金尾款',
        Fullpayment: '全款',
        Whetherthepresaleorderisconvertedtoformal: '预售单是否转正式',
        Combinedorders: '组合订单',
        to: '至',
        Startdate: '开始日期',
        Enddate: '结束日期',
        Paymenttime: '支付时间',
        Pleaseenterthesource: '请输入来源',
        platform: '平台',
        Positiveconversionsingle: '转正式单',
        Numberofproductmodels: '商品款数',
        activity: '活动',
        money: '金额',
        Paymentmethod: '支付方式',
        Deliverytime: '发货时间',
        Whethertotransfertoformal: '是否转正式',
        Tobereplied_consulted: '待回复/咨询',
        detailed: '详细',
        Manualshipping: '手动发货',
        Manuallogisticsshipment: '手动物流发货',
        logisticscompany: '物流公司',
        Pleaseselectalogisticscompany: '请选择物流公司',
        Logisticstrackingnumber: '物流跟踪号',
        SelectDateTime: '选择日期时间',
        Shippingremarks: '发货备注',
        Pleasenote: '请备注',
        confirm1: '确定',
        state: '状态',
        Pleaseselect: '请选择',
        Pleaseselectastatus: '请选择状态',
        Homepagerecommendation: '首页推荐',
        Pleaseenterthelogisticstrackingnumber: '请输入物流跟踪号',
        Pleaseselecttheshippingtime: '请选择发货时间',
        edit: '编辑',
        Successfullyshippedmanually: '手动发货成功',
        Remittanceconfirmationsuccessful: '汇款确认成功',
        confirmdeletion: '确认删除',
        Successfullydeleted: '删除成功',
        Pleaseselectapresaleorder: '请选择预售单',
        Confirmtoconverttheorderintoaformalorder: '确认将订单转为正式单',
        success: '成功',
        OrderData: '订单数据',
        Areyousuretoexportthefileandcontinue: '是否确认导出文件, 是否继续',
        Basicinformationoftheorder: '订单基本信息',
        Useremail: '用户邮箱',
        Documentremarks: '单据备注',
        combination: '组合',
        PostalCode: '邮编',
        RecipientCompanyAddressAdditionalContent: '收件人公司，地址附加内容',
        Invoiceaddress: '发票地址',
        InvoiceStatus: '发票状态',
        Paymentinformation: '支付信息',
        Paymentstatus: '支付状态',
        coupon: '优惠券',
        freight: '运费',
        CouponName: '优惠券名称',
        Couponcode: '优惠券券码',
        Paymentamount: '支付金额',
        Trackingnumber: '追踪单号',
        Manualshippingoperator: '手动发货操作人',
        Manualshippingoperationtime: '手动发货操作时间',
        Bankremittance: '银行汇款',
        Remittancestatus: '汇款状态',
        Remittanceconfirmationoperator: '汇款确认操作人',
        Remittanceconfirmationoperationtime: '汇款确认操作时间',
        Preorderinformation: '预售单信息',
        Converttoformaldocument: '是否转正式单',
        Positiveconversionsinglemode: '转正式单方式',
        automatic: '自动',
        Manual: '手动',
        Positiveconversionsingletime: '转正式单时间',
        category1: '类别',
        Subordernumber: '子单号',
        Paymentdeadline: '支付截止时间',
        Addremarks: '添加备注',
        ProductInformation: '商品信息',
        Serialnumber: '序号',
        name: '名称',
        VariantInformation: '变体信息',
        Pricing_unitprice: '定价（单价）',
        Sellingprice_unitprice: '售价（单价）',
        quantity: '数量',
        Totalprice: '总价',
        MerchandiseSubtotal: '商品小计',
        total: '总计',
        CancelOrder: '取消订单',
        Areyousuretocancelthisorder: '确定取消该订单?',
        reason: '原因',
        Pleaseselectabrand: '请选择品牌',
        Pleaseselectacategory: '请选择类目',
        Successfullyadded1: '添加成功',
        Addfailed: '添加失败',
        Whethertosubmitdata: '是否提交数据',
        Canceledsuccessfully: '取消成功',
        Onlypendingorderscanbeshipped: '只有待发货订单可以发货',
        Existinglogisticstrackingnumber: '该订单已有物流跟踪号，不可提交',
        Thereareonlytworemittancestatuses: '汇款状态只能是通过或者不通过',
        Onlyordersthathavebeenremittedcanbeconfirmed: '只有已汇款订单可以确认',
        Orderdoesnotexist: '订单不存在',
        Thisordercannotbecancelled: '该订单不可以取消',
        Norulesconfigured: '当前租户未配置爱国币规则',
        Parametererror: '参数错误',
        Countrycodecannotbeempty1: '国家编码不能为空',
        Thecurrentcountrypricingisnotconfigured: '当前国家定价未配置',
        Thepriceconfigurationdoesnotexist: '价格配置不存在，请配置价格',
        Reasonforcancellationoforderisrequired: '取消订单原因必填',
        Exportorderinformation: '订单信息导出',
        OrderIDmustbepassed: '订单id必传',
        Youcanonlybatchprocessupto100itemsatatime: '一次最多只能批量处理100条',
        Notapresaleorder: '订单号为:101的订单是非预售单,请先取消选择',
        Transferredtoofficial: '订单号为:101的订单已转正式单,请先取消选择',
        Ordernotpaid: '订单号为:101的订单非已支付,请先取消选择',
        Nonpresaleorderscannotbeoperated: '非预售单不可操作',
        Transferredtoformalformandcannotbeoperated: '已转正式单不可操作',
        Nonpaidorderscannotbeoperated: '非已支付单不可操作',
        Orderremarksarerequired: '订单备注必填',
        Upto500wordscanbeentered: '最多可输入500字',
        common: '共',
        strip: '条',
        goto: '前往',
        page: '页',
        Returnandexchangeapplication: '退换申请',
        type: '类型',
        Return: '退货',
        Exchange: '换货',
        Auditstatus: '审核状态',
        Approved: '审核通过',
        Auditfailed: '审核失败',
        Inprogress: '进行中',
        Auditended: '审核结束',
        Processingstatus: '处理状态',
        Completed: '已完成',
        Applicationtime: '申请时间',
        Aftersalestype: '售后类型',
        ApplicationDescription: '申请说明',
        Numberofexplanatoryimages: '说明图片数量',
        Illustrativeimage: '说明图片',
        Returnlogisticstrackingcode: '寄回物流追踪码',
        CanIcontinuetoapply: '是否可以继续申请',
        Auditing: '审核',
        details: '详情',
        handle: '处理',
        Add1: '添加',
        ApplicationDetails: '申请详情',
        Exchangeitem: '换货商品',
        Returnedgoods: '退货商品',
        Audittime: '审核时间',
        Reviewedby: '审核人',
        result: '结果',
        Failed: '未通过',
        Thereplycontentwillbedisplayedtotheuser: '回复内容将展示给用户',
        Replycontent: '回复内容',
        Operator: '操作人',
        Pleaseenterthereason: '请输入原因',
        Pleasechoosewhethertocontinueapplying: '请选择是否可继续申请',
        Pleaseselectaresult: '请选择结果',
        Successfullymodified: '修改成功',
        Verificationfailed: '验证失败',
        Datadoesnotexist: '数据不存在',
        OrderNumber_UserID: '订单号/用户ID',
        ReplyStatus: '回复状态',
        Replied: '已回复',
        Noresponse: '未回复',
        MyOrder: '我的订单',
        refund: '退款',
        Canwedeliverontheagreeddate: '能在约定的日期交货吗？',
        Modifyshippingaddress: '修改收货地址',
        Otherissuesrelatedtotransportation: '有关运输的其他问题',
        Receivedincorrectproduct: '收到错误的产品',
        Receiveddamagedproductsandproductswithqualityissues: '收到损坏的产品和有质量问题的产品',
        RegulationsonReturnsandRefunds: '关于退货和退款的规定',
        Applyforinvoice: '申请发票',
        productdetails1: '产品详情',
        Thankyouletter: '感谢信',
        Otherissues: '其他问题',
        Respondent: '回复人',
        Consultationdate: '咨询日期',
        content: '内容',
        explain: '说明',
        Consultationtime: '咨询时间',
        Replytime: '回复时间',
        reply: '回复',
        OrderInformation: '订单信息',
        Consultationcontent: '咨询内容',
        Consultationinstructions: '咨询说明',
        EnterReply: '输入回复',
        Consultationrecords: '咨询记录',
        Replycontentisrequired: '回复内容必填',
        Replysuccessful: '回复成功',
        Successfullyedited: '编辑成功',
        Ordernumber_UserID_Taxnumber: '订单号/用户ID/税号',
        Unaudited: '未审核',
        Passed: '已通过',
        Notuploaded: '未上传',
        Uploaded: '已上传',
        AuditDescription: '审核说明',
        download: '下载',
        Applying: '申请中',
        Enterthereason: '输入原因,不通过时必须填写原因',
        Thereasonfornotpassingwillbedisplayedtotheuser: '不通过的原因将展示给用户',
        Pleaseenterareply: '请输入回复',
        Auditsuccessful: '审核成功',
        ProductManagement: '商品管理',
        Categorymanagement: '类目管理',
        Categoryname1: '类目名称',
        Isthehomepagedisplayed: '首页是否显示',
        Enableornot: '是否启用',
        search: '搜索',
        Directoryhierarchy: '目录层级',
        Category: '所属类目',
        sort: '排序',
        picture: '图片',
        Updatetime: '更新时间',
        Multilingualconfiguration: '多语言配置',
        Relatedimportantparameters: '关联重要参数',
        Associatesearchparameters: '关联搜索参数',
        delete: '删除',
        SelectAll: '全选',
        Imagesize: '图片尺寸',
        icon: '图标',
        intotal25: '共25条',
        Gotopage1: '前往1页',
        Chinese: '汉语',
        English: '英语',
        German: '德语',
        Polishlanguage: '波兰语',
        Italian: '意大利语',
        French: '法语',
        Dutchlanguage: '荷兰语',
        Spanish: '西班牙语',
        Pleaseenteraname: '请输入名称',
        Areyousureyouwanttodeleteit: '确定要删除吗',
        Categoryassociation: '类目关联',
        PrimaryKeyID: '主键ID',
        CatalogID: '目录ID',
        Addtime: '添加时间',
        Relatedcategories: '关联类目',
        Selectmaincategory: '选择主类目',
        addassociations: '添加关联',
        Maincategory: '主类目',
        ProductCatalogID: '产品目录ID',
        RelatedCategoryName: '关联类目名称',
        AddAssociatedCategory: '添加关联类目',
        Pleaseselectacategoryfirst1: '请先选类目',
        Productvariants: '商品变体',
        ID: 'ID',
        Add: '新增',
        Productquantity: '商品数量',
        Selectvariant: '选择变体',
        Selectparameters: '选择参数',
        commodity: '商品',
        Selectproduct: '选择商品',
        Colortemperature: '色温',
        frequency: '频率',
        power: '功率',
        style: '款式',
        MoveDown: '下移',
        MoveUp: '上移',
        Selectvariantparameters: '选择变体参数',
        SKU_EAN_Encoding: 'SKU/EAN/编码',
        Pleaseselectparameters: '请选择参数',
        Countrieslisted: '上架国家',
        ProductID: '产品ID',
        Productimage1: '产品图片',
        ProductName: '产品名称',
        VariantManagement: '变体管理',
        Class: '类目',
        Selected: '已选择',
        Thereiscurrentlynodataavailable: '暂无数据',
        Variantparameters: '变体参数',
        VariantName: '变体名称',
        CustomName: '自定义名称',
        Batchlisting: '批量上架',
        Pleaseselectaproduct: '请选择商品',
        Batchdelisting: '批量下架',
        Batchsearch: '批量搜索',
        Enterproductbarcodeandsubmitforaddition: '输入商品条码提交添加，多个条码换行输入',
        Enterproductbarcode: '输入商品条码，多个请换行输入，最多输入500条',
        Pleaseenterbarcode: '请输入条码',
        Editlistingcountry: '编辑上架国家',
        Pleasecompletetheproductinformationbeforelistingit: '请先完善商品信息再上架',
        ProductTitle: '商品标题',
        ProductLargePicture: '商品大图',
        commodityprice: '商品价格',
        Confirmtheexecutionofbatchdelisting: '确认执行批量下架',
        EditCategory: '编辑类目',
        SynchronizeSKU: '同步SKU',
        ConfirmsynchronizationofSKU: '确认同步SKU',
        SynchronizationrequiresdockingwithEBP: '由于同步需要和EBP对接，为避免出错，批量同步每30分钟可操作一次。确定后同步为异步进行，获得SKU后立即显示，请耐心等候。',
        Synccompleted: '同步完成',
        Setlabel: '设置标签',
        label: '标签',
        SynchronizeEBPproductinformation: '同步EBP产品信息',
        Afterturningonsynchronization: '开启同步后，按商品SKU同步EBP商品的以下信息',
        Synchronizeproducttitlesbylanguage: '商品标题：按语言同步对应语言的标题',
        Synchronizeproductimagesbylanguage: '商品大图：按语言同步对应语言的大图，读取EBP图片排序，EBP第一张大图自动设置为主图',
        Synchronizeproductimagesandtextbylanguage: '图文详情：按语言同步对应语言的',
        Productkeywords: '产品关键字',
        Automaticallysynchronizeornot: '是否自动同步',
        Doyouwanttoforcesynchronization: '是否强制同步',
        tenant: '租户',
        Noinventorydisplay: '无库存显示',
        Synchronizetoothertenants: '同步到其他租户',
        UpdateRecords: '更新记录',
        Importtime: '导入时间',
        number: '编号',
        file: '文件',
        UpdateResults: '更新结果',
        Downloadfiles: '下载文件',
        Isthereafixedprice: '是否有固定价格',
        Whethertopromoteornot: '是否促销',
        Inpromotion: '促销中',
        whole: '全部',
        Fixedprice1: '固定价格',
        Lowestprice: '最低价格',
        fixedprice: '定价',
        Promotionprice_unitprice: '促销价(单价)',
        price1: '售价',
        inventory: '库存',
        Inventorybottomline: '库存底线',
        AutomaticallysynchronizeEBP: '是否自动同步EBP',
        ForcesynchronizationofEBP: '是否强制同步EBP',
        Presaleornot: '是否预售',
        Evaluation_Shown_total: '评价(已展示/总数)',
        coding: '编码',
        ProductDetails: '商品详情',
        EditProduct: '编辑商品',
        Basicinformation: '基础信息',
        price: '价格',
        Pleaseentertheproducttitle: '请输入商品标题',
        Sizeinformation: '尺寸信息',
        weight: '重量',
        volume: '体积',
        Salesmultiples: '销售倍数',
        Numberofitems: '物品数量',
        EditFixedPrice: '编辑固定价格',
        Largeimage: '大图',
        Uploadimages: '上传图片',
        UploadVideo: '上传视频',
        Batchdisplay: '批量显示',
        BatchCloseDisplay: '批量关闭显示',
        Batchdeletion: '批量删除',
        Synchronizetootherlanguages: '同步到其他语言',
        Selectfrompicturelibrary: '从图片库选择',
        Pleaseenterthemaintext: '请输入正文',
        Hideemptyparameters: '隐藏空参数',
        Detailedcontentofgraphicsandtext: '请先输入英语的图文详情内容',
        parameter: '参数',
        Parametervalue: '参数值',
        ParameterProperties: '参数属性',
        Pleaseenteraparametername: '请输入参数名',
        Pagekeywords: '页面关键字',
        PageDescription: '页面描述',
        Pleaseentercontent: '请输入内容',
        PictureLanguage: '图片语言',
        Pictures_Videos: '图片/视频',
        ImageID: '图片ID',
        language: '语言',
        size: '尺寸',
        Selectapictureorvideo: '请先选中图片库的图片或视频!',
        Selectionsuccessful: '选择成功',
        Isitthemainimage: '是否主图',
        Displayornot: '是否显示',
        Whethertodisplayall: '是否全部显示',
        Areallclosed: '是否全部关闭',
        Confirmdeletionoftheselectedpicture_video: '确认删除所选图片/视频',
        ProductReview: '商品评论',
        PromotionName_ProductID_Barcode: '促销名称/商品ID/条码',
        PromotionCountry: '促销国家',
        Activitystatus: '活动状态',
        begininaminute: '即将开始',
        Ended: '已结束',
        Isitaflashkill: '是否秒杀',
        PromotionName: '促销名称',
        CommentID: '评论ID',
        RatingStarRating: '评分星级',
        star0: '星',
        star1: '1星',
        star2: '2星',
        star3: '3星',
        star4: '4星',
        star5: '5星',
        Commentstartdate: '评论开始日期',
        CommentEndDate: '评论结束日期',
        Batchreview: '批量审核',
        Productimage: '商品图片',
        Evaluationcontent: '评价内容',
        video: '视频',
        ReviewerId: '审核人Id',
        user: '用户',
        Pendingreview: '待审核',
        Evaluationtime: '评价时间',
        ReviewerName: '审核人名称',
        Numberofselectedcomments: '已选择评论数',
        Picturepreview: '图片预览',
        VideoPreview: '视频预览',
        Lastmonth: '最近一个月',
        LastThreeMonths: '最近三个月',
        Areyousuretodelete: '是否确认删除',
        Pleaseselectacomment: '请选择评论',
        Amazon: '亚马逊',
        Aigostarbrandnetwork: 'aigostar品牌网',
        marketingmanagement: '营销管理',
        Productrecommendations: '商品推荐',
        Recommendedlocation: '推荐位置',
        Categoryrecommendation: '类目推荐',
        Recommendationmodule: '推荐模块',
        NewProductZone: '新品专区',
        HotSellingZone: '热销专区',
        Recommendedforyou1: '为您推荐',
        Numberofproducts: '商品数',
        Addproduct: '添加商品',
        DirectoryName: '目录名称',
        title: '标题',
        ContentTitle: '内容标题',
        EnablePlatform: '启用平台',
        DisplayPlatform: '显示平台',
        catalogue: '目录',
        JumpAddress: '跳转地址',
        PageTitle: '页面标题',
        CategoryName: '类别名称',
        Cancelleddeletion: '已取消删除',
        Pleaseselectacategoryfirst: '请先选择类别',
        TheEnglishcategorynamecannotbeempty: '英文类别名称不能为空',
        Successfullysaved: '保存成功',
        DeleteError: '删除错误',
        Apieceofcontentalreadyexists: '已经存在一条内容',
        Pleaseselecttherecommendedlocation: '请选择推荐位置',
        Pleaseselecttherecommendedmodule: '请选择推荐模块',
        remove: '移除',
        Successfullyadded2: '增加成功',
        Areyousuretoremovethisproduct: '确认移除该商品？',
        Successfullyremoved: '移除成功',
        Dataalreadyexists: '数据已存在',
        PortfolioManagement: '组合管理',
        Combinationname: '组合名称',
        Remainingquantity_combinedquantity: '剩余数量/组合数量',
        starttime: '开始时间',
        Endtime: '结束时间',
        Creator: '创建人',
        NationalSDPDictionary: '国家sdp 字典',
        Combinationtime: '组合时间',
        Combinationquantity: '组合数量',
        CanIusecoupons: '是否可使用优惠券',
        Mainimage: '主图',
        Setcombinationdiscount: '设置组合折扣',
        Combinationprice: '组合价格',
        Combinedtotalprice: '组合总价',
        Isitontheshelf: '是否上架',
        Selectupto5: '最多选择5个',
        Promotionprice1: '促销价',
        Pleaseenterthecombinationquantity: '请输入组合数量',
        Pleaseenteracombinationname: '请输入组合名称',
        Upto100wordscanbeentered: '最多可输入100字',
        Pleaseselectadaterange: '请选择日期区间',
        Pleaseselectastartdate: '请选择开始日期',
        Pleaseselecttheenddate: '请选择结束日期',
        Pleasechoosewhethertousecoupons: '请选择是否可使用优惠券',
        Pleaseselect25items: '请选择2-5个商品',
        Pleaseselectaproductthathasbeenlisted: '请选择已上架的商品',
        Inventoryofselecteditems: '已选择商品的库存/销售倍数不能小于活动数量',
        Combinationpricecannotbeempty: '组合价格不能为空',
        Caninput0andpositiveintegers: '可输入0和正整数、符号只能输入%和.，最多可输如小数点后两位',
        Presaleproductscannotbeadded: '为预售商品，不可添加为组合',
        Pleaseselectaproductfirst: '请先选择商品',
        Freeshippingonfirstorder: '首单免运费',
        Firstfreeshippingitem: '首单免运费商品',
        SelectedProduct: '已选择商品',
        Between1and50charactersinlength: '长度在 1 到 50 个字符',
        Thisoperationwillremovethefile: '此操作将移除该文件, 是否继续?',
        Brandandcountrycannotbeempty: '品牌和国家不能为空',
        Pleaseselectatime: '请选择时间',
        Editedsuccessfully: '编辑成功!',
        Shoppingrebate: '购物返利',
        Rebateratio: '返利比例',
        Rebatestarttime: '返利开始时间',
        Rebateendtime: '返利结束时间',
        Rebatetime: '返利时间',
        doyouwanttodelete2: '是否要删除',
        ActivityTopic: '活动专题',
        ActivityName: '活动名称',
        Whethertodisplayonthehomepage: '是否首页显示',
        QuerySearch: '查询搜索',
        TopicName: '专题名称',
        Activitytime: '活动时间',
        Richtextornot: '是否富文本',
        ActivityDescription: '活动说明',
        SpecialSEO: '专题SEO',
        LogisticsPricing: '物流定价',
        Basicfreight: '基础运费',
        Freeshippingprice: '免运费价格',
        Deliverytimeprompt: '发货时间提示',
        Receivingtimeprompt: '收货时间提示',
        PricingSettings: '定价设置',
        Freightstandard: '运费标准',
        Freightallocation: '运费配置',
        FreightType: '运费类型',
        Freeshipping: '免运费',
        Configureshippingcosts: '配置运费',
        Fullreductionoffreight: '运费满减',
        Orderamountfull: '订单金额满',
        Receivingandshippingreminder: '收发货提示',
        link: '链接',
        confirm: '确认',
        Cannotbe0: '不能为0',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: '小数点后最多只能输入两位',
        Pleaseinputthenumbercorrectly: '请正确输入数字',
        Pleaseenterthecountry: '请输入国家',
        Pleaseselectshippingcost: '请选择运费',
        Pleasecompletetheshippingcostconfiguration: '请完成运费配置',
        Pleaseenteradeliveryprompt: '请输入收货提示',
        Pleaseenteralink: '请输入链接',
        Addlogisticsconfiguration: '添加物流配置',
        Editlogisticsconfiguration: '编辑物流配置',
        Pleaseenterthelogisticscompany: '请输入物流公司',
        Editingfailed: '编辑失败',
        Addlogisticscompany: '添加物流公司',
        Pleaseuploadtheicon: '请上传图标',
        Pleaseenterthelinkcorrectly: '请正确输入链接',
        Successfullyadded: '新增成功',
        Editlogisticscompany: '编辑物流公司',
        Thisisalreadythefirstitemmoveupfailed: '已经是第一条，上移失败',
        Thisisalreadythelastitemmovedownfailed: '已经是最后一条，下移失败',
        Costprice: '成本价',
        taxrate: '税率',
        commission: '佣金',
        Grossmargin: '毛利率',
        Fillinanumberthatistoolarge: '填写数字过大',
        Pleaseselectapricetype: '请选择价格类型',
        Pleasecompletethetaxrateconfiguration: '请完成税率配置',
        Pleasecompletethecommissionconfiguration: '请完成佣金配置',
        PleasecompleteGrossmarginconfiguration: '请完成毛利率配置',
        Weightrange_start_kg: '重量范围(开始)kg',
        Weightrange_end_kg: '重量范围(结束)kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: '小数点后最多只能输入四位',
        Theendvaluemustbegreaterthanorequaltothestartvalue: '结束值必须大于等于开始值',
        Pleasecompletetheweightrangeconfiguration: '请完成重量范围配置',
        Pleaseentertheshippingcost: '请输入运费',
        Addshippingstandard: '添加运费标准',
        EditFreightStandard: '编辑运费标准',
        Allocationofpatrioticcoins: '爱国币配置',
        RuleConfiguration: '规则配置',
        ObtainingPatrioticCoinObject: '获得爱国币对象',
        Rewardconditionsobtained: '获得奖励条件',
        Obtainpatrioticcoinallocation: '获得爱国币配置',
        Inputquantity: '输入数量',
        EnterName: '输入名称',
        Registrationsuccessfulnot: '注册成功（未使用邀请码）',
        Inviteduserplacesanorder: '被邀请用户下单',
        Registrationsuccessful: '注册成功（使用邀请码）',
        Registeredaccount: '注册账号（注册国家）',
        Registrationsuccessfulnot1: '未使用邀请码成功注册账号',
        Numberoflocalcurrencies: '以下配置的数量均为对应国家的本国货币数量',
        Inviteraccount: '邀请者账号（提供邀请码的账号，邀请码的国家）',
        Provideinvitationcode: '提供邀请码给其他用户，其他用户使用邀请码注册后成功完成一笔订单(每个邀请用户只可获得1次奖励)',
        Usinganinvitationcode: '使用邀请码成功注册账号',
        ProductPromotion: '商品促销',
        discount: '折扣',
        Freeshippingornot: '是否免运费',
        Secondkillsorting: '秒杀排序',
        surplus: '剩余',
        Totalnumber: '总数',
        ImportFile: '导入文件',
        Selectupto50: '最多选择50个',
        Setuppromotions: '设置促销',
        Nocountrieslisted: '无上架国家',
        Promotiontime: '促销时间',
        Displaypromotionalprices: '是否显示促销价',
        PromotionPrice: '促销价格',
        Promotionquantity: '促销数量',
        Doyousupporttheuseofcoupons: '是否支持使用优惠券',
        Purchaserestrictionsforthesameuser: '同一用户限购',
        Singleorderlimit: '单笔订单限购',
        CurrentactivitypricesinSpain: '{countryCode}当前活动价格的毛利率为',
        SubmitConfirmation: '提交确认',
        Productdistribution: '商品分销',
        DistributionName: '分销名称',
        DistributionCountry: '分销国家',
        Distributionprice1: '分销价',
        Distributiontime: '分销时间',
        DistributionPrice: '分销价格',
        Distributionquantity: '分销数量',
        CopyConnection: '复制连接',
        Successfullycopied: '复制成功',
        Countryofuse: '使用国家',
        Discounttype: '优惠类型',
        Fullreduction: '满减',
        Isthereafixedcode: '是否有固定码',
        Effective: '有效',
        Comingsoon1: '即将有效',
        Expired: '已过期',
        Overlayornot: '是否叠加',
        Istheexchangecodefixed: '是否固定兑换码',
        Received: '已领取',
        Used: '已使用',
        Availableamount: '可用金额',
        Maximumdeductionamount: '最高抵扣金额',
        Canitbestacked: '是否可叠加',
        Redemptioncode: '兑换码',
        Fullreductionamount: '满减金额',
        Enterredemptioncode: '输入兑换码',
        Numberofredemptioncodes: '兑换码数量',
        Numberofusesbythesameuser: '同一用户使用次数',
        Effectivetime: '有效时间',
        Isitpossibletooverlaydistribution: '是否可叠加分销',
        Canpromotionsbestacked: '是否可叠加促销',
        Entercoupondescription: '输入优惠券说明',
        Pleaseenteradescription: '请输入说明',
        Pleaseselectadate: '请选择日期',
        Pleaseenterthenumberoftimesthesameuserhasusedit: '请输入同一用户使用次数',
        Pleaseenteranavailableamountof0toindicateunlimited: '请输入可用金额0表示不限',
        Pleaseentertheavailableamount: '请输入可用金额',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: '兑换码数量只能比原来的多',
        Pleaseenterthenumberofredemptioncodes: '请输入兑换码数量',
        Applicableclassification: '适用分类',
        Applicableproducts: '适用商品',
        Useordernumber: '使用订单号',
        Claimstatus: '领取状态',
        Notclaimed: '未领取',
        notused: '未使用',
        Usagestatus: '使用状态',
        Collectiontime: '领取时间',
        Usagetime: '使用时间',
        UsingUsers: '使用用户',
        BannerManagement: 'Banner管理',
        position: '位置',
        JumpType: '跳转类型',
        Nojump: '无跳转',
        Homepagerecommendationposition: '首页推荐位置',
        Homebanner: '首页banner',
        Displaytime: '展示时间',
        JumpLink: '跳转链接',
        Suggestedsize: '建议尺寸',
        Enterjumplink: '输入跳转链接',
        Uploadingimagesonlysupports: '上传图片只支持jpg、png、gif、jpeg',
        Topwithlargenumbers: '默认为0,数字越大,排序越靠前',
        Pleaseselectatleastonecountry: '请至少选择一个国家',
        Boutiquerecommendationspace: '精品推荐位',
        Fullproductrecommendation: '全品推荐位',
        popupnotification: '弹窗',
        Addbanner: '添加banner',
        subscriptionmanagement: '订阅管理',
        Emailaddress: '邮箱地址',
        Subscribing: '订阅中',
        Canceled: '已取消',
        SubscriptionSource: '订阅来源',
        Unsubscribe: '取消订阅',
        Resubscribe: '重新订阅',
        Pleaseselectatimerange: '请选择时间范围',
        WebsiteManagement: '网站管理',
        informationservice: '信息服务',
        New: '新建',
        Isitdisplayedatthebottomofthewebsite: '是否在网站底部显示',
        Pleaseselectaninformationservice: '请选择信息服务',
        deleteconfirmation: '是否确认删除该节点以及节点下的所有信息',
        Pleaseenteracolumnname: '请输入栏目名称',
        Thelengthcannotexceed50characters: '长度不能超过50个字符',
        ThelengthcannotexceedXcharacters: '长度不能超过{num}个字符',
        Pleaseentersorting: '请输入排序',
        Selectedcountrycontentisrequired: '所选国家内容必填',
        TitleName: '标题名称',
        Chinesetitleisrequired: '中文标题必填',
        Selectedcountrytitleisrequired: '所选国家标题必填',
        EditingInformationServices: '编辑信息服务',
        ColumnName: '栏目名称',
        Pleaseenterthecreationtime: '请输入创建时间',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: '请输入是否在网站底部显示',
        ViewInformationServices: '查看信息服务',
        Modificationtime: '修改时间',
        Countrycannotbeempty: '国家不能为空',
        Titlecannotbeempty: '标题不能为空',
        Richtextcontentonthepagecannotbeempty: '页面富文本内容不能为空',
        Nationalconfiguration: '国家配置',
        currency: '货币',
        allocation: '配置',
        Statuseditedsuccessfully: '状态编辑成功',
        Areyousuretocontinuedeletingdata: '是否确认继续删除数据',
        WebsiteAddress: '网址地址',
        Contactinformation: '联系信息',
        Customerserviceemail: '客服邮箱',
        Socialmedia: '社交媒体',
        Socialmediainformation: '社交媒体信息',
        Pleasechooseapaymentmethod: '请选择付费方式',
        Lengthgreaterthan20characters: '长度大于20个字符',
        Lengthgreaterthan100characters: '长度大于100个字符',
        Successfullydeletedinformation: '删除信息成功',
        Iconmustbeuploaded: '图标必上传',
        Successfullyaddedinformation: '添加信息成功',
        Successfullyeditedinformation: '编辑信息成功',
        Pleaseentertheemailcorrectly: '请正确输入邮箱',
        Thesizeoftheuploadedimagecannotexceed: '上传图片大小不能超过',
        Countryname: '国家名称',
        Pleaseuploadpictures: '请上传图片',
        NewCountryInformation: '新建国家信息',
        EditCountryInformation: '编辑国家信息',
        Picturesmustbetransmitted: '图片必传',
        NewCountryInformationTableImageAddress: '新建国家信息表图片地址',
        Operationsuccessful: '操作成功',
        Euro: '欧元',
        pound: '英镑',
        Zloti: '兹罗提',
        Brandcannotbeempty: '品牌不能为空',
        Thewebsiteaddresscannotbeempty: '网站地址不能为空',
        Contactinformationcannotbeempty: '联系信息不能为空',
        Thecustomerserviceemailaddresscannotbeempty: '客服邮箱地址不能为空',
        Medianamecannotbeempty: '媒体名称不能为空',
        Themediaiconcannotbeempty: '媒体图标不能为空',
        Medialinkcannotbeempty: '媒体链接不能为空',
        Thecountryiconcannotbeempty: '国家图标不能为空',
        Languagecannotbeempty: '语言不能为空',
        Currencycannotbeempty: '货币不能为空',
        Pagedescriptioncannotbeempty: '页面描述不能为空',
        Statusenable_disablecannotbeempty: '状态 启用/禁用 不能为空',
        SEOManagement: 'SEO管理',
        Import: '导入',
        DownloadImportTemplate: '下载导入模板',
        PageURL: '页面URL',
        SEOdata: 'SEO数据',
        SEOConfigurationTemplate: 'SEO配置模板',
        Downloadsuccessful: '下载成功',
        SEOManagementImport: 'SEO管理导入',
        DragtheExcelfilehere_orclickonupload: '将excel文件拖到此处，或点击上传',
        Fileimportedsuccessfully: '文件导入成功',
        Fileimportfailed_pleasecheckthefilecontent: '文件导入失败，请检查文件内容',
        Pleaseenterthelanguage: '请输入语言',
        Thelengthcannotexceed2characters: '长度不能超过2个字符',
        PleaseenterthepageURL: '请输入页面URL',
        Thelengthcannotexceed100characters: '长度不能超过100个字符',
        Thelengthcannotexceed20characters: '长度不能超过20个字符',
        No_Illegalrowdata: '第?行数据不合法',
        Failedtouploadfile_Pleasecheckthefilecontent: '上传文件失败，请检查文件内容',
        Namecannotbeempty: '名称不能为空',
        PageURLcannotbeempty: '页面URL不能为空',
        Thepagetitlecannotbeempty: '页面标题不能为空',
        SEOManagementImportTemplate: 'SEO管理导入模板',
        Donotmodify: '模版前三行标题请勿修改',
        Required: '带“*”号为必填项',
        Brandandlanguagemustbefullycapitalized: '品牌和语言必须全大写',
        SEOManagementExport: 'SEO管理导出',
        Pagekeywordscannotbeempty: '页面关键字不能为空',
        Prohibitedsalesarea: '禁售区域',
        Pleaseenterthepostalcodecorrectly: '请正确输入邮编',
        Pleasefillinthepostalcode: '请填写邮编',

        Countrycodecannotbeempty: '国家代码不能为空',
        Postalcodecannotbeempty: '邮编不能为空',
        Postalcodealreadyexists: '邮编已存在',
        Homepagearticle: '首页文章',
        BrandStory: '品牌故事',
        SciencePopularizationZone: '科普专区',
        Lightstripzone: '灯带专区',
        Pictureandtext: '图文',
        Comingsoon: '即将发布',
        Publishinginprogress: '发布中',
        column: '栏目',
        Releasetime: '发布时间',
        Pleaseenteratitle: '请输入标题',
        Pleaseselectacolumn: '请选择栏目',
        Pleaseselectatype: '请选择类型',
        Patrioticcoinusagelog: '爱国币使用日志',
        income: '收入',
        expenditure: '支出',
        mode: '方式',
        Orderexpenses: '订单支出',
        Orderrewards: '订单奖励',
        OrderCloseReturn: '订单关闭退回',
        other: '其他',
        Backendrecharge: '后台充值',
        Orderrebate: '订单返点',
        Numberofchangesinpatrioticcoins: '爱国币变化数量',
        Numberofpatrioticcoinsafterchanges: '变化后爱国币数量',
        CountryCode: '国家编码',
        Changetime: '变化时间',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: '请选择用户爱国币消费记录',
        WebsiteNavigation: '网站导航',
        HomepageConfiguration: '首页配置',
        Navigationcontent: '导航内容',
        Recommendedforyou: '为你推荐',
        SelectNavigation: '选择导航',
        Pleasecheckfirst: '请先勾选',
        Logmanagement: '日志管理',
        UserID_IP_LoginDevice: '用户id/ip/登录设备',
        logintime: '登录时间',
        Loginmethod: '登录方式',
        IPaddress: 'ip地址',
        Logindevice: '登录设备',

        //11-22
        AssociatedOrderNumber: '关联订单号',
        recordId: '记录id',

        UserAccess: '用户访问',
        PleaseSelectTheStatisticalType: '请选择统计类型',
        StatisticalByDate: '按日期统计',
        StatisticalBySource: '按来源统计',
        Date: '日期',
        Visits: '访问量',
        NewVisitors: '新访客',
        OrderStatistics: '订单统计',
        Sales: '销售额',
        TotalOrders: '订单总数',
        AverageOrderAmount: '平均订单金额',
        SynchronizeEBP: '同步EBP',
        ParameterName: '参数名称',
        OnTheShelf: '上架',
        OfflineShoppingCart: '离线购物车',
        AddToCart: '添加购物车',
        EnterCheckout: '进入结账',
        UserLogin: '用户登录',
        UserRegistration: '用户注册',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: '进行添加购物车操作的不同ip数',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: '进入确定订单页面的不同ip数',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: '离线购物车跳转登录不同ip数',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: '离线购物车跳转注册不同ip数',
        UserBehavior: '用户行为',
        Display: '显示',
        DoNotDisplay: '不显示',
        AddToPresale: '添加预售',
        CancelPresale: '取消预售',
        PresaleConfiguration: '预售配置',
        OffTheShelf: '下架',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: '请先完善商品信息再设置预售',
        SynchronizedEBPPrompts: '由于同步需要和EBP对接，为避免出错，批量同步每X分钟可操作一次。确定后同步为异步进行，获得SKU后立即显示，请耐心等候。',
        PleaseWait: '稍等',
        PleaseSelectATag: '请选择标签',
        Updating: '更新中',
        Abortive: '失败',
        DefaultRule: '默认规则',
        BottomInventory: '底线库存',
        SynchronisedAlertsForGraphicDetails: '图文详情：按语言同步对应语言的【产品关键字】',
        SynchronisedAlertsForLargeProductImages: '商品大图：按语言同步对应语言的大图，读取EBP图片排序，EBP第一张大图自动设置为主图',
        EnterUpToNumItems: '最多输入{num}条',
        SynchronizedSuccessfully: '同步成功',
        SuccessfullyListed: '上架成功',
        SearchSuccessful: '搜索成功',
        EnterNewPassword: '输入新密码',
        OnlyNumbersAndLettersAreAllowed: '只允许输入数字+字母组合',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: '预售商品请先完成预售配置后才能上架',
        ProductWeight: '商品重量',
        ConfirmExecution: '确认执行?',
        ConfirmBatchListing: '确认执行批量上架?',
        Template: '模板',
        PleaseSelectTheProductsToBeSynchronized: '请勾选需要同步的产品',
        ForcedSynchronisationDescription: '强制同步，会删除已修改的数据替换成同步数据，普通同步如果有改动过的产品数据则不会同步',
        ConfirmSynchronizationExecution: '确认执行同步',
        SynchronisationHasBeenSentPleaseWait: '已发送同步任务消息,请耐心等待数据同步完成',
        ProhibitLogin: '禁止登录',
        IsTheUserLoggedIn: '该用户登录吗',
        Prohibit: '禁止',
        ProhibitedSuccessfully: '禁止成功',
        EnableLogin: '开启登录',
        EnabledSuccessfully: '开启成功',
        OperationCanceled: '已取消操作',
        UserData: '用户数据',
        GraphicDetails: '图文详情',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: '该商品已被设置为组合商品，不可设置预售，请先前往组合内移除该商品?',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: '该商品是促销商品，不可设置预售，请先取消促销',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: '该商品是分销商品，不可设置预售，请先取消分销',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: '该商品，已存在满减活动不可添加',
        ESNoPriceNotAvailableForPresale: '该商品在{Any}没有价格不允许添加预售。请先下架对应国家或同步对应国家的价格再添加预售商品！',
        ConfirmSettingThisProductAsAPresaleItem: '确认将该商品设置为预售商品?',
        PresaleAddedSuccessfully: '添加预售操作成功',
        ConfirmSettingThisProductAsANonpresaleItem: '确认将该商品设置为非预售商品?',
        CancelPresaleSuccessful: '取消预售操作成功',
        Preview: '预览',
        TheInventoryOfCurrentProducts0Continue: '当前产品{stock}库存为0, 是否继续?',
        TheInputContentCannotExceedCharacters: '输入内容不能超过{x}个字符',
        PleaseSelectTheActivity: '请选择活动',
        OrderDetails: '订单详情',
        LogisticsInformation: '物流信息',
        CancelRemark: '取消备注',
        DepositAmount: '定金金额',
        FinalPaymentAmount: '尾款金额',
        FullReductionDiscount: '满减优惠',
        YourBrowserDoesNotSupportVideoPlayback: '您的浏览器不支持视频播放',
        EditBanner: '编辑banner',
        AddBanner: '添加banner',
        UpdateTime: '更新时间',
        HomepageRecommendationPosition: '首页推荐位置',
        ActivityID: '活动ID',
        PleaseSelectThePosition: '请选择位置',
        PleaseSelectTheJumpType: '请选择跳转类型',
        PleaseEnterTheAssociatedID: '请输入关联ID',
        TheUploadedImageSizeCannotExceed5MB: '上传图片大小不能超过 {num}MB!',
        OnlyMp4FormatIsSupportedForVideoUpload: '上传视频只支持mp4',
        TheUploadedVideoSizeCannotExceed500MB: '上传视频大小不能超过 {num}MB!',
        PleaseUploadTheVideo: '请上传视频',
        PleaseEnterTheJumpLink: '请输入跳转链接',
        PleaseEnterTheCorrectLink: '请输入正确的链接',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: '验证失败，请先输入必填项',
        AddFailed: '新增失败',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: "已经是第一条，上移失败'",
        PleaseEnterTheRebateRatio: '请输入返利比例',
        EditCoupon: '编辑优惠券',
        AddCoupon: '添加优惠券',
        IDCouponName: 'ID/优惠券名称/兑换码',
        Random: '随机',
        Fixed: '固定',
        AboutToTakeeffect: '即将生效',
        NotStackable: '不可叠加',
        Stackable: '叠加',
        Frozen: '冻结',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: '请输入最高抵扣金额0表示不限',
        UseUserID: '使用用户ID',
        UseOrderNumberExchangeCodeUserID: '使用订单号/兑换码/用户ID',
        PleaseEnterTheDiscount: '请输入折扣',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: '只能输入数字0~100内的数字，最多小数点后1位数',
        PleaseEnterTheFullReductionAmount: '请输入满减金额',
        PleaseEnterTheExchangeCode: '请输入兑换码',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: '只能输入数字/字母，至少为3位,最多20位',
        PleaseSelectAFixedExchangeCode: '请选择固定兑换码',
        PleaseEnterTheMaximumDeductionAmount: '请输入最高抵扣金额',
        PleaseSelectWhetherItCanbeStacked: '请选择是否可叠加',
        WarehousingTime: '入库时间',
        EnterActivityDescription: '输入活动说明',
        ActivityTitleCannotBeEmpty: '活动标题不能为空',
        TheTitleCannotExceed200Characters: '标题不能超过{num}个字符',
        PleaseEnterTheActivityDescription: '请输入活动说明',
        ActivityTimeCannotBeEmpty: '活动时间不能为空',
        ActivityDescriptionLimitOf10000Words: '活动说明上限{num}字',
        ActivityRecommendation: '活动推荐',
        Promotion: '促销',
        EveryFull: '每满{num}',
        EnterAmount: '输入金额',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: '只能输入正数，最多可输入小数点后两位，不能以0开头',
        PleaseEnterTheCorrectAmount: '请输入正确金额',
        PleaseSelectApplicableCategoriesOrApplicableProducts: '请选择适用分类或者适用商品',
        Rules: '规则',
        RemainingTotal: '剩余/总数',
        CopyLink: '复制链接',
        DistributionNameProductIDBarcode: '分销名称/商品ID/条码',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: '暂不开放该国家的次功能，后续开放',
        PleaseEnterThePrice: '请输入价格',
        AutomaticallyCalculateAfterEnteringTheDiscount: '输入折扣后自动计算',
        PleaseEnterTheQuantity: '请输入数量',
        OrderRemarkLabel: '订单备注标签',
        OrdersWorkWithInfluencers: '网红单',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: '当前活动价格的毛利率为',
        ThePriceCannotBeLowerThanTheLowestPrice: '价格不能低于最低价：',
        Distribution: '分销',
        IDName: 'ID/名称',
        ProductSelected: '商品已选择',
        ProductHasNoPricing: '商品无定价',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: '当前活动价格的毛利率为{rate}%,是否确认提交？',
        SubscriptionStatus: '订阅状态',
        URLClickToCopyAddress: 'URL(点击地址复制)',
        URLSourceManagement: '来源URL管理',
        PleaseEnterTheURL: '请输入URL',
        PleaseEnterEnglishOrNumbers: '请输入英文或数字',
        LengthCannotExceed1000Characters: '长度不能超过1000个字符',
        URLFixedAddressIsEmpty: 'URL固定地址为空',
        cover: '封面',
        PleaseEnterOrPasteTheVideoScript: '请输入或粘贴视频脚本',
        VideoScript: '视频脚本',
        PleaseUploadAVideoFileOrFillInTheVideoScript: '请上传视频文件或填写视频脚本',
        PleaseEnterTheBrand: '请输入品牌',
        Portuguese: '葡萄牙语',
        Chinese1: '中文',
        Register: '注册',
        InviteUsers: '邀请用户',
        OrderPayment: '订单支付',
        SupportsMP4Forma500MB: '支持mp4格式，≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: '当前未选择商品, 是否关闭',
        ExitFullScreen: '取消全屏',
        FullScreen: '全屏',
        Logout: '退出登录',
        OnlySupportsFilesInFormat: '仅支持{xxx}格式的文件',
        PleaseSetAllPromotionalProducts: '请设置所有促销商品',
        PleaseEnterTheWeight: '请输入重量',
        FixedPriceCannotBeLowerThanTheLowestPrice: '固定价格不能低于最低价',
        CloseOthers: '关闭其他',
        CloseAll: '关闭所有',
        ImportSuccessful: '导入成功',
        BatchRecharge: '批量充值',
        RechargeEmail: '充值邮箱',
        RechargeCountry: '充值国家',
        Submit: '提交',
        RechargeSuccessful: '充值成功',
        RechargeInformation: '充值信息',
        PleaseDownloadTheRechargeInformationFirst: '请先下载充值信息',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: '爱国币批量充值数据不能为空',
        TheBatchRechargeDataCannotExceed1000Records: '批量充值数据不允许超过1000条',
        ConfirmSubmission: '确认提交',
        ExportFailed: '导出失败',
        PackingQuantity: '包装数',
        Activity: '活动',
        PleaseSelectWhetherToEnable: '请选择是否启用',
        OnlyOneProductCanBeSynchronized: '同步只能选择一个商品',
        SKUWithAQuantityOfNumDoesNotExist: '数量为{num}的SKU不存在',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: '数量为{num}的SKU不符合销售倍数',
        SalesMultipleDoesNotMatchCannotBeListed: '销售倍数不符，不可上架',
        SalesMultipleIsEmptyInSpain: '西班牙销售倍数为空',
        SynchronizationOfPackageQuantityIsAbnormal: '数量装同步异常',
        CannotContinueToMoveUp: '不能继续向上移动',
        CannotContinueToMoveDown: '不能继续向下移动',
        SynchronizeThePackageQuantity: '同步数量装',
        ProductAssociationIDCannotBeEmpty: '商品关联ID不能为空',
        PackageQuantityCannotBeEmpty: '包装数不能为空',
        PleaseEnterTheCorrectQuantityFormat: '请输入正确的数量格式',
        PleaseEnterSeparatedBy: '请输入（多个用#隔开）',
        PackageQuantity: '包装数量',
        LengthBetween1And140Characters: '长度在 1 到 140 个字符',
        PleaseSelectTheVariantParametersFirst: '请先选变体参数',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: '参数已关联变体，请谨慎操作',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: '请选择同一类目的数据,请重选',
        UpTo5VariantsCanBeSelected: '最多只能选5个变体',
        NoVariant: '无变体',
        VerificationFailedPleaseSelectTheVariant: '验证失败,请选择变体',
        VerificationFailedPleaseSelectTheProduct: '验证失败,请选择商品',
        VerificationFailedTheProductParameterValueIsEmpty: '验证失败,商品参数值为空',
        SetSuccessfully: '设置成功',
        UploadingVideo: '正在上传视频',
        SearchParameters: '搜索参数',
        ImportantParameters: '重要参数',
        Select: '选择',
        ConfirmSettingThisImageAsTheMainImage: '确认将该图片设为主图',
        ConfirmDisplayDoNotDisplay: '确认显示/不显示',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: '当前限制选择 {num1} 个文件，本次选择了 {num2}个文件，共选择了{num3}个文件',
        ConfirmToRemoveAny: '确定移除{Any}?',
        TheTestEnvironmentCannotUploadFiles: '测试环境无法上传文件',
        PleaseEnterTheEnglishTitle: '请输入英语的标题',
        CancelSettingAsTheMainImage: '是否取消设置为主图',
        SetAsTheMainImage: '是否设置为主图',
        CannotSubmitImagesOrVideosThatAlreadyExist: '不可提交已有的图片或视频',
        SynchronizeAnyImagesToOtherLanguages: '是否将{Any}的图片同步添加到其他语言',
        PleaseSelectTheProductToBeSynchronizedFirst: '请先选要同步的商品',
        PleaseSelectTheProductFirst: '请先选中商品',
        ConfirmWhetherToDeleteTheSelectedImageVideo: '确认删除所选图片/视频？',
        TheMainPictureCannotBeTurnedOff: '主图不可关闭显示',
        CancelDisplay: '是否取消显示',
        ConfirmWhetherToDelete: '确认是否删除',
        PleaseUploadTheEnglishImage: '请上传英语的大图',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: '失败，请输入所有销售国家的商品标题',
        PresaleTime: '预售时间',
        PresalePrice: '预售价格',
        PleaseEnter: '请输入',
        PresaleQuantity: '预售数量',
        DepositRatio: '定金比例',
        AssociatedAGBSOnthewayOrders: '关联AGBS在途订单',
        EstimatedDeliveryTime: '预计发货时间',
        FinalPaymentDeadline: '尾款支付截止时间',
        SelectTime: '选择时间',
        PreSalePrice: '预售价',
        FixedPrice: '固定价',
        ByProportion: '按比例',
        ByFullPayment: '按全款',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: '只能输入大于0的数，最多两位小数',
        PresaleTimeCannotBeEmpty: '预售时间不能为空',
        PresalePriceTypeCannotBeEmpty: '预售价格类型不能为空',
        PresaleQuantityCannotBeEmpty: '预售数量不能为空',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: '只能输入正整数，不能以0开头',
        DepositRatioTypeCannotBeEmpty: '定金比例类型不能为空',
        DepositRatioCannotBeEmpty: '定金比例不能为空',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: '只能输入0~100之间的整数，不含0、100',
        EstimatedDeliveryTimeCannotBeEmpty: '预计发货时间不能为空',
        FinalPaymentDeadlineCannotBeEmpty: '尾款支付截止时间不能为空',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: '尾款支付截止时间必须≥预售结束时间5小时',
        TheRemainingAmount: '剩余数量',
        PresaleStatus: '预售状态',
        PresaleStartTime: '预售开始时间',
        PresaleEndTime: '预售结束时间',
        OnPresale: '预售中',
        DirectVisit: '直接访问',
        BackendManagementSystem: '后台管理系统',
        Login: '登录',
        TipsFillInTheUsernameAndPasswordCasually: 'Tips : 用户名和密码随便填。',
        PleaseEnterUserName: '请输入用户名',
        PleaseEnterPassword: '请输入密码',
        LoginSuccessful: '登录成功',
        PleaseEnterYourAccountNumberAndPassword: '请输入账号和密码',
        TheSameProductCanOnlyBeReturnedOrExchanged: '同一商品只能退货或者换货',
        B2CBrandWebsite: 'B2C品牌网',
        CommentManagement: '评论管理',
        AIGOCoins: '爱国币',
        PleaseEnterThePackageQuantity: '请输入包装数量',
        PresalePriceCannotBeEmpty: '预售价格不能为空',
        Product: '商品',
        HomePageColumn: '首页栏目',
        ColumnDescription: '栏目说明',
        FlashSaleArea: '秒杀专区',
        //1.2.8之后
        SoldOutOrNot: '是否售罄',
        VirtualQuantity: '虚拟数量',
        VirtualQuantityPackageQuantity: '虚拟数量/数量装',
        CombinationIcon: '组合图标',
        UploadFailedFileSizeShouldBe3MB: '上传失败，文件大小需＜3MB',
        PromotionQuantityPackageQuantity: '促销数量/数量装',
        RecommendedSize100: '建议尺寸：100*100 ，≤ 20 KB',
        TotalRemainingTotal: '合计-剩余/总数',
        Verification: '校验',
        VirtualSalesVolume: '虚拟销量',
        VirtualSalesVolumePackageQuantity: '虚拟销量/数量装',
        DeleteOrNot: '是否要删除',
        EnabledOrNot: '是否启用',
        Cancel: '取消',
        Code: '编码',
        Confirm: '确定',
        RebateDimension: '返利维度',
        InProgress: '进行中',
        ActivityStatus: '活动状态',
        RebateRatioManagement: '返利比例管理',
        StartTime: '开始时间',
        EndTime: '结束时间',
        RebateAmount: '返利金额',
        NewUser: '新用户',
        RebateObjectNumber: '返利对象编号',
        NumberOfCompletedOrders: '成交订单数',
        RebateObject: '返利对象',
        Operation: '操作',
        RecentOperator: '最近操作人',
        Country: '国家',
        Prompt: '提示',
        RebateProducts: '返利商品池',
        NewUserPromotion: '新用户促销',
        RegularUserPromotion: '老用户促销',
        Upcoming: '即将开始',
        RebateRatio: '返利比例',
        RebateOrNot: '是否返利',
        Rebate: '返利',
        PleaseSelectARebateDimension: '请选择返利维度',
        PleaseSelectARebateObject: '请选择返利对象',
        PleaseSelectACountry: '请选择国家',
        PleaseSelectARebateRatio: '请选择返利比例',
        RecentOperationTime: '最近操作时间',
        PromotionObjectType: '促销对象类型',
        ProductPromotionType: '商品促销类型',
        UserType: '用户类型',
        ApplicableUserType: '适用用户类型',
        RegularUser: '老用户',
        UseAigoCoinsOrNot: '是否使用爱国币',
        EndDateOfMembership: '会员结束日期',
        StartDateOfMembership: '会员开始日期',
        memberOrNot: '是否会员',
        No: '否',
        Yes: '是',
        LowInventory: '库存紧张',
        RechargeEmailbox: '充值邮箱',
        SubmittedSuccessfully: '提交成功',
        Importing: '导入中',
        InformationOfTheRechargeFailed: '失败信息',
        RechargeFailed: '充值失败',
        RechargeInBatch: '批量充值',
        RechargeSuccessfully: '充值成功',
        RechargeAigoCoins: '充值爱国币',

        RequiredField: '必填',
        PDFFormat: 'PDF格式',
        FileSize3MB: '文件大小＜3MB',
        UploadFailedPDFFormatRequired: '上传失败，需PDF格式',
        AuditFailedPleaseUploadInvoiceAttachment: '审核失败，请上传发票附件',
        PleaseUploadInvoiceAttachment: '请上传发票附件',
        InvoiceAttachment: '发票附件',
        UploadFailed: '上传失败',

        HaveAnAccount: '没有账号？点击此处注册',
        SoldOut: '已抢光',
        HaveAnInvitationCode: '选填，没有邀请码可不填',
        RestockedItems: '补货商品',
        NotMeetPleaseEnterAgain: '昵称不符合规范，请重新输入',
        ThisCouponIsOnlyAvailableForNewUsers: '该优惠券仅新用户可使用',
        ThiscouponIsOnlyAvailableForExistingUsers: '该优惠券仅老用户可使用',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: '同一商品只能退货或者换货',
        With365DaysRemaining: '（2024.09.25到期）剩余365天',
        TheNicknameDoesNotMeet: '昵称不符合规范，请重新输入',
        GoToView: '前往查看',
        DoNotRegisterRepeatedly: '请勿重复注册',
        ValidityPeriod: '有效期',
        RegisterSuccessfully: '注册成功',
        MemberRegistration: '注册会员',
        RegistrationFailed: '注册失败',
        OneYear: '一年',
        AddToShoppingCart: '加入购物车',
        AddANewAddress: '增加一个新地址',
        ZIPCode: '邮政编码',
        EditYourAddress: '编辑你的地址',
        Remove: '移除',
        SetAsDefault: '设为默认',
        FailedChangeDefaultAddress: '默认地址更改失败，请稍后再试',
        Fullname: '姓名（名字和姓氏）',
        PhoneNumber: '电话号码',
        DefaultAddressHasBeenChanged: '默认地址已更改',
        ViewComments: '查看评论',
        ClickToCheckLogisticsInfo: '点击查询物流',
        RestockingOfOutOfStockItems: '无库存商品补货',
        ThisWeekRestockedItems: '本周补货商品',
        ProductListInTheShoppingCart: '购物车产品列表',
        WarmReminderVideoResources: '温馨提示：视频资源仅支持单个下载',
        PleaseLeaveYourMessage: '请填写留言内容',
        InvoiceManagement: '发票管理',

        //v2
        ReadmeFile: '自述文件',
        HelpCenter: '帮助中心',
        FileUpload: '文件上传',
        Globalization: '国际化',
        SalespersonProfile: '业务员档案',
        BatchSyncPage: '批量同步页面',
        CountryConfigurationPage: '国家配置页',
        UserConsultation: '用户咨询',
        PreSaleItems: '预售商品',
        EventManagement: '活动管理',
        BlankPage: '空白页',
        ThereAreCurrentlyNoProductPromotions: '暂无商品促销活动',
        EditInventoryBottomLine: '编辑库存底线',
        SetAFixedPrice: '设置固定价格',
        EnterUpTo500Entries: '最多输入500条',
        CantRelateToYourself: '不能关联自己',
        PleaseSelectTheApplicableUserType: '请选择适用用户类型',
        TheActivityDescriptionLimitIs10000Words: '活动说明上限1万字',
        EnabledStatus: '启用状态',
        Enable: '启用',
        OperatingTime: '操作时间',
        NewUserPromotionEnabled: '新用户促销启用',
        NewUserPromotionDisabled: '新用户促销禁用',
        OldUserPromotionEnabled: '老用户促销启用',
        OldUserPromotionDisabled: '老用户促销禁用',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: '促销价格不能低于最低价',
        AutomaticallyCalculateAfterEnteringPrice: '输入价格后自动计算',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: '新用户促销价大于老用户促销价，是否继续？',
        PleaseSelectPromotionCountry: '请选择促销国家',
        PromotionDiscount: '促销优惠',
        PleaseEnterThePromotionalQuantity: '请输入促销数量',
        PleaseEnterVirtualQuantity: '请输入虚拟数量',
        NumberOfUserPurchases: '用户购买数',
        PleaseEnterThePurchaseLimitForTheSameUser: '请输入同一用户限购',
        PleaseEnterThePurchaseLimitForASingleOrder: '请输入单笔订单限购',
        QuantityGreaterThan0: '数量大于0',
        GrossProfitMarginCalculationResults: '毛利率计算结果',
        FixedPriceGrossMargin: '固定价格毛利率',
        SellingPriceGrossProfitMargin: '售价毛利率',
        PromotionalPriceGrossProfitMargin: '促销价毛利率',
        PromotionStartTime: '促销开始时间',
        PromotionEndTime: '促销结束时间',
        PromotionType: '促销类型',
        PleaseSetTheSKUToPromotionalProducts: '请设置SKU为${xxx}促销商品',
        DistributionOffers: '分销优惠',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: '输入{Any}数量后,根据商品数量自动计算',
        TheNumberOfUserPurchasesMustBeGreaterThan0: '用户购买数需大于0',
        PleaseSelectCountry: '请选择${typeName}国家',

        Upload: '上传',
        PleaseSelectAtLeastTheApplicableUserType: '请至少选择适用用户类型',
        PleaseEnterTheRedeemCode: '请输入输入兑换码',
        ApplicableUserRange: '适用用户范围',
        NoProductIsSelectedWhetherToClose: '当前未选择商品是否关闭',
        DeliveryCountry: '收货国家',
        MemberSettings: '会员设置',
        PleaseEnterTheAccountName: '请输入账号',
        MembershipEndDateCannotBeLessThanMembershipStartDate: '会员结束日期不能小于会员开始日期',
        CharactersLimited: '长度在 1 到 30 个字符',
        NumberOfDifferentIPsToAccessTheURL: '访问网址不同ip数',
        TheNumberOfDifferentIPAddressesForTheFirstVisitToTheWebsite: '首次访问网址不同ip数',
        TheTotalOrderAmountPaidAvailableForShipmentShippedCompleted: '已支付（可发货、已发货、已完成）订单金额总和',
        QuantityOfPaidOrderAmountAvailableForShipmentShippedCompleted: '已支付（可发货、已发货、已完成）订单金额数量',
        BatchRemoval: '批量移除',
        ConfirmBatchDeletionOfSelectedProducts: '确认批量删除选中商品',
        RegisterOrNot: '是否注册',
        FromSubscriptionTime: '订阅时间起',
        EndOfSubscriptionPeriod: '订阅时间止',
        SubscriptionManagementExport: '订阅管理导出',
        FromTheEventStartTime: '活动开始时间起',
        UntilTheEventStartTime: '活动开始时间止',
        EventEndTime: '活动结束时间',
        EventStartTime: '活动开始时间',

        SignUpForSubscription: '注册订阅',
        EnterYourEmailAddressToSubscribe: '输入邮箱订阅',
        DownloadTheLatestProductInformationSheet: '下载最新商品信息表',

        Full: '每满',
        Reduce: '减',
        Superimposable: '可叠加',
        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: '输入促销数量后,根据商品数量自动计算',

        PleaseCancelTheAssociationInRebateProductPoolFirst: '请先在【返利商品池】内取消关联',
        EditBottomLineInventory: '编辑底线库存',
        PleaseConfigureFixedPrice: '请配置固定价格',
        BalancePayment: '尾款',
        Deposit: '定金',

        EndofValidity: '请输入有效期',
        Year: '年',
        Month: '月',
        Day: '日',
        UnitOfValidity: '有效期单位',
        AIGOCoinDetails: '爱国币明细',
        AIGOCoinsAreCloseToExpiring: '爱国币距离过期(日)',
        CountryOfShipmentAddress: '发货地址国家',

        // V1.3.3 start
        EstimatedTimeOfDelivery: '预计发货日期',
        DataCorrespondingToTheSource: '来源对应数据',
        AccountStatus: '账户状态',
        OrderID: '订单ID',
        RecipientName: '收件人名字',
        RecipientLastName: '收件人姓氏',
        MailsOfUser: '用户邮件',
        StateProvince: '州/省份',
        CityTown: '城市/城镇',
        County: '县',
        ReceivingCompany: '接收公司',
        BatchInput: '批量导入',
        InputManually: '手工导入',
        ProductCommentsInputTemplate: '商品评论导入模板',
        UserName: '用户名称',
        Comments: '评论内容',
        StarRatingOfTheComment: '评论星级',
        CommentTime: '评论时间',
        UploadAttachment: '上传附件',
        NumberOfAttachments: '附件数量',
        FileName: '文件名',
        Appendix: '附件',
        BatchInputOfProductComments: '商品评论批量导入',
        SoldOut1: '售罄',
        PreSalePromotionID: '预售活动ID',
        PromotionAbnormal: '活动异常',
        Updater: '更新人',
        AddPreSalePromotion: '新增预售',
        EditPreSalePromotion: '编辑预售',
        Barcode: '商品条码',
        PreSaleCountries: '预售国家',
        PreSalePrice1: '预售价格',
        PreSaleDeposit: '预售定金',
        PleaseEnterTheDocumentCode: '请输入单据编码',
        BalancePaymentDeadline: '尾款截止时间',
        FixedShippingFee: '固定运费',
        PreSaleShippingFee: '预售运费',
        FreeShippingSubjectToConditions: '根据条件免运费',
        OrderPrice: '订单价格',
        PleaseEnterPriceConditions: '请输入价格条件',
        TheEndTimeNeedsToBeLaterThanTheStartTime: '结束时间需要大于开始时间',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: '该国家已存在部分时间重叠的活动，请修改',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: '预售数量只能是大于0的正整数',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: '预计发货时间需要大于活动结束时间',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: '尾款截止时间需要小于预计发货时间',
        PromotionID: '促销活动ID',
        DistributionPromotionID: '分销活动ID',
        CouponID: '优惠券ID',
        PromotionManagementID: '活动管理ID',
        PortfolioManagementID: '组合管理ID',
        ExportForFiltering: '导出筛选',
        ListDisplay: '列表展示',
        InventoryItems: '库存商品',
        InStock: '有库存',
        OutOfStock: '无库存',
        FieldName: '字段名称',
        PleaseSelectInventory: '请选择库存',
        PreSaleEvent: '预售活动',
        PerPage: '每页展示',

        // V1.3.3 end

        //V1.3.4 start

        PleaseProvideTheNameOfTheLandingPageActivity: "请填写落地页活动名称",
        PleaseIndicateTheNumberOfActivities: "请填写活动数量",
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: "活动数量需要大于已赠数量",
        PleaseChooseATimeForTheEvent: "请选择活动时间",
        ActivityNameExceedsLength: "活动名称超过长度",
        ThisItemIsRestrictedToAMaximumOf10PerOrder: "该商品每单限购10个",
        PleaseChooseTheGiveawayAgain: "请重新选择赠品",
        AnalysisOfGiveawayActivities: "赠品活动分析",
        StatisticsOfGiveawayActivities: "赠品活动数据统计",
        PleaseEnterTheNameOfTheEvent: "请填写活动名称",
        PleaseSelectTheActivityType: "请选择活动方式",
        PleaseCompleteTheActivityRating: "请填写活动分级",
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: "请先配置{Any}的预售活动才可以配置预售商品上架国家",
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: "订单金额和赠品数量不能小于序号排在前的分级",
        SelectImage: "选择图片",
        GiftInformation: "赠品信息",
        Gifts: "全部赠品",
        GiftsOfEndOfEvent: "活动结束的赠品",
        CheckOutTheGifts: "查看赠品",
        GiveawayEvent: "赠品活动",
        SelectAGift: "选择赠品",
        YouHaveNotSelectedAGift: "您未选择赠品",
        NoNeedForGifts: "不需要赠品",
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: "存在部分赠品无库存，请更换赠品",
        ReplaceAGift: "更换赠品",
        ThereAreCurrently3FreeGiftsAvailable: "目前可免费领取3个赠品",
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: "目前可免费领取3个赠品，还差 99 € 可领取4个赠品",
        GiftList: "赠品列表",
        GiftID: "赠品ID",
        CurrentInventory: "当前库存",
        NotEnabled: "未启用",
        GoodsForFree: "赠送商品",
        ConfirmToDeleteThisGiftInformation: "确认删除该赠品信息？",
        GiftsSortingManagement: "赠品排序管理",
        GiftsTopicManagement: "赠品专题管理",
        GiveawayEventID: "赠品活动ID",
        GiveawayEventName: "赠品活动名称",
        ProductCategory: "商品品种",
        ActivityMode: "活动方式",
        TotalNumberOfActivities: "活动总数",
        GiftsGivenQuantity: "已赠数量",
        ThemeConfiguration: "专题配置 ",
        GiftSorting: "赠品排序",
        GlobalConfiguration: "全局配置",
        ActiveCountry: "活动国家",
        PromotionalPpicture: "宣传图",
        RecommendedSizeX: "推荐尺寸：{XXX*XXX}",
        FileSize1MB: "文件大小：≤1MB",
        ActivitySorting: "活动排序",
        DragToSort: "拖拽排序",
        ActivityCode: "活动编码",
        GiftsForOrderAmount: "订单金额赠送商品",
        ActivityRating: "活动分级",
        GiftsQuantity: "赠品数量",
        EventGiftsSorting: "活动赠品排序",
        DefaultSelectionOfUsers: "用户默认选择",
        LandingPageDisplay: "落地页展示",
        LandingPageActivityName: "落地页活动名称",
        ConfirmDeleteThisGiftEvent: "确认删除该赠品活动？",
        IsThereAGiftEvent: "是否赠品活动",
        OrderQuantity: "订单量",
        NumberOfGiftsIssued: "已发放赠品数",
        CustomerPrice: "客单价",
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: "预售活动时间至少大于2天，活动数量大于50 才可以设置虚拟活动数量功能",
        PreSaleSwitch: "预售开关",
        PreSaleActivity: "预售活动",
        GiftManagement: "赠品管理",


        //V1.3.4 end

        //V1.3.5 start 
        OrderSource: "订单来源",
        NumberOfNewUserRegistrations: "新用户注册量",
        RegisteredUsersWhoVsitTheWebsite: "访问网站的注册用户",
        SoldDistributionQuantity: "分销数量已售",
        DistributionQuantityLock: "分销数量锁定",
        InsufficientDstributionQuantityRemaining: "分销数量剩余不足",
        Previous: "上一篇",
        Next: "下一篇",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "食谱管理",
        RecipeDisplayImage: "食谱展示图",
        NewRecipeDisplayImage: "编辑/新建食谱展示图",
        CoverPicture: "封面图",
        PicturesVideos: "最多可上传10个图片+视频，图片每张≤5MB，视频支持.mp4格式",
        AddPicturesVideos: "添加图片/视频",
        CommenOnPicturesVideos: "评论图片/视频",
        FileSize: "文件大小",
        Account: "帐套",
        Account1: "账号",
        BarcodeAssociated: "已关联条码",
        FirstUploadTime: "首次上传时间",
        EditTime: "编辑时间",
        TagType: "标记类型",
        FileName1: "文件名称",
        NameLengthISTooLong: "名称长度太长",
        ProductDoesNoExist: "商品不存在",
        PleaseUploadImages: "请上传5MB以下的jpg/png/jpeg图片",
        PleaseUploadTheVideoMP4: "请上传格式是mp4视频",
        PictureOrVideo: "图片或视频不能为空",
        allowed: "允许所有",
        cookie1: "管理cookie",
        collectsordinances: "Aigostar使用 Cookie 来改善访客的浏览体验，并将其用于营销目的。在下方查看并管理您的 Cookie 设置。欲详细了解我们如何使用 Cookie，请查阅我们的隐私政策。",
        RequiredCookies: "必要Cookie",
        cookie2: "登录、使用购物车和支付等核心功能需要用到这些 Cookie。必要 Cookie 无法关闭。不过别担心，这些 Cookie 不会存储您的任何信息。",
        AnalyticsCookies: "营销和分析Cookie",
        cookie3: "这些 Cookie 收集的信息包括网站的访问次数，或者哪些页面比较受欢迎，以帮助我们改善客户体验。您可以选择关闭这些 Cookie，但这样我们将无法收集所需信息，改善您在我们网站上的体验。",
        StoragePreferences: "存储偏好设置",
        BuyItNow: "立即购买",
        PictureVideoSize: "图片、视频尺寸或者格式不对",
        RecipeConfiguration: "食谱配置",
        //V1.3.6 end


        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "确认同步子条码？",
        SynchronizationEbpEanSUB: "由于同步需要和EBP对接，批量同步预计3分钟内可同步完成。确定后同步为异步进行，获得子条码后立即显示，请耐心等候。",
        Keywords: "关键词",
        SystemAutoUpdate: "系统自动更新",
        IPAddress: "IP地址",
        SearchKeywords: "搜索关键词",
        LoginEmail: "登录邮箱",
        SearchTime: "搜索时间",
        NumbeOfTimes: "次数",
        MyBrowsing: "我的浏览",
        LoggingOuOfYouAccount: "注销账号后将视为您自行放弃且无法继续使用该账号",
        RequestLogout: "申请注销",
        ProductsYet: "你还没有浏览商品",
        clearall: "清空历史记录",
        CancellTip1: "购物车列表，订单购买记录，收藏记录信息将被永远删除且无法恢复",
        CancellTip2: "在平台的所有账户信息，服务记录，消费情况等信息将被永远删除且无法恢复",
        CancellTip3: "您将无法登录、使用本AigoSmart账号",
        CancellTip4: "您AigoSmart账号的个人资料和历史消息（包含头像、昵称、我的爱国币、平台推送、我的评论等）等都将无法找回",
        CancellTip5: "您通过此账号前需妥善处理账户资金（积分、会员等级、资格、订单、优惠券、道具及其他有价服务），注销账号后将视为您自行放弃且无法继续使用。您理解并同意，AigoSmart无法协助您恢复前述服务。",
        SynchronizeSubBarcodes: "同步子条码",
        PleaseEnterKeywords: "请输入关键词",
        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "按小时统计",
        NumbeOfUsers: "下单用户数",
        NumberOfCanceled: "取消订单数",
        RefundAmount: "退款金额",
        UsersWhoHavePaid: "已支付（可发货、已发货、已完成）下单的用户",
        TotalNumber: "平台产生取消的订单数量总和",
        AmountOfCanceled: "已支付未发货取消的订单金额、已支付（在途、已到货）退货的订单金额",
        RedemptionCode: "ID/优惠券名称/兑换码",
        DeleteAll: "删除",
        AccountIsUnderReview: "当前账号出于注销审核期，继续登录将撤销注销流程，是否继续使用？",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "时间不能为空",
        ProductImporTemplate: "商品导入模板",
        NormalOrdersExceed: "正常订单超过：",
        OrderSautomaticallyClosed: "未付款，订单自动关闭",
        OrdeIsShipped: "订单发货后：",
        AutomaticallyComplete: "自动完成订单",
        Minutes: "分",
        Days: "天",
        OrderSettings: "订单设置",
        ClearCache: "清除缓存",
        PleaseEnterNavigationContent: "请输入导航内容",
        WebsiteNavigationConfiguration: "网站导航配置",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "弹窗延迟时间",
        SubscriptionPopup: "订阅弹窗",
        MeansNoPopupDelay: "请输入弹窗延迟提示时间",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "UGC专区",

        //V1.5.0 end
        //V1.6.0 start
        uploaFilesInJpg: "只能上传jpg/png文件，且不超过2M",
        SynchronizeEbpPrice: "同步EBP售价",
        ConfirmToSynchronizeEbp: "确认同步EBP售价？",
        AsynchronousEbp: "确定后同步为异步进行，获得售价后立即显示，请耐心等候。",
        CopywritingDisplay: "展示文案",
        HomepageCopywriting: "首页文案",
        EditHomepageCopywriting: "编辑首页文案",
        SuperimposedEvents: "是否叠加活动",
        GiftsEvents: "（该活动的赠品不可以和其他活动叠加领取）",
        SmallGiftEvent: "小礼品活动",
        SpecialProductEvent: "特殊商品活动",
        SpecialGiftEvent: "（小礼品和特殊活动的赠品不能同时领取）",
        ProductsWithSKU: "请选择有SKU的商品",
        LimitWereSelected: "当前限制选择{limitNum}个文件，本次选择了{selectNum}个文件",
        FileUploadFailed: "文件上传失败！",
        CompleteMultiLanguage: "请完善多语言配置",
        FirstSynchronizeSKU: "请先同步该商品的SKU",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "是否公开优惠券",
        RedemptionCode1: "码",
        NowOnlyNeed: "现在只需 10 欧元",
        WebLogReporting: "web日志上报",
        PageAddress: "页面地址",
        ErrorLogs: "错误日志",
        CustomParameter: "自定义参数",
        ParameterID: "参数ID",
        // ParameterName: "参数名称",
        SearchParameter: "搜索参数",
        EnterParameterName: "请输入参数名称",
        ParameterType: "参数类型",
        AssociatedProductCount: "关联商品数",
        MandatoryPortugueseContent: "葡萄牙语内容必填",
        MandatoryEnglishContent: "英语内容必填",
        MandatoryDutchContent: "荷兰语内容必填",
        MandatoryPolishContent: "波兰语内容必填",
        MandatoryFrenchContent: "法语内容必填",
        MandatoryGermanContent: "德语内容必填",
        MandatoryItalianContent: "意大利语内容必填",
        MandatorySpanishContent: "西班牙语内容必填",
        MandatoryChineseContent: "汉语内容必填",
        ImportTemplate: "自定义参数商品导入模板",
        ConfirmImportFile: "是否确认导入文件, 是否继续",
        SelectDataRemove: "请选择要移除的数据",
        ImportTemplateDownload: "导入模板下载",
        AssociatedProducts: "关联商品",

        //V1.7.0 end

        //V1.8.0 start
        Published: "已发布",
        CanBeIgnored: "可忽略",
        Resolved: "已解决",
        ActivityType: "活动类型",
        PromotionActivity: "促销活动",
        DistributionActivity: "分销活动",
        InputLink: "输入链接",
        EnterAddress: "请输入链接地址",
        DetailPageAd: "详情页广告位",

        //V1.8.0 end

        //V1.9.0 start
        ProductPromotionID: "商品促销ID",
        PromotionalActivityCollection: "促销活动合集",
        PromotionalActivityName: "促销活动名称",
        ProductPromotionBarcode: "商品促销ID/条码",
        ProductPromotionIDCopy: "商品促销ID（双击可以复制所有ID）",
        ProductBarcode: "产品条码（双击可以复制所有条码）",
        SelectPromotionalActivity: "选择促销活动",
        SelectPromotionalList: "选择促销列表",
        TypesPerOrder: "单个订单限制购买种类数",
        PromotionalActivity: "请选择促销活动",
        ProductAlready: "该商品已有促销活动，请前往编辑",
        PromotionalActivityNameID: "促销活动名称/ID",
        PromotionalCollectionName: "促销合集名称",
        SelectedPromotionalActivity: "已选促销活动",
        PromotionalCollectionActivit: "已有促销合集活动，请前往编辑",

        EnterHholidayTheme: "请输入节日主题",
        HolidayTheme: "节日主题",
        HolidayImage: "节日图片",
        AddLogoImage: "添加Logo图片",
        PleaseProceedEdit: "请前往编辑",
        SKUBIDActivities: "SKU:{sku},在{country}下已有{activity}活动",
        LogImageManagement: "Logo图片管理",
        LogoManagement: "Logo管理",

//V1.9.0 end

    }
};

export default cn;